import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { Subject, combineLatest } from 'rxjs';
import { mergeMap, takeUntil, map, filter, distinctUntilChanged, switchMap, withLatestFrom } from 'rxjs/operators';
import keys from 'lodash-es/keys';
import orderBy from 'lodash-es/orderBy';
import { authActiveUserServicesActiveGrouped, identity, authActiveClientUser, activeManagedServicesAliases, authorType, cloudDnsRoleCode, authSlice } from '@app/base/auth-base';
import { clientServicesListEntities } from '@app/base/client-services-base';
import { ServicesTypes, ServiceCategory } from '@app/base/services-base';
import { SetTicketTriggerState, TicketTriggerOpen } from '@app/common/tickets-trigger';
import { currencyMap } from '@app/common/settings';
import { environment } from 'environments/environment';
import { toBoolean } from 'utils';
// Encoding UTF8 ⇢ base64
function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (_, p1) {
        return String.fromCharCode(parseInt(p1, 16));
    }));
}
var ServicesCardContainer = /** @class */ (function (_super) {
    tslib_1.__extends(ServicesCardContainer, _super);
    function ServicesCardContainer(_store) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        // # Event streams
        _this.onContactSupportForNewService$ = new Subject();
        // # Data
        // -- sync
        _this.serviceTypeExternalMap = keys(ServicesTypes).reduce(function (obj, key) {
            var item = ServicesTypes[key];
            obj[item.key] = item.type === ServiceCategory.External;
            return obj;
        }, {});
        _this.enableCDN = toBoolean(environment.enableCDN);
        _this.enableCloudMail = toBoolean(environment.enableCloudMail);
        // -- async
        _this.groupedServices$ = _this._store.pipe(select(authActiveUserServicesActiveGrouped));
        _this.activeManagedServices$ = _this._store.pipe(select(activeManagedServicesAliases), map(function (s) { return s.join(','); }));
        _this.graphsAndSettingsServices$ = _this.groupedServices$.pipe(map(function (groupedServices) { return groupedServices.filter(function (group) { return !_this.serviceTypeExternalMap[group.key] && group.key !== 'CDN'; }); }));
        _this.latestClientServices$ = _this._store.pipe(select(clientServicesListEntities), map(function (clientServices) {
            if (clientServices && clientServices.length) {
                var filteredClientServices = clientServices.filter(function (service) { return service.clientZoneStatus !== 'BEING_ACTIVATED'; });
                return orderBy(filteredClientServices, ['activationDate'], ['desc']).slice(0, 5);
            }
            return [];
        }));
        _this.currencyMap$ = _this._store.pipe(select(currencyMap));
        _this.clientUser$ = _this._store.pipe(select(authActiveClientUser), filter(function (user) { return !!user; }));
        _this.authorType$ = _this._store.pipe(select(authorType));
        _this.cloudDnsRoleCode$ = _this._store.pipe(select(cloudDnsRoleCode));
        _this.showCloudDns$ = combineLatest(_this.clientUser$, _this.authorType$, _this.cloudDnsRoleCode$).pipe(map(function (_a) {
            var clientUser = _a[0], author = _a[1], code = _a[2];
            return (clientUser.cloudDnsEnabled && author !== 'BACKOFFICE')
                || (clientUser.cloudDnsEnabled
                    && author === 'BACKOFFICE'
                    && (code === 'MODIFY_ALL_DNS' || code === 'MODIFY_CLIENT_DNS'));
        }));
        _this.cdnServices$ = _this.clientUser$.pipe(filter(function (d) { return !!d; }), switchMap(function (clientUser) { return _this._store.pipe(select(authSlice)).pipe(filter(function (d) { return !!d; }), map(function (auth) { return ({ clientUser: clientUser, auth: auth }); })); }), map(function (_a) {
            var clientUser = _a.clientUser, auth = _a.auth;
            return (!!clientUser.cdnEnabled
                && !!clientUser.client
                && !!clientUser.client.connectedServiceList
                && !!clientUser.client.connectedServiceList.length)
                && !!clientUser.client.connectedServiceList.filter(function (itm) { return itm.standardService && itm.standardService.isActive && itm.standardService.code === 'CDN'; }).length
                ? clientUser.client.connectedServiceList.map(function (itm) {
                    var path = auth.accessToken + "|" + auth.refreshToken + "|" + itm.id + "|" + clientUser.id;
                    var encodedPath = encodeURIComponent(b64EncodeUnicode(path));
                    return {
                        url: environment.cdnAuthRedirectUrl + "/" + encodedPath,
                        service: itm
                    };
                })
                : [];
        }), distinctUntilChanged());
        _this.showCloudMail$ = _this.clientUser$.pipe(map(function (clientUser) { return !!clientUser.cloudMailEnabled
            && !!clientUser.client
            && !!clientUser.client.connectedServiceList
            && !!clientUser.client.connectedServiceList.find(function (itm) { return itm.standardService && itm.standardService.isActive && itm.standardService.code === 'CLOUD_MAIL'; }); }), distinctUntilChanged());
        _this.cloudMailUrl$ = _this.showCloudMail$.pipe(filter(function (d) { return !!d; }), switchMap(function () { return _this._store.pipe(select(authSlice)); }), filter(function (d) { return !!d; }), withLatestFrom(_this.clientUser$), map(function (_a) {
            var d = _a[0], clientUser = _a[1];
            var service = clientUser.client.connectedServiceList.find(function (itm) { return itm.standardService && itm.standardService.isActive && itm.standardService.code === 'CLOUD_MAIL'; });
            var path = d.accessToken + "|" + service.id;
            var encodedPath = encodeURIComponent(b64EncodeUnicode(path));
            return environment.cloudMailAuthRedirectUrl + "/" + encodedPath;
        }));
        _this.showGraphAndSettings$ = combineLatest(_this.graphsAndSettingsServices$, _this.showCloudDns$, _this.showCloudMail$, _this.cdnServices$).pipe(map(function (_a) {
            var services = _a[0], showCloudDns = _a[1], showCloudMail = _a[2], cdnServices = _a[3];
            return showCloudDns
                || (cdnServices && cdnServices.length)
                || showCloudMail
                || (services && services.length);
        }));
        _this.languageId$ = _this._store.pipe(select(identity), map(function (user) { return user && user.language
            ? user.language.id
            : undefined; }));
        // -- angular
        _this.contentUpdated = new EventEmitter();
        _this.contentClicked = new EventEmitter();
        // # Action streams
        _this._contactSupportForNewServiceAction$ = _this.onContactSupportForNewService$.pipe(mergeMap(function () { return [
            new SetTicketTriggerState({
                state: 1,
                meta: 'SERVICE_NEW'
            }),
            new TicketTriggerOpen()
        ]; }));
        // emit that content changed so menu can resize the pop
        combineLatest(_this.latestClientServices$, _this.groupedServices$)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(function () { return _this.contentUpdated.emit(); });
        // # Store Dispatcher
        _this._contactSupportForNewServiceAction$
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    ServicesCardContainer.prototype._trackBy = function (index) {
        return index;
    };
    return ServicesCardContainer;
}(BaseClass));
export { ServicesCardContainer };
