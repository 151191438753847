import {
  USER_INFO_FORM_DEFAULT_TRANSLATION_KEYS as UIF
} from './modules/common/user-info-form';
import {
  PASSWORD_FORM_DEFAULT_TRANSLATION_KEYS as PF
} from './modules/common/password-form';
import {
  USER_CARD_DEFAULT_TRANSLATION_KEYS as UC
} from './modules/common/user-card';
import {
  TICKET_TEXT_FORM_DEFAULT_TRANSLATION_KEYS as TT
} from './modules/common/ticket-text-form';
import {
  PRICE_OFFER_REJECT_FORM_DEFAULT_TRANSLATION_KEYS as PO
} from './modules/common/ticket-price-offer';
import {
  REALIZATION_DATE_REJECT_FORM_DEFAULT_TRANSLATION_KEYS as RD
} from './modules/common/ticket-realization-date';
import {
  emailFormGenerateTranslationKeys as efg
} from './modules/common/email-form';
import { ServerIntervalTypes } from '@app/base/servers-base';

const ticketTextFormDefault = {
  en: {
    [TT.placeholders.ticketMessage]: `What can we do for you?`,
  },
  cs: {
    [TT.placeholders.ticketMessage]: `Co pro vás můžeme udělat?`,
  },
  hu: {
    [TT.placeholders.ticketMessage]: `Mit tehetünk önért?`,
  },
  de: {
    [TT.placeholders.ticketMessage]: `Was können wir für Sie tun?`,
  }
};

const priceOfferRejectFormDefault = {
  en: {
    [PO.labels.messageText]: `Message text`,
  },
  cs: {
    [PO.labels.messageText]: `Text zprávy`,
  },
  hu: {
    [PO.labels.messageText]: `Az üzenet szövege`,
  },
  de: {
    [PO.labels.messageText]: `Text der Nachricht`,
  }
};

const realizationDateRejectFormDefault = {
  en: {
    [RD.labels.messageText]: `Message text`,
  },
  cs: {
    [RD.labels.messageText]: `Text zprávy`,
  },
  hu: {
    [RD.labels.messageText]: `Az üzenet szövege`,
  },
  de: {
    [RD.labels.messageText]: `Text der Nachricht`,
  }
};

const userInfoFormDefault = {
  en: {
    [UIF.labels.firstName]: 'First Name',
    [UIF.labels.lastName]: 'Last Name',
    [UIF.labels.phoneNumber]: 'Phone Number',
    [UIF.labels.smsNotifications]: 'Send important events SMS notifications',
    [UIF.labels.language]: 'Language',
    [UIF.errors.firstName.required]: 'First Name has to be filled in',
    [UIF.errors.lastName.required]: 'Last Name has to be filled in',
    [UIF.errors.phoneNumber.required]: 'Phone Number has to be filled in'
  },
  cs: {
    [UIF.labels.firstName]: 'Jméno',
    [UIF.labels.lastName]: 'Příjmení',
    [UIF.labels.phoneNumber]: 'Telefonní číslo',
    [UIF.labels.smsNotifications]: 'Posílat SMS při důležitých událostech',
    [UIF.labels.language]: 'Jazyk',
    [UIF.errors.firstName.required]: 'Jméno musí být vyplněno.',
    [UIF.errors.lastName.required]: 'Příjmení musí být vyplněno.',
    [UIF.errors.phoneNumber.required]: 'Telefonní číslo musí být vyplněno.'
  },
  hu: {
    [UIF.labels.firstName]: 'Név',
    [UIF.labels.lastName]: 'Vezetéknév',
    [UIF.labels.phoneNumber]: 'Telefonszám',
    [UIF.labels.smsNotifications]: 'SMS küldése fontos eseményeknél',
    [UIF.labels.language]: 'Nyelv',
    [UIF.errors.firstName.required]: 'A nevet ki kell tölteni',
    [UIF.errors.lastName.required]: 'A vezetéknevet ki kell tölteni.',
    [UIF.errors.phoneNumber.required]: 'A telefonszámot ki kell tölteni.'
  },
  de: {
    [UIF.labels.firstName]: 'Vorname',
    [UIF.labels.lastName]: 'Nachname',
    [UIF.labels.phoneNumber]: 'Telefonnummer',
    [UIF.labels.smsNotifications]: 'SMS bei wichtigen Ereignissen senden',
    [UIF.labels.language]: 'Sprache',
    [UIF.errors.firstName.required]: 'Der Vorname muss ausgefüllt werden.',
    [UIF.errors.lastName.required]: 'Der Nachname muss ausgefüllt werden.',
    [UIF.errors.phoneNumber.required]: 'Die Telefonnummer muss ausgefüllt werden.',
  }
};

const passwordFormDefault = {
  en: {
    [PF.labels.password]: 'Password',
    [PF.errors.password.required]: 'Password is required.'
  },
  cs: {
    [PF.labels.password]: 'Heslo',
    [PF.errors.password.required]: 'Heslo musí být vyplněno.'
  },
  hu: {
    [PF.labels.password]: 'Jelszó',
    [PF.errors.password.required]: 'Jelszót kell kitölteni.'
  },
  de: {
    [PF.labels.password]: 'Passwort:',
    [PF.errors.password.required]: 'Das Passwort muss ausgefüllt werden.',
  }
};

const userCardDefault = {
  en: {
    [UC.tags.accessToClientZone]: 'Access to CZ',
    [UC.tags.authorizedToSendTickets]: 'Support contact rights',
    [UC.tags.cloudDnsEnabled]: 'Managing CloudDNS',
    [UC.tags.cdnEnabled]: 'Managing CDN',
    [UC.tags.cloudMailEnabled]: 'Managing CloudMail',
  },
  cs: {
    [UC.tags.accessToClientZone]: 'Přístup do KZ',
    [UC.tags.authorizedToSendTickets]: 'Zakládání požadavků',
    [UC.tags.cloudDnsEnabled]: 'Přístup do CloudDNS',
    [UC.tags.cdnEnabled]: 'Přístup do CDN',
    [UC.tags.cloudMailEnabled]: 'Přístup do CloudMail'
  },
  hu: {
    [UC.tags.accessToClientZone]: 'Hozzáférés az ügyfélzónába',
    [UC.tags.authorizedToSendTickets]: 'Követelmény létrehozása',
    [UC.tags.cloudDnsEnabled]: 'Hozzáférés a CloudDNS-hez',
    [UC.tags.cdnEnabled]: 'A CDN kezelése',
    [UC.tags.cloudMailEnabled]: 'A CloudMail kezelése',
  },
  de: {
    [UC.tags.accessToClientZone]: 'Zugang zum KB',
    [UC.tags.authorizedToSendTickets]: 'Erstellen von Anfragen',
    [UC.tags.cloudDnsEnabled]: 'Zugang zu CloudDNS'
  }
};

import {
  TICKET_ADD_FORM_DEFAULT_TRANSLATION_KEYS as TAF
} from '@app/common/ticket-add-form';

const ticketAddForm = {
  cs: {
    [TAF.labels.title]: 'Předmět',
    [TAF.hints.title]: 'Výstižný název požadavku.',
    [TAF.errors.title.required]: 'Předmět musí být vyplněn',
    [TAF.labels.ticketMessage]: 'Obsah požadavku',
    [TAF.hints.ticketMessage]: 'Zevrubný popis vašeho požadavku.',
    [TAF.errors.ticketMessage.required]: 'Obsah požadavku musí být vyplněn.'
  },
  en: {
    [TAF.labels.title]: 'Subject',
    [TAF.hints.title]: 'Short description of the subject.',
    [TAF.errors.title.required]: 'Subject is required',
    [TAF.labels.ticketMessage]: 'Request summary',
    [TAF.hints.ticketMessage]: 'Extensive description of your request.',
    [TAF.errors.ticketMessage.required]: 'Summary is required.'
  },
  hu: {
    [TAF.labels.title]: 'Tárgy',
    [TAF.hints.title]: 'Az igény rövid elnevezése.',
    [TAF.errors.title.required]: 'A tárgyat ki kell tölteni',
    [TAF.labels.ticketMessage]: 'Az igény tartalma',
    [TAF.hints.ticketMessage]: 'Az igény rövid leírása.',
    [TAF.errors.ticketMessage.required]: 'Az igény tartalmát ki kell tölteni.'
  },
  de: {
    [TAF.labels.title]: 'Betreff',
    [TAF.hints.title]: 'Ein kurzer Titel für die Anfrage.',
    [TAF.errors.title.required]: 'Betreff muss ausgefüllt werden.',
    [TAF.labels.ticketMessage]: 'Inhalt der Anfrage',
    [TAF.hints.ticketMessage]: 'Eine detaillierte Beschreibung Ihrer Anfrage.',
    [TAF.errors.ticketMessage.required]: 'Inhalt der Anfrage muss ausgefüllt werden.',
  }
};

const COMMON = {
  en: {
    common: {
      close: 'Close',
      retry: 'Retry',
      delete: 'Delete',
      confirm: 'Confirm',
      takeBack: 'Take back',
      showMore: 'Show more',
      hideMore: 'Hide more',
      selectAll: 'Select all',
      requestChanges: 'Request details changes',
      companyTeam: 'VSH team',
      created: 'Created',
      dueDate: 'Due date',
      codename: 'your codename',
      paid: 'Paid',
      and: 'and',
      or: 'or',
      copyToUrl: 'Copy URL to clipboard.',
      openInNew: 'Open in new tab or window',
      copied: 'URL copied to the clipboard.',
      copiedDomainRecord: 'Record copied to the clipboard.',
      copiedDnssecKey: 'Signature key copied to the clipboard',
      kvmConsole: 'Active KVM connections',
      roles: {
        MANAGER: 'Administrator',
        TECHNICAL_USER: 'Technical user',
        FINANCIAL_USER: 'Financial user'
      },
      contactUs: 'Contact us',
      files: {
        maxCount: 'Maximum allowed number of files reached.',
        maxSize: 'Maximum allowed files size reached.',
        fileSize: 'Size: <strong>{size}</strong>'
      },
      lastUpdate: 'Last update',
      TODO: 'TODO TRANSLATION',
      support: 'Support',
      monthly: 'monthly',
      true: 'yes',
      false: 'no'
    }
  },
  cs: {
    common: {
      close: 'Zavřít',
      retry: 'Opakovat',
      delete: 'Smazat',
      confirm: 'Potvrdit',
      takeBack: 'Vzít zpět',
      showMore: 'Zobrazit více',
      hideMore: 'Skrýt více',
      selectAll: 'Vybrat vše',
      requestChanges: 'Požádat o změnu údajů',
      companyTeam: 'VSH tým',
      created: 'Vytvořeno',
      dueDate: 'Splatnost',
      codename: 'vaše označení ',
      paid: 'Uhrazeno',
      or: 'nebo',
      copyToUrl: 'Zkopírovat URL do schránky.',
      openInNew: 'Otevřít v novém okně či záložce',
      copied: 'URL zkopírována do schránky.',
      copiedDomainRecord: 'Záznam zkopírován do clipboardu.',
      copiedDnssecKey: 'Podpis zkopírován do clipboardu',
      kvmConsole: 'Aktivní spojení s KVM',
      roles: {
        MANAGER: 'Administrátor',
        TECHNICAL_USER: 'Technický správce',
        FINANCIAL_USER: 'Ekonomický správce'
      },
      files: {
        maxCount: 'Dosažen maximální povolený počet souborů.',
        maxSize: 'Maximální velikost vybraných souborů dosažena.',
        fileSize: 'Velikost: <strong>{size}</strong>'
      },
      lastUpdate: 'Poslední změna',
      contactUs: 'Kontaktujte nás',
      TODO: 'TODO PŘEKLAD',
      support: 'Podpora',
      monthly: 'měsíčně',
      true: 'ano',
      false: 'ne'
    }
  },
  hu: {
    common: {
      close: 'Bezárás',
      retry: 'Ismétlés',
      delete: 'Törlés',
      confirm: 'Visszaigazolás',
      takeBack: 'Visszavonás',
      showMore: 'Több mutatása',
      hideMore: 'Továbbiak elrejtése',
      selectAll: 'Összes kijelölése',
      requestChanges: 'Adatok módosításának kérelme',
      companyTeam: 'VSH csapat',
      created: 'Létrehozva',
      dueDate: 'Esedékesség',
      codename: 'az Ön megjelölése',
      paid: 'Fizetve',
      or: 'vagy',
      copyToUrl: 'URL másolása a fiókba',
      openInNew: 'Megnyitás új ablakban vagy fülön',
      copied: 'URL másolva a fiókba.',
      copiedDomainRecord: 'Bejegyzés a vágólapra másolva.',
      copiedDnssecKey: 'Aláírás a vágólapra másolva',
      kvmConsole: 'Aktív kapcsolat a KVM-mel',
      roles: {
        MANAGER: 'Rendszergazda',
        TECHNICAL_USER: 'Műszaki rendszergazda',
        FINANCIAL_USER: 'Gazdasági rendszergazda'
      },
      files: {
        maxCount: 'Elérte a maximálisan engedélyezett fájlok számát.',
        maxSize: 'Elérte a kiválasztott fájlok maximális méretét.',
        fileSize: 'Méret: <strong>{size}</strong>'
      },
      lastUpdate: 'Utolsó módosítás',
      contactUs: 'Lépjen kapcsolatba velünk',
      TODO: 'TODO FORDÍTÁS',
      support: `Támogatás`,
      monthly: `havonta`,
      true: `igen`,
      false: `nem`,
    }
  },
  de: {
    common: {
      close: 'Schließen',
      retry: 'Wiederholen',
      delete: 'Löschen',
      confirm: 'Bestätigen',
      takeBack: 'Rückgängig machen',
      showMore: 'Mehr anzeigen',
      hideMore: 'Mehr ausblenden',
      selectAll: 'Alle auswählen',
      requestChanges: 'Änderungen der Daten anfordern',
      companyTeam: 'VSH Team',
      created: 'Erstellt',
      dueDate: 'Fälligkeit',
      codename: 'Ihre Bezeichnung',
      paid: 'Bezahlt',
      or: 'oder',
      copyToUrl: 'Die URL in die Zwischenablage kopieren.',
      openInNew: 'In einem neuen Fenster oder Tab öffnen',
      copied: 'URL in die Zwischenablage kopiert.',
      copiedDomainRecord: 'Eintrag in die Zwischenablage kopiert.',
      copiedDnssecKey: 'Unterschrift in die Zwischenablage kopiert.',
      kvmConsole: 'Aktive Verbindung zu KVM',
      roles: {
        MANAGER: 'Administrator',
        TECHNICAL_USER: 'Technischer Administrator',
        FINANCIAL_USER: 'Finanzadministrator',
      },
      files: {
        maxCount: 'Die maximal zulässige Anzahl von Dateien erreicht.',
        maxSize: 'Maximale Größe der ausgewählten Dateien erreicht.',
        fileSize: 'Größe: <strong>{size}</strong>'
      },
      lastUpdate: 'Letzte Änderung',
      contactUs: 'Kontaktieren Sie uns',
      TODO: 'TODO ÜBERSETZUNG',
      support: 'Support',
      monthly: 'monatlich',
      true: 'ja',
      false: 'nein'
    }
  }
};

const API_ERROR_CODES = {
  en: {
    error: {
      400: `Invalid user input`,
      401: `Not authorized`,
      402: `Insufficient privileges`,
      403: `Refresh token invalid or expired`,
      404: `Endpoint not found`,
      405: `Potentially fraud behaviour detected. Please provide additional validation by Google reCaptcha.`,
      406: `Google reCaptcha response invalid`,
      407: `Download token invalid or expired`,
      408: `Invalid URL address Format`,
      409: ``,
      410: ``,
      411: `No rights to selected action.`,
      412: `File size limit 4MB exceeded.`,
      413: `Additional validation failed.`,
      414: `This action is not allowed for VSHosting users.`,
      500: `Internal server error.`,
      2000: `Incorrect credentials.`,
      2001: `Incorrect user's credentials.`,
      2002: `Not valid clientUserId.`,
      2003: `Your temporary token for password recovery has expired. Please recover password again.`,
      2004: `Token does not found.`,
      2005: `Password too weak or too long.`,
      2006: `Invalid IP address.`,
      2007: `User has no active connection to any client.`,
      2008: `Your temporary token has expired.`,
      2009: `You do not have permission for the action you want. Permissions may have changed.`,
      2010: `You don't have access to the client zone. Please contact your VSHosting account administrator.`,
      2011: `PTR record is invalid. Please use a fully qualified domain name (FQDN) like admin.vshosting.cloud`,
      2012: `Login with email and password has been deactivated. Please use another login method.`,
      2013: `Login with email and password has been deactivated. For this reason, the recover forgotten password
      function is not available. Please use another login method.`,
      2101: `User account does not exists.`,
      2102: `User account already exists.`,
      2103: `Language does not exists.`,
      2104: `Country calling code does not exists.`,
      2105: `Template does not exists.`,
      2106: `Country calling code does not match the phone number.`,
      2107: `Invalid phone number.`,
      2108: `Invalid user role.`,
      2109: `Client does not found.`,
      2110: `Invalid client and user group combination.`,
      2111: `User with email already exists.`,
      2112: `User with same email is already connected to {name}.`,
      2113: `Message type does not exists.`,
      2114: `Not valid meta atributes.`,
      2115: `Client already activated.`,
      2116: `Token is not valid UUID.`,
      2117: `Invalid email pattern.`,
      2118: `Invalid client pattern.`,
      2119: `invalid userId pattern.`,
      2120: `Country does not exists.`,
      2121: `The operation could not be performed because the entered data is not valid.`,
      2122: `Record does not found.`,
      2123: `Exceeded the maximum number of users on the client (70).`,
      2124: `Invalid PIN pattern.`,
      2125: `Invalid ticket priority ID.`,
      2126: `Invalid Ticket Topic ID`,
      2127: `Invalid application ID.`,
      2128: `Invalid rating type.`,
      2129: `Ticket is not resolved or closed. Rating is denied till the ticket is resolved.`,
      2130: `No data found.`,
      2131: `User is not in BEING DELETED status.`,
      2132: `Ticket is authorized already.`,
      2133: `Unable to delete your own account.`,
      2134: `Client first user have to be MANAGER.`,
      2135: `User's email can only be edited within the first 24 hours of its creation.`,
      2136: `Not valid postal address.`,
      2137: `Invoice does not exist.`,
      2138: `Server does not exists.`,
      2139: `IP address already used.`,
      2140: `Ip addres not in client IP address range.`,
      2141: `Unable to set PTR. IP address is not connected to any server.`,
      2142: `Server Park access does not found.`,
      2143: `At least one connected user in the role of "MANAGER" is required for each active client.`,
      2144: `Active user must be connected to at least one client.`,
      2145: `User does not have permission to receive secure communication.`,
      2146: `No free IPv4 Address.`,
      2147: `IP address is reserved and cannot be assigned to a server.`,
      2148: `Ticket not found.`,
      2149: `Ticket attachment not found.`,
      2150: `Support department not found.`,
      2151: `Your e-mail is not in VSHosting clients' contact list. Your message has been denied.`,
      2152: `Creating tickets by e-mail feature has been disabled for your account. You can enable it at VSHosting clients' zone.`,
      2153: `Unknown ticket mailbox.`,
      2154: `Ticket is closed. Further messages cannot be added.`,
      2155: `External administrator is not allowed to create tickets.`,
      2156: `Ticket rating has been sent already.`,
      2157: `Rating is allowed for tickets from clients only.`,
      2158: `Message is required for bad rating.`,
      2159: `Ticket does not belong to the client.`,
      2160: `Administrator is not allowed to create tickets.`,
      2161: `Administrator is only allowed to reply to internal tickets he created.`,
      2162: `Ticket is closed and cannot be modified.`,
      2163: `Ticket rejected due to duplicate checksum.`,
      2164: `Creating tickets by e-mail is not available. Please, create a ticket at the SnackHost dashboard.`,
      2165: `Conflict with country VAT change process. Try again later.`,
      2166: `Conflict with billing in process. Try again later.`,
      2167: `ClientUser not found or not active.`,
      2168: `User is not authorized to send or edit client's tickets.`,
      2169: `Client user connection not found.`,
      2170: `Authorization token is invalid or expired.`,
      2171: `PTR record cannot be set. IP address is not assigned to any server.`,
      2172: `Payment not found.`,
      2173: `Amount of requested records is out of limit.`,
      2174: `Invalid file type.`,
      2175: `Server graph does not exist.`,
      2176: `Bad or expired activation link.`,
      2177: `This person has access to the ServerPark already.`,
      2178: `Please provide exactly 1 file for upload, sent via form-data.`,
      2179: `Internal server error.`,
      2180: `Date of visit have to be in future.`,
      2181: `Unable to edit deleted user.`,
      2182: `Invalid identity document number.`,
      2183: `Birthdate have to be in past.`,
      2184: `Bad or expired link.`,
      2185: `User does not have CloudDNS access enabled for this client.`,
      2186: `Ticket was not send from email connected to logged user.`,
      2190: `This e-mail is already used for VSHosting administrator account. Use another e-mail.`,
      2194: `This ticket has no price offer.`,
      2195: `A new message has been added to the ticket. Please read it first before you confirm the price offer.`,
      2196: `The price offer has already been approved.`,
      2197: `Ticket has no realization date waiting for confirmation.`,
      2198: `Realization date cannot be confirmed because is already in the past.`,
      2199: `Realization date has already been confirmed.`,
      2200: `The user is the authorised person of the master agreement. Before changing the role, the user must be removed
      from the list of authorized persons. Please contact VSHosting support.`,
      2201: `The user is the authorised person of the master agreement. Before deleting, the user must be removed from the
      list of authorized persons. Please contact VSHosting support.`,
      2501: `Unspecified Error.`,
      2502: `Action is temporarily blocked by another conflicting operation.`,
      2503: `Error sending email.`,
      2504: `Unable to login user automatically.`,
      2505: `Failed to change client PIN`,
      4101: `Client not found.`,
      4102: `Invalid domain name.`,
      4103: `Domain name is too long.`,
      4104: `Only second level domain names and first level before public suffix domain names are allowed.`,
      4105: `Domain is deleted. No changes of domain records are allowed. Please recover the domain first.`,
      4106: `Domain is deactivated. No changes of domain records are allowed. Please activate the domain first.`,
      4107: `Only VSHosting administrator can perform this action.`,
      4108: `Domain not found.`,
      4109: `Domain record not found.`,
      4110: `Domain version not found.`,
      4111: `Domain log not found.`,
      4112: `The domain already exists in your domain list.`,
      4113: `The domain is already used by another client. If you are the owner of the domain, please contact
        <a class="__link" href="/tickets/new/MANAGED_SERVICES_ISSUE?title=Domain ownership">VSHosting support</a>.
      `,
      4114: `Domain record is not deleted.`,
      4115: `This domain record is part of an outdated domain change and cannot be recovered. Please Refresh domain record list.`,
      4116: `The domain has already been recovered.`,
      4117: `Unable to reset domain changes, which are publicated or being publicated.`,
      4118: `Unpublished and deleted domain cannot be deactivated.`,
      4119: `Deactivated domain required.`,
      4120: `Only ASCII or A-label form domain names are allowed.`,
      4121: `Unable to activate DNSSEC on domain.`,
      4122: `Unable to deactivate DNSSEC on domain.`,
      4123: `DNSSEC is already enabled.`,
      4124: `DNSSEC is already disabled.`,
      4125: `Unable to publish DNSSEC public key.`,
      4126: `Unable to revoke DNSSEC public key.`,
      4127: `Error reading DNSSEC public key.`,
      4128: `DNSSEC can be disabled after one hour since DNSSEC was enabled.`,
      4129: `DNSSEC cannot be disabled until its public key is deleted at the domain registrar.`,
      4130: `Domain and registrator DNSSEC public key mismatch.`,
      4131: `Unsupported domain record type.`,
      4132: `DNSSEC public key cannot be published before the DNSSEC is activated.`,
      4133: `The domain is being modified. Please try again later.`,
      4134: `Unsupported TTL Value. Allowed range is from 60 to 86400 seconds.`,
      4135: `This domain record is part of an outdated domain change and cannot be modified. Please Refresh domain record list.`,
      4136: `The identical domain record already exists. Duplicates are not allowed.`,
      4137: ``,
      4138: `The domain record cannot be recovered due to a duplicate record.`,
      4139: `The identical domain record has been deleted. Please use recover function instead of adding a new domain record.`,
      4140: `There are no changes in domain records. Nothing to publish.`,
      4141: `Domain record limit exceeded. Please contact vshosting~ support.`,
      4142: `Invalid characters in record name. Valid hostname required.`,
      4143: `Invalid record name. Valid hostname required.`,
      4144: `Only ASCII chars are allowed. Non-ASCII characters must be encoded in IDN format.`,
      4145: `Invalid domain record length. Allowed range is between 1 and 1024 characters.`,
      4146: `This domain is managed by an external application and cannot be modified in CloudDNS.`,
      4147: `Account domain count limit exceeded. Please contact VSHosting support.`,
      4148: `Active client is required to add a domain.`,
      4149: `Domain already exist in PowerDNS as a no-master zone. Please contact VSHosting support.`,
      4150: `The domain is blacklisted and can not be deleted or deactivated.`,
      4151: `The domain is blacklisted and cannot be modified by an external application.`,
      4152: `Inserted domain record is in conflict with existing CNAME record with the same name.`,
      4153: `Inserted domain record is in conflict with existing record with the same name.`,
      4154: `The domain is managed by CloudDNS and cannot be modified by an external application.
        Please delete the domain in CloudDNS first or contact VSHosting support.`,
      4155: `The domain is managed by another external application. You have to delete the domain in the application first.`,
      4501: `Internal server error. Checksum control failed.`,
      4502: `The domain is temporarily locked by another conflicting action. Please try again later.`,
      4503: `Client temporary not available. Please try again later.`,
      4504: `DnsClientApp temporary not available. Please try again later.`,
      4505: `DNSSEC activation failed.`,
      4506: `DNSSEC deactivation failed.`,
      4507: `DNSSEC public key publication failed.`,
      4508: `DNSSEC public key revocation failed.`,
      4509: `Loading of public DNSSEC key failed.`,

      5001: `This action is not allowed for VSHosting users.`,
      5002: `For security reasons, please login again before updating your login options.`,
      5003: `MojeID authentication token has expired. Please login to your MojeID account again.`,
      5004: `Verification of your MojeID account has failed. Please login to your MojeID account again.
      If problems persist, please contact VSHosting support.`,
      5005: `There is no VSHosting account linked to this MojeID account. Login with your email and password
      first and connect your MojeID account to your VSHosting account.`,
      5006: `Google authentication token has expired. Please login to your Google account again.`,
      5007: `Verification of your Google account has failed. Please login to your Google account again. If problems
      persist, please contact VSHosting support.`,
      5008: `There is no VSHosting account linked to this Google account. Login with your email and password first and
      connect your Google account to your VSHosting account.`,
      5009: `Your MojeID account is already connected to another VSHosting user account. Please use another MojeID account.`,
      5010: `Your Google account is already connected to another VSHosting user account. Please login to another Google account.`,
      5011: `At least one login method is required.`,
      5012: `Verification with your Apple ID has failed. Please login with your Apple ID again. If problems persist,
      please contact VSHosting support.`,
      5013: `There is no VSHosting account linked to this Apple ID. Login with your email and password first
      and connect your Apple ID to your VSHosting account.`,
      5014: `Your Apple ID is already connected to another VSHosting user account. Please login with another Apple ID.`,
      5015: `Apple ID authentication token has expired. Please login with your Apple ID again.`,

      5101: `Internal server error.`,
      5102: `Internal server error.`,
      5103: `Internal server error.`,
      5104: `Internal server error.`,
      5105: `Internal server error.`,
      5106: `Internal server error.`,
      5107: `Internal server error.`,
      5108: `Internal server error.`,
      5109: `Internal server error.`,
      5110: `This domain is blacklisted and can not be deleted.`,
      5111: `Internal server error.`,
      5112: `Internal server error.`,
      5113: `Internal server error.`,
      5114: `Internal server error.`,
      5115: `Internal server error.`,
      5116: `Internal server error.`,
      5117: `Internal server error.`,
      5118: `Internal server error.`,
      5119: `Internal server error.`,
      5500: `Internal system error.`,
      6101: `Clients domain limit have to be a positive number.`,
      6102: `Clients domain record limit have to be a positive number.`,
      6103: `Domain not found.`,
      6104: `Domain log not found.`,
      6105: `Client not found.`,
      6106: `Client DNS App not found.`,
      6107: `Action log record not found.`,
      6108: `Change log record not found.`,
      6109: `Unable to import domain existing in CloudDNS.`,
      6110: `Function disabled in application config.`
    }
  },
  cs: {
    error: {
      400: `Zadané údaje jsou neplatné`,
      401: `K provedení akce je vyžadováno přihlášení`,
      402: `Nemáte dostatečná oprávnění na provedení činnosti.`,
      403: `Refresh token je neplatný nebo vypršela jeho platnost`,
      404: `Endpoint nenalezen`,
      405: `Bylo zjištěno potenciálně podvodné chování. Proveďte prosím ověření službou Google reCaptcha.`,
      406: `Neplatná odpověď Google reCaptcha`,
      407: `Token pro download je neplatný nebo expirovaný`,
      408: `Neplatný formát URL adresy`,
      409: ``,
      410: ``,
      411: `Nemáte práva k provedení vybrané akce.`,
      412: `Překročena max. velikost souboru 4MB pro upload.`,
      413: `Neočekávaná chyba aplikace. Opakuj prosím akci znovu. Pokud potíže přetrvají, nahlaš chybu vývojářům.`,
      414: `Tato akce není povolena pro uživatele VSHosting.`,
      500: `Interní chyba systému.`,
      2000: `Chybné přihlašovací údaje.`,
      2001: `Neplatné uživatelské údaje.`,
      2002: `Neplatná identifikace uživatele.`,
      2003: `Časový úsek na změnu hesla vypršel, zažádejte prosím o změnu hesla znovu.`,
      2004: `Token neodpovídá žádnému záznamu.`,
      2005: `Zadané uživatelské heslo nesplňuje bezpečnostní požadavky. Heslo musí obsahovat nejméně
        8 znaků bez slov ze slovníku nebo alespoň 12 znaků. Zároveň nesmí být delší než 72 znaků.`,
      2006: `Neplatná IP adresa.`,
      2007: `Váš uživatelský profil není v tuto chvíli aktivní. Pro jeho aktivaci prosím
        kontaktujte administrátora Vašeho klientského účtu "VSHosting."`,
      2008: `Operaci nebylo možné provést, protože vyhrazený časový úsek již vypršel.`,
      2009: `Na požadovanou akci nemáte oprávnění. Pravděpodobně došlo ke změně nastavení práv.`,
      2010: `Nemáte práva na přístup do klientské zóny. Kontaktujte prosím správce účtu VSHosting.`,
      2011: `Zadaný PTR záznam není platný. Zadejte jej ve formátu FQDN, např. admin.vshosting.cloud`,
      2012: `Přihlášení pomocí e-mailu a hesla bylo deaktivováno. Použijte prosím jinou metodu přihlášení.`,
      2013: `Přihlášení pomocí e-mailu a hesla bylo deaktivováno. Z tohoto důvodu není k dispozici funkce
      obnovy zapomenutého hesla. Použijte prosím jinou metodu přihlášení.`,
      2101: `Uživatelský účet neexistuje.`,
      2102: `Uživatelský účet již existuje.`,
      2103: `Zadaný kód jazyka je neplatný.`,
      2104: `Telefonní předvolba státu je neplatná.`,
      2105: `Neplatná šablona.`,
      2106: `Telefonní předvolba neodpovídá telefonnímu číslu.`,
      2107: `Neplatné telefonní číslo.`,
      2108: `Neplatná role uživatele.`,
      2109: `Klient nenalezen.`,
      2110: `Neplatná kombinace klienta a skupiny uživatele.`,
      2111: `Uživatel se zadaným e-mailem nebo telefonem již existuje.`,
      2112: `Uživatel se shodným emailem je již připojen ke klientovi {name}.`,
      2113: `Zadaný typ zprávy neexistuje.`,
      2114: `Předané parametry jsou neplatné.`,
      2115: `Klient je již aktivovaný.`,
      2116: `Token má nesprávný tvar..`,
      2117: `Neplatný tvar emailu.`,
      2118: `Neplatný tvar klientského čísla.`,
      2119: `Neplatný tvar čísla uživatele.`,
      2120: `Zadaný kód země je neplatný.`,
      2121: `Operaci nebylo možné provést, protože zadané údaje nejsou platné.`,
      2122: `Záznam nenalezen.`,
      2123: `Byl překročen maximální počet uživatelů u klienta (70).`,
      2124: `Neplatný tvar PINu.`,
      2125: `Neplatný typ priority požadavku.`,
      2126: `Neplatný typ požadavku.`,
      2127: `Neplatný typ aplikace.`,
      2128: `Neplatný typ hodnocení.`,
      2129: `Požadavek není vyřešený nebo uzavřený. Hodnotit je možné až po uzavření požadavku.`,
      2130: `Nenalezena žádná data.`,
      2131: `Uživatel není ve stavu "připraven na smazání".`,
      2132: `Požadavek již byl smazán.`,
      2133: `Nelze smazat vlastní účet.`,
      2134: `První uživatel klienta musí být připojen v roli "Administrátor".`,
      2135: `E-mail uživatele je možné editovat pouze prvních 24 hod od jeho založení.`,
      2136: `Nesprávně zadaná poštovní adresa.`,
      2137: `Faktura neexistuje.`,
      2138: `Server neexistuje.`,
      2139: `IP adresa je již použita.`,
      2140: `IP adresa nespadá do žádného IP rozsahu klienta`,
      2141: `Reverzní záznam nelze nastavit. IP adresa není připojena k žádnému serveru.`,
      2142: `Přístup do Server Park nenalezen`,
      2143: `Minimálně jeden propojený uživatel v roli "Administrátor" je požadován u každého aktivního klienta.`,
      2144: `Aktivní uživatel musí být připojený alespoň k jednomu uživateli.`,
      2145: `Uživatel nemá oprávnění na přijímání zabezpečené komunikace.`,
      2146: `Žádná volná IP adresa nebyla nalezena.`,
      2147: `Ip adresa je rezervována a nemůže být přiřazena k serveru.`,
      2148: `Požadavek nebyl nalezen.`,
      2149: `Příloha požadavku nebyla nalezena.`,
      2150: `Oddělení nebylo nalezeno.`,
      2151: `Váš email není zapsaný v klientských kontaktech. Vaše zpráva bude zahozena.`,
      2152: `Vytváření požadavků pomocí emailu byla pro Váš účet zakázána. Nastavení můžete změnit v klientské zóně VSHostingu.`,
      2153: `Neznámá emailová schránka.`,
      2154: `Požadavek je uzavřený. Další zprávy již nelze přidávat.`,
      2155: `Není dovoleno vytvářet požadavky z účtu externího administrátora.`,
      2156: `Hodnocení požadavku již bylo odesláno.`,
      2157: `Hodnocení je možné přidávat pouze ke klientským požadavkům.`,
      2158: `V případě špatného hodnocení je třeba přidat poznámku.`,
      2159: `Požadavek nepatří klientovi.`,
      2160: `Administrátorovi není dovoleno zakládat požadavky.`,
      2161: `Administrátor smí odpovídat pouze na interní požadavky, které založil.`,
      2162: `Požadavek je uzavřen a tedy nemůže být upravován.`,
      2163: `Požadavek je zamítnutý, protože obsahuje duplikát otisku.`,
      2164: `Vytváření požadavků pomocí emailu není povoleno. Založte prosím požadavek v dashboardu Snackhostu.`,
      2165: `Nastal problém při změně DPH. Prosím zkuste to za chvíli.`,
      2166: `Nastal problém při fakturaci. Zkuste to prosím za chvíli.`,
      2167: `Uživatel klienta nebyl nalezen, nebo je neaktivní.`,
      2168: `Uživatel není oprávněn odesílat nebo upravovat požadavky.`,
      2169: `Vazba mezi klientam a uživatelem nebyla nalezena.`,
      2170: `Autorizační token je neplatný nebo již vypršel.`,
      2171: `PTR záznam nemůže být nastaven, protože IP adresa není přiřazena žádnému serveru.`,
      2172: `Platba neexistuje.`,
      2173: `Počet požadovaných záznamů je mimo limit.`,
      2174: `Chybný typ souboru.`,
      2175: `Graf serveru neexistuje.`,
      2176: `Chybný nebo expirovaný aktivační odkaz.`,
      2177: `Tato osoba již má do ServerParku přidělený přístup.`,
      2178: `Pošlete prosím právě jeden soubor pomocí metody form-data.`,
      2179: `Interní chyba systému.`,
      2180: `Datum návštěvy musí být v budoucnosti.`,
      2181: `Smazaného uživatele nelze upravovat.`,
      2182: `Nesprávné číslo identifikačního dokumentu.`,
      2183: `Datum narození musí být v minulosti.`,
      2184: `Špatný enbo expirovaný odkaz.`,
      2185: `Uživatel nemá u tohoto klienta povolený přístup do CloudDns.`,
      2186: `Požadavek nebyl odeslaný z emalu patřícímu přihlášenému uživateli.`,
      2190: `Tento e-mail se již používá pro účet správce VSHosting. Použijte jiný e-mail.`,
      2194: `Tento ticket nemá žádnou cenovou nabídku.`,
      2195: `K požadavku byla přidána nová zpráva. Před potvrzením cenové nabídky si ji nejprve přečtěte.`,
      2196: `Cenová nabídka již byla schválena.`,
      2197: `Ticket nemá žádný datum realizace čekající na schválení.`,
      2198: `Datum realizace nemůže být schválen, protože je v minulosti.`,
      2199: `Datum realizace již byl schválen.`,
      2200: `Uživatel je oprávněnou osobou rámcové smlouvy. Před změnou role je třeba uživatele odebrat ze seznamu
      oprávněných osob. Kontaktujte, prosím, podporu VSHosting.`,
      2201: `Uživatel je oprávněnou osobou rámcové smlouvy. Před smazáním je třeba uživatele odebrat ze seznamu
      oprávněných osob. Kontaktujte, prosím, podporu VSHosting.`,
      2501: `Nespecifikovaná chyba serveru. Chyba byla nahlášena správci systému.`,
      2502: `Akce je dočasné blokována jinou operací. Zkuste to prosím za chvíli.`,
      2503: `Při odesílání emailu nastala chyba.`,
      2504: `Uživatele se nepodařilo automaticky přihlásit.`,
      2505: `Nepodařilo se změnit PIN klienta`,
      4101: `Klient nenalezen.`,
      4102: `Neplatný název domény.`,
      4103: `Název domény je příliš dlouhý.`,
      4104: `Povoleny jsou pouze domény druhého řádu a řádu prvního před veřejným suffixem.`,
      4105: `Doména je smazána. Změny doménových záznamů nejsou povoleny. Nejprve prosím obnovte doménu.`,
      4106: `Doména je deaktivována. Změny doménových záznamů nejsou povoleny. Nejprve prosím aktivujte doménu.`,
      4107: `Tuto akci může provést pouze vshosting~ administrátor.`,
      4108: `Doména nenalazena.`,
      4109: `Doménový záznam nenalezen.`,
      4110: `Verze domény nenalezena.`,
      4111: `Log domény nenalezen.`,
      4112: `Tato doména již je ve vašem seznamu domén.`,
      4113: `Tuto doméu již používá jiný klient. Pokud je doména ve vašem vlastnictví, obraťte se prosím na naši
        <a class="__link" href="/tickets/new/MANAGED_SERVICES_ISSUE?title=Vlastnictví domény">vshosting~ podporu</a>.
      `,
      4114: `Doménový záznam není smazaný.`,
      4115: `Doménový záznam je součástí neaktuální doménové změny a nemůže být obnoven. Prosím obnovte seznam doménových záznamů.`,
      4116: `Doména již byla obnovena.`,
      4117: `Nelze stornovat již publikované změny nebo změny, u kterých probíhá publikace.`,
      4118: `Nepublikovaná a smazaná doména nemůže být deaktivována.`,
      4119: `Vyžadována deaktivovaná doména.`,
      4120: `Pro název domény jsou povoleny pouze znaky ASCII nebo A-label.`,
      4121: `Nelze aktivovat DNSSEC na této doméně.`,
      4122: `Nelze deaktivovat DNSSEC na této doméně.`,
      4123: `DNSSEC je již aktivovaný.`,
      4124: `DNSSEC je již deaktivovaný.`,
      4125: `Nelze publikovat veřejný klíč DNSSECu.`,
      4126: `Nelze zrušit publikaci veřejného klíče DNSSECu.`,
      4127: `Chyba při čtení veřejného klíče DNSSECu.`,
      4128: `DNSSEC může být deaktivován nejdříve hodinu po aktivaci.`,
      4129: `Nelze deaktivovat DNSSEC, pokud je veřejný klíč u registrátora domény smazán.`,
      4130: `DNSSEC klíč domény a registrátora se neshodují.`,
      4131: `Nepodporovaný typ záznamu.`,
      4132: `Nelze publikovat veřejný klíč před aktivováním DNSSECU.`,
      4133: `Doména je právě upravována. Opakujte prosím akci později`,
      4134: `Nepodporovaná hodnota TTL. Podporované rozmezí je 60 až 86400 vteřin.`,
      4135: `Doménový záznam je součástí neaktuální doménové změny a nemůže být upraven. Prosím obnovte seznam doménových záznamů.`,
      4136: `Vložený doménový záznam je duplicitní s již existujícím záznamem. Duplicitní záznamy nejsou povoleny.`,
      4137: ``,
      4138: `Nelze obnovit doménový záznam, jelikož identický záznam již existuje.`,
      4139: `Doménový záznam se shodnou hodnotou existuje v seznamu SMAZANÝCH záznamů.
        Použijte prosím fukci obnovení namísto přidání nového záznamu.`,
      4140: `Nelze publikovat doménu. Doménové záznamy nebyly změněny.`,
      4141: `Byl překročen limit na počet doménových záznamů. Prosím kontaktujte technickou podporu ~vshosting.`,
      4142: `Nesprávné znaky ve jménu doménového záznamu. Validní hosstname je vyžadováno.`,
      4143: `Nesprávný doménový záznam. Validní hosstname je vyžadováno.`,
      4144: `Jsou povoleny pouze ASCII znaky. Jiné než ASCII znaky musí být manuálně změněny na IDN formát.`,
      4145: `Nesprávnaá délka doménového záznamu. Délka hodnoty doménového záznamu musí být v rozmezí 1 až 1024 charakterů.`,
      4146: `Doména je spravována externí aplikací a nemůže být upravena v CloudDNS.`,
      4147: `Byl překročen limit na počet domén. Prosím kontaktujte technickou podporu ~vshosting`,
      4148: `Pro přidání domény je vyžadován aktivní klient.`,
      4149: `Doména již existuje v PowerDNS v jiném režimu než Master. Kontaktujte prosím podporu ~vshosting.`,
      4150: `Doména je na seznamu zakázaných domén a nemůže být smazána nebo deaktivována.`,
      4151: `Doména je na seznamu zakázaných domén a nemůže být upravena externí aplikací.`,
      4152: `Vložený doménový záznam je v konfliktu s existujícím CNAME záznamem se stejným názvem.`,
      4153: `Vložený doménový záznam je v konfliktu s existujícím záznamem se stejným názvem.`,
      4154: `Doména je spravována CloudDNS a nemůže být upravena externí aplikací. Nejprve prosím smažte doménu v CloudDNS
        nebo kontaktujte podporu vshosting~.`,
      4155: `Doména je spravována jinou externí aplikací. Nejprve ji smažte v apliikaci nebo kontaktujte podporu vshosting~.`,
      4501: `Interní chyba systému. Kontrolní součet se nezdařil.`,
      4502: `Doména je dočasně zamčena jinou konflitní akcí. Prosím zkuste to později.`,
      4503: `Klient je dočasně nedostupný. Prosím zkuste to později.`,
      4504: `DnsClientApp je dočasně nedostupná. Prosím zkuste to později.`,
      4505: `Aktivace DNSSEC selhala.`,
      4506: `Deaktivace DNSSEC selhala.`,
      4507: `Publikace DNSSEC veřejného klíče selhala.`,
      4508: `Odvolání veřejného DNSSEC klíče selhalo.`,
      4509: `Načtení veřejného DNSSEC klíče selhalo.`,

      5001: `Tato akce není povolena pro uživatele VSHosting.`,
      5002: `Z bezpečnostních důvodů se před aktualizací přihlašovacích údajů prosím znovu přihlašte.`,
      5003: `Autentifikační token MojeID vypršel. Prosím, přihlašte se znovu do svého účtu MojeID.`,
      5004: `Ověření vašeho účtu MojeID selhalo. Prosím, přihlašte se znovu do svého účtu MojeID. Pokud problémy
      přetrvávají, kontaktujte podporu VSHosting.`,
      5005: `K tomuto účtu MojeID není přiřazen žádný účet VSHosting. Nejprve se přihlašte e-mailem a heslem a připojte
      svůj účet MojeID k vašemu účtu VSHosting.`,
      5006: `Autentifikační token Google vypršel. Prosím, přihlašte se znovu do svého Google účtu.`,
      5007: `Ověření vašeho Google účtu selhalo. Prosím, přihlašte se znovu do svého Google účtu. Pokud problémy přetrvávají,
      kontaktujte podporu VSHosting.`,
      5008: `K tomuto Google účtu není přiřazen žádný účet VSHosting. Nejprve se přihlašte e-mailem a heslem a připojte svůj
      Google účet k vašemu účtu VSHosting.`,
      5009: `Váš účet MojeID je již připojen k jinému účtu uživatele VSHosting. Prosím, použijte jiný účet MojeID.`,
      5010: `Váš Google účet je již připojen k jinému účtu uživatele VSHosting. Prosím, přihlašte se k jinému Google účtu.`,
      5011: `Je vyžadována alespoň jedna metoda přihlášení.`,

      5012: `Ověření vašeho Apple ID selhalo. Prosím, přihlašte se znovu do svého účtu Apple ID. Pokud problémy
      přetrvávají, kontaktujte podporu VSHosting.`,
      5013: `K tomuto Apple ID není přiřazen žádný účet VSHosting. Nejprve se přihlašte e-mailem a heslem a připojte
      své Apple ID k vašemu účtu VSHosting.`,
      5014: `Vaše Apple ID je již připojeno k jinému účtu uživatele VSHosting. Prosím, použijte jiné Apple ID.`,
      5015: `Autentifikační token Apple ID vypršel. Prosím, přihlašte se znovu do svého Apple ID účtu.`,

      5101: `Interní chyba systému.`,
      5102: `Interní chyba systému.`,
      5103: `Interní chyba systému.`,
      5104: `Interní chyba systému.`,
      5105: `Interní chyba systému.`,
      5106: `Interní chyba systému.`,
      5107: `Interní chyba systému.`,
      5108: `Interní chyba systému.`,
      5109: `Interní chyba systému.`,
      5110: `Tato doména je chráněna proti smazání.`,
      5111: `Interní chyba systému.`,
      5112: `Interní chyba systému.`,
      5113: `Interní chyba systému.`,
      5114: `Interní chyba systému.`,
      5115: `Interní chyba systému.`,
      5116: `Interní chyba systému.`,
      5117: `Interní chyba systému.`,
      5118: `Interní chyba systému.`,
      5119: `Interní chyba systému.`,
      5500: `Interní chyba systému.`,
      6101: `Limit domén klientů musí být kladné číslo.`,
      6102: `Limit doménových záznamů musí být kladné číslo.`,
      6103: `Doména nenalezena.`,
      6104: `Doménový log nenalezen.`,
      6105: `Klient nenalezen.`,
      6106: `ClientDNSApp nenalezena.`,
      6107: `Log akcí nenalezen.`,
      6108: `Log změn nenalezen.`,
      6109: `Nelze importovat domény existující v CloudDNS.`,
      6110: `Fukce je deaktivována v configuraci aplikace.`
    }
  },
  hu: {
    error: {
      400: `A bemeneti adatok érvénytelenek`,
      401: `A művelet végrehajtásához bejelentkezés szükséges`,
      402: `Nincs elegendő jogosultsága a művelet végrehajtásához`,
      403: `Refresh token érvénytelen vagy lejárt`,
      404: `Az endpoint nem található`,
      405: `Lehetséges csaló viselkedést észleltek. Kérjük, ellenőrizze a hitelesítést a Google reCaptcha szolgáltatással.`,
      406: `Érvénytelen Google reCaptcha válasz`,
      407: `A token a letöltéshez érvénytelen vagy lejárt`,
      408: `Érvénytelen URL cím formátum`,
      409: ``,
      410: ``,
      411: `Nincsenek jogosultságai a kiválasztott művelet végrehajtásához.`,
      412: `Meghaladta a fájl maximális 4MB méretét a feltöltéshez.`,
      413: `Váratlan alkalmazási hiba. Kérjük, ismételje meg a műveletet. Ha a probléma továbbra is fennáll,
      jelentse a hibát a fejlesztőknek.`,
      414: `Ez a művelet nem engedélyezett a VSHosting felhasználók számára.`,
      500: `Belső rendszerhiba.`,
      2000: `Hibás bejelentkezési adatok.`,
      2001: `Érvénytelen felhasználói adatok.`,
      2002: `Érvénytelen felhasználói azonosítás.`,
      2003: `A jelszóváltoztatás időkorlátja lejárt, kérjük, kérje újra a jelszóváltoztatást.`,
      2004: `A token nem egyezik egyetlen bejegyzéssel sem.`,
      2005: `A megadott felhasználói jelszó nem felel meg a biztonsági követelményeknek. A jelszónak tartalmaznia kell legalább 8
      karaktert, kivéve a szótári szavakat, vagy legalább 12 karaktert. A jelszó nem lehet hosszabb 72 karakternél.`,
      2006: `Érvénytelen IP-cím`,
      2007: `A felhasználói profilja jelenleg nem aktív. Aktiválásához kérjük forduljon a "VSHosting" ügyfélfiók rendszergazdához.`,
      2008: `A művelet nem végezhető el, mert a lefoglalt időszak lejárt.`,
      2009: `Nincs jogosultsága a kért művelethez. A jogosultsági beállítások valószínűleg megváltoztak.`,
      2010: `Nincs hozzáférése az ügyfélzónához. Kérjük, lépjen kapcsolatba a VSHosting-fiók rendszergazdájával.`,
      2011: `A megadott PTR bejegyzés nem érvényes. Kérjük, adja meg FQDN formátumban, pl. admin.vshosting.cloud`,
      2012: `Az e-maillel és jelszóval történő bejelentkezés le lett tiltva. Kérjük, használjon egy másik bejelentkezési módszert.`,
      2013: `Az e-maillel és jelszóval történő bejelentkezés le lett tiltva. Emiatt az elfelejtett jelszó visszaállítása funkció
      nem érhető el. Kérjük, használjon egy másik bejelentkezési módszert.`,
      2101: `A felhasználói fiók nem létezik.`,
      2102: `A felhasználói fiók már létezik..`,
      2103: `A megadott nyelvi kód érvénytelen.`,
      2104: `A megadott telefonos országkód érvénytelen.`,
      2105: `Érvénytelen sablon.`,
      2106: `A telefonos körzetszám nem felel meg a telefonszámnak.`,
      2107: `Érvénytelen telefonszám.`,
      2108: `Érvénytelen felhasználói szerepkör.`,
      2109: `Ügyfél nem található.`,
      2110: `Érvénytelen ügyfél és felhasználói csoport kombináció.`,
      2111: `A megadott e-mail címmel vagy telefonszámmal rendelkező felhasználó már létezik.`,
      2112: `Ezzl az e-mail címmel rendelkező felhasználó már csatlakozott a {name} ügyfélhez.`,
      2113: `A megadott üzenettípus nem létezik.`,
      2114: `Az átadott paraméterek érvénytelenek.`,
      2115: `Az ügyfél már aktiválva van.`,
      2116: `A token alakja helytelen.`,
      2117: `Érvénytelen e-mail formátum`,
      2118: `Érvénytelen az ügyfélszám alakja.`,
      2119: `Érvénytelen a felhasználói szám alakja`,
      2120: `A megadott országkód érvénytelen.`,
      2121: `A műveletet nem lehetett végrehajtani, mert a megadott adatok érvénytelenek.`,
      2122: `A bejegyzés nem található.`,
      2123: `A felhasználók maximális száma (70) az ügyfélnél túllépésre került.`,
      2124: `Érvénytelen a PIN-kód alakja`,
      2125: `Érvénytelen kérési prioritás típusa.`,
      2126: `Érvénytelen az igény típusa.`,
      2127: `Érvénytelen alkalmazástípus.`,
      2128: `Érvénytelen kiértékelési típus.`,
      2129: `A kérés nincs megoldva vagy lezárva Kiértékelés csak a kérelem lezárása után lehetséges.`,
      2130: `Adatok nem találhatók.`,
      2131: `A felhasználó nincs a "törlésre kész" állapotban.`,
      2132: `A kérés már törlésre került.`,
      2133: `Nem sikerült törölni a saját fiókot.`,
      2134: `Az ügyfél első felhasználójának a „Rendszergazda" szerepkörben kell csatlakoznia.`,
      2135: `A felhasználó e-mail címe csak a létrehozást követő első 24 órában szerkeszthető.  `,
      2136: `Helytelenül megadott postacím.`,
      2137: `Számla nem létezik.`,
      2138: `Szerver nem létezik.`,
      2139: `Az IP-cím már használatban van.`,
      2140: `Az IP-cím nem tartozik egyik ügyfél IP-tartományába sem`,
      2141: `Fordított bejegyzés nem állítható be. Az IP-cím nem kapcsolódik egyetlen szerverhez sem.`,
      2142: `Elérés a Server Parkhoz nem található.`,
      2143: `Mindegyik aktív ügyfélhez legalább egy "Rendszergazda" szerepkörben lévő kapcsolódó felhasználó szükséges.`,
      2144: `Egy aktív felhasználónak legalább egy felhasználóhoz kapcsolódnia kell.`,
      2145: `A felhasználónak nincs jogosultság a biztosított kommunikáció fogadására.`,
      2146: `Nem található szabad IP-cím.`,
      2147: `Az IP-cím foglalt és nem rendelhető a szerverhez.`,
      2148: `Az igény nem található.`,
      2149: `Az igény csatolmánya nem található.`,
      2150: `Az osztály nem található.`,
      2151: `Az Ön e-mail címe nem szerepel az ügyfélkapcsolatok között. Az üzenetét elvetették.`,
      2152: `Az igények létrehozása e-mail segítségével letiltásra került az Ön fiókjában. A beállításokat a
      VSHosting ügyfélkörében módosíthatja`,
      2153: `Ismeretlen e-mail postafiók.`,
      2154: `Az igény lezárult. Több üzenet már nem adható hozzá.`,
      2155: `Nem engedélyezett az igények létrehozása külső rendszergazdai fiókból.`,
      2156: `Az igény értékelése már elküldésre került.`,
      2157: `Értékelések csak az ügyféligényekhez adhatók.`,
      2158: `Rossz értékelés esetén megjegyzés hozzáadása szükséges.`,
      2159: `Az igény nem az ügyfélhez tartozik.`,
      2160: `A rendszergazdának nem engedélyezett igények létrehozása.`,
      2161: `A rendszergazda csak az általa létrehozott belső igényekre válaszolhat.`,
      2162: `Az igény lezárult, így nem módosítható.`,
      2163: `Az igényt elutasították, mert a lenyomat duplikátumát tartalmazza.`,
      2164: `Az igény létrehozása e-mailben nem engedélyezett. Kérjük, hozza létre az igényt a Snackhost felületen.`,
      2165: `Probléma merült fel az ÁFA módosításával kapcsolatban. Kérjük, próbálja meg újra egy pillanat múlva.`,
      2166: `Probléma adódott a számlázással. Próbálja meg újra egy kis idő múlva.`,
      2167: `Az ügyfél felhasználóját nem találtuk vagy inaktív.`,
      2168: `A felhasználónak nincs jogosultsága az igények küldésére vagy szerkesztésére.`,
      2169: `Az ügyfél és a felhasználó közötti kapcsolatot nem találtuk.`,
      2170: `Az autorizációs token érvénytelen vagy lejárt.`,
      2171: `PTR bejegyzés nem állítható be, mert az IP-cím nincs hozzárendelve egyetlen szerverhez sem.`,
      2172: `Fizetés nem létezik.`,
      2173: `A kért bejegyzések száma túllépte a korlátot.`,
      2174: `Sikertelen fájltípus.`,
      2175: `A szerver grafikon nem létezik.`,
      2176: `Érvénytelen vagy lejárt aktiválási hivatkozás.`,
      2177: `Ez a személy már rendelkezik hozzáféréssel a ServerParkhoz.`,
      2178: `Kéremm, csak egy fájlt küldjön el a form-data módszerrel.`,
      2179: `Belső rendszerhiba.`,
      2180: `A látogatás dátumának a jövőben kell lennie.`,
      2181: `A törölt felhasználó nem szerkeszthető.`,
      2182: `A dokumentum azonosítószáma helytelen.`,
      2183: `A születési dátumnak a múltban kell lennie.`,
      2184: `Rossz vagy lejárt hivatkozás.`,
      2185: `A felhasználó nem férhet hozzá a CloudDns-hez ennél az ügyfélnél.`,
      2186: `A kérést nem a bejelentkezett felhasználóhoz tartozó e-mail címről küldték.`,
      2190: `Ez az e-mail már használatban van a VSHosting rendszergazdai fiókhoz. Használjon másik e-mail címet.`,
      2194: `Ez a ticket nem tartalmaz árajánlatot.`,
      2195: `A kérelemhez új üzenet került hozzáadásra. Az árajánlat megerősítése előtt olvassa el.`,
      2196: `Az árajánlatot már jóváhagyták.`,
      2197: `A tickethez nincs jóváhagyásra váró végrehajtási dátum.`,
      2198: `Ez a végrehajtási dátum nem hagyható jóvá, mert már a múltban van.`,
      2199: `A végrehajtási dátumot már jóváhagyták.`,
      2501: `Meghatározatlan szerverhiba. A hibát jelentették a rendszergazdának.`,
      2502: `A műveletet egy másik művelet ideiglenesen blokkolja. Próbálja meg újra egy kis idő múlva.`,
      2503: `Hiba történt az e-mail küldése közben.`,
      2504: `A felhasználó nem tudott automatikusan bejelentkezni.`,
      2505: `Nem tudta megváltoztatni az ügyfél PIN-kódját`,
      4101: `Ügyfél nem található.`,
      4102: `Érvénytelen domain név`,
      4103: `Túl hosszú a domain név.`,
      4104: `Csak másodrendű és elsőrendű domainek engedélyezettek a nyilvános utótag előtt.`,
      4105: `A domain törlésre került. A domain bejegyzések módosítása nem engedélyezett. Kérjük, először állítsa vissza a domaint.`,
      4106: `A domaint deaktiválták. A domain bejegyzések módosítása nem engedélyezett. Kérjük, először aktiválja a domaint.`,
      4107: `Ezt a műveletet csak a vshosting~ remdszergazda végezheti el.`,
      4108: `A domain nem található.`,
      4109: `A domain bejegyzés nem található.`,
      4110: `A domain verzió nem található.`,
      4111: `A domain log-ja nem található.`,
      4112: `Ez a domain már szerepel a domainlistán.`,
      4113: `Ezt a domaint már egy másik ügyfél használja. Ha a domain az Ön tulajdonában van, kérjük, lépjen kapcsolatba a
        <a class="__link" href="/tickets/new/MANAGED_SERVICES_ISSUE?title=Vlastnictví domény">vshosting~ ügyfélszolgálatával’</a>.`,
      4114: `A domain bejegyzés nincs törölve.`,
      4115: `A domain bejegyzés egy elavult domainváltás része, és nem frissíthető. Kérjük, frissítse a domain bejegyzések listáját.`,
      4116: `A domain már frissítve lett.`,
      4117: `Nem sztornózhatók a már közzétett változások, vagy a közzététel alatt álló változások.`,
      4118: `Egy még nem publikált és törölt domain nem deaktiválható.`,
      4119: `Igényelt deaktivált domain.`,
      4120: `A domain névben csak ASCII vagy A-label karakterek engedélyezettek.`,
      4121: `A DNSSEC nem aktiválható ezen a tartományon.`,
      4122: `A DNSSEC nem deaktiválható ezen a domainen.`,
      4123: `A DNSSEC már aktiválva van.`,
      4124: `A DNSSEC már deaktiválva van.`,
      4125: `Nem tehető közzé a DNSSEC nyilvános kulcsa.`,
      4126: `Nem törölhető a DNSSEC nyilvános kulcsának közzététele.`,
      4127: `Hiba a DNSSEC nyilvános kulcsának olvasásában.`,
      4128: `A DNSSEC legkorábban egy órával az aktiválás után deaktiválható.`,
      4129: `Nem deaktiválható a DNSSEC, ha a nyilvános kulcsot a domain-regisztrátornál törölték.`,
      4130: `A domain és a regisztrátor DNSSEC kulcsa nem egyezik meg.`,
      4131: `Nem támogatott bejegyzés típus.`,
      4132: `Nem tehető közzé a nyilvános kulcs a DNSSEC aktiválása előtt.`,
      4133: `A domain jelenleg módosítás alatt áll. Kérjük, ismételje meg a műveletet később.`,
      4134: `Nem támogatott TTL érték. A támogatott tartomány 60 és 86400 másodperc között van.`,
      4135: `A domain bejegyzés egy elavult domainváltás része, és nem módosítható. Kérjük, frissítse a domain bejegyzések listáját.`,
      4136: `A beillesztett domain bejegyzés duplikálódik egy meglévő bejegyzésdal. A duplikált bejegyzésok nem engedélyezettek.`,
      4137: ``,
      4138: `Nem lehet visszaállítani egy tartománybejegyzésot, mert egy azonos bejegyzés már létezik.`,
      4139: `Egy azonos értékű tartománybejegyzés létezik a SHRINKED bejegyzésok listájában.
      Új bejegyzés hozzáadása helyett használja a restore funkciót.`,
      4140: `Nem lehet közzétenni a tartományt. A tartománybejegyzésok nem lettek megváltoztatva.`,
      4141: `A tartománybejegyzésok számának korlátja túllépésre került. Kérjük, lépjen kapcsolatba a ~vshosting technikai támogatással`,
      4142: `Hibás karakterek a domain bejegyzés nevében. Érvényes hosstname szükséges.`,
      4143: `Hibás domain bejegyzés. Érvényes hosstname szükséges.`,
      4144: `Kizárólag ASCII karakterek engedélyezettek. A nem ASCII karaktereket kézzel kell IDN formátumra módosítani.`,
      4145: `Helytelen domain bejegyzés hossza. A domain bejegyzés értékének hossza 1 és 1024 karakter között kell lennie.`,
      4146: `A tartományt egy külső alkalmazás kezeli, és nem módosítható a CloudDNS-ben.`,
      4147: `A tartományok számának korlátja túllépésre került. Kérjük, vegye fel a kapcsolatot a ~vshosting technikai támogatásával`,
      4148: `A domain hozzáadásához aktív ügyfél szükséges.`,
      4149: `A tartomány már létezik a PowerDNS-ben a Master-től eltérő üzemmódban. Kérjük, lépjen
      kapcsolatba a ~vshosting technikai támogatásával.`,
      4150: `A domain a tiltólistán van, és nem törölhető vagy deaktiválható.`,
      4151: `A domain a tiltott domainek listáján szerepel, és külső alkalmazással nem módosítható.`,
      4152: `A beillesztett domain bejegyzés ütközik egy létező, azonos nevű CNAME bejegyzéssel.`,
      4153: `A beillesztett domain bejegyzés ütközik egy létező, azonos nevű bejegyzéssel.`,
      4154: `A tartományt a CloudDNS kezeli, és külső alkalmazással nem módosítható. Kérjük, először törölje a tartományt a CloudDNS-ben.
      Vagy lépjen kapcsolatba a ~vshosting ügyfélszolgálattal.`,
      4155: `A domaint egy másik külső alkalmazás kezeli. Kérjük, először törölje az alkalmazásban, vagy lépjen
      kapcsolatba a vshosting~ ügyfélszolgálattal`,
      4501: `Belső rendszerhiba. Az ellenőrző összegzés sikertelen.`,
      4502: `A domain ideiglenesen le van zárva egy másik konflikt művelet miatt. Kérjük, próbálja meg később újra.`,
      4503: `Az ügyfél átmenetileg nem elérhető. Kérjük, próbálja meg később újra.`,
      4504: `A DnsClientApp átmenetileg nem elérhető. Kérjük, próbálja meg később.`,
      4505: ` DNSSEC aktiválása sikertelen.`,
      4506: `DNSSEC deaktiválása sikertelen.`,
      4507: `DNSSEC nyilvános kulcs közzététele sikertelen.`,
      4508: `DNSSEC nyilvános kulcs meghívása sikertelen.`,
      4509: `DNSSEC nyilvános kulcs olvasása sikertelen.`,

      5001: `Ez a művelet nem engedélyezett a VSHosting felhasználók számára.`,
      5002: `Biztonsági okokból kérjük, jelentkezzen be újra a bejelentkezési beállítások frissítése előtt.`,
      5003: `A MojeID hitelesítési token lejárt. Kérjük, jelentkezzen be újra a MojeID fiókjába.`,
      5004: `A MojeID fiókja ellenőrzése sikertelen volt. Kérjük, jelentkezzen be újra a MojeID fiókjába.
       Ha a probléma továbbra is fennáll, lépjen kapcsolatba a VSHosting támogatással.`,
      5005: `Nincs VSHosting fiók csatlakoztatva ehhez a MojeID fiókhoz. Először jelentkezzen be e-mail
      címével és jelszavával, majd csatlakoztassa a MojeID fiókját a VSHosting fiókjához.`,
      5006: `A Google hitelesítési token lejárt. Kérjük, jelentkezzen be újra a Google fiókjába.`,
      5007: `A Google fiókja ellenőrzése sikertelen volt. Kérjük, jelentkezzen be újra a Google fiókjába.
      Ha a probléma továbbra is fennáll, lépjen kapcsolatba a VSHosting támogatással.`,
      5008: `Nincs VSHosting fiók csatlakoztatva ehhez a Google fiókhoz. Először jelentkezzen be e-mail címével és jelszavával,
      majd csatlakoztassa a Google fiókját a VSHosting fiókjához.`,
      5009: `A MojeID fiókja már csatlakozik egy másik VSHosting felhasználói fiókhoz. Kérjük, használjon egy másik MojeID fiókot.`,
      5010: `A Google fiókja már csatlakozik egy másik VSHosting felhasználói fiókhoz. Kérjük, jelentkezzen be egy másik Google fiókba.`,
      5011: `Legalább egy bejelentkezési módszer szükséges.`,

      5101: `Belső rendszerhiba.`,
      5102: `Belső rendszerhiba.`,
      5103: `Belső rendszerhiba.`,
      5104: `Belső rendszerhiba.`,
      5105: `Belső rendszerhiba.`,
      5106: `Belső rendszerhiba.`,
      5107: `Belső rendszerhiba.`,
      5108: `Belső rendszerhiba.`,
      5109: `Belső rendszerhiba.`,
      5110: `Ez a domain védett a törlés ellen.`,
      5111: `Belső rendszerhiba.`,
      5112: `Belső rendszerhiba.`,
      5113: `Belső rendszerhiba.`,
      5114: `Belső rendszerhiba.`,
      5115: `Belső rendszerhiba.`,
      5116: `Belső rendszerhiba.`,
      5117: `Belső rendszerhiba.`,
      5118: `Belső rendszerhiba.`,
      5119: `Belső rendszerhiba.`,
      5500: `Belső rendszerhiba.`,
      6101: `Az ügyfél domain határértéknek pozitív számnak kell lennie.`,
      6102: `A domain bejegyzés limitnek pozitív számnak kell lennie.`,
      6103: `A domain nem található.`,
      6104: `A domain log nem található.`,
      6105: `Ügyfél nem található.`,
      6106: `A ClientDNSApp nem található.`,
      6107: `A művelet log-ja nem található.`,
      6108: `A változtatások log-ja nem található.`,
      6109: `A CloudDNS-ben létező domainek importálása nem lehetséges.`,
      6110: `Funkció letiltva az alkalmazás konfigurációjában.`
    }
  },
  de: {
    error: {
      400: `Die eingegebenen Daten sind ungültig`,
      401: `Für die Durchführung des Vorgangs ist eine Anmeldung erforderlich`,
      402: `Sie verfügen nicht über ausreichende Berechtigungen, um den Vorgang durchzuführen.`,
      403: `Refresh-Token ist ungültig oder abgelaufen`,
      404: `Endpoint nicht gefunden`,
      405: `Potenziell betrügerisches Verhalten wurde festgestellt. Bitte verifizieren Sie mit Google reCaptcha.`,
      406: `Ungültige Google reCaptcha-Antwort`,
      407: `Download-Token ist ungültig oder abgelaufen`,
      408: `Ungültiges URL-Format`,
      409: ``,
      410: ``,
      411: `Sie haben keine Berechtigungen, um die ausgewählte Aktion durchzuführen.`,
      412: `Die maximale Dateigröße von 4 MB wurde beim Hochladen überschritten.`,
      413: `Unerwarteter Anwendungsfehler. Bitte wiederholen Sie den Vorgang erneut.
        Wenn die Probleme weiterhin bestehen, melden Sie den Fehler den Entwicklern.`,
      500: `Interner Systemfehler.`,
      2000: `Falsche Anmeldedaten.`,
      2001: `Ungültige Benutzerdaten.`,
      2002: `Ungültige Benutzeridentifizierung.`,
      2003: `Die Frist für die Änderung Ihres Passworts ist abgelaufen. Bitte beantragen Sie erneut eine Passwortänderung.`,
      2004: `Das Token stimmt mit keinem Eintrag überein.`,
      2005: `Das eingegebene Benutzerpasswort entspricht nicht den Sicherheitsanforderungen. Das Passwort muss mindestens
        8 Zeichen ohne Wörter aus dem Wörterbuch oder mindestens 12 Zeichen enthalten. Es darf aber nicht länger als 72 Zeichen sein.`,
      2006: `Ungültige IP-Adresse.`,
      2007: `Ihr Benutzerprofil ist zur Zeit nicht aktiv. Um es zu aktivieren,
        wenden Sie sich bitte an den Administrator Ihres Kundenkontos "VSHosting."`,
      2008: `Der Vorgang konnte nicht durchgeführt werden, weil das zugewiesene Zeitfenster bereits abgelaufen war.`,
      2009: `Sie sind für den angeforderten Vorgang nicht autorisiert. Die Rechteeinstellungen wurden wahrscheinlich geändert.`,
      2010: `Sie haben keine Zugriffsrechte auf den Kundenbereich. Bitte kontaktieren Sie den Administrator des VSHosting-Kontos.`,
      2011: `Der eingegebene PTR-Eintrag ist nicht gültig. Geben Sie ihn im FQDN-Format ein, z. B. admin.vshosting.cloud`,
      2101: `Das Nutzerkonto existiert nicht.`,
      2102: `Das Nutzerkonto existiert bereits.`,
      2103: `Der eingegebene Sprachcode ist ungültig.`,
      2104: `Die Ländervorwahl ist ungültig.`,
      2105: `Ungültige Vorlage.`,
      2106: `Die Vorwahl entspricht nicht der Telefonnummer.`,
      2107: `Ungültige Telefonnummer.`,
      2108: `Ungültige Benutzerrolle.`,
      2109: `Kunde nicht gefunden.`,
      2110: `Ungültige Kombination von Kunde und Benutzergruppe.`,
      2111: `Der Benutzer mit der eingegebenen E-Mail-Adresse oder Telefonnummer existiert bereits.`,
      2112: `Ein Benutzer mit der gleichen E-Mail-Adresse ist bereits mit dem Kunden verbunden {name}.`,
      2113: `Der eingegebene Nachrichtentyp existiert nicht.`,
      2114: `Die übergebenen Parameter sind ungültig.`,
      2115: `Der Kunde ist bereits aktiviert.`,
      2116: `Der Token hat die falsche Form.`,
      2117: `Ungültiges E-Mail-Format.`,
      2118: `Ungültige Form der Kundennummer.`,
      2119: `Ungültige Form der Benutzernummer.`,
      2120: `Der eingegebene Ländercode ist ungültig.`,
      2121: `Der Vorgang konnte nicht ausgeführt werden, weil die eingegebenen Daten nicht gültig sind.`,
      2122: `Eintrag nicht gefunden.`,
      2123: `Die maximale Anzahl von Benutzern pro Kunde (70) wurde überschritten.`,
      2124: `Ungültige PIN-Form.`,
      2125: `Ungültiger Prioritätstyp der Anfrage.`,
      2126: `Ungültiger Anfragetyp.`,
      2127: `Ungültiger Anwendungstyp.`,
      2128: `Ungültiger Bewertungstyp.`,
      2129: `Die Anfrage ist noch nicht gelöst oder abgeschlossen. Die Bewertung ist erst möglich, wenn die Anfrage abgeschlossen ist.`,
      2130: `Keine Daten gefunden.`,
      2131: `Der Benutzer befindet sich nicht im Status "bereit zur Löschung".`,
      2132: `Die Anfrage wurde bereits gelöscht.`,
      2133: `Das eigene Konto kann nicht gelöscht werden.`,
      2134: `Der erste Benutzer des Kunden muss mit der Rolle "Administrator" verbunden sein.`,
      2135: `Die E-Mail-Adresse eines Benutzers kann nur innerhalb der ersten 24 Stunden nach ihrer Erstellung bearbeitet werden.`,
      2136: `Falsch eingegebene Postanschrift.`,
      2137: `Die Rechnung existiert nicht.`,
      2138: `Der Server existiert nicht.`,
      2139: `Die IP-Adresse wird bereits verwendet.`,
      2140: `Die IP-Adresse liegt nicht in einem IP-Bereich des Kunden`,
      2141: `Der Reverse-Eintrag kann nicht gesetzt werden. Die IP-Adresse ist mit keinem Server verbunden.`,
      2142: `Zugang zu Server Park nicht gefunden`,
      2143: `Für jeden aktiven Kunden ist mindestens ein verbundener Benutzer mit der Rolle "Administrator" erforderlich.`,
      2144: `Der aktive Benutzer muss mit mindestens einem anderen Benutzer verbunden sein.`,
      2145: `Der Benutzer hat nicht die Berechtigung, sichere Kommunikation zu empfangen.`,
      2146: `Es wurde keine freie IP-Adresse gefunden.`,
      2147: `Die IP-Adresse ist reserviert und kann nicht an einen Server zugewiesen werden.`,
      2148: `Die Anfrage wurde nicht gefunden.`,
      2149: `Die Anlage zur Anfrage wurde nicht gefunden.`,
      2150: `Die Abteilung wurde nicht gefunden.`,
      2151: `Ihre E-Mail-Adresse ist nicht in den Kundenkontakten registriert.
        Ihre Nachricht wird verworfen.`,
      2152: `Das Erstellen von Anfragen per E-Mail wurde für Ihr Konto gesperrt.
        Sie können die Einstellungen im VSHosting-Kundenbereich ändern.`,
      2153: `Unbekanntes E-Mail-Postfach.`,
      2154: `Die Anfrage ist abgeschlossen. Es können keine weiteren Nachrichten hinzugefügt werden.`,
      2155: `Es ist nicht erlaubt, Anfragen von einem externen Administratorkonto aus zu erstellen.`,
      2156: `Die Bewertung der Anfrage wurde bereits abgeschickt.`,
      2157: `Bewertungen können nur zu Kundenanfragen hinzugefügt werden.`,
      2158: `Im Falle einer schlechten Bewertung sollte ein Vermerk hinzugefügt werden.`,
      2159: `Die Anfrage gehört nicht dem Kunden.`,
      2160: `Der Administrator ist nicht berechtigt, Anfragen zu erstellen.`,
      2161: `Der Administrator darf nur auf interne Anfragen antworten, die er selbst erstellt hat.`,
      2162: `Die Anfrage ist abgeschlossen und kann daher nicht geändert werden.`,
      2163: `Die Anfrage wird abgelehnt, weil sie ein Abdruckduplikat enthält.`,
      2164: `Die Erstellung von Anfragen per E-Mail ist nicht zulässig. Bitte erstellen Sie eine Anfrage im Snackhost Dashboard.`,
      2165: `Es trat ein Problem bei der Änderung der Mehrwertsteuer auf. Versuchen Sie es bitte gleich noch einmal.`,
      2166: `es trat ein Problem mit der Rechnungsstellung auf. Bitte versuchen Sie es gleich noch einmal.`,
      2167: `Der Benutzer des Kunden wurde nicht gefunden oder ist inaktiv.`,
      2168: `Der Benutzer ist nicht berechtigt, Anfragen zu senden oder zu ändern.`,
      2169: `Die Verbindung zwischen dem Kunden und dem Benutzer wurde nicht gefunden.`,
      2170: `Das Autorisierungs-Token ist ungültig oder abgelaufen.`,
      2171: `Der PTR-Eintrag kann nicht gesetzt werden, da die IP-Adresse keinem Server zugewiesen ist.`,
      2172: `Die Zahlung existiert nicht.`,
      2173: `Die Anzahl der angeforderten Einträge übersteigt das Limit.`,
      2174: `Falscher Dateityp.`,
      2175: `Der Server-Graph existiert nicht.`,
      2176: `Falscher oder abgelaufener Aktivierungslink.`,
      2177: `Dieser Person ist bereits ein Zugang zum ServerPark zugewiesen.`,
      2178: `Bitte senden Sie nur eine Datei mit der "form-data"-Methode.`,
      2179: `Interner Systemfehler.`,
      2180: `Das Datum des Besuchs muss in der Zukunft liegen.`,
      2181: `Ein gelöschter Benutzer kann nicht bearbeitet werden.`,
      2182: `Falsche ID-Dokumentennummer.`,
      2183: `Das Geburtsdatum muss in der Vergangenheit liegen.`,
      2184: `Falscher oder abgelaufener Link.`,
      2185: `Der Benutzer ist nicht berechtigt, auf CloudDns bei diesem Kunden zuzugreifen.`,
      2186: `Die Anfrage wurde nicht von einer E-Mail-Adresse gesendet, die dem angemeldeten Benutzer gehört.`,
      2190: `Diese E-Mail-Adresse wird bereits für das VSHosting-Administratorkonto verwendet. Verwenden Sie eine andere E-Mail-Adresse.`,
      2194: `Dieses Ticket hat kein Preisangebot.`,
      2195: `Der Anfrage wurde eine neue Nachricht hinzugefügt. Bitte lesen Sie sie zuerst, bevor Sie das Preisangebot bestätigen.`,
      2196: `Das Preisangebot wurde bereits genehmigt.`,
      2197: `Das Ticket hat kein Datum für die Umsetzung, das auf die Genehmigung wartet.`,
      2198: `Das Datum der Umsetzung kann nicht genehmigt werden, weil es in der Vergangenheit liegt.`,
      2199: `Das Datum der Umsetzung wurde bereits genehmigt.`,
      2501: `Nicht spezifizierter Serverfehler. Der Fehler wurde an den Systemadministrator gemeldet.`,
      2502: `Der Vorgang ist vorübergehend durch einen anderen Vorgang blockiert. Bitte versuchen Sie es gleich noch einmal.`,
      2503: `Beim Senden einer E-Mail ist ein Fehler aufgetreten.`,
      2504: `Der Benutzer konnte nicht automatisch eingeloggt werden.`,
      2505: `Änderung der Kunden-PIN fehlgeschlagen`,
      4101: `Kunde nicht gefunden.`,
      4102: `Ungültiger Domainname.`,
      4103: `Der Domainname ist zu lang.`,
      4104: `Nur Domains zweiter und erster Ordnung vor dem öffentlichen Suffix sind zulässig.`,
      4105: `Domain wurde gelöscht. Änderungen an Domaineinträgen sind nicht erlaubt. Bitte erneuern Sie zunächst die Domain.`,
      4106: `Domain wurde deaktiviert. Änderungen an Domaineinträgen sind nicht erlaubt. Bitte aktivieren Sie zunächst die Domain.`,
      4107: `Dieser Vorgang kann nur vom vshosting~-Administrator durchgeführt werden.`,
      4108: `Domain nicht gefunden.`,
      4109: `Domaineintrag nicht gefunden.`,
      4110: `Domainversion nicht gefunden.`,
      4111: `Domainprotokoll nicht gefunden.`,
      4112: `Diese Domain befindet sich bereits in Ihrer Domainliste.`,
      4113: `Diese Domain wird bereits von einem anderen Kunden verwendet. Wenn Sie Eigentümer der Domain sind,
        wenden Sie sich bitte an unseren
        <a class="__link" href="/tickets/new/MANAGED_SERVICES_ISSUE?title=Domainbesitz">vshosting~ Support</a>.`,
      4114: `Domaineintrag wurde gelöscht.`,
      4115: `Der Domaineintrag ist Teil eines nicht mehr aktuellen Domainwechsels und kann
          nicht wiederhergestellt werden. Bitte aktualisieren Sie die Liste der Domaineinträge.`,
      4116: `Die Domain wurde bereits wiederhergestellt.`,
      4117: `Bereits veröffentlichte oder in der Veröffentlichung befindliche Änderungen können nicht rückgängig gemacht werden.`,
      4118: `Eine unveröffentlichte und gelöschte Domain kann nicht deaktiviert werden.`,
      4119: `Deaktivierte Domain erforderlich.`,
      4120: `Für den Domain-Namen sind nur ASCII- oder A-Label-Zeichen zulässig.`,
      4121: `DNSSEC kann für diese Domain nicht aktiviert werden.`,
      4122: `DNSSEC kann für diese Domain nicht deaktiviert werden.`,
      4123: `DNSSEC ist bereits aktiviert.`,
      4124: `DNSSEC ist bereits deaktiviert.`,
      4125: `Ein öffentlicher DNSSEC-Schlüssel kann nicht veröffentlicht werden.`,
      4126: `Die Veröffentlichung eines öffentlichen DNSSEC-Schlüssels kann nicht rückgängig gemacht werden.`,
      4127: `Fehler beim Lesen des öffentlichen DNSSEC-Schlüssels.`,
      4128: `DNSSEC kann frühestens eine Stunde nach der Aktivierung deaktiviert werden.`,
      4129: `DNSSEC kann nicht deaktiviert werden, wenn der öffentliche Schlüssel bei dem Domain-Registrar gelöscht wurde.`,
      4130: `Der DNSSEC-Schlüssel der Domain und des Registrars stimmen nicht überein.`,
      4131: `Nicht unterstützter Eintragstyp.`,
      4132: `Ein öffentlicher Schlüssel kann nicht vor der Aktivierung von DNSSEC
        veröffentlicht werden.`,
      4133: `Die Domain wird derzeit bearbeitet. Bitte wiederholen Sie den Vorgang später`,
      4134: `Nicht unterstützter TTL-Wert. Der unterstützte Bereich liegt zwischen 60 und 86.400 Sekunden.`,
      4135: `Der Domaineintrag ist Teil eines nicht mehr aktuellen Domainwechsels und kann nicht bearbeitet werden.
        Bitte aktualisieren Sie die Liste der Domaineinträge.`,
      4136: `Der eingefügte Domaineintrag stimmt mit einem bestehenden Eintrag überein.
        Doppelte Einträge sind nicht erlaubt.`,
      4137: ``,
      4138: `Ein Domaineintrag kann nicht wiederhergestellt werden, da bereits ein identischer Eintrag existiert.`,
      4139: `In der Liste der GELÖSCHTEN Einträge existiert ein Domaineintrag mit dem identischen Wert.
        Bitte verwenden Sie die Wiederherstellungsfunktion, anstatt einen neuen Eintrag hinzuzufügen.`,
      4140: `Domain kann nicht veröffentlicht werden.
        Die Domaineinträge wurden nicht geändert.`,
      4141: `Das Limit für die Anzahl der Domaineinträge wurde überschritten.
        Bitte kontaktieren Sie den technischen Support von ~vshosting.`,
      4142: `Falsche Zeichen im Namen des Domaineintrags. Ein gültiger Hostname ist erforderlich.`,
      4143: `Falscher Domaineintrag. Ein gültiger Hostname ist erforderlich.`,
      4144: `Nur ASCII-Zeichen sind erlaubt. Nicht-ASCII-Zeichen müssen manuell in das IDN-Format geändert werden.`,
      4145: `Falsche Länge des Domaineintrags. Die Länge des Domaineneintragswertes muss zwischen 1 und 1024 Zeichen liegen.`,
      4146: `Die Domain wird von einer externen Anwendung verwaltet und kann in CloudDNS nicht bearbeitet werden.`,
      4147: `Das Limit für die Anzahl der Domains wurde überschritten. Bitte kontaktieren Sie den technischen Support von ~vshosting`,
      4148: `Um eine Domain hinzuzufügen, ist ein aktiver Kunde erforderlich.`,
      4149: `Die Domain existiert bereits in PowerDNS in einem anderen Modus als Master.
        Bitte kontaktieren Sie den ~vshosting-Support.`,
      4150: `Die Domain steht auf der Liste der gesperrten Domains und kann nicht gelöscht oder deaktiviert werden.`,
      4151: `Die Domain steht auf der Liste der gesperrten Domains und kann von einer externen Anwendung nicht bearbeitet werden.`,
      4152: `Der eingefügte Domaineintrag steht in Konflikt mit einem vorhandenen CNAME-Eintrag mit demselben Namen.`,
      4153: `Der eingefügte Domaineintrag steht in Konflikt mit einem vorhandenen Eintrag mit demselben Namen.`,
      4154: `Die Domain wird von CloudDNS verwaltet und kann in einer externen Anwendung nicht bearbeitet werden.
        Bitte löschen Sie zunächst die Domain in CloudDNS oder kontaktieren Sie den vshosting~-Support.`,
      4155: `Die Domain wird von einer anderen externen Anwendung verwaltet.
        Löschen Sie sie zunächst in der Anwendung oder kontaktieren Sie den vshosting~-Support.`,
      4501: `Interner Serverfehler. Die Prüfsumme ist fehlgeschlagen.`,
      4502: `Die Domain ist vorübergehend durch einen anderen kollidierenden Vorgang gesperrt. Bitte versuchen Sie es später noch einmal.`,
      4503: `Kunde ist vorübergehend nicht verfügbar. Bitte versuchen Sie es später noch einmal.`,
      4504: `DnsClientApp ist vorübergehend nicht verfügbar. Bitte versuchen Sie es später noch einmal.`,
      4505: `DNSSEC-Aktivierung fehlgeschlagen.`,
      4506: `DNSSEC-Deaktivierung fehlgeschlagen.`,
      4507: `Veröffentlichung des öffentlichen DNSSEC-Schlüssels fehlgeschlagen.`,
      4508: `Widerruf des öffentlichen DNSSEC-Schlüssels fehlgeschlagen.`,
      4509: `Laden des öffentlichen DNSSEC-Schlüssels fehlgeschlagen.`,
      5101: `Interner Serverfehler.`,
      5102: `Interner Serverfehler.`,
      5103: `Interner Serverfehler.`,
      5104: `Interner Serverfehler.`,
      5105: `Interner Serverfehler.`,
      5106: `Interner Serverfehler.`,
      5107: `Interner Serverfehler.`,
      5108: `Interner Serverfehler.`,
      5109: `Interner Serverfehler.`,
      5110: `Diese Domain ist gegen Löschung geschützt.`,
      5111: `Interner Serverfehler.`,
      5112: `Interner Serverfehler.`,
      5113: `Interner Serverfehler.`,
      5114: `Interner Serverfehler.`,
      5115: `Interner Serverfehler.`,
      5116: `Interner Serverfehler.`,
      5117: `Interner Serverfehler.`,
      5118: `Interner Serverfehler.`,
      5119: `Interner Serverfehler.`,
      5500: `Interner Serverfehler.`,
      6101: `Der Grenzwert der Kunden-Domains muss eine positive Zahl sein.`,
      6102: `Der Grenzwert der Domaineinträge muss eine positive Zahl sein.`,
      6103: `Domain nicht gefunden.`,
      6104: `Domainprotokoll nicht gefunden.`,
      6105: `Kunde nicht gefunden.`,
      6106: `ClientDNSApp nicht gefunden.`,
      6107: `Protokoll der Vorgänge nicht gefunden.`,
      6108: `Protokoll der Änderungen nicht gefunden.`,
      6109: `Domains, die in CloudDNS existieren, können nicht importiert werden.`,
      6110: `Die Funktion ist in der Anwendungskonfiguration deaktiviert.`
    }
  }
};

const loggedUser = {
  cs: {
    loggedUser: {
      myAccountButton: 'Můj profil',
      passChangeButton: 'Změna hesla',
      logoutButton: 'Odhlásit se',
      companiesHeading: 'Spravované účty',
      changePasswordConfirm: 'Opravdu si přejete změnit heslo?',
      changePassword: 'Změnit heslo'
    }
  },
  en: {
    loggedUser: {
      myAccountButton: 'My account',
      passChangeButton: 'Change password',
      logoutButton: 'Logout',
      companiesHeading: 'Managed companies',
      changePasswordConfirm: 'Do you really want to change your password?',
      changePassword: 'Change password'
    }
  },
  hu: {
    loggedUser: {
      myAccountButton: 'Saját profil',
      passChangeButton: 'Jelszó megváltoztatása',
      logoutButton: 'Kijelentkezés',
      companiesHeading: 'Kezelt fiókok',
      changePasswordConfirm: 'Valóban szeretné megváltoztatni a jelszót?',
      changePassword: 'A jelszó megváltoztatása'
    }
  },
  de: {
    loggedUser: {
      myAccountButton: 'Mein Profil',
      passChangeButton: 'Passwortänderung',
      logoutButton: 'Abmelden',
      companiesHeading: 'Verwaltete Konten',
      changePasswordConfirm: 'Wollen Sie Ihr Passwort wirklich ändern?',
      changePassword: 'Passwort ändern'
    }
  },
};

const authBase = {
  cs: {
    authBase: {
      passwordChangeSuccessSnack: 'Odkaz na změnu hesla byl odeslán na váš E-mail.',
      externalAuthValidationSuccess: 'Propojení proběhlo úspěšně',
      externalAuthDeleteSuccess: 'Propojení bylo úspěšně zrušeno',
      emailLoginDisabledHint: `Z důvodu bezpečnosti bylo přihlášení e-mailem a heslem deaktivováno.
      Ze stejného důvodu nelze použít funkci pro zaslání zapomenutého hesla.`
    }
  },
  en: {
    authBase: {
      passwordChangeSuccessSnack: 'URL for password change was sent to your E-mail address.',
      externalAuthValidationSuccess: 'Connection was successful',
      externalAuthDeleteSuccess: 'Connection was successfully removed',
      emailLoginDisabledHint: `For security reasons, login by email and password has been deactivated.
      The forgotten password function has also been disabled.`
    }
  },
  hu: {
    authBase: {
      passwordChangeSuccessSnack: 'A jelszó megváltoztatásához szükséges hivatkozást elküldtük az Ön e-mail címére.',
      externalAuthValidationSuccess: 'A kapcsolat sikeres volt.',
      externalAuthDeleteSuccess: 'A kapcsolat sikeresen eltávolításra került',
      emailLoginDisabledHint: `Biztonsági okokból az e-mail és jelszóval történő bejelentkezés le van tiltva.
      Az elfelejtett jelszó funkció is ki van kapcsolva.`
    }
  },
  de: {
    authBase: {
      passwordChangeSuccessSnack: 'Ein Link zum Ändern Ihres Passworts wurde an Ihre E-Mail-Adresse gesendet.',
      externalAuthValidationSuccess: 'Die Verbindung war erfolgreich',
      externalAuthDeleteSuccess: 'Die Verbindung war erfolgreich entfernt',
      emailLoginDisabledHint: `Aus Sicherheitsgründen wurde die Anmeldung per E-Mail und Passwort deaktiviert.
        Die Funktion zum Zurücksetzen des Passworts wurde ebenfalls deaktiviert.`
    }
  },

};

const domainsBase = {
  cs: {
    domainsBase: {
      deleteSuccessSnack: 'Doména úspěšně smazána.',
      restoreSuccessSnack: 'Doména úspěšně obnovena.',
      deactivateDomainSuccessSnack: 'Doména úspěšně deaktivována',
      activateDomainSuccessSnack: 'Doména úspěšně aktivována',
      deleteRecordSuccessSnack: 'Záznam úspěšně smazán.',
      restoreRecordSuccessSnack: 'Záznam úspěšně obnoven.'
    }
  },
  en: {
    domainsBase: {
      deleteSuccessSnack: 'Domain succesfully deleted.',
      restoreSuccessSnack: 'Domain succesfully restored.',
      deactivateDomainSuccessSnack: 'Domain succesfully deactivated',
      activateDomainSuccessSnack: 'Domain succesfully activated',
      deleteRecordSuccessSnack: 'Record succesfully deleted.',
      restoreRecordSuccessSnack: 'Record succesfully restored.'
    }
  },
  hu: {
    domainsBase: {
      deleteSuccessSnack: 'A domain sikeresen törölve.',
      restoreSuccessSnack: 'A domain sikeresen visszaállítva.',
      deactivateDomainSuccessSnack: 'A domain sikeresen deaktiválva',
      activateDomainSuccessSnack: 'A domain sikeresen aktiválva',
      deleteRecordSuccessSnack: 'A bejegyzés sikeresen törölve.',
      restoreRecordSuccessSnack: 'A bejegyzés sikeresen visszaállítva.'
    }
  },
  de: {
    domainsBase: {
      deleteSuccessSnack: 'Domain erfolgreich gelöscht.',
      restoreSuccessSnack: 'Domain erfolgreich wiederhergestellt.',
      deactivateDomainSuccessSnack: 'Domain erfolgreich deaktiviert.',
      activateDomainSuccessSnack: 'Domain erfolgreich aktiviert.',
      deleteRecordSuccessSnack: 'Eintrag erfolgreich gelöscht.',
      restoreRecordSuccessSnack: 'Eintrag erfolgreich wiederhergestellt.',
    }
  }
};

const menuItems = {
  en: {
    menuItems: {
      company: 'Company',
      billing: 'Billing',
      services: 'Services'
    }
  },
  cs: {
    menuItems: {
      company: 'Firma',
      billing: 'Fakturace',
      services: 'Služby'
    }
  },
  hu: {
    menuItems: {
      company: 'Cég',
      billing: 'Számlázás',
      services: 'Szolgáltatások'
    }
  },
  de: {
    menuItems: {
      company: 'Firma',
      billing: 'Fakturierung',
      services: 'Dienste'
    }
  }
};

const billingCard = {
  en: {
    billingCard: {
      latestInvoices: 'Latest invoices',
      seeAll: 'See all',
      lastPayments: 'Last payments'
    }
  },
  cs: {
    billingCard: {
      latestInvoices: 'Nejnovější faktury',
      seeAll: 'Vše',
      lastPayments: 'Poslední platby'
    }
  },
  hu: {
    billingCard: {
      latestInvoices: 'Legutóbbi számlák',
      seeAll: 'Összes',
      lastPayments: 'Az utolsó kifizetések'
    }
  },
  de: {
    billingCard: {
      latestInvoices: 'Letzte Rechnungen',
      seeAll: 'Alle',
      lastPayments: 'Letzte Zahlungen'
    }
  }
};

const servicesCard = {
  en: {
    servicesCard: {
      graphsAndSettings: 'Graphs & settings',
      external: 'External',
      financialAndStatus: 'Lastest installed services',
      seeAll: 'See all',
      lookingForNewService: 'Looking for a new Service?',
      press: 'Press the ',
      toTalk: ' icon to talk with our helpful <strong>customer support</strong>.'
    }
  },
  cs: {
    servicesCard: {
      graphsAndSettings: 'Grafy & nastavení',
      external: 'Externí',
      financialAndStatus: 'Poslední instalované služby',
      seeAll: 'Vše',
      lookingForNewService: 'Hledáte novou službu?',
      press: 'Stiskněte ',
      toTalk: ' k vytvoření požadavku na naši <strong>technickou podporu</strong>.'
    }
  },
  hu: {
    servicesCard: {
      graphsAndSettings: 'Grafikokon és beállítások',
      external: 'Külső',
      financialAndStatus: 'Utoljára telepített szolgáltatások',
      seeAll: 'Összes',
      lookingForNewService: 'Új szolgáltatást keres?',
      press: 'Lépjen kapcsolatba ügyfélszolgálatunkkal a ',
      toTalk: ' <strong>ikonra kattintva!</strong>.'
    }
  },
  de: {
    servicesCard: {
      graphsAndSettings: 'Diagramme & Einstellungen',
      external: 'Extern',
      financialAndStatus: 'Zuletzt eingerichtete Dienste',
      seeAll: 'Alle',
      lookingForNewService: 'Suchen Sie einen neuen Dienst?',
      press: 'Drücken Sie',
      toTalk: ' um eine Anfrage für unseren <strong>technischen Support</strong> zu erstellen.'
    }
  }
};

const recaptcha = {
  en: {
    recaptcha: {
      title: `Hmm, something doesn't look right`,
      desc: `We've detected some unusual traffic, looks like someone on your network
        is sending automatic requests to ours servers. If you want to proceed, please
        let us know that you are not a robot. Beep. Boop.`,
      footer: 'Need help? Contact us at +420 246 035 835'
    }
  },
  cs: {
    recaptcha: {
      title: `Detekovali jsme neobvyklý provoz`,
      desc: `Vypadá to, že někdo z vaší sítě posílá automatické dotazy na naše servery,
        pokud chcete pokračovat, dejte nám vědět, že nejste robot.`,
      footer: 'Potřebujete pomoct? Kontaktujte nás na telefonu +420 246 035 835'
    }
  },
  hu: {
    recaptcha: {
      title: `Szokatlan forgalmat észleltünk`,
      desc: `Úgy tűnik, hogy valaki az Ön hálózatán automatizált lekérdezéseket küld a szervereinknek,
        ha folytatni szeretné, tudassa velünk, hogy nem robot.`,
      footer: `Segítségre van szüksége? Lépjen kapcsolatba velünk a +36 80 206 005 telefonszámon.`
    }
  },
  de: {
    recaptcha: {
      title: `Wir haben ungewöhnlichen Verkehr festgestellt`,
      desc: `Es sieht so aus, als würde jemand in Ihrem Netzwerk automatische Anfragen an unsere Server senden,
        wenn Sie fortfahren möchten, lassen Sie uns wissen, dass Sie kein Roboter sind.`,
      footer: 'Brauchen Sie Hilfe?? Kontaktieren Sie uns unter +420 246 035 835'
    }
  }
};

const invoiceItem = {
  en: {
    invoiceItem: {
      paid: 'Paid ',
      created: 'Created ',
      daysNumber: '{days, plural, =1 { 1 day} other { # days}} ',
      overdue: 'overdue',
      dueIn: 'Due in ',
      today: 'Due today'
    }
  },
  cs: {
    invoiceItem: {
      paid: 'Uhrazeno ',
      created: 'Vytvořeno ',
      daysNumber: '{days, plural, =1 { 1 den} =2 { 2 dny} =3 { 3 dny} =4 { 4 dny} other { # dní}} ',
      overdue: 'po splatnosti',
      dueIn: 'Splatnost za',
      today: 'Splatnost dnes'
    }
  },
  hu: {
    invoiceItem: {
      paid: 'Fizetve',
      created: 'Létrehozva',
      daysNumber: '{days, plural, =1 { 1 nap} =2 { 2 nap} =3 { 3 nap} =4 { 4 nap} other { # nap}} ',
      overdue: 'lejárt',
      dueIn: 'Esedékes ezért',
      today: 'Ma esedékes'
    }
  },
  de: {
    invoiceItem: {
      paid: 'Bezahlt ',
      created: 'Erstellt ',
      daysNumber: '{days, plural, =1 { 1 Tag} =2 { 2 Tage} =3 { 3 Tage} =4 { 4 Tage} other { # Tage}} ',
      overdue: 'überfällig',
      dueIn: 'Fällig in',
      today: 'Fällig heute'
    }
  }
};

const clientServiceItem = {
  en: {
    clientServiceItem: {
      installed: 'Installed on <strong>{date}</strong>'
    }
  },
  cs: {
    clientServiceItem: {
      installed: 'Instalováno dne <strong>{date}</strong>'
    }
  },
  hu: {
    clientServiceItem: {
      installed: 'Telepítve <strong>{date}</strong> napon'
    }
  },
  de: {
    clientServiceItem: {
      installed: 'Installiert am <strong>{date}</strong>'
    }
  }
};

const ticketBase = {
  cs: {
    ticketBase: {
      copyListDuplicaterErr: 'Zvolený email již v seznamu existuje.',
      approvePriceOfferSuccess: 'Cenová nabídka byla schválena.',
      rejectPriceOfferSuccess: 'Cenová nabídka byla zamítnuta.',
      attachedPriceOrderText: `Cenová nabídka čeká na Vaše schválení.
        Další práce na ticketu vyžadují schválení cenové nabídky.`,
      realizationDateText: 'Další práce na ticketu vyžadují schválení navrhovaného termínu realizace.',
      approve: 'Schválit',
      reject: 'Zamítnout',
      rejectPriceOfferPopTitle: 'Zamítnout cenovou nabídku',
      rejectRealizationDatePopTitle: 'Zamítnout datum realizace',
      confirmRealizationDateSuccess: 'Děkujeme! Ticket byl naplánován na domluvený datum realizace.',
      rejectRealizationDateSuccess: 'Navržený termín realizace byl zamítnut. Ticket byl předán k dalšímu zpracování.',
    }
  },
  en: {
    ticketBase: {
      copyListDuplicaterErr: 'Mail address already a part of copy list.',
      approvePriceOfferSuccess: 'Price offer was approved.',
      rejectPriceOfferSuccess: 'Price offer was rejected.',
      attachedPriceOrderText: `The price offer is waiting for your approval.
        Further work on the ticket requires the approval of the price offer.`,
      realizationDateText: 'Further work on the ticket requires the approval of the realization date.',
      approve: 'Approve',
      reject: 'Reject',
      rejectPopTitle: 'Reject price offer',
      confirmRealizationDateSuccess: 'Thank you, the ticket was scheduled for agreed realization date!',
      rejectRealizationDateSuccess: `The suggested implementation date was rejected.
        The ticket has been handed over for further processing.`,
    }
  },
  hu: {
    ticketBase: {
      copyListDuplicaterErr: 'A kiválasztott e-mail már létezik a listában.',
      approvePriceOfferSuccess: 'Az árajánlatot jóváhagyták.',
      rejectPriceOfferSuccess: 'Az árajánlatot elutasították.',
      attachedPriceOrderText: `Az árajánlat az Ön jóváhagyására vár. A ticketen végzett további
      munkához az árajánlat jóváhagyása szükséges.`,
      realizationDateText: 'A tickettel kapcsolatos további munkához a javasolt végrehajtási időpont jóváhagyása szükséges.',
      approve: 'Jóváhagyás',
      reject: 'Elutasítani',
      rejectPriceOfferPopTitle: 'Az árajánlat visszautasítása',
      rejectRealizationDatePopTitle: 'A végrehajtás dátumának visszautasítása',
      confirmRealizationDateSuccess: 'Köszönjük! A ticketet a megállapodott végrehajtási időpontra ütemezték.',
      rejectRealizationDateSuccess: `A végrehajtás javasolt időpontját elutasították. A ticketet továbbítottuk további feldolgozásra.`,
    }
  },
  de: {
    ticketBase: {
      copyListDuplicaterErr: 'Die ausgewählte E-Mail-Adresse ist bereits in der Liste vorhanden.',
      approvePriceOfferSuccess: 'Das Preisangebot wurde genehmigt.',
      rejectPriceOfferSuccess: 'Das Preisangebot wurde abgelehnt.',
      attachedPriceOrderText: `Das Preisangebot wartet auf Ihre Genehmigung.
        Die weitere Bearbeitung des Tickets erfordert
        die Genehmigung des Preisangebots.`,
      realizationDateText: 'Die weitere Bearbeitung des Tickets erfordert die Genehmigung des vorgeschlagenen Umsetzungstermins.',
      approve: 'Genehmigen',
      reject: 'Ablehnen',
      rejectPriceOfferPopTitle: 'Das Preisangebot ablehnen',
      rejectRealizationDatePopTitle: 'Umsetzungsdatum ablehnen',
      confirmRealizationDateSuccess: 'Vielen Dank! Das Ticket wurde für das vereinbarte Umsetzungsdatum eingeplant.',
      rejectRealizationDateSuccess: `Der vorgeschlagene Umsetzungstermin wurde abgelehnt.
        Das Ticket ist zur weiteren Bearbeitung weitergeleitet worden.`,
    }
  }
};

const ticketState = {
  cs: {
    ticketState: {
      CLOSED: 'Uzavřený',
      PLANNED: 'Plánovaný',
      RESOLVED: 'Vyřešený',
      ACCEPTED: 'Přijatý',
      IN_REALISATION: 'Pracujeme na tom',
      WAITING_FOR_CLIENT: 'Čeká na klienta',
      WAITING_AUTHORIZATION: 'Čeká na ověření'
    }
  },
  en: {
    ticketState: {
      CLOSED: 'Closed',
      PLANNED: 'Planned',
      RESOLVED: 'Resolved',
      ACCEPTED: 'Accepted',
      IN_REALISATION: 'In Realisation',
      WAITING_FOR_CLIENT: 'Waiting for Client',
      WAITING_AUTHORIZATION: 'Waiting for authorization'
    }
  },
  hu: {
    ticketState: {
      CLOSED: 'Lezárva',
      PLANNED: 'Tervezett',
      RESOLVED: 'Megoldva',
      ACCEPTED: 'Beértkezett',
      IN_REALISATION: 'Dolgozunk rajta',
      WAITING_FOR_CLIENT: 'Várakozás az ügyfélre',
      WAITING_AUTHORIZATION: 'Ellenőrzésre vár'
    }
  },
  de: {
    ticketState: {
      CLOSED: 'Geschlossen',
      PLANNED: 'Geplant',
      RESOLVED: 'Gelöst',
      ACCEPTED: 'Angenommen',
      IN_REALISATION: 'Wir arbeiten daran',
      WAITING_FOR_CLIENT: 'Warten auf den Kunden',
      WAITING_AUTHORIZATION: 'Warten auf Autorisierung'
    }
  }
};

const ticketList = {
  en: {
    ticketList: {
      emptyStateTitle: 'Nothing to be found..',
      emptyStateDescPreLink: 'There are no results with this filter on. Consider ',
      emptyStateDescLink: 'resetting',
      emptyStateDescPostLink: ' it.'
    }
  },
  cs: {
    ticketList: {
      emptyStateTitle: 'Žádný ticket nenalezen..',
      emptyStateDescPreLink: 'Neexistují žádné tickety s tímto filtrem. Zkuste ho ',
      emptyStateDescLink: 'vyresetovat',
      emptyStateDescPostLink: '.'
    }
  },
  hu: {
    ticketList: {
      emptyStateTitle: 'A ticket nem található...',
      emptyStateDescPreLink: 'Nincsenek ticketek ezzel a szűrővel. Próbálja meg ',
      emptyStateDescLink: 'újraindítani',
      emptyStateDescPostLink: '.'
    }
  },
  de: {
    ticketList: {
      emptyStateTitle: 'Kein Ticket gefunden.',
      emptyStateDescPreLink: 'Es gibt keine Tickets mit diesem Filter. Versuchen Sie ',
      emptyStateDescLink: 'es zurückzusetzen',
      emptyStateDescPostLink: '.'
    }
  }
};

const ticketsCard = {
  en: {
    ticketsCard: {
      emptyStateTitle: `You don't have any tickets yet..`,
      emptyStateDesc: 'You can add one by clicking on the support button at the bottom right of the page.',
      emptyStateButton: 'Open support now'
    }
  },
  cs: {
    ticketsCard: {
      emptyStateTitle: `Dosud nebyl zadán žádný požadavek.`,
      emptyStateDesc: 'Nový požadavek můžete přidat kliknutím na tlačítko podpory v pravo dole na stránce.',
      emptyStateButton: 'Otevřít podporu nyní'
    }
  },
  hu: {
    ticketsCard: {
      emptyStateTitle: `Önnek nincs nyitott hibajegye`,
      emptyStateDesc: 'Új hibajegyet a jobb alsó sarokban található támogatás gombra kattintva nyithat.',
      emptyStateButton: 'Támogatás megnyitása most'
    }
  },
  de: {
    ticketsCard: {
      emptyStateTitle: `Es wurde noch keine Anfrage gestellt.`,
      emptyStateDesc: 'Sie können eine neue Anfrage hinzufügen, indem Sie auf die Support-Schaltfläche unten rechts auf der Seite klicken.',
      emptyStateButton: 'Support jetzt öffnen'
    }
  }
};

const ticketAddContainer = {
  en: {
    ticketAddContainer: {
      filesLabel: 'Select up to 5 files to attach to ticket',
      addTicket: 'Add new Ticket'
    }
  },
  cs: {
    ticketAddContainer: {
      filesLabel: 'Připojte až 5 souborů k požadavku',
      addTicket: 'Přidat nový Požadavek'
    }
  },
  hu: {
    ticketAddContainer: {
      filesLabel: 'Legfeljebb 5 fájl csatolása az igényhez',
      addTicket: 'Új igény hozzáadása',
    }
  },
  de: {
    ticketAddContainer: {
      filesLabel: 'Hängen Sie bis zu 5 Dateien an eine Anfrage an',
      addTicket: 'Neue Anfrage hinzufügen'
    }
  }
};

const ticketsCommon = {
  en: {
    ticketsCommon: {
      numMore: '{num} more messages',
      openHistoryTitle: `Don't see what you're looking for?`,
      openHistoryPress: 'Press ',
      openHistoryToShow: ' to open list of all tickets, or ',
      specialWindow: 'open them in a new window.'
    }
  },
  cs: {
    ticketsCommon: {
      numMore: '{num} dalších zpráv',
      openHistoryTitle: 'Nevidíte, co potřebujete?',
      openHistoryPress: 'Stiskněte ',
      openHistoryToShow: ' pro otevření <strong>seznamu všech požadavků</strong>, nebo si je',
      specialWindow: 'zobrazte ve vlastním okně.'
    }
  },
  hu: {
    ticketsCommon: {
      numMore: '{num} további üzenetek',
      openHistoryTitle: 'Nem találja, amit keres?',
      openHistoryPress: 'Nyomja meg ',
      openHistoryToShow: ' az összes hibajegy <strong>megtekintéséhez</strong>, vagy ',
      specialWindow: 'nyissa meg egy új ablakban',
    }
  },
  de: {
    ticketsCommon: {
      numMore: '{num} weitere Nachrichten',
      openHistoryTitle: 'Sie sehen nicht, was Sie brauchen?',
      openHistoryPress: 'Drücken Sie',
      openHistoryToShow: ' um die <strong>Liste aller Anfragen</strong> zu öffnen, oder sie',
      specialWindow: 'in eigenem Fenster anzuzeigen.'
    }
  }
};

const search = {
  en: {
    search: {
      inputPlaceholder: 'Enter any keyword to start searching..',
      emptyStateHeading: 'No results were found, sorry!',
      emptySuggestStateHeading: 'Uh oh, nothing to suggest, sorry!',
      emptyStateDesc: 'Looks like there is nothing under <strong>{keyword}</strong>, try editing the search term.',
      entities: {
        ticket: 'Ticket',
        clientUser: 'User',
        clientPersonServerParkAccess: 'Server Park Access',
        invoice: 'Invoice',
        cloudManagedServer: 'Cloud Managed Server',
        windowsManagedServer: 'Managed Windows Server',
        windowsManagedCluster: 'Managed Windows Cluster',
        windowsManagedBasic: 'Managed Windows Basic',
        windowsManagedCloud: 'Managed Windows Cloud',
        managedCluster: 'Managed Cluster',
        managedServer: 'Managed Server',
        m1ManagedCluster: 'M1 Managed Cluster',
        m1ManagedServer: 'M1 Managed Server',
        m2ManagedCluster: 'M2 Managed Cluster',
        m2ManagedServer: 'M2 Managed Server',
        serverHosting: 'Server Hosting',
        rackHosting: 'Rack Hosting',
        dedicatedServer: 'Dedicated Server',
        vds: 'VDS',
        domain: 'Domain'
      },
      navigation: {
        start: 'Press arrow ',
        or: ' or ',
        middle: ' to cycle through results. When item is selected, press ',
        end: ' to navigate to it\'s detail.'
      }
    }
  },
  cs: {
    search: {
      inputPlaceholder: 'Začněte vyhledávat..',
      emptyStateHeading: 'Je nám líto, nenalezli jsme žádné výsledky!',
      emptySuggestStateHeading: 'Bohužel nebyla nalezena žádná data.',
      emptyStateDesc: 'Zkuste prosím upravit vyhledávací dotaz <strong>{keyword}</strong>.',
      entities: {
        ticket: 'Ticket',
        clientUser: 'Uživatel',
        clientPersonServerParkAccess: 'Přístup do Server Parku',
        invoice: 'Faktura',
        cloudManagedServer: 'Cloud Managed Server',
        windowsManagedServer: 'Managed Windows Server',
        windowsManagedCluster: 'Managed Windows Cluster',
        windowsManagedBasic: 'Managed Windows Basic',
        windowsManagedCloud: 'Managed Windows Cloud',
        managedCluster: 'Managed Cluster',
        managedServer: 'Managed Server',
        m1ManagedCluster: 'M1 Managed Cluster',
        m1ManagedServer: 'M1 Managed Server',
        m2ManagedCluster: 'M2 Managed Cluster',
        m2ManagedServer: 'M2 Managed Server',
        serverHosting: 'Server Hosting',
        rackHosting: 'Rack Hosting',
        dedicatedServer: 'Dedikovaný Server',
        vds: 'VDS',
        domain: 'Domain'
      },
      navigation: {
        start: 'Stiskněte šipku ',
        or: ' nebo ',
        middle: ' k procházení výsledků. Když je položka vybrána, stisknutím ',
        end: ' otevřete její detail.'
      }
    }
  },
  hu: {
    search: {
      inputPlaceholder: 'Keresés megkezdése..',
      emptyStateHeading: 'Sajnálom, nem találtunk találatot!',
      emptySuggestStateHeading: 'Nincs találat.',
      emptyStateDesc: 'Kérjük módosítson a keresésen <strong>{keyword}</strong>.',
      entities: {
        ticket: 'Ticket',
        clientUser: 'Felhasználó',
        clientPersonServerParkAccess: 'Hozzáférés a Server Park-hoz',
        invoice: 'Számla',
        cloudManagedServer: 'Cloud Managed Server',
        windowsManagedServer: 'Managed Windows Server',
        windowsManagedCluster: 'Managed Windows Cluster',
        windowsManagedBasic: 'Managed Windows Basic',
        windowsManagedCloud: 'Managed Windows Cloud',
        managedCluster: 'Managed Cluster',
        managedServer: 'Managed Server',
        m1ManagedCluster: 'M1 Managed Cluster',
        m1ManagedServer: 'M1 Managed Server',
        m2ManagedCluster: 'M2 Managed Cluster',
        m2ManagedServer: 'M2 Managed Server',
        serverHosting: 'Server Hosting',
        rackHosting: 'Rack Hosting',
        dedicatedServer: 'Dedikált szerver',
        vds: 'VDS',
        domain: 'Domain'
      },
      navigation: {
        start: 'Nyomja meg a nyilat ',
        or: ' vagy ',
        middle: ' a találatok közötti lapozáshoz. Ha kiválasztott egy elemet, nyomja meg a ',
        end: ' részletek megnyitásához.'
      }
    }
  },
  de: {
    search: {
      inputPlaceholder: 'Suche starten..',
      emptyStateHeading: 'Tut uns leid, wir haben keine Ergebnisse gefunden!',
      emptySuggestStateHeading: 'Leider wurden keine Daten gefunden.',
      emptyStateDesc: 'Bitte versuchen Sie, Ihre Suchanfrage <strong>{keyword}</strong> zu ändern.',
      entities: {
        ticket: 'Ticket',
        clientUser: 'Benutzer',
        clientPersonServerParkAccess: 'Zugang zu Server Park',
        invoice: 'Rechnung',
        cloudManagedServer: 'Cloud Managed Server',
        windowsManagedServer: 'Managed Windows Server',
        windowsManagedCluster: 'Managed Windows Cluster',
        windowsManagedBasic: 'Managed Windows Basic',
        windowsManagedCloud: 'Managed Windows Cloud',
        managedCluster: 'Managed Cluster',
        managedServer: 'Managed Server',
        m1ManagedCluster: 'M1 Managed Cluster',
        m1ManagedServer: 'M1 Managed Server',
        m2ManagedCluster: 'M2 Managed Cluster',
        m2ManagedServer: 'M2 Managed Server',
        serverHosting: 'Server Hosting',
        rackHosting: 'Rack Hosting',
        dedicatedServer: 'Dedizierter Server',
        vds: 'VDS',
        domain: 'Domain'
      },
      navigation: {
        start: 'Drücken Sie die Pfeiltaste ',
        or: ' oder ',
        middle: ' zum Durchsuchen der Ergebnisse. Wenn ein Eintrag ausgewählt ist, drücken Sie auf ',
        end: ' um seine Details zu öffnen.'
      }
    }
  }
};

const ticketTopics = {
  en: {
    ticketTopics: {
      TECHNICAL: 'Technical',
      BILLING: 'Billing',
      SALES: 'Sales'
    }
  },
  cs: {
    ticketTopics: {
      TECHNICAL: 'Technické témata',
      BILLING: 'Účtování',
      SALES: 'Prodeje a služby'
    }
  },
  hu: {
    ticketTopics: {
      TECHNICAL: 'Műszaki témák',
      BILLING: 'Számlázás',
      SALES: 'Eladás és szolgáltatások'
    }
  },
  de: {
    ticketTopics: {
      TECHNICAL: 'Technische Themen',
      BILLING: 'Abrechnung',
      SALES: 'Verkäufe und Dienste'
    }
  }
};

export const ticketsFilter = {
  en: {
    ticketsFilter: {
      buttonPrefix: 'Status includes',
      buttonPlaceholder: 'Ticket status filter'
    }
  },
  cs: {
    ticketsFilter: {
      buttonPrefix: 'Status obsahuje',
      buttonPlaceholder: 'Filtr statusů ticketu'
    }
  },
  hu: {
    ticketsFilter: {
      buttonPrefix: 'Állapot tartalmazza',
      buttonPlaceholder: 'Ticket státusz szűrő'
    }
  },
  de: {
    ticketsFilter: {
      buttonPrefix: 'Der Status enthält',
      buttonPlaceholder: 'Filter für den Ticketstatus'
    }
  }
};

export const ticketsMessage = {
  en: {
    ticketsMessage: {
      failSnack: 'Message could not be added - {err} - please try again'
    }
  },
  cs: {
    ticketsMessage: {
      failSnack: 'Zpráva nemohla být přidána - {err} - zkuste to prosím znovu.'
    }
  },
  hu: {
    ticketsMessage: {
      failSnack: 'Üzenetet nem sikerült hozzáadni - {err} - kérjük, próbálja meg újra.'
    }
  },
  de: {
    ticketsMessage: {
      failSnack: 'Nachricht konnte nicht hinzugefügt werden - {err} - bitte versuchen Sie es erneut.'
    }
  }
};

export const ticketsTrigger = {
  en: {
    ticketsTrigger: {
      supportButton: 'Support',
      supportAddButtonTooltip: 'Select topic and create new ticket',
      closedGroupHeading: 'All Closed statuses',
      openGroupHeading: 'All Open statuses',
      title: 'VSHosting Support HUB',
      topicSelect: 'Select Topic and Add new Support Ticket',
      or: 'OR',
      showHistory: 'Show Support Ticket History',
      newTicket: 'New support Ticket with topic <strong>{topic}</strong>',
      copyList: 'Ticket copy list',
      copyListDesc: 'All ticket communication will be send as CC to:',
      subscriptionTooltip: 'Add a recipient who will receive information about the ticket',
      unwatch: 'Stop following the ticket',
      watch: 'Start following the ticket',
      watchingTooltip: 'You are watching the ticket and you will be notified of new messages',
      notWatchingTooltip: 'You are not watching the ticket, you will be not notified of new messages',
      technicalSupport: 'Technical support',
      technicalSupportDesc: 'In case of critical issues (outages or critical failure of server)',
      salesDepartment: 'Sales department',
      phonePrefix: '420',
      phoneSupport: '246035835',
      mailSupport: 'podpora@vshosting.cz',
      mailSalesDepartment: 'obchod@vshosting.cz'
    }
  },
  cs: {
    ticketsTrigger: {
      supportButton: 'Podpora',
      closedGroupHeading: 'Všechny zavřené statusy',
      supportAddButtonTooltip: 'Vyberte předmět a přidejte nový požadavek',
      openGroupHeading: 'Všechny otevřené statusy',
      title: 'Centrum podpory VSHosting',
      topicSelect: 'Vyberte předmět a přidejte nový požadavek',
      or: 'NEBO',
      showHistory: 'Zobrazit historii požadavků',
      newTicket: 'Nový Požadavek s předmětem <strong>{topic}</strong>',
      copyList: 'Seznam příjemců informací o požadavku',
      copyListDesc: 'Veškerá komunikace o požadavku bude odeslána jako kopie.',
      subscriptionTooltip: 'Přidat příjemce informací o požadavku',
      unwatch: 'Přestat sledovat požadavek',
      watch: 'Začít sledovat požadavek',
      watchingTooltip: 'Tento požadavek sledujete a budete notifikován e-mailem o nových zprávách',
      notWatchingTooltip: 'Tento požadavek nesledujete, nebudete notifikován e-mailem o nových zprávách',
      technicalSupport: 'Technická podpora',
      technicalSupportDesc: 'V případě kritické situace (výpadky či kritická nefunkčnost části serveru)',
      salesDepartment: 'Obchodní oddělení',
      phonePrefix: '420',
      phoneSupport: '246035835',
      mailSupport: 'podpora@vshosting.cz',
      mailSalesDepartment: 'obchod@vshosting.cz'
    }
  },
  hu: {
    ticketsTrigger: {
      supportButton: 'Támogatás',
      closedGroupHeading: 'Minden lezárt státusz',
      supportAddButtonTooltip: 'Válassza ki a tárgyat és adjon hozzá új igényt',
      openGroupHeading: 'Minden nyitott státusz',
      title: 'A VSHosting támogatási központja',
      topicSelect: 'Válassza ki a tárgyat és adjon hozzá új igényt',
      or: 'VAGY',
      showHistory: 'Előzmények megtekintése',
      newTicket: 'Új Igény <strong>{topic}</strong> tárggyal',
      copyList: 'Az igénnyel kapcsolatos információk címzettjeinek listája',
      copyListDesc: 'Az igénnyel kapcsolatos minden kommunikáció másolatban kerül elküldésre.',
      subscriptionTooltip: 'Az igénnyel kapcsolatos információk címzettjének hozzáadása',
      unwatch: 'Az igény nyomon követésének leállítása',
      watch: 'Az igény nyomon követésének megkezdése',
      watchingTooltip: 'Ezt az igény nyomonköveti, és az új üzenetekről e-mailben kap értesítést',
      notWatchingTooltip: 'Ön nem követi ezt az igényt, nem kap e-mailben értesítést az új üzenetekről',
      technicalSupport: 'Műszaki támogatás',
      technicalSupportDesc: 'Kritikus helyzet esetén (kiesés vagy a szerver egy részének kritikus meghibásodása)',
      salesDepartment: 'Sales támogatás',
      phonePrefix: '36',
      phoneSupport: '80206005',
      mailSupport: 'podpora@vshosting.cz',
      mailSalesDepartment: 'obchod@vshosting.cz'
    }
  },
  de: {
    ticketsTrigger: {
      supportButton: 'Support',
      closedGroupHeading: 'Alle geschlossenen Status',
      supportAddButtonTooltip: 'Wählen Sie einen Betreff aus und fügen Sie eine neue Anfrage hinzu',
      openGroupHeading: 'Alle offenen Status',
      title: 'VSHosting Support Center',
      topicSelect: 'Wählen Sie einen Betreff aus und fügen Sie eine neue Anfrage hinzu',
      or: 'ODER',
      showHistory: 'Anfrageverlauf anzeigen',
      newTicket: 'Neue Anfrage mit Betreff <strong>{topic}</strong>',
      copyList: 'Liste der Empfänger der Informationen über die Anfrage',
      copyListDesc: 'Alle Mitteilungen über die Anfrage werden in Kopie versandt.',
      subscriptionTooltip: 'Empfänger von Informationen über die Anfrage hinzufügen',
      unwatch: 'Nicht mehr die Anfrage verfolgen',
      watch: 'Verfolgung der Anfrage beginnen',
      watchingTooltip: 'Sie verfolgen diese Anfrage und werden per E-Mail über neue Nachrichten informiert',
      notWatchingTooltip: 'Sie verfolgen diese Anfrage nicht, Sie werden nicht per E-Mail über neue Nachrichten informiert',
      technicalSupport: 'Technischer Support',
      technicalSupportDesc: 'Im Falle einer kritischen Situation (Ausfälle oder kritisches Nichtfunktionieren eines Teils des Servers)',
      salesDepartment: 'Vertriebsabteilung',
      phonePrefix: '49',
      phoneSupport: '22194899055',
      mailSupport: 'support@vshosting.de',
      mailSalesDepartment: 'vertrieb@vshosting.de'
    }
  }
};

export const wsReconnect = {
  en: {
    wsReconnect: {
      reconnecting: 'Reconnecting websocket...',
      outdated: 'The data may not be actual',
      connected: 'Connected!'
    }
  },
  cs: {
    wsReconnect: {
      reconnecting: 'Připojování websocketu...',
      outdated: 'Vaše data nemusí být aktuální',
      connected: 'Připojeno!'
    }
  },
  hu: {
    wsReconnect: {
      reconnecting: 'A websocket csatlakoztatása...',
      outdated: 'Előfordulhat, hogy az Ön adatai nem naprakészek',
      connected: 'Csatlakoztatva!'
    }
  },
  de: {
    wsReconnect: {
      reconnecting: 'Verbinden eines Websockets...',
      outdated: 'Die Daten sind möglicherweise nicht aktuell',
      connected: 'Verbunden!'
    }
  }
};

const keys = efg('ticketCopyList');

const ticketCopyList = {
  en: {
    ticketCopyList: {
      addButton: 'Add to copy list',
      empty: '👀 No Copy E-mails added, use the form below to add the first one.',
      securedCommunication: 'Secured communication',
      addAnyway: 'Add anyway',
      secureDialog: `
        <p>You are adding a Copy E-mail which does not belong to any company user
          with permission to accept secured communication.</p>

        <p>Because company {company} has an option of a secured communication enabled,
          only notifications about request change with a link to the content will be sent.
          Recipients which are not authorized to access the client zone will thus be unable to view the content.</p>
        `
    },
    // form
    [keys.labels.email]: 'E-mail',
    [keys.errors.email.email]: 'E-mail has wrong format.',
    [keys.errors.email.required]: 'E-mail is required.'
  },
  cs: {
    ticketCopyList: {
      addButton: 'Přidat do seznamu',
      empty: '👀 Nejsou zadány žádné E-maily pro příjem kopií, použijte formulář níže pro přidání.',
      securedCommunication: 'Zabezpečená komunikace',
      addAnyway: 'Přesto přidat',
      secureDialog: `
        <p>Do seznamu příjemců kopie požadavků přidáváte email, který nepatří žádnému uživateli firmy {company},
          který je oprávněný přijímat zabezpečenou komunikaci.</p>

        <p>Vzhledem k tomu, že má firma {company} nastavenou volbu zabezpečené komunikace,
          budou emailem zaslány pouze notifikace o změně požadavku s odkazem na vlastní obsah.
          Příjemci, kteří nejsou oprávněni k přístupu do klientské zóny tedy nebudou moci obsah zobrazit.</p>
        `
    },
    // form
    [keys.labels.email]: 'E-mail',
    [keys.errors.email.email]: 'E-mail má špatný formát.',
    [keys.errors.email.required]: 'E-mail je vyžadován.'
  },
  hu: {
    ticketCopyList: {
      addButton: 'Hozzáadás a listához',
      empty: '👀 Nincs megadva e-mail cím a másolatok fogadásához, használja az alábbi űrlapot a hozzáadáshoz.',
      securedCommunication: 'Biztonságos kommunikáció',
      addAnyway: 'Hozzáadás ennek ellenére',
      secureDialog: `
        <p>Egy olyan e-mailt ad hozzá a másolatkérések címzettjeinek listájához, amely nem tartozik a cég {company} egyetlen
        felhasználójához sem, aki jogosult a biztonságos kommunikáció fogadására.</p>

        <p>Mivel a cégnél {company} be van állítva a biztonságos kommunikáció opció,
        csak a módosítási igényekről szóló értesítéseket küldi el e-mailben, a tényleges tartalomra mutató hivatkozással.
        Ezért azok a címzettek, akiknek nincs jogosultságuk az ügyfélzónához való hozzáférésre, nem tekinthetik meg a tartalmat.</p>
        `
    },
    // form
    [keys.labels.email]: 'E-mail',
    [keys.errors.email.email]: 'Az e-mail rossz formátumú.',
    [keys.errors.email.required]: 'Az e-mail szükséges.'
  },
  de: {
    ticketCopyList: {
      addButton: 'Zur Liste hinzufügen',
      empty: '👀 Es sind keine E-Mail-Adressen eingetragen, um Kopien zu erhalten. Verwenden Sie das Formular unten, um eine hinzuzufügen.',
      securedCommunication: 'Sichere Kommunikation',
      addAnyway: 'Dennoch hizufügen',
      secureDialog: `
        <p>Sie fügen der Liste der Empfänger von Kopien der Anfragen eine E-Mail-Adresse hinzu, die keinem Benutzer von {company} gehört,
          der berechtigt ist, sichere Mitteilungen zu empfangen.</p>

        <p>Da die Firma {company} die Option Sichere Kommunikation aktiviert hat,
          werden nur Benachrichtigungen über Änderungen der Anfrage per E-Mail mit einem Link zum eigentlichen Inhalt versandt.
          Daher können Empfänger, die nicht zum Zugriff auf den Kundenbereich berechtigt sind, den Inhalt nicht sehen.</p>
        `
    },
    // form
    [keys.labels.email]: 'E-mail',
    [keys.errors.email.email]: 'Die E-Mail-Adresse hat ein falsches Format.',
    [keys.errors.email.required]: 'E-Mail-Adresse ist erforderlich.'
  }
};

const ticketUnlock = {
  en: {
    ticketUnlock: {
      heading: 'Ticket is locked to a single employee',
      tooltip: 'Ticket is locked to a single employee, click to find out more and optionally unlock it.',
      desc: `
        <p>Your ticket would be best resolved by the same employee who was previously responsible for dealing with it;
          this will help ensure that the resolution of your request wil have greater continuity.</p>

        <p>You can expect a further reply within 72 hours.</p>

        <p>If you don't agree with these lock for employee who was previously responsible for dealing with your request,
          you can always cancel the lock.<p>
      `,
      confirmButton: 'Cancel lock'
    }
  },
  cs: {
    ticketUnlock: {
      heading: 'Požadavek zpracováván konkrétním pracovníkem',
      tooltip: 'Požadavek zpracováván konkrétním pracovníkem, klikněte pro více informací a případné odemčení.',
      desc: `
        <p>Váš požadavek nejlépe vyřeší pracovník, který byl dosud odpovědný za jeho řešení,
          neboť řešení požadavku bude mít lepší kontinuitu.</p>

        <p>Odpověď můžete očekávat do 72 hodin.</p>

        <p>Pokud nesouhlasíte s tímto zámkem na konkrétního pracovníka, který byl dosud zodpovědný
          za řešení Vašeho požadavku, klikněte na níže uvedený odkaz. Vaši žádost bude řešit jiný pracovník.</p>
      `,
      confirmButton: 'Zrušit uzamčení'
    }
  },
  hu: {
    ticketUnlock: {
      heading: 'Az igényt egy adott munkatárs dolgozza fel',
      tooltip: 'Az igényt egy adott munkatárs dolgozza fel, kattintson a további információkért és az esetleges feloldásért.',
      desc: `
        <p>Az Ön igényét az a munkatárs kezeli a legjobban, aki eddig is felelős volt annak megoldásáért,
          mivel az igény megoldása így folyamatosabb lesz.</p>

        <p>A válaszra 72 órán belül számíthat.</p>

        <p>Ha nem ért egyet ezzel a zárolással az a konkrét munkatárs, aki eddig felelős volt
          az igénye megoldásáért, kérjük, kattintson az alábbi hivatkozásra. Az igényét egy másik munkatárs fogja kezelni.</p>
      `,
      confirmButton: 'Zárolás megszüntetése'
    }
  },
  de: {
    ticketUnlock: {
      heading: 'Anfrage wird von einem bestimmten Mitarbeiter bearbeitet',
      tooltip:  `Anfrage wird von einem bestimmten Mitarbeiter bearbeitet.
        Klicken Sie für weitere Informationen und eine mögliche Entsperrung.`,
      desc: `
        <p>Ihre Anfrage wird am besten von dem Mitarbeiter bearbeitet, der bisher für die Lösung der Anfrage zuständig war,
          da die Lösung der Anfrage eine bessere Kontinuität aufweist.</p>

        <p>Sie können innerhalb von 72 Stunden mit einer Antwort rechnen.</p>

        <p>Wenn Sie mit dieser Sperre für einen bestimmten Mitarbeiter,
          der bisher für die Bearbeitung Ihrer Anfrage zuständig war,
          nicht einverstanden sind, klicken Sie bitte auf den unten stehenden Link.
          Ihre Anfrage wird von einem anderen Mitarbeiter bearbeitet.</p>
      `,
      confirmButton: 'Sperre aufheben'
    }
  }
};

const ticketRating = {
  en: {
    ticketRating: {
      title: 'Do you feel like you request has been resolved?',
      desc: 'Please let us know how satisfied you were with the process, ticket will then be closed.',
      goodRating: 'I was satisfied',
      badRating: 'It could have went better',
      badRatingHeading: 'Please rate your satisfaction with the ticket solution',
      sendRating: 'Send rating',
      messagePlaceholder: 'Your comments and suggestions',
      speedTitle: 'Speed',
      expertiseTitle: 'Expertise',
      communicationTitle: 'Communication'
    }
  },
  cs: {
    ticketRating: {
      title: 'Považujete požadavek za vyřešený?',
      desc: 'Dejte nám, prosím, vědět, jak jste byli spokojeni s řešením, poté bude ticket uzavřen.',
      goodRating: 'Byl/a jsem spokojen/a',
      badRating: 'Mohlo to být lepší',
      badRatingHeading: 'Ohodnoťte prosím Vaši spokojenost s řešením tiketu',
      sendRating: 'Odeslat hodnocení',
      messagePlaceholder: 'Vaše poznámky a připomínky',
      speedTitle: 'Rychlost',
      expertiseTitle: 'Odbornost',
      communicationTitle: 'Komunikace'
    }
  },
  hu: {
    ticketRating: {
      title: 'Megoldottnak tekinti az igényét?',
      desc: 'Kérjük, tájékoztasson, mennyire elégedett a megoldással, ezután a ticketet lezárjuk',
      goodRating: 'Elégedett voltam',
      badRating: 'Lehetett volna jobb is',
      badRatingHeading: 'Kérjük, értékelje, mennyire elégedett a ticket megoldásával',
      sendRating: 'Értékelés elküldése',
      messagePlaceholder: 'Az Ön megjegyzései',
      speedTitle: 'Sebessége',
      expertiseTitle: 'Szakmaiság',
      communicationTitle: 'Kommunikáció'
    }
  },
  de: {
    ticketRating: {
      title: 'Betrachten Sie die Anfrage als gelöst?',
      desc: 'Bitte teilen Sie uns mit, wie zufrieden Sie mit der Lösung waren, dann wird das Ticket geschlossen.',
      goodRating: 'Ich war zufrieden',
      badRating: 'Es hätte besser sein können',
      badRatingHeading: 'Bitte bewerten Sie Ihre Zufriedenheit mit der Ticketlösung',
      sendRating: 'Bewertung absenden',
      messagePlaceholder: 'Ihre Notizen und Kommentare',
      speedTitle: 'Schnelligkeit',
      expertiseTitle: 'Kompetenz',
      communicationTitle: 'Kommunikation'
    }
  }
};

const ticketAuthorization = {
  en: {
    ticketAuthorization: {
      heading: 'Ticket needs to be authorized',
      descSingle: `This ticket was created from your E-mail and need to be authorized first.`,
      descMulti: `Since your E-mail is associated with multiple clients, select which the ticket belongs to first.`,
      clientSelectLabel: 'Select which client to assign the ticket to',
      authorize: 'Authorize ticket',
      assign: '& assign to {company}',
      authorizeSuccess: 'The ticket has been authorized and assigned to company of your choice.'
    }
  },
  cs: {
    ticketAuthorization: {
      heading: 'Ticket je potřeba autorizovat',
      descSingle: 'Tento ticket byl vytvořen z vašeho E-mailu a je ho potřeba nejdříve autorizovat.',
      descMulti: 'Jelikož je váš E-mail spojen s několika klienty, vyberte ke kterému ticket patří.',
      clientSelectLabel: 'Vyberte, kterému klientovi ticket přiřadit',
      authorize: 'Autorizovat ticket',
      assign: 'a přiřadit {company}',
      authorizeSuccess: 'Ticket byl autorizován a přiřazen vámi zvolené firmě.'
    }
  },
  hu: {
    ticketAuthorization: {
      heading: 'A ticket engedélyezésre szorul',
      descSingle: 'Ezt a ticketet az Ön e-mailjéből hozták létre, és először engedélyeztetni kell.',
      descMulti: 'Mivel az Ön e-mailje több ügyfélhez is kapcsolódik, kérjük, válassza ki, melyik tickethez tartozik.',
      clientSelectLabel: 'Válassza ki, melyik ügyfélhez rendelje a ticketet',
      authorize: 'Ticket engedélyezése',
      assign: 'és hozzárendelése {company}',
      authorizeSuccess: 'A ticketet engedélyeztük és hozzárendeltük az Ön által kiválasztott vállalathoz.'
    }
  },
  de: {
    ticketAuthorization: {
      heading: 'Das Ticket muss autorisiert werden',
      descSingle: 'Dieses Ticket wurde von Ihrer E-Mail-Adresse aus erstellt und muss zunächst autorisiert werden.',
      descMulti: 'Da Ihre E-Mail mit mehreren Kunden verbunden ist, wählen Sie aus, zu welchem das Ticket gehört.',
      clientSelectLabel: 'Wählen Sie den Kunden, dem das Ticket zugewiesen werden soll',
      authorize: 'Ticket autorisieren',
      assign: 'und {company} zuweisen',
      authorizeSuccess: 'Das Ticket wurde genehmigt und der Firma Ihrer Wahl zugewiesen.'
    }
  }
};

const files = {
  en: {
    files: {
      addMoreFiles: 'Add more files'
    }
  },
  cs: {
    files: {
      addMoreFiles: 'Přidat další soubory'
    }
  },
  hu: {
    files: {
      addMoreFiles: 'További fájlok hozzáadása'
    }
  },
  de: {
    files: {
      addMoreFiles: 'Weitere Dateien hinzufügen'
    }
  }
};

const paymentItem = {
  en: {
    paymentItem: {
      on: 'on '
    }
  },
  cs: {
    paymentItem: {
      on: 'dne '
    }
  },
  hu: {
    paymentItem: {
      on: 'napon '
    }
  },
  de: {
    paymentItem: {
      on: 'am '
    }
  }

};

const dashboard = {
  en: {
    dashboard: {
      meta: {
        title: 'Dashboard'
      },
      company: 'Company',
      billing: 'Billing',
      tickets: '<strong>Open</strong> Support tickets',
      services: 'Services'
    }
  },
  cs: {
    dashboard: {
      meta: {
        title: 'Dashboard'
      },
      company: 'Firma',
      billing: 'Fakturace',
      tickets: '<strong>Otevřené</strong> Požadavky',
      services: 'Služby'
    }
  },
  hu: {
    dashboard: {
      meta: {
        title: 'Dashboard'
      },
      company: 'Cég',
      billing: 'Számlázás',
      tickets: 'Hibajegyek <strong>megnyitása</strong>',
      services: 'Szolgáltatások'
    }
  },
  de: {
    dashboard: {
      meta: {
        title: 'Dashboard'
      },
      company: 'Firma',
      billing: 'Fakturierung',
      tickets: '<strong>Offene</strong> Anfragen',
      services: 'Dienste'
    }
  }
};

const serversEditPages = {
  en: {
    serversEditPages: {
      graphsTitle: 'Graphs',
      mainCategoryTitle: 'Main',
      otherCategoryTitle: 'Others',
      activeMetricsTitle: 'Active metrics',
      metricsButtonPlaceholder: 'Select graphs to show',
      noDataPlaceholder: 'Data for this graph could not be loaded, try again later please.',
      autoRefreshLabel: 'Automatic refresh'
    }
  },
  cs: {
    serversEditPages: {
      graphsTitle: 'Grafy',
      mainCategoryTitle: 'Hlavní',
      otherCategoryTitle: 'Ostatní',
      activeMetricsTitle: 'Aktivní metriky',
      metricsButtonPlaceholder: 'Vyberte které grafy zobrazit',
      noDataPlaceholder: 'Data pro tento graf nebylo možné načíst, zkuste to prosím později.',
      autoRefreshLabel: 'Automatické obnovování'
    }
  },
  hu: {
    serversEditPages: {
      graphsTitle: 'Grafikonok',
      mainCategoryTitle: 'Fő',
      otherCategoryTitle: 'Egyéb',
      activeMetricsTitle: 'Aktív mérőszámok',
      metricsButtonPlaceholder: 'Válassza ki a megjelenítendő grafikonokat',
      noDataPlaceholder: 'Az adatokat ehhez a grafikonhoz nem sikerült betölteni, kérjük, próbálja meg később újra',
      autoRefreshLabel: 'Automatikus frissítés'
    }
  },
  de: {
    serversEditPages: {
      graphsTitle: 'Diagramme',
      mainCategoryTitle: 'Primär',
      otherCategoryTitle: 'Andere',
      activeMetricsTitle: 'Aktive Metriken',
      metricsButtonPlaceholder: 'Wählen Sie die anzuzeigenden Diagramme aus',
      noDataPlaceholder: 'Die Daten für dieses Diagramm konnten nicht geladen werden. Bitte versuchen Sie es später noch einmal.',
      autoRefreshLabel: 'Automatische Aktualisierung'
    }
  }
};

const companyCard = {
  en: {
    companyCard: {
      editButton: 'Change company settings',
      serverParkAccess: 'Server Park access',
      teamMembers: 'Team members',
      seeList: 'See List',
      youAndMe: 'You and me',
      bio: `
        You have been our client for over <strong>{days, plural, =1 {1 day} other {# days}}</strong>.
        Right now you are using <strong>{serviceCount} of our services</strong>
        with total of ~<strong>{instances, plural, =1 {1 instance} other {# instances}}</strong>.
        Your average spend is about <strong>{monthlySpend}</strong> a month.
        `
    }
  },
  cs: {
    companyCard: {
      editButton: 'Změnit nastavení firmy',
      serverParkAccess: 'Přístup do Server Parku',
      teamMembers: 'Členové týmu',
      seeList: 'Seznam',
      youAndMe: 'Vy a My',
      bio: `
        Jste naším klientem již <strong>{days, plural, =1 {1 den} =2 {2 dny} =3 {3 dny} =4 {4 dny} other {# dní}}</strong>.
        Používáte <strong>{serviceCount, plural, =1 {1 naší službu} =2 {2 naše služby} =3 {3 naše služby} =4 {4 naše služby}
        other {# našich služeb}}</strong>
        s celkovým počtem ~<strong>{instances, plural, =1 {1 instance} other {# instancí}}</strong>.
        Měsíčně utratíte okolo <strong>{monthlySpend}</strong>.`
    }
  },
  hu: {
    companyCard: {
      editButton: 'Beállítások módosítása',
      serverParkAccess: 'Hozzáférés a Server Park-hoz',
      teamMembers: 'Csapattagok',
      seeList: 'Megnézem',
      youAndMe: 'Ön és mi',
      bio: `
        Ön már az ügyfelünk <strong>{days, plural, =1 {1 nap} =2 {2 nap} =3 {3 nap} =4 {4 nap} other {# nap}}</strong>.
        Ön <strong>{serviceCount,
          plural,
          =1 {1 szolgáltatásunkat}
          =2 {2 szolgáltatásunkat}
          =3 {3 szolgáltatásunkat}
          =4 {4 szolgáltatásunkat}
        other {# szolgáltatásunkat}
        } </strong>
        összesen ~<strong>{instances, plural, =1 {1 instanciával} other {# instanciával}}</strong>.
        Havonta kb. ennyit költ <strong>{monthlySpend}</strong>.`
    }
  },
  de: {
    companyCard: {
      editButton: 'Firmeneinstellungen ändern',
      serverParkAccess: 'Zugang zu Server Park',
      teamMembers: 'Teammitglieder',
      seeList: 'Liste',
      youAndMe: 'Sie und Wir',
      bio: `
        Sie sind seit <strong>{days, plural, =1 {1 Tag} =2 {2 Tagen} =3 {3 Tagen} =4 {4 Tagen} other {# Tagen}}</strong> unser Kunde.
        Sie nutzen <strong>{serviceCount, plural, =1 {1 unseren Dienst} =2 {2 unsere Dienste} =3 {3 unsere Dienste} =4 {4 unsere Dienste}
        other {# unsere Dienste}}</strong>
        mit einer Gesamtzahl von ~<strong>{instances, plural, =1 {1 Instanz} other {# Instanzen}}</strong>.
        Sie geben monatlich etwa <strong>{monthlySpend}</strong> aus.`
    }
  }
};

const searchTable = {
  en: {
    searchTable: {
      title: 'Title',
      description: 'Description',
      date: 'Date',
      type: 'Type'
    }
  },
  cs: {
    searchTable: {
      title: 'Název',
      description: 'Popis',
      date: 'Datum',
      type: 'Typ'
    }
  },
  hu: {
    searchTable: {
      title: 'Elnevezés',
      description: 'Leírás',
      date: 'Dátum',
      type: 'Típus',
    }
  },
  de: {
    searchTable: {
      title: 'Name',
      description: 'Beschreibung',
      date: 'Datum',
      type: 'Typ'
    }
  }
};

const searchTrigger = {
  en: {
    searchTrigger: {
      start: 'Click ',
      or: ' or press ',
      end: ' anywhere to start searching..',
      endMob: ' to start searching..',
      search: 'Search..'
    }
  },
  cs: {
    searchTrigger: {
      start: 'Klikněte na ',
      or: ' nebo stiskněte ',
      end: ' kdekoliv pro vyhledávání..',
      endMob: ' pro vyhledávání..',
      search: 'Vyhledávání..'
    }
  },
  hu: {
    searchTrigger: {
      start: 'Kattintson a ',
      or: ' ikonra vagy nyomja meg az ',
      end: ' billentyűkombinációt a kereséshez..',
      endMob: ' a kereséshez..',
      search: 'Keresés..'
    }
  },
  de: {
    searchTrigger: {
      start: 'Klicken Sie auf ',
      or: ' oder drücken Sie ',
      end: ' irgendwo zum Suchen..',
      endMob: ' zum Suchen..',
      search: 'Suche..'
    }
  }
};

const avatarCard = {
  en: {
    avatarCard: {
      gravatarTooltip: 'Profile picture can be changed at www.gravatar.com',
      clearbitTooltip: 'Profile picture can be changed at www.clearbit.com'
    }
  },
  cs: {
    avatarCard: {
      gravatarTooltip: 'Profilový obrázek lze změnit na www.gravatar.com',
      clearbitTooltip: 'Profilový obrázek lze změnit na www.clearbit.com'
    }
  },
  hu: {
    avatarCard: {
      gravatarTooltip: 'A profilkép megváltoztatható a www.gravatar.com címen',
      clearbitTooltip: 'A profilkép megváltoztatható a www.clearbit.com'
    }
  },
  de: {
    avatarCard: {
      gravatarTooltip: 'Das Profilbild kann unter www.gravatar.com geändert werden.',
      clearbitTooltip: 'Das Profilbild kann unter www.clearbit.com geändert werden'
    }
  }
};

const loginCompanySelect = {
  en: {
    loginCompanySelect: {
      title: 'Select company',
      desc: 'Your account is connected to multiple companies',
      logout: 'Logout'
    }
  },
  cs: {
    loginCompanySelect: {
      title: 'Zvolte firmu',
      desc: 'Váš účet je propojen s více firmami',
      logout: 'Odhlásit se'
    }
  },
  hu: {
    loginCompanySelect: {
      title: 'Cég kiválasztása',
      desc: 'A fiókja több vállalathoz is kapcsolódik',
      logout: 'Kijelentkezés'
    }
  },
  de: {
    loginCompanySelect: {
      title: 'Wählen Sie eine Firma',
      desc: 'Ihr Konto ist mit mehreren Firmen verknüpft',
      logout: 'Abmelden'
    }
  }
};

const services = {
  en: {
    services: {
      codes: {
        ALL_MANAGED: 'Managed services',
        MANAGED_SERVERS: 'Managed servers',
        MANAGED_CLUSTERS: 'Managed clusters',
        CLOUD_MANAGED_SERVERS: 'Cloud managed servers',
        SERVER_HOSTINGS: 'Hosted servers',
        RACK_HOSTINGS: 'Hosted racks',
        DEDICATED_SERVERS: 'Dedicated servers',
        GPU_DEDICATED_SERVER: 'GPU dedicated server',
        MANAGED_SERVER: 'Managed server',
        MANAGED_CLUSTER: 'Managed cluster',
        MANAGED_WINDOWS_SERVER: 'Managed windows server',
        MANAGED_WINDOWS_CLUSTER: 'Managed windows cluster',
        MANAGED_WINDOWS_BASIC: 'Managed windows basic',
        MANAGED_WINDOWS_CLOUD: 'Managed windows cloud',
        AWS_MANAGED_SERVER: 'AWS Managed server',
        M2_MANAGED_SERVER: 'M2 managed server',
        M2_MANAGED_CLUSTER: 'M2 managed cluster',
        CLOUD_MANAGED_SERVER: 'Cloud managed server',
        SERVER_HOSTING: 'Hosted server',
        RACK_HOSTING: 'Hosted rack',
        DEDICATED_SERVER: 'Dedicated server',
        VDS: 'VDS',
        CLOUD_DNS: 'Cloud DNS',
        CDN_APP: 'CDN',
        CLOUD_MAIL: 'Cloud Mail',
        CDN: 'F1 CDN'
      }
    }
  },
  cs: {
    services: {
      codes: {
        ALL_MANAGED: 'Managed služby',
        MANAGED_SERVERS: 'Managed servery',
        MANAGED_CLUSTERS: 'Managed clustery',
        CLOUD_MANAGED_SERVERS: 'Cloud managed servery',
        SERVER_HOSTINGS: 'Hostované servery',
        RACK_HOSTINGS: 'Hostované racky',
        DEDICATED_SERVERS: 'Dedicated servery',
        GPU_DEDICATED_SERVER: 'GPU dedicated server',
        MANAGED_SERVER: 'Managed server',
        MANAGED_CLUSTER: 'Managed cluster',
        M2_MANAGED_CLUSTER: 'M2 managed cluster',
        CLOUD_MANAGED_SERVER: 'Cloud managed server',
        M1_MANAGED_SERVER: 'M1 managed server',
        M2_MANAGED_SERVER: 'M2 managed server',
        MANAGED_WINDOWS_SERVER: 'Managed windows server',
        MANAGED_WINDOWS_CLUSTER: 'Managed windows cluster',
        MANAGED_WINDOWS_BASIC: 'Managed windows basic',
        MANAGED_WINDOWS_CLOUD: 'Managed windows cloud',
        AWS_MANAGED_SERVER: 'AWS Managed server',
        SERVER_HOSTING: 'Hostovaný server',
        RACK_HOSTING: 'Hostovaný rack',
        DEDICATED_SERVER: 'Dedicated server',
        VDS: 'VDS',
        CLOUD_DNS: 'Cloud DNS',
        CDN_APP: 'CDN',
        CLOUD_MAIL: 'Cloud Mail',
        CDN: 'F1 CDN'
      }
    }
  },
  hu: {
    services: {
      codes: {
        ALL_MANAGED: 'Managed szolgáltatások',
        MANAGED_SERVERS: 'Managed szerverek',
        MANAGED_CLUSTERS: 'Managed clusterek',
        CLOUD_MANAGED_SERVERS: 'Cloud managed szerverek',
        SERVER_HOSTINGS: 'Hostolt szerverek',
        RACK_HOSTINGS: 'Hostolt rack-ek',
        DEDICATED_SERVERS: 'Dedicated szerverek',
        GPU_DEDICATED_SERVER: 'GPU dedicated szerverek',
        MANAGED_SERVER: 'Managed server',
        MANAGED_CLUSTER: 'Managed cluster',
        M2_MANAGED_CLUSTER: 'M2 managed cluster',
        CLOUD_MANAGED_SERVER: 'Cloud managed server',
        M1_MANAGED_SERVER: 'M1 managed server',
        M2_MANAGED_SERVER: 'M2 managed server',
        MANAGED_WINDOWS_SERVER: 'Managed windows server',
        MANAGED_WINDOWS_CLUSTER: 'Managed windows cluster',
        MANAGED_WINDOWS_BASIC: 'Managed windows basic',
        MANAGED_WINDOWS_CLOUD: 'Managed windows cloud',
        SERVER_HOSTING: 'Hostolt szerver',
        RACK_HOSTING: 'Hostolt rack',
        DEDICATED_SERVER: 'Dedicated szerver',
        VDS: 'VDS',
        CLOUD_DNS: 'Cloud DNS',
        CDN_APP: 'CDN',
        CLOUD_MAIL: 'Cloud Mail',
        CDN: 'F1 CDN'
      }
    }
  },
  de: {
    services: {
      codes: {
        ALL_MANAGED: 'Managed Dienste',
        MANAGED_SERVERS: 'Managed Server',
        MANAGED_CLUSTERS: 'Managed Cluster',
        CLOUD_MANAGED_SERVERS: 'Cloud managed Server',
        SERVER_HOSTINGS: 'Gehostete Server',
        RACK_HOSTINGS: 'Gehostete Racks',
        DEDICATED_SERVERS: 'Dedizierte Server',
        GPU_DEDICATED_SERVERS: 'GPU dedizierte Server',
        MANAGED_SERVER: 'Managed server',
        MANAGED_CLUSTER: 'Managed cluster',
        M2_MANAGED_CLUSTER: 'M2 managed cluster',
        CLOUD_MANAGED_SERVER: 'Cloud managed server',
        M1_MANAGED_SERVER: 'M1 managed Server',
        M2_MANAGED_SERVER: 'M2 managed server',
        MANAGED_WINDOWS_SERVER: 'Managed windows server',
        MANAGED_WINDOWS_CLUSTER: 'Managed windows cluster',
        MANAGED_WINDOWS_BASIC: 'Managed windows basic',
        MANAGED_WINDOWS_CLOUD: 'Managed windows cloud',
        SERVER_HOSTING: 'Gehosteter Server',
        RACK_HOSTING: 'Gehostetes Rack',
        DEDICATED_SERVER: 'Dedicated server',
        VDS: 'VDS',
        CLOUD_DNS: 'Cloud DNS',
        CLOUD_MAIL: 'Cloud Mail',
        CDN: 'F1 CDN'
      }
    }
  }
};

const graphTypes = {
  en: {
    graphTypes: {
      cpuUsage: 'CPU usage',
      memoryUsage: 'Memory usage',
      ioWait: 'IO wait',
      maxDiskSpaceQuotaUsage: 'Max disk space quota usage',
      diskSpaceQuotaUsage: 'Disk space quota usage',
      diskFilesFoldersQuotaUsage: 'Disk Files Folders Quota Usage',
      networkTraffic: 'Network traffic',
      networkTrafficRelative: 'Network traffic relative',
      apacheRequests: 'Apache Requests',
      nginxRequests: 'Nginx Requests',
      mySqlQueries: 'Mysql Queries',
      mySqlRunningThreads: 'Mysql Running Threads',
      mySqlSlowQueries: 'Mysql Slow Queries',
      postgreSqlQueries: 'PostgreSql Queries',
      postgreSqlActiveBackends: 'PostgreSql Active Backends',
      redisCommands: 'Redis Commands',
      redisMemoryUsage: 'Redis Memory Usage',
      mongoOperations: 'Mongo Operations',
      memcachedRequests: 'Memcached Requests',
      memcachedMemoryUsage: 'Memcached Memory Usage',
      elasticsearchOperations: 'Elasticsearch Operations',
      elasticSearchMemoryUsage: 'Elasticsearch Memory Usage'
    }
  },
  cs: {
    graphTypes: {
      cpuUsage: 'CPU usage',
      memoryUsage: 'Memory usage',
      ioWait: 'IO wait',
      maxDiskSpaceQuotaUsage: 'Max disk space quota usage',
      diskSpaceQuotaUsage: 'Disc space quota usage',
      diskFilesFoldersQuotaUsage: 'Disk Files Folders Quota Usage',
      networkTraffic: 'Network traffic',
      networkTrafficRelative: 'Network traffic relative',
      apacheRequests: 'Apache Requests',
      nginxRequests: 'Nginx Requests',
      mySqlQueries: 'Mysql Queries',
      mySqlRunningThreads: 'Mysql Running Threads',
      mySqlSlowQueries: 'Mysql Slow Queries',
      postgreSqlQueries: 'PostgreSql Queries',
      postgreSqlActiveBackends: 'PostgreSql Active Backends',
      redisCommands: 'Redis Commands',
      redisMemoryUsage: 'Redis Memory Usage',
      mongoOperations: 'Mongo Operations',
      memcachedRequests: 'Memcached Requests',
      memcachedMemoryUsage: 'Memcached Memory Usage',
      elasticsearchOperations: 'Elasticsearch Operations',
      elasticSearchMemoryUsage: 'Elasticsearch Memory Usage'
    }
  },
  hu: {
    graphTypes: {
      cpuUsage: 'CPU usage',
      memoryUsage: 'Memory usage',
      ioWait: 'IO wait',
      maxDiskSpaceQuotaUsage: 'Max disk space quota usage',
      diskSpaceQuotaUsage: 'Disc space quota usage',
      diskFilesFoldersQuotaUsage: 'Disk Files Folders Quota Usage',
      networkTraffic: 'Network traffic',
      networkTrafficRelative: 'Network traffic relative',
      apacheRequests: 'Apache Requests',
      nginxRequests: 'Nginx Requests',
      mySqlQueries: 'Mysql Queries',
      mySqlRunningThreads: 'Mysql Running Threads',
      mySqlSlowQueries: 'Mysql Slow Queries',
      postgreSqlQueries: 'PostgreSql Queries',
      postgreSqlActiveBackends: 'PostgreSql Active Backends',
      redisCommands: 'Redis Commands',
      redisMemoryUsage: 'Redis Memory Usage',
      mongoOperations: 'Mongo Operations',
      memcachedRequests: 'Memcached Requests',
      memcachedMemoryUsage: 'Memcached Memory Usage',
      elasticsearchOperations: 'Elasticsearch Operations',
      elasticSearchMemoryUsage: 'Elasticsearch Memory Usage'
    }
  },
  de: {
    graphTypes: {
      cpuUsage: 'CPU usage',
      memoryUsage: 'Memory usage',
      ioWait: 'IO wait',
      maxDiskSpaceQuotaUsage: 'Max disk space quota usage',
      diskSpaceQuotaUsage: 'Disc space quota usage',
      diskFilesFoldersQuotaUsage: 'Disk Files Folders Quota Usage',
      networkTraffic: 'Network traffic',
      networkTrafficRelative: 'Network traffic relative',
      apacheRequests: 'Apache Requests',
      nginxRequests: 'Nginx Requests',
      mySqlQueries: 'Mysql Queries',
      mySqlRunningThreads: 'Mysql Running Threads',
      mySqlSlowQueries: 'Mysql Slow Queries',
      postgreSqlQueries: 'PostgreSql Queries',
      postgreSqlActiveBackends: 'PostgreSql Active Backends',
      redisCommands: 'Redis Commands',
      redisMemoryUsage: 'Redis Memory Usage',
      mongoOperations: 'Mongo Operations',
      memcachedRequests: 'Memcached Requests',
      memcachedMemoryUsage: 'Memcached Memory Usage',
      elasticsearchOperations: 'Elasticsearch Operations',
      elasticSearchMemoryUsage: 'Elasticsearch Memory Usage'
    }
  }
};

const paymentTypes = {
  en: {
    paymentTypes: {
      BANK_TRANSFER: 'Bank transfer',
      INTERNAL: 'Internal transfer',
      CREDIT: 'By credit'
    }
  },
  cs: {
    paymentTypes: {
      BANK_TRANSFER: 'Bankovní převod',
      INTERNAL: 'Interní převod',
      CREDIT: 'Úhrada kreditem'
    }
  },
  hu: {
    paymentTypes: {
      BANK_TRANSFER: 'Banki átutalás',
      INTERNAL: 'Belső átvitel',
      CREDIT: 'Fizetés kredittel'
    }
  },
  de: {
    paymentTypes: {
      BANK_TRANSFER: 'Banküberweisung',
      INTERNAL: 'Interne Überweisung',
      CREDIT: 'Zahlung mit Guthaben'
    }
  }
};

const paymentStatuses = {
  en: {
    paymentStatuses: {
      tooltips: {
        OK: 'Everything OK',
        AMOUNT_NOT_MATCHED: 'Amount not matched'
      }
    }
  },
  cs: {
    paymentStatuses: {
      tooltips: {
        OK: 'Vše OK',
        AMOUNT_NOT_MATCHED: 'Částka neodpovídá'
      }
    }
  },
  hu: {
    paymentStatuses: {
      tooltips: {
        OK: 'Minden rendben',
        AMOUNT_NOT_MATCHED: 'Az összeg nem egyezik'
      }
    }
  },
  de: {
    paymentStatuses: {
      tooltips: {
        OK: 'Alles OK',
        AMOUNT_NOT_MATCHED: 'Der Betrag entspricht nicht'
      }
    }
  }
};

const invoiceStatuses = {
  en: {
    invoiceStatuses: {
      tooltips: {
        PAYMENT_OVERDUE: 'Payment overdue',
        WAITING_FOR_PAYMENT: 'Waiting for payment',
        PAID: 'Paid',
        CANCELED: 'Canceled'
      }
    }
  },
  cs: {
    invoiceStatuses: {
      tooltips: {
        PAYMENT_OVERDUE: 'Po splatnosti',
        WAITING_FOR_PAYMENT: 'Čeká se na platbu',
        PAID: 'Uhrazeno',
        CANCELED: 'Zrušeno'
      }
    }
  },
  hu: {
    invoiceStatuses: {
      tooltips: {
        PAYMENT_OVERDUE: 'Esedékesség után',
        WAITING_FOR_PAYMENT: 'Fizetésre várakozik',
        PAID: 'Fizetve',
        CANCELED: 'Megszüntetve'
      }
    }
  },
  de: {
    invoiceStatuses: {
      tooltips: {
        PAYMENT_OVERDUE: 'Überfällig',
        WAITING_FOR_PAYMENT: 'Wartet auf die Zahlung',
        PAID: 'Bezahlt',
        CANCELED: 'Abgebrochen'
      }
    }
  }
};

const domainStatuses = {
  en: {
    domainStatuses: {
      tooltips: {
        NEW: 'New',
        ACTIVE: 'Active',
        WAITING_TO_TTL: 'Propagating changes to the internet',
        PUBLISHING_TO_INTERNET: 'Being publicated',
        WARNING: 'Warning',
        DOMAIN_NOT_AVAILABLE: 'Not available',
        DOMAIN_NOT_HEADING_TO_VSHOSTING: 'Not heading to vshosting',
        DELETED: 'Deleted',
        DEACTIVATED: 'Deactivated'
      }
    }
  },
  cs: {
    domainStatuses: {
      tooltips: {
        NEW: 'Nová',
        ACTIVE: 'Aktivní',
        WAITING_TO_TTL: 'Probíhá propagace změn do internetu',
        PUBLISHING_TO_INTERNET: 'Publikována',
        WARNING: 'Varování',
        DOMAIN_NOT_AVAILABLE: 'Nedostupná',
        DOMAIN_NOT_HEADING_TO_VSHOSTING: 'Směrována mimo vshosting',
        DELETED: 'Smazána',
        DEACTIVATED: 'Deaktivována'
      }
    }
  },
  hu: {
    domainStatuses: {
      tooltips: {
        NEW: 'Új',
        ACTIVE: 'Aktív',
        WAITING_TO_TTL: 'Folyamatban van a változások közzététele az interneten',
        PUBLISHING_TO_INTERNET: 'Közzétéve',
        WARNING: 'Figyelemeztetés',
        DOMAIN_NOT_AVAILABLE: 'Nem elérhető',
        DOMAIN_NOT_HEADING_TO_VSHOSTING: 'A vshostingon kívülre irányítva',
        DELETED: 'Törölve',
        DEACTIVATED: 'Deaktiválva'
      }
    }
  },
  de: {
    domainStatuses: {
      tooltips: {
        NEW: 'Neu',
        ACTIVE: 'Aktiv',
        WAITING_TO_TTL: 'Verbreitung der Änderungen im Internet ist im Gange',
        PUBLISHING_TO_INTERNET: 'Veröffentlicht',
        WARNING: 'Warnung',
        DOMAIN_NOT_AVAILABLE: 'Nicht verfügbar',
        DOMAIN_NOT_HEADING_TO_VSHOSTING: 'Außerhalb von vshosting geroutet',
        DELETED: 'Gelöscht',
        DEACTIVATED: 'Deaktiviert'
      }
    }
  }
};

const clientServiceStatuses = {
  en: {
    clientServiceStatuses: {
      tooltips: {
        ACTIVE: 'Active',
        TERMINATED: 'Terminated'
      }
    }
  },
  cs: {
    clientServiceStatuses: {
      tooltips: {
        ACTIVE: 'Aktivní',
        TERMINATED: 'Ukončená'
      }
    }
  },
  hu: {
    clientServiceStatuses: {
      tooltips: {
        ACTIVE: 'Aktív',
        TERMINATED: 'Befejezve'
      }
    }
  },
  de: {
    clientServiceStatuses: {
      tooltips: {
        ACTIVE: 'Aktiv',
        TERMINATED: 'Abgeschlossen'
      }
    }
  }
};

const graphIntervals = {
  cs: {
    graphIntervals: {
      [ServerIntervalTypes.Hours]: 'Poslední <strong>3 hodiny</strong>',
      [ServerIntervalTypes.Day]: 'Posledních <strong>24 hodin</strong>',
      [ServerIntervalTypes.Days]: 'Posledních <strong>48 hodin</strong>',
      [ServerIntervalTypes.Week]: 'Posledních <strong>7 dní</strong>',
      [ServerIntervalTypes.Month]: 'Posledních <strong>30 dní</strong>',
      [ServerIntervalTypes.Months]: 'Posledních <strong>60 dní</strong>',
      [ServerIntervalTypes.Year]: 'Posledních <strong>366 dní</strong>'
    }
  },
  en: {
    graphIntervals: {
      [ServerIntervalTypes.Hours]: 'Last <strong>3 hours</strong>',
      [ServerIntervalTypes.Day]: 'Last <strong>24 hours</strong>',
      [ServerIntervalTypes.Days]: 'Last <strong>48 hours</strong>',
      [ServerIntervalTypes.Week]: 'Last <strong>7 days</strong>',
      [ServerIntervalTypes.Month]: 'Last <strong>30 days</strong>',
      [ServerIntervalTypes.Months]: 'Last <strong>60 days</strong>',
      [ServerIntervalTypes.Year]: 'Last <strong>366 days</strong>'
    }
  },
  hu: {
    graphIntervals: {
      [ServerIntervalTypes.Hours]: 'Az utolsó <strong>3 óra</strong>',
      [ServerIntervalTypes.Day]: 'Az utolsó <strong>24 óra</strong>',
      [ServerIntervalTypes.Days]: 'Az utolsó <strong>48 óra</strong>',
      [ServerIntervalTypes.Week]: 'Az utolsó <strong>7 nap</strong>',
      [ServerIntervalTypes.Month]: 'Az utolsó <strong>30 nap</strong>',
      [ServerIntervalTypes.Months]: 'Az utolsó <strong>60 nap</strong>',
      [ServerIntervalTypes.Year]: 'Az utolsó <strong>366 nap</strong>',
    }
  },
  de: {
    graphIntervals: {
      [ServerIntervalTypes.Hours]: 'Letzte <strong>3 Stunden</strong>',
      [ServerIntervalTypes.Day]: 'Letzte <strong>24 Stunden</strong>',
      [ServerIntervalTypes.Days]: 'Letzte <strong>48 Stunden</strong>',
      [ServerIntervalTypes.Week]: 'Letzte <strong>7 Tage</strong>',
      [ServerIntervalTypes.Month]: 'Letzte <strong>30 Tage</strong>',
      [ServerIntervalTypes.Months]: 'Letzte <strong>60 Tage</strong>',
      [ServerIntervalTypes.Year]: 'Letzte <strong>366 Tage</strong>'
    }
  }
};

const bulkPayment = {
  en: {
    bulkPayment: {
      dialogTitle: 'Pay {count, plural, =1 {1 unpaid invoice} other {# unpaid invoices}}',
      overviewTitle: `
        Pay <strong class="u-color--primary">{count, plural,
        =1 {1 unpaid</strong> invoice} other {# unpaid</strong> invoices}}
      `,
      dialogText: `
        You can pay off multiple invoices in one payment.
        ID (variable symbol) stays the same for each invoice.
        Unpaid invoices are going to be paid off in order of their creation; the oldest will be paid off first.
      `,
      dialogTriggerButton: 'Open full transfer details',
      info: 'Info',
      toBePaid: 'To be paid',
      total: 'Total',
      id: 'ID',
      bankAccount: 'Acc.',
      bankName: 'Bank',
      desc: 'Desc.',
      creditShort: 'from credit',
      creditLong: 'will be paid from credit'
    }
  },
  cs: {
    bulkPayment: {
      dialogTitle: `
        Uhradit {count, plural, =1 {1 nezaplacenou fakturu}
        =2 {2 nezaplacené faktury} =3 {3 nezaplacené faktury}
        =4 {4 nezaplacené faktury} other {# nezaplacených faktur}}
      `,
      overviewTitle: `
        Uhradit <strong class="u-color--primary">{count, plural, =1 {1 nezaplacenou</strong> fakturu}
        =2 {2 nezaplacené</strong> faktury} =3 {3 nezaplacené</strong> faktury}
        =4 {4 nezaplacené</strong> faktury} other {# nezaplacených</strong> faktur}}
      `,
      dialogText: `
        V rámci jedné platby lze uhradit více faktur.
        Variabilní symbol je pro všechny faktury stejný.
        Neuhrazené faktury budou hrazeny v pořadí jejich vystavení, nejstarší faktura bude uhrazena nejdříve.
      `,
      dialogTriggerButton: 'Zobrazit informace o převodu',
      info: 'Info',
      toBePaid: 'K zaplacení',
      total: 'Celkem',
      id: 'VS',
      bankAccount: 'Účet',
      bankName: 'Banka',
      desc: 'Popis',
      creditShort: 'z kreditu',
      creditLong: 'bude uhrazeno z kreditu'
    }
  },
  hu: {
    bulkPayment: {
      dialogTitle: `
        {count, plural, =1 {1 az esedékes számlát}
        =2 {2 esedékes számlát} =3 {3 esedékes számlát}
        =4 {4 esedékes számlát} other {# esedékes számlát}}
      `,
      overviewTitle: `
        <strong class="u-color--primary">{count, plural, =1 {1 esedékes</strong> számlát}
        =2 {2 esedékes</strong> számlát} =3 {3 esedékes</strong> számlát}
        =4 {4 esedékes </strong> számlát} other {# esedékes</strong> számlát}}
      `,
      dialogText: `
        Egy átutalással több számla is kifizethető.
        A változó szimbólum minden számla esetében ugyanaz.
        A kintlévő számlák kifizetése a kiállításuk sorrendjében történik, a legrégebbi számla kerül kifizetésre először.
      `,
      dialogTriggerButton: 'Részletek megtekintése',
      info: 'Info',
      toBePaid: 'Fizetendő',
      total: 'Összesen',
      id: 'VS',
      bankAccount: 'Számla',
      bankName: 'Bank',
      desc: 'Leírás',
      creditShort: 'a kreditből',
      creditLong: 'kreditből kerül kifizetésre'
    }
  },
  de: {
    bulkPayment: {
      dialogTitle: `
        Bezahlen {count, plural, =1 {1 offene Rechnung}
        =2 {2 offene Rechnungen} =3 {3 offene Rechnungen}
        =4 {4 offene Rechnungen} other {# offene Rechnungen}}
      `,
      overviewTitle: `
        Bezahlen <strong class="u-color--primary">{count, plural, =1 {1 offene</strong> Rechnung}
        =2 {2 offene</strong> Rechnungen} =3 {3 offene</strong> Rechnungen}
        =4 {4 offene</strong> Rechnungen} other {# offene</strong> Rechnungen}}
      `,
      dialogText: `
        Mehrere Rechnungen können mit einer Zahlung beglichen werden.
        Das Kassenzeichen ist für alle Rechnungen gleich.
        Offene Rechnungen werden in der Reihenfolge ihrer Ausstellung bezahlt, wobei die älteste Rechnung zuerst bezahlt wird.
      `,
      dialogTriggerButton: 'Informationen zur Überweisung anzeigen',
      info: 'Info',
      toBePaid: 'Zur Bezahlung',
      total: 'Summe',
      id: 'VS',
      bankAccount: 'Konto',
      bankName: 'Bank',
      desc: 'Beschreibung',
      creditShort: 'mit dem Guthaben',
      creditLong: 'wird mit dem Guthaben bezahlt'
    }
  }
};

const domainRoutingInfoDialog = {
  en: {
    domainRoutingInfoDialog: {
      dialogTitle: 'Domain routing',
      isCzDomainText: `You need to set NSSET <strong>NSSID: VSHOSTING</strong> in your registrar's {domainName} domain settings.
        This will ensure the proper domain {domainName} routing to CloudDNS.`,
      nonCzDomainText: `The following NS records must be set in the {domainName} domain settings at your registrar:
        <br><strong>ns.vshosting.cz</strong>
        <br><strong>ns.vshosting.cloud</strong>
        <br><strong>ns.vshosting.eu</strong>
        <br><br>This will ensure the proper domain {domainName} routing to CloudDNS.`,
      additionalText: `If you had an active domain {domainName} DNSSEC at your current provider
        it is necessary to deactivate it before changing the settings, otherwise the whole domain may not work.
        If you are not sure of the correct settings, contact our`,
      support: '~vshosting support'
    }
  },
  cs: {
    domainRoutingInfoDialog: {
      dialogTitle: 'Nasměrování domény',
      isCzDomainText: `V nastavení domény {domainName} u Vašeho registrátora je třeba nastavit NSSET <strong>NSSID:VSHOSTING</strong>.
        Tím bude zajištěno nasměrování domény {domainName} na CloudDNS.`,
      nonCzDomainText: `V nastavení domény {domainName} u Vašeho registrátora je třeba nastavit následující NS záznamy:
        <br><strong>ns.vshosting.cz</strong>
        <br><strong>ns.vshosting.cloud</strong>
        <br><strong>ns.vshosting.eu</strong>
        <br><br>Tím bude zajištěno nasměrování domény {domainName} na CloudDNS.`,
      additionalText: `Pokud jste měli u stávajícího poskytovatele aktivní DNSSEC u domény {domainName}
        je třeba před změnou nastavení u původního poskytovatele DNSSEC deaktivovat, jinak hrozí nefunkčnost celé domény.
        Pokud si nejste jisti správným nastavením, kontaktujte naši`,
      support: '~vshosting podporu'
    }
  },
  hu: {
    domainRoutingInfoDialog: {
      dialogTitle: 'A domain irányítása',
      isCzDomainText: `A domai {domainName} beállításáiban a regisztrátoránál be kell állítania az NSSET <strong>NSSID:VSHOSTING</strong>.
        Ez biztosítja, hogy a {domainName} domain a CloudDNS-re irányuljon.`,
      nonCzDomainText: `A domai {domainName} beállításáiban a regisztrátoránál be kell állítania a következő NS bejegyzéseket:
        <br><strong>ns.vshosting.cz</strong>
        <br><strong>ns.vshosting.cloud</strong>
        <br><strong>ns.vshosting.eu</strong>
        <br><br>Ez biztosítja, hogy a {domainName} domain a CloudDNS-re irányítását.`,
      additionalText: `Ha a meglévő szolgáltatójánál aktív volt a DNSSEC a {domainName} domainnél
        a beállítások módosítása előtt deaktiválja a DNSSEC-et, különben a teljes domain működésképtelenné válhat.
        Ha nem biztos a helyes beállításokban, kérjük, lépjen kapcsolatba a`,
      support: '~vshosting támogatással'
    }
  },
  de: {
    domainRoutingInfoDialog: {
      dialogTitle: 'Domain-Routing',
      isCzDomainText: `In den Domaineinstellungen von {domainName} Ihres Registrars
        müssen Sie das NSSET <strong>NSSID:VSHOSTING</strong> setzen.
        Dadurch wird die korrekte Weiterleitung der Domain {domainName} an CloudDNS sichergestellt.`,
      nonCzDomainText: `In den Domaineneinstellungen von {domainName} Ihres Registrars müssen Sie die folgenden NS-Einträge festlegen:
        <br><strong>ns.vshosting.cz</strong>
        <br><strong>ns.vshosting.cloud</strong>
        <br><strong>ns.vshosting.eu</strong>
        <br><br>Dadurch wird die korrekte Weiterleitung der Domain {domainName} an CloudDNS sichergestellt.`,
      additionalText: `Wenn Sie DNSSEC für die Domain {domainName} bei Ihrem
        derzeitigen Provider aktiviert hatten,
        müssen Sie DNSSEC deaktivieren, bevor Sie die Einstellungen beim ursprünglichen Provider
        ändern, da sonst die gesamte Domain nicht mehr funktionsfähig sein kann.
        Wenn Sie sich über die korrekten Einstellungen nicht sicher sind, wenden Sie sich bitte an unseren`,
      Support: '~vshosting-Support'
    }
  }
};

const ipListOerviewTrigger = {
  en: {
    ipListOverviewTrigger: {
      button: 'Show IP addresses',
      empty: 'Server has no IP address'
    }
  },
  cs: {
    ipListOverviewTrigger: {
      button: 'Zobrazit IP adresy',
      empty: 'Server nemá žádnou IP adresu'
    }
  },
  hu: {
    ipListOverviewTrigger: {
      button: 'Extra IP címek',
      empty: 'A szervernek nincs IP-címe'
    }
  },
  de: {
    ipListOverviewTrigger: {
      button: 'IP-Adressen anzeigen',
      empty: 'Der Server hat keine IP-Adresse'
    }
  }
};

const creditStatus = {
  en: {
    creditStatus: {
      tooltip: 'Credit of company',
      credit: 'Your credit: '
    },
  },
  cs: {
    creditStatus: {
      tooltip: 'Stav kreditu společnosti',
      credit: 'Váš kredit: '
    }
  },
  hu: {
    creditStatus: {
      tooltip: 'A társaság kredit státusza',
      credit: 'A Ön kreditje: '
    }
  },
  de: {
    creditStatus: {
      tooltip: 'Guthabenstand der Firma',
      credit: 'Ihr Guthaben: '
    }
  }
};

const serversBase = {
  en: {
    serversBase: {
      graphMetrics: {
        usage_idle: 'Usage',
        bytes_recv: 'Bytes recieved',
        bytes_sent: 'Bytes sent',
        commands_delete: 'Deletes',
        commands_insert: 'Inserts',
        commands_replace: 'Replaces',
        commands_select: 'Selects',
        commands_update: 'Updates',
        commands_per_sec: 'Commands/s',
        deletes_per_sec: 'Deletes/s',
        flushes_per_sec: 'Flushes/s',
        getmores_per_sec: 'Getmores/s',
        inserts_per_sec: 'Inserts/s',
        page_faults_per_sec: 'Page faults/s',
        queries_per_sec: 'Queries/s',
        cmd_get: 'Get',
        cmd_set: 'Set',
        BusyWorkers: 'Busy workers',
        TotalAccesses: 'Total accesses',
        waiting: 'Waiting',
        requests: 'Total requests',
        get_total: 'Get total',
        indexing_delete_total: 'Indexing delete total',
        indexing_index_total: 'Indexing index total',
        search_query_total: 'Search query total'
      },
      ip4AddSuccessSnack: 'IPv4 address {ip} was successfully added.',
      ip6AddSuccessSnack: 'IPv6 address {ip} was successfully added.',
      ipDeleteSuccessSnack: 'IP address {ip} was successfully removed.',
      ptrSaveSuccessSnack: 'PTR for {ip} was successfully updated.',
    }
  },
  cs: {
    serversBase: {
      graphMetrics: {
        usage_idle: 'Usage',
        bytes_recv: 'Bytes recieved',
        bytes_sent: 'Bytes sent',
        commands_delete: 'Deletes',
        commands_insert: 'Inserts',
        commands_replace: 'Replaces',
        commands_select: 'Selects',
        commands_update: 'Updates',
        commands_per_sec: 'Commands/s',
        deletes_per_sec: 'Deletes/s',
        flushes_per_sec: 'Flushes/s',
        getmores_per_sec: 'Getmores/s',
        inserts_per_sec: 'Inserts/s',
        page_faults_per_sec: 'Page faults/s',
        queries_per_sec: 'Queries/s',
        cmd_get: 'Get',
        cmd_set: 'Set',
        BusyWorkers: 'Čekající requesty',
        waiting: 'Čekající requesty',
        TotalAccesses: 'Počet requestů',
        requests: 'Počet requestů',
        get_total: 'Get total',
        indexing_delete_total: 'Indexing delete total',
        indexing_index_total: 'Indexing index total',
        search_query_total: 'Search query total'
      },
      ip4AddSuccessSnack: 'IPv4 adresa {ip} byla úspěšně přidána.',
      ip6AddSuccessSnack: 'IPv6 adresa {ip} byla úspěšně přidána.',
      ipDeleteSuccessSnack: 'IP adresa {ip} byla úspěšně smazána.',
      ptrSaveSuccessSnack: 'PTR pro {ip} bylo úspěšně nastaveno.',
    }
  },
  hu: {
    serversBase: {
      graphMetrics: {
        usage_idle: 'Használat',
        bytes_recv: 'Fogadott bájtok',
        bytes_sent: 'Küldött bájtok',
        commands_delete: 'Törlések',
        commands_insert: 'Beillesztések',
        commands_replace: 'Cserék',
        commands_select: 'Kijelölések',
        commands_update: 'Frissítések',
        commands_per_sec: 'Parancsok',
        deletes_per_sec: 'Törlések',
        flushes_per_sec: 'Öblítések',
        getmores_per_sec: 'Getmores/s',
        inserts_per_sec: 'Beillesztések',
        page_faults_per_sec: 'Oldalhibák',
        queries_per_sec: 'Lekérdezések',
        cmd_get: 'Beszerzés',
        cmd_set: 'Beállítás',
        BusyWorkers: 'Várakozó visszaigazolások',
        waiting: 'Várakozó visszaigazolások',
        TotalAccesses: 'Visszaigazolások száma',
        requests: 'Visszaigazolások száma',
        get_total: 'Összesen',
        indexing_delete_total: 'Indexelés törlés összesen',
        indexing_index_total: 'Indexelés index összesen',
        search_query_total: 'Keresési lekérdezés összesen'
      },
      ip4AddSuccessSnack: 'IPv4 címet {ip} sikeresen hozzáadták.',
      ip6AddSuccessSnack: 'IPv6 címet {ip} sikeresen hozzáadták.',
      ipDeleteSuccessSnack: 'IP címet {ip} sikeresen törölték.',
      ptrSaveSuccessSnack: 'PTR az {ip}-hez sikeresen beállították.',
    }
  },
  de: {
    serversBase: {
      graphMetrics: {
        usage_idle: 'Usage',
        bytes_recv: 'Bytes recieved',
        bytes_sent: 'Bytes sent',
        commands_delete: 'Deletes',
        commands_insert: 'Inserts',
        commands_replace: 'Replaces',
        commands_select: 'Selects',
        commands_update: 'Updates',
        commands_per_sec: 'Commands/s',
        deletes_per_sec: 'Deletes/s',
        flushes_per_sec: 'Flushes/s',
        getmores_per_sec: 'Getmores/s',
        inserts_per_sec: 'Inserts/s',
        page_faults_per_sec: 'Page faults/s',
        queries_per_sec: 'Queries/s',
        cmd_get: 'Get',
        cmd_set: 'Set',
        BusyWorkers: 'Wartende Anfragen',
        waiting: 'Wartende Anfragen',
        TotalAccesses: 'Anzahl der Anfragen',
        requests: 'Anzahl der Anfragen',
        get_total: 'Get total',
        indexing_delete_total: 'Indexing delete total',
        indexing_index_total: 'Indexing index total',
        search_query_total: 'Search query total'
      },
      ip4AddSuccessSnack: 'Die IPv4-Adresse {ip} wurde erfolgreich hinzugefügt.',
      ip6AddSuccessSnack: 'Die IPv6-Adresse {ip} wurde erfolgreich hinzugefügt.',
      ipDeleteSuccessSnack: 'Die IP-Adresse {ip} wurde erfolgreich gelöscht.',
      ptrSaveSuccessSnack: 'PTR für {ip} wurde erfolgreich gesetzt.',
    }
  }
};

// @translationDefinition:app
export const TRANSLATIONS = {
  en: {
    ...ipListOerviewTrigger.en,
    ...loginCompanySelect.en,
    ...bulkPayment.en,
    ...domainRoutingInfoDialog.en,
    ...paymentStatuses.en,
    ...invoiceStatuses.en,
    ...clientServiceStatuses.en,
    ...paymentTypes.en,
    ...menuItems.en,
    ...billingCard.en,
    ...servicesCard.en,
    ...invoiceItem.en,
    ...clientServiceItem.en,
    ...dashboard.en,
    ...searchTrigger.en,
    ...avatarCard.en,
    ...searchTable.en,
    ...companyCard.en,
    ...files.en,
    ...paymentItem.en,
    ...userInfoFormDefault.en,
    ...passwordFormDefault.en,
    ...userCardDefault.en,
    ...ticketState.en,
    ...ticketList.en,
    ...ticketsCard.en,
    ...ticketUnlock.en,
    ...ticketTextFormDefault.en,
    ...priceOfferRejectFormDefault.en,
    ...realizationDateRejectFormDefault.en,
    ...ticketTopics.en,
    ...ticketsMessage.en,
    ...ticketsTrigger.en,
    ...wsReconnect.en,
    ...ticketsFilter.en,
    ...ticketCopyList.en,
    ...ticketRating.en,
    ...ticketAuthorization.en,
    ...ticketAddForm.en,
    ...ticketAddContainer.en,
    ...ticketsCommon.en,
    ...graphTypes.en,
    ...ticketBase.en,
    ...search.en,
    ...services.en,
    ...graphIntervals.en,
    ...serversBase.en,
    ...serversEditPages.en,
    ...COMMON.en,
    ...authBase.en,
    ...loggedUser.en,
    ...recaptcha.en,
    ...API_ERROR_CODES.en,
    ...domainStatuses.en,
    ...domainsBase.en,
    ...creditStatus.en
  },
  cs: {
    ...ipListOerviewTrigger.cs,
    ...loginCompanySelect.cs,
    ...bulkPayment.cs,
    ...domainRoutingInfoDialog.cs,
    ...paymentStatuses.cs,
    ...invoiceStatuses.cs,
    ...clientServiceStatuses.cs,
    ...paymentTypes.cs,
    ...menuItems.cs,
    ...billingCard.cs,
    ...servicesCard.cs,
    ...invoiceItem.cs,
    ...clientServiceItem.cs,
    ...dashboard.cs,
    ...searchTrigger.cs,
    ...avatarCard.cs,
    ...searchTable.cs,
    ...companyCard.cs,
    ...files.cs,
    ...paymentItem.cs,
    ...userInfoFormDefault.cs,
    ...passwordFormDefault.cs,
    ...userCardDefault.cs,
    ...ticketState.cs,
    ...ticketList.cs,
    ...ticketsCard.cs,
    ...ticketUnlock.cs,
    ...ticketTextFormDefault.cs,
    ...priceOfferRejectFormDefault.cs,
    ...realizationDateRejectFormDefault.cs,
    ...ticketTopics.cs,
    ...ticketsMessage.cs,
    ...ticketsTrigger.cs,
    ...wsReconnect.cs,
    ...ticketsFilter.cs,
    ...ticketCopyList.cs,
    ...ticketRating.cs,
    ...ticketAuthorization.cs,
    ...ticketAddForm.cs,
    ...ticketAddContainer.cs,
    ...ticketsCommon.cs,
    ...graphTypes.cs,
    ...ticketBase.cs,
    ...search.cs,
    ...services.cs,
    ...graphIntervals.cs,
    ...serversBase.cs,
    ...serversEditPages.cs,
    ...COMMON.cs,
    ...authBase.cs,
    ...loggedUser.cs,
    ...recaptcha.cs,
    ...API_ERROR_CODES.cs,
    ...domainStatuses.cs,
    ...domainsBase.cs,
    ...creditStatus.cs
  },
  hu: {
    ...ipListOerviewTrigger.hu,
    ...loginCompanySelect.hu,
    ...bulkPayment.hu,
    ...domainRoutingInfoDialog.hu,
    ...paymentStatuses.hu,
    ...invoiceStatuses.hu,
    ...clientServiceStatuses.hu,
    ...paymentTypes.hu,
    ...menuItems.hu,
    ...billingCard.hu,
    ...servicesCard.hu,
    ...invoiceItem.hu,
    ...clientServiceItem.hu,
    ...dashboard.hu,
    ...searchTrigger.hu,
    ...avatarCard.hu,
    ...searchTable.hu,
    ...companyCard.hu,
    ...files.hu,
    ...paymentItem.hu,
    ...userInfoFormDefault.hu,
    ...passwordFormDefault.hu,
    ...userCardDefault.hu,
    ...ticketState.hu,
    ...ticketList.hu,
    ...ticketsCard.hu,
    ...ticketUnlock.hu,
    ...ticketTextFormDefault.hu,
    ...priceOfferRejectFormDefault.hu,
    ...realizationDateRejectFormDefault.hu,
    ...ticketTopics.hu,
    ...ticketsMessage.hu,
    ...ticketsTrigger.hu,
    ...wsReconnect.hu,
    ...ticketsFilter.hu,
    ...ticketCopyList.hu,
    ...ticketRating.hu,
    ...ticketAuthorization.hu,
    ...ticketAddForm.hu,
    ...ticketAddContainer.hu,
    ...ticketsCommon.hu,
    ...graphTypes.hu,
    ...ticketBase.hu,
    ...search.hu,
    ...services.hu,
    ...graphIntervals.hu,
    ...serversBase.hu,
    ...serversEditPages.hu,
    ...COMMON.hu,
    ...authBase.hu,
    ...loggedUser.hu,
    ...recaptcha.hu,
    ...API_ERROR_CODES.hu,
    ...domainStatuses.hu,
    ...domainsBase.hu,
    ...creditStatus.hu
  },
  de: {
    ...ipListOerviewTrigger.de,
    ...loginCompanySelect.de,
    ...bulkPayment.de,
    ...domainRoutingInfoDialog.de,
    ...paymentStatuses.de,
    ...invoiceStatuses.de,
    ...clientServiceStatuses.de,
    ...paymentTypes.de,
    ...menuItems.de,
    ...billingCard.de,
    ...servicesCard.de,
    ...invoiceItem.de,
    ...clientServiceItem.de,
    ...dashboard.de,
    ...searchTrigger.de,
    ...avatarCard.de,
    ...searchTable.de,
    ...companyCard.de,
    ...files.de,
    ...paymentItem.de,
    ...userInfoFormDefault.de,
    ...passwordFormDefault.de,
    ...userCardDefault.de,
    ...ticketState.de,
    ...ticketList.de,
    ...ticketsCard.de,
    ...ticketUnlock.de,
    ...ticketTextFormDefault.de,
    ...priceOfferRejectFormDefault.de,
    ...realizationDateRejectFormDefault.de,
    ...ticketTopics.de,
    ...ticketsMessage.de,
    ...ticketsTrigger.de,
    ...wsReconnect.de,
    ...ticketsFilter.de,
    ...ticketCopyList.de,
    ...ticketRating.de,
    ...ticketAuthorization.de,
    ...ticketAddForm.de,
    ...ticketAddContainer.de,
    ...ticketsCommon.de,
    ...graphTypes.de,
    ...ticketBase.de,
    ...search.de,
    ...services.de,
    ...graphIntervals.de,
    ...serversBase.de,
    ...serversEditPages.de,
    ...COMMON.de,
    ...authBase.de,
    ...loggedUser.de,
    ...recaptcha.de,
    ...API_ERROR_CODES.de,
    ...domainStatuses.de,
    ...domainsBase.de,
    ...creditStatus.de
  },
};
