import * as tslib_1 from "tslib";
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11;
import { USER_INFO_FORM_DEFAULT_TRANSLATION_KEYS as UIF } from './modules/common/user-info-form';
import { PASSWORD_FORM_DEFAULT_TRANSLATION_KEYS as PF } from './modules/common/password-form';
import { USER_CARD_DEFAULT_TRANSLATION_KEYS as UC } from './modules/common/user-card';
import { TICKET_TEXT_FORM_DEFAULT_TRANSLATION_KEYS as TT } from './modules/common/ticket-text-form';
import { PRICE_OFFER_REJECT_FORM_DEFAULT_TRANSLATION_KEYS as PO } from './modules/common/ticket-price-offer';
import { REALIZATION_DATE_REJECT_FORM_DEFAULT_TRANSLATION_KEYS as RD } from './modules/common/ticket-realization-date';
import { emailFormGenerateTranslationKeys as efg } from './modules/common/email-form';
import { ServerIntervalTypes } from '@app/base/servers-base';
var ticketTextFormDefault = {
    en: (_a = {},
        _a[TT.placeholders.ticketMessage] = "What can we do for you?",
        _a),
    cs: (_b = {},
        _b[TT.placeholders.ticketMessage] = "Co pro v\u00E1s m\u016F\u017Eeme ud\u011Blat?",
        _b),
    hu: (_c = {},
        _c[TT.placeholders.ticketMessage] = "Mit tehet\u00FCnk \u00F6n\u00E9rt?",
        _c),
    de: (_d = {},
        _d[TT.placeholders.ticketMessage] = "Was k\u00F6nnen wir f\u00FCr Sie tun?",
        _d)
};
var priceOfferRejectFormDefault = {
    en: (_e = {},
        _e[PO.labels.messageText] = "Message text",
        _e),
    cs: (_f = {},
        _f[PO.labels.messageText] = "Text zpr\u00E1vy",
        _f),
    hu: (_g = {},
        _g[PO.labels.messageText] = "Az \u00FCzenet sz\u00F6vege",
        _g),
    de: (_h = {},
        _h[PO.labels.messageText] = "Text der Nachricht",
        _h)
};
var realizationDateRejectFormDefault = {
    en: (_j = {},
        _j[RD.labels.messageText] = "Message text",
        _j),
    cs: (_k = {},
        _k[RD.labels.messageText] = "Text zpr\u00E1vy",
        _k),
    hu: (_l = {},
        _l[RD.labels.messageText] = "Az \u00FCzenet sz\u00F6vege",
        _l),
    de: (_m = {},
        _m[RD.labels.messageText] = "Text der Nachricht",
        _m)
};
var userInfoFormDefault = {
    en: (_o = {},
        _o[UIF.labels.firstName] = 'First Name',
        _o[UIF.labels.lastName] = 'Last Name',
        _o[UIF.labels.phoneNumber] = 'Phone Number',
        _o[UIF.labels.smsNotifications] = 'Send important events SMS notifications',
        _o[UIF.labels.language] = 'Language',
        _o[UIF.errors.firstName.required] = 'First Name has to be filled in',
        _o[UIF.errors.lastName.required] = 'Last Name has to be filled in',
        _o[UIF.errors.phoneNumber.required] = 'Phone Number has to be filled in',
        _o),
    cs: (_p = {},
        _p[UIF.labels.firstName] = 'Jméno',
        _p[UIF.labels.lastName] = 'Příjmení',
        _p[UIF.labels.phoneNumber] = 'Telefonní číslo',
        _p[UIF.labels.smsNotifications] = 'Posílat SMS při důležitých událostech',
        _p[UIF.labels.language] = 'Jazyk',
        _p[UIF.errors.firstName.required] = 'Jméno musí být vyplněno.',
        _p[UIF.errors.lastName.required] = 'Příjmení musí být vyplněno.',
        _p[UIF.errors.phoneNumber.required] = 'Telefonní číslo musí být vyplněno.',
        _p),
    hu: (_q = {},
        _q[UIF.labels.firstName] = 'Név',
        _q[UIF.labels.lastName] = 'Vezetéknév',
        _q[UIF.labels.phoneNumber] = 'Telefonszám',
        _q[UIF.labels.smsNotifications] = 'SMS küldése fontos eseményeknél',
        _q[UIF.labels.language] = 'Nyelv',
        _q[UIF.errors.firstName.required] = 'A nevet ki kell tölteni',
        _q[UIF.errors.lastName.required] = 'A vezetéknevet ki kell tölteni.',
        _q[UIF.errors.phoneNumber.required] = 'A telefonszámot ki kell tölteni.',
        _q),
    de: (_r = {},
        _r[UIF.labels.firstName] = 'Vorname',
        _r[UIF.labels.lastName] = 'Nachname',
        _r[UIF.labels.phoneNumber] = 'Telefonnummer',
        _r[UIF.labels.smsNotifications] = 'SMS bei wichtigen Ereignissen senden',
        _r[UIF.labels.language] = 'Sprache',
        _r[UIF.errors.firstName.required] = 'Der Vorname muss ausgefüllt werden.',
        _r[UIF.errors.lastName.required] = 'Der Nachname muss ausgefüllt werden.',
        _r[UIF.errors.phoneNumber.required] = 'Die Telefonnummer muss ausgefüllt werden.',
        _r)
};
var passwordFormDefault = {
    en: (_s = {},
        _s[PF.labels.password] = 'Password',
        _s[PF.errors.password.required] = 'Password is required.',
        _s),
    cs: (_t = {},
        _t[PF.labels.password] = 'Heslo',
        _t[PF.errors.password.required] = 'Heslo musí být vyplněno.',
        _t),
    hu: (_u = {},
        _u[PF.labels.password] = 'Jelszó',
        _u[PF.errors.password.required] = 'Jelszót kell kitölteni.',
        _u),
    de: (_v = {},
        _v[PF.labels.password] = 'Passwort:',
        _v[PF.errors.password.required] = 'Das Passwort muss ausgefüllt werden.',
        _v)
};
var userCardDefault = {
    en: (_w = {},
        _w[UC.tags.accessToClientZone] = 'Access to CZ',
        _w[UC.tags.authorizedToSendTickets] = 'Support contact rights',
        _w[UC.tags.cloudDnsEnabled] = 'Managing CloudDNS',
        _w[UC.tags.cdnEnabled] = 'Managing CDN',
        _w[UC.tags.cloudMailEnabled] = 'Managing CloudMail',
        _w),
    cs: (_x = {},
        _x[UC.tags.accessToClientZone] = 'Přístup do KZ',
        _x[UC.tags.authorizedToSendTickets] = 'Zakládání požadavků',
        _x[UC.tags.cloudDnsEnabled] = 'Přístup do CloudDNS',
        _x[UC.tags.cdnEnabled] = 'Přístup do CDN',
        _x[UC.tags.cloudMailEnabled] = 'Přístup do CloudMail',
        _x),
    hu: (_y = {},
        _y[UC.tags.accessToClientZone] = 'Hozzáférés az ügyfélzónába',
        _y[UC.tags.authorizedToSendTickets] = 'Követelmény létrehozása',
        _y[UC.tags.cloudDnsEnabled] = 'Hozzáférés a CloudDNS-hez',
        _y[UC.tags.cdnEnabled] = 'A CDN kezelése',
        _y[UC.tags.cloudMailEnabled] = 'A CloudMail kezelése',
        _y),
    de: (_z = {},
        _z[UC.tags.accessToClientZone] = 'Zugang zum KB',
        _z[UC.tags.authorizedToSendTickets] = 'Erstellen von Anfragen',
        _z[UC.tags.cloudDnsEnabled] = 'Zugang zu CloudDNS',
        _z)
};
import { TICKET_ADD_FORM_DEFAULT_TRANSLATION_KEYS as TAF } from '@app/common/ticket-add-form';
var ticketAddForm = {
    cs: (_0 = {},
        _0[TAF.labels.title] = 'Předmět',
        _0[TAF.hints.title] = 'Výstižný název požadavku.',
        _0[TAF.errors.title.required] = 'Předmět musí být vyplněn',
        _0[TAF.labels.ticketMessage] = 'Obsah požadavku',
        _0[TAF.hints.ticketMessage] = 'Zevrubný popis vašeho požadavku.',
        _0[TAF.errors.ticketMessage.required] = 'Obsah požadavku musí být vyplněn.',
        _0),
    en: (_1 = {},
        _1[TAF.labels.title] = 'Subject',
        _1[TAF.hints.title] = 'Short description of the subject.',
        _1[TAF.errors.title.required] = 'Subject is required',
        _1[TAF.labels.ticketMessage] = 'Request summary',
        _1[TAF.hints.ticketMessage] = 'Extensive description of your request.',
        _1[TAF.errors.ticketMessage.required] = 'Summary is required.',
        _1),
    hu: (_2 = {},
        _2[TAF.labels.title] = 'Tárgy',
        _2[TAF.hints.title] = 'Az igény rövid elnevezése.',
        _2[TAF.errors.title.required] = 'A tárgyat ki kell tölteni',
        _2[TAF.labels.ticketMessage] = 'Az igény tartalma',
        _2[TAF.hints.ticketMessage] = 'Az igény rövid leírása.',
        _2[TAF.errors.ticketMessage.required] = 'Az igény tartalmát ki kell tölteni.',
        _2),
    de: (_3 = {},
        _3[TAF.labels.title] = 'Betreff',
        _3[TAF.hints.title] = 'Ein kurzer Titel für die Anfrage.',
        _3[TAF.errors.title.required] = 'Betreff muss ausgefüllt werden.',
        _3[TAF.labels.ticketMessage] = 'Inhalt der Anfrage',
        _3[TAF.hints.ticketMessage] = 'Eine detaillierte Beschreibung Ihrer Anfrage.',
        _3[TAF.errors.ticketMessage.required] = 'Inhalt der Anfrage muss ausgefüllt werden.',
        _3)
};
var COMMON = {
    en: {
        common: {
            close: 'Close',
            retry: 'Retry',
            delete: 'Delete',
            confirm: 'Confirm',
            takeBack: 'Take back',
            showMore: 'Show more',
            hideMore: 'Hide more',
            selectAll: 'Select all',
            requestChanges: 'Request details changes',
            companyTeam: 'VSH team',
            created: 'Created',
            dueDate: 'Due date',
            codename: 'your codename',
            paid: 'Paid',
            and: 'and',
            or: 'or',
            copyToUrl: 'Copy URL to clipboard.',
            openInNew: 'Open in new tab or window',
            copied: 'URL copied to the clipboard.',
            copiedDomainRecord: 'Record copied to the clipboard.',
            copiedDnssecKey: 'Signature key copied to the clipboard',
            kvmConsole: 'Active KVM connections',
            roles: {
                MANAGER: 'Administrator',
                TECHNICAL_USER: 'Technical user',
                FINANCIAL_USER: 'Financial user'
            },
            contactUs: 'Contact us',
            files: {
                maxCount: 'Maximum allowed number of files reached.',
                maxSize: 'Maximum allowed files size reached.',
                fileSize: 'Size: <strong>{size}</strong>'
            },
            lastUpdate: 'Last update',
            TODO: 'TODO TRANSLATION',
            support: 'Support',
            monthly: 'monthly',
            true: 'yes',
            false: 'no'
        }
    },
    cs: {
        common: {
            close: 'Zavřít',
            retry: 'Opakovat',
            delete: 'Smazat',
            confirm: 'Potvrdit',
            takeBack: 'Vzít zpět',
            showMore: 'Zobrazit více',
            hideMore: 'Skrýt více',
            selectAll: 'Vybrat vše',
            requestChanges: 'Požádat o změnu údajů',
            companyTeam: 'VSH tým',
            created: 'Vytvořeno',
            dueDate: 'Splatnost',
            codename: 'vaše označení ',
            paid: 'Uhrazeno',
            or: 'nebo',
            copyToUrl: 'Zkopírovat URL do schránky.',
            openInNew: 'Otevřít v novém okně či záložce',
            copied: 'URL zkopírována do schránky.',
            copiedDomainRecord: 'Záznam zkopírován do clipboardu.',
            copiedDnssecKey: 'Podpis zkopírován do clipboardu',
            kvmConsole: 'Aktivní spojení s KVM',
            roles: {
                MANAGER: 'Administrátor',
                TECHNICAL_USER: 'Technický správce',
                FINANCIAL_USER: 'Ekonomický správce'
            },
            files: {
                maxCount: 'Dosažen maximální povolený počet souborů.',
                maxSize: 'Maximální velikost vybraných souborů dosažena.',
                fileSize: 'Velikost: <strong>{size}</strong>'
            },
            lastUpdate: 'Poslední změna',
            contactUs: 'Kontaktujte nás',
            TODO: 'TODO PŘEKLAD',
            support: 'Podpora',
            monthly: 'měsíčně',
            true: 'ano',
            false: 'ne'
        }
    },
    hu: {
        common: {
            close: 'Bezárás',
            retry: 'Ismétlés',
            delete: 'Törlés',
            confirm: 'Visszaigazolás',
            takeBack: 'Visszavonás',
            showMore: 'Több mutatása',
            hideMore: 'Továbbiak elrejtése',
            selectAll: 'Összes kijelölése',
            requestChanges: 'Adatok módosításának kérelme',
            companyTeam: 'VSH csapat',
            created: 'Létrehozva',
            dueDate: 'Esedékesség',
            codename: 'az Ön megjelölése',
            paid: 'Fizetve',
            or: 'vagy',
            copyToUrl: 'URL másolása a fiókba',
            openInNew: 'Megnyitás új ablakban vagy fülön',
            copied: 'URL másolva a fiókba.',
            copiedDomainRecord: 'Bejegyzés a vágólapra másolva.',
            copiedDnssecKey: 'Aláírás a vágólapra másolva',
            kvmConsole: 'Aktív kapcsolat a KVM-mel',
            roles: {
                MANAGER: 'Rendszergazda',
                TECHNICAL_USER: 'Műszaki rendszergazda',
                FINANCIAL_USER: 'Gazdasági rendszergazda'
            },
            files: {
                maxCount: 'Elérte a maximálisan engedélyezett fájlok számát.',
                maxSize: 'Elérte a kiválasztott fájlok maximális méretét.',
                fileSize: 'Méret: <strong>{size}</strong>'
            },
            lastUpdate: 'Utolsó módosítás',
            contactUs: 'Lépjen kapcsolatba velünk',
            TODO: 'TODO FORDÍTÁS',
            support: "T\u00E1mogat\u00E1s",
            monthly: "havonta",
            true: "igen",
            false: "nem",
        }
    },
    de: {
        common: {
            close: 'Schließen',
            retry: 'Wiederholen',
            delete: 'Löschen',
            confirm: 'Bestätigen',
            takeBack: 'Rückgängig machen',
            showMore: 'Mehr anzeigen',
            hideMore: 'Mehr ausblenden',
            selectAll: 'Alle auswählen',
            requestChanges: 'Änderungen der Daten anfordern',
            companyTeam: 'VSH Team',
            created: 'Erstellt',
            dueDate: 'Fälligkeit',
            codename: 'Ihre Bezeichnung',
            paid: 'Bezahlt',
            or: 'oder',
            copyToUrl: 'Die URL in die Zwischenablage kopieren.',
            openInNew: 'In einem neuen Fenster oder Tab öffnen',
            copied: 'URL in die Zwischenablage kopiert.',
            copiedDomainRecord: 'Eintrag in die Zwischenablage kopiert.',
            copiedDnssecKey: 'Unterschrift in die Zwischenablage kopiert.',
            kvmConsole: 'Aktive Verbindung zu KVM',
            roles: {
                MANAGER: 'Administrator',
                TECHNICAL_USER: 'Technischer Administrator',
                FINANCIAL_USER: 'Finanzadministrator',
            },
            files: {
                maxCount: 'Die maximal zulässige Anzahl von Dateien erreicht.',
                maxSize: 'Maximale Größe der ausgewählten Dateien erreicht.',
                fileSize: 'Größe: <strong>{size}</strong>'
            },
            lastUpdate: 'Letzte Änderung',
            contactUs: 'Kontaktieren Sie uns',
            TODO: 'TODO ÜBERSETZUNG',
            support: 'Support',
            monthly: 'monatlich',
            true: 'ja',
            false: 'nein'
        }
    }
};
var API_ERROR_CODES = {
    en: {
        error: {
            400: "Invalid user input",
            401: "Not authorized",
            402: "Insufficient privileges",
            403: "Refresh token invalid or expired",
            404: "Endpoint not found",
            405: "Potentially fraud behaviour detected. Please provide additional validation by Google reCaptcha.",
            406: "Google reCaptcha response invalid",
            407: "Download token invalid or expired",
            408: "Invalid URL address Format",
            409: "",
            410: "",
            411: "No rights to selected action.",
            412: "File size limit 4MB exceeded.",
            413: "Additional validation failed.",
            414: "This action is not allowed for VSHosting users.",
            500: "Internal server error.",
            2000: "Incorrect credentials.",
            2001: "Incorrect user's credentials.",
            2002: "Not valid clientUserId.",
            2003: "Your temporary token for password recovery has expired. Please recover password again.",
            2004: "Token does not found.",
            2005: "Password too weak or too long.",
            2006: "Invalid IP address.",
            2007: "User has no active connection to any client.",
            2008: "Your temporary token has expired.",
            2009: "You do not have permission for the action you want. Permissions may have changed.",
            2010: "You don't have access to the client zone. Please contact your VSHosting account administrator.",
            2011: "PTR record is invalid. Please use a fully qualified domain name (FQDN) like admin.vshosting.cloud",
            2012: "Login with email and password has been deactivated. Please use another login method.",
            2013: "Login with email and password has been deactivated. For this reason, the recover forgotten password\n      function is not available. Please use another login method.",
            2101: "User account does not exists.",
            2102: "User account already exists.",
            2103: "Language does not exists.",
            2104: "Country calling code does not exists.",
            2105: "Template does not exists.",
            2106: "Country calling code does not match the phone number.",
            2107: "Invalid phone number.",
            2108: "Invalid user role.",
            2109: "Client does not found.",
            2110: "Invalid client and user group combination.",
            2111: "User with email already exists.",
            2112: "User with same email is already connected to {name}.",
            2113: "Message type does not exists.",
            2114: "Not valid meta atributes.",
            2115: "Client already activated.",
            2116: "Token is not valid UUID.",
            2117: "Invalid email pattern.",
            2118: "Invalid client pattern.",
            2119: "invalid userId pattern.",
            2120: "Country does not exists.",
            2121: "The operation could not be performed because the entered data is not valid.",
            2122: "Record does not found.",
            2123: "Exceeded the maximum number of users on the client (70).",
            2124: "Invalid PIN pattern.",
            2125: "Invalid ticket priority ID.",
            2126: "Invalid Ticket Topic ID",
            2127: "Invalid application ID.",
            2128: "Invalid rating type.",
            2129: "Ticket is not resolved or closed. Rating is denied till the ticket is resolved.",
            2130: "No data found.",
            2131: "User is not in BEING DELETED status.",
            2132: "Ticket is authorized already.",
            2133: "Unable to delete your own account.",
            2134: "Client first user have to be MANAGER.",
            2135: "User's email can only be edited within the first 24 hours of its creation.",
            2136: "Not valid postal address.",
            2137: "Invoice does not exist.",
            2138: "Server does not exists.",
            2139: "IP address already used.",
            2140: "Ip addres not in client IP address range.",
            2141: "Unable to set PTR. IP address is not connected to any server.",
            2142: "Server Park access does not found.",
            2143: "At least one connected user in the role of \"MANAGER\" is required for each active client.",
            2144: "Active user must be connected to at least one client.",
            2145: "User does not have permission to receive secure communication.",
            2146: "No free IPv4 Address.",
            2147: "IP address is reserved and cannot be assigned to a server.",
            2148: "Ticket not found.",
            2149: "Ticket attachment not found.",
            2150: "Support department not found.",
            2151: "Your e-mail is not in VSHosting clients' contact list. Your message has been denied.",
            2152: "Creating tickets by e-mail feature has been disabled for your account. You can enable it at VSHosting clients' zone.",
            2153: "Unknown ticket mailbox.",
            2154: "Ticket is closed. Further messages cannot be added.",
            2155: "External administrator is not allowed to create tickets.",
            2156: "Ticket rating has been sent already.",
            2157: "Rating is allowed for tickets from clients only.",
            2158: "Message is required for bad rating.",
            2159: "Ticket does not belong to the client.",
            2160: "Administrator is not allowed to create tickets.",
            2161: "Administrator is only allowed to reply to internal tickets he created.",
            2162: "Ticket is closed and cannot be modified.",
            2163: "Ticket rejected due to duplicate checksum.",
            2164: "Creating tickets by e-mail is not available. Please, create a ticket at the SnackHost dashboard.",
            2165: "Conflict with country VAT change process. Try again later.",
            2166: "Conflict with billing in process. Try again later.",
            2167: "ClientUser not found or not active.",
            2168: "User is not authorized to send or edit client's tickets.",
            2169: "Client user connection not found.",
            2170: "Authorization token is invalid or expired.",
            2171: "PTR record cannot be set. IP address is not assigned to any server.",
            2172: "Payment not found.",
            2173: "Amount of requested records is out of limit.",
            2174: "Invalid file type.",
            2175: "Server graph does not exist.",
            2176: "Bad or expired activation link.",
            2177: "This person has access to the ServerPark already.",
            2178: "Please provide exactly 1 file for upload, sent via form-data.",
            2179: "Internal server error.",
            2180: "Date of visit have to be in future.",
            2181: "Unable to edit deleted user.",
            2182: "Invalid identity document number.",
            2183: "Birthdate have to be in past.",
            2184: "Bad or expired link.",
            2185: "User does not have CloudDNS access enabled for this client.",
            2186: "Ticket was not send from email connected to logged user.",
            2190: "This e-mail is already used for VSHosting administrator account. Use another e-mail.",
            2194: "This ticket has no price offer.",
            2195: "A new message has been added to the ticket. Please read it first before you confirm the price offer.",
            2196: "The price offer has already been approved.",
            2197: "Ticket has no realization date waiting for confirmation.",
            2198: "Realization date cannot be confirmed because is already in the past.",
            2199: "Realization date has already been confirmed.",
            2200: "The user is the authorised person of the master agreement. Before changing the role, the user must be removed\n      from the list of authorized persons. Please contact VSHosting support.",
            2201: "The user is the authorised person of the master agreement. Before deleting, the user must be removed from the\n      list of authorized persons. Please contact VSHosting support.",
            2501: "Unspecified Error.",
            2502: "Action is temporarily blocked by another conflicting operation.",
            2503: "Error sending email.",
            2504: "Unable to login user automatically.",
            2505: "Failed to change client PIN",
            4101: "Client not found.",
            4102: "Invalid domain name.",
            4103: "Domain name is too long.",
            4104: "Only second level domain names and first level before public suffix domain names are allowed.",
            4105: "Domain is deleted. No changes of domain records are allowed. Please recover the domain first.",
            4106: "Domain is deactivated. No changes of domain records are allowed. Please activate the domain first.",
            4107: "Only VSHosting administrator can perform this action.",
            4108: "Domain not found.",
            4109: "Domain record not found.",
            4110: "Domain version not found.",
            4111: "Domain log not found.",
            4112: "The domain already exists in your domain list.",
            4113: "The domain is already used by another client. If you are the owner of the domain, please contact\n        <a class=\"__link\" href=\"/tickets/new/MANAGED_SERVICES_ISSUE?title=Domain ownership\">VSHosting support</a>.\n      ",
            4114: "Domain record is not deleted.",
            4115: "This domain record is part of an outdated domain change and cannot be recovered. Please Refresh domain record list.",
            4116: "The domain has already been recovered.",
            4117: "Unable to reset domain changes, which are publicated or being publicated.",
            4118: "Unpublished and deleted domain cannot be deactivated.",
            4119: "Deactivated domain required.",
            4120: "Only ASCII or A-label form domain names are allowed.",
            4121: "Unable to activate DNSSEC on domain.",
            4122: "Unable to deactivate DNSSEC on domain.",
            4123: "DNSSEC is already enabled.",
            4124: "DNSSEC is already disabled.",
            4125: "Unable to publish DNSSEC public key.",
            4126: "Unable to revoke DNSSEC public key.",
            4127: "Error reading DNSSEC public key.",
            4128: "DNSSEC can be disabled after one hour since DNSSEC was enabled.",
            4129: "DNSSEC cannot be disabled until its public key is deleted at the domain registrar.",
            4130: "Domain and registrator DNSSEC public key mismatch.",
            4131: "Unsupported domain record type.",
            4132: "DNSSEC public key cannot be published before the DNSSEC is activated.",
            4133: "The domain is being modified. Please try again later.",
            4134: "Unsupported TTL Value. Allowed range is from 60 to 86400 seconds.",
            4135: "This domain record is part of an outdated domain change and cannot be modified. Please Refresh domain record list.",
            4136: "The identical domain record already exists. Duplicates are not allowed.",
            4137: "",
            4138: "The domain record cannot be recovered due to a duplicate record.",
            4139: "The identical domain record has been deleted. Please use recover function instead of adding a new domain record.",
            4140: "There are no changes in domain records. Nothing to publish.",
            4141: "Domain record limit exceeded. Please contact vshosting~ support.",
            4142: "Invalid characters in record name. Valid hostname required.",
            4143: "Invalid record name. Valid hostname required.",
            4144: "Only ASCII chars are allowed. Non-ASCII characters must be encoded in IDN format.",
            4145: "Invalid domain record length. Allowed range is between 1 and 1024 characters.",
            4146: "This domain is managed by an external application and cannot be modified in CloudDNS.",
            4147: "Account domain count limit exceeded. Please contact VSHosting support.",
            4148: "Active client is required to add a domain.",
            4149: "Domain already exist in PowerDNS as a no-master zone. Please contact VSHosting support.",
            4150: "The domain is blacklisted and can not be deleted or deactivated.",
            4151: "The domain is blacklisted and cannot be modified by an external application.",
            4152: "Inserted domain record is in conflict with existing CNAME record with the same name.",
            4153: "Inserted domain record is in conflict with existing record with the same name.",
            4154: "The domain is managed by CloudDNS and cannot be modified by an external application.\n        Please delete the domain in CloudDNS first or contact VSHosting support.",
            4155: "The domain is managed by another external application. You have to delete the domain in the application first.",
            4501: "Internal server error. Checksum control failed.",
            4502: "The domain is temporarily locked by another conflicting action. Please try again later.",
            4503: "Client temporary not available. Please try again later.",
            4504: "DnsClientApp temporary not available. Please try again later.",
            4505: "DNSSEC activation failed.",
            4506: "DNSSEC deactivation failed.",
            4507: "DNSSEC public key publication failed.",
            4508: "DNSSEC public key revocation failed.",
            4509: "Loading of public DNSSEC key failed.",
            5001: "This action is not allowed for VSHosting users.",
            5002: "For security reasons, please login again before updating your login options.",
            5003: "MojeID authentication token has expired. Please login to your MojeID account again.",
            5004: "Verification of your MojeID account has failed. Please login to your MojeID account again.\n      If problems persist, please contact VSHosting support.",
            5005: "There is no VSHosting account linked to this MojeID account. Login with your email and password\n      first and connect your MojeID account to your VSHosting account.",
            5006: "Google authentication token has expired. Please login to your Google account again.",
            5007: "Verification of your Google account has failed. Please login to your Google account again. If problems\n      persist, please contact VSHosting support.",
            5008: "There is no VSHosting account linked to this Google account. Login with your email and password first and\n      connect your Google account to your VSHosting account.",
            5009: "Your MojeID account is already connected to another VSHosting user account. Please use another MojeID account.",
            5010: "Your Google account is already connected to another VSHosting user account. Please login to another Google account.",
            5011: "At least one login method is required.",
            5012: "Verification with your Apple ID has failed. Please login with your Apple ID again. If problems persist,\n      please contact VSHosting support.",
            5013: "There is no VSHosting account linked to this Apple ID. Login with your email and password first\n      and connect your Apple ID to your VSHosting account.",
            5014: "Your Apple ID is already connected to another VSHosting user account. Please login with another Apple ID.",
            5015: "Apple ID authentication token has expired. Please login with your Apple ID again.",
            5101: "Internal server error.",
            5102: "Internal server error.",
            5103: "Internal server error.",
            5104: "Internal server error.",
            5105: "Internal server error.",
            5106: "Internal server error.",
            5107: "Internal server error.",
            5108: "Internal server error.",
            5109: "Internal server error.",
            5110: "This domain is blacklisted and can not be deleted.",
            5111: "Internal server error.",
            5112: "Internal server error.",
            5113: "Internal server error.",
            5114: "Internal server error.",
            5115: "Internal server error.",
            5116: "Internal server error.",
            5117: "Internal server error.",
            5118: "Internal server error.",
            5119: "Internal server error.",
            5500: "Internal system error.",
            6101: "Clients domain limit have to be a positive number.",
            6102: "Clients domain record limit have to be a positive number.",
            6103: "Domain not found.",
            6104: "Domain log not found.",
            6105: "Client not found.",
            6106: "Client DNS App not found.",
            6107: "Action log record not found.",
            6108: "Change log record not found.",
            6109: "Unable to import domain existing in CloudDNS.",
            6110: "Function disabled in application config."
        }
    },
    cs: {
        error: {
            400: "Zadan\u00E9 \u00FAdaje jsou neplatn\u00E9",
            401: "K proveden\u00ED akce je vy\u017Eadov\u00E1no p\u0159ihl\u00E1\u0161en\u00ED",
            402: "Nem\u00E1te dostate\u010Dn\u00E1 opr\u00E1vn\u011Bn\u00ED na proveden\u00ED \u010Dinnosti.",
            403: "Refresh token je neplatn\u00FD nebo vypr\u0161ela jeho platnost",
            404: "Endpoint nenalezen",
            405: "Bylo zji\u0161t\u011Bno potenci\u00E1ln\u011B podvodn\u00E9 chov\u00E1n\u00ED. Prove\u010Fte pros\u00EDm ov\u011B\u0159en\u00ED slu\u017Ebou Google reCaptcha.",
            406: "Neplatn\u00E1 odpov\u011B\u010F Google reCaptcha",
            407: "Token pro download je neplatn\u00FD nebo expirovan\u00FD",
            408: "Neplatn\u00FD form\u00E1t URL adresy",
            409: "",
            410: "",
            411: "Nem\u00E1te pr\u00E1va k proveden\u00ED vybran\u00E9 akce.",
            412: "P\u0159ekro\u010Dena max. velikost souboru 4MB pro upload.",
            413: "Neo\u010Dek\u00E1van\u00E1 chyba aplikace. Opakuj pros\u00EDm akci znovu. Pokud pot\u00ED\u017Ee p\u0159etrvaj\u00ED, nahla\u0161 chybu v\u00FDvoj\u00E1\u0159\u016Fm.",
            414: "Tato akce nen\u00ED povolena pro u\u017Eivatele VSHosting.",
            500: "Intern\u00ED chyba syst\u00E9mu.",
            2000: "Chybn\u00E9 p\u0159ihla\u0161ovac\u00ED \u00FAdaje.",
            2001: "Neplatn\u00E9 u\u017Eivatelsk\u00E9 \u00FAdaje.",
            2002: "Neplatn\u00E1 identifikace u\u017Eivatele.",
            2003: "\u010Casov\u00FD \u00FAsek na zm\u011Bnu hesla vypr\u0161el, za\u017E\u00E1dejte pros\u00EDm o zm\u011Bnu hesla znovu.",
            2004: "Token neodpov\u00EDd\u00E1 \u017E\u00E1dn\u00E9mu z\u00E1znamu.",
            2005: "Zadan\u00E9 u\u017Eivatelsk\u00E9 heslo nespl\u0148uje bezpe\u010Dnostn\u00ED po\u017Eadavky. Heslo mus\u00ED obsahovat nejm\u00E9n\u011B\n        8 znak\u016F bez slov ze slovn\u00EDku nebo alespo\u0148 12 znak\u016F. Z\u00E1rove\u0148 nesm\u00ED b\u00FDt del\u0161\u00ED ne\u017E 72 znak\u016F.",
            2006: "Neplatn\u00E1 IP adresa.",
            2007: "V\u00E1\u0161 u\u017Eivatelsk\u00FD profil nen\u00ED v tuto chv\u00EDli aktivn\u00ED. Pro jeho aktivaci pros\u00EDm\n        kontaktujte administr\u00E1tora Va\u0161eho klientsk\u00E9ho \u00FA\u010Dtu \"VSHosting.\"",
            2008: "Operaci nebylo mo\u017En\u00E9 prov\u00E9st, proto\u017Ee vyhrazen\u00FD \u010Dasov\u00FD \u00FAsek ji\u017E vypr\u0161el.",
            2009: "Na po\u017Eadovanou akci nem\u00E1te opr\u00E1vn\u011Bn\u00ED. Pravd\u011Bpodobn\u011B do\u0161lo ke zm\u011Bn\u011B nastaven\u00ED pr\u00E1v.",
            2010: "Nem\u00E1te pr\u00E1va na p\u0159\u00EDstup do klientsk\u00E9 z\u00F3ny. Kontaktujte pros\u00EDm spr\u00E1vce \u00FA\u010Dtu VSHosting.",
            2011: "Zadan\u00FD PTR z\u00E1znam nen\u00ED platn\u00FD. Zadejte jej ve form\u00E1tu FQDN, nap\u0159. admin.vshosting.cloud",
            2012: "P\u0159ihl\u00E1\u0161en\u00ED pomoc\u00ED e-mailu a hesla bylo deaktivov\u00E1no. Pou\u017Eijte pros\u00EDm jinou metodu p\u0159ihl\u00E1\u0161en\u00ED.",
            2013: "P\u0159ihl\u00E1\u0161en\u00ED pomoc\u00ED e-mailu a hesla bylo deaktivov\u00E1no. Z tohoto d\u016Fvodu nen\u00ED k dispozici funkce\n      obnovy zapomenut\u00E9ho hesla. Pou\u017Eijte pros\u00EDm jinou metodu p\u0159ihl\u00E1\u0161en\u00ED.",
            2101: "U\u017Eivatelsk\u00FD \u00FA\u010Det neexistuje.",
            2102: "U\u017Eivatelsk\u00FD \u00FA\u010Det ji\u017E existuje.",
            2103: "Zadan\u00FD k\u00F3d jazyka je neplatn\u00FD.",
            2104: "Telefonn\u00ED p\u0159edvolba st\u00E1tu je neplatn\u00E1.",
            2105: "Neplatn\u00E1 \u0161ablona.",
            2106: "Telefonn\u00ED p\u0159edvolba neodpov\u00EDd\u00E1 telefonn\u00EDmu \u010D\u00EDslu.",
            2107: "Neplatn\u00E9 telefonn\u00ED \u010D\u00EDslo.",
            2108: "Neplatn\u00E1 role u\u017Eivatele.",
            2109: "Klient nenalezen.",
            2110: "Neplatn\u00E1 kombinace klienta a skupiny u\u017Eivatele.",
            2111: "U\u017Eivatel se zadan\u00FDm e-mailem nebo telefonem ji\u017E existuje.",
            2112: "U\u017Eivatel se shodn\u00FDm emailem je ji\u017E p\u0159ipojen ke klientovi {name}.",
            2113: "Zadan\u00FD typ zpr\u00E1vy neexistuje.",
            2114: "P\u0159edan\u00E9 parametry jsou neplatn\u00E9.",
            2115: "Klient je ji\u017E aktivovan\u00FD.",
            2116: "Token m\u00E1 nespr\u00E1vn\u00FD tvar..",
            2117: "Neplatn\u00FD tvar emailu.",
            2118: "Neplatn\u00FD tvar klientsk\u00E9ho \u010D\u00EDsla.",
            2119: "Neplatn\u00FD tvar \u010D\u00EDsla u\u017Eivatele.",
            2120: "Zadan\u00FD k\u00F3d zem\u011B je neplatn\u00FD.",
            2121: "Operaci nebylo mo\u017En\u00E9 prov\u00E9st, proto\u017Ee zadan\u00E9 \u00FAdaje nejsou platn\u00E9.",
            2122: "Z\u00E1znam nenalezen.",
            2123: "Byl p\u0159ekro\u010Den maxim\u00E1ln\u00ED po\u010Det u\u017Eivatel\u016F u klienta (70).",
            2124: "Neplatn\u00FD tvar PINu.",
            2125: "Neplatn\u00FD typ priority po\u017Eadavku.",
            2126: "Neplatn\u00FD typ po\u017Eadavku.",
            2127: "Neplatn\u00FD typ aplikace.",
            2128: "Neplatn\u00FD typ hodnocen\u00ED.",
            2129: "Po\u017Eadavek nen\u00ED vy\u0159e\u0161en\u00FD nebo uzav\u0159en\u00FD. Hodnotit je mo\u017En\u00E9 a\u017E po uzav\u0159en\u00ED po\u017Eadavku.",
            2130: "Nenalezena \u017E\u00E1dn\u00E1 data.",
            2131: "U\u017Eivatel nen\u00ED ve stavu \"p\u0159ipraven na smaz\u00E1n\u00ED\".",
            2132: "Po\u017Eadavek ji\u017E byl smaz\u00E1n.",
            2133: "Nelze smazat vlastn\u00ED \u00FA\u010Det.",
            2134: "Prvn\u00ED u\u017Eivatel klienta mus\u00ED b\u00FDt p\u0159ipojen v roli \"Administr\u00E1tor\".",
            2135: "E-mail u\u017Eivatele je mo\u017En\u00E9 editovat pouze prvn\u00EDch 24 hod od jeho zalo\u017Een\u00ED.",
            2136: "Nespr\u00E1vn\u011B zadan\u00E1 po\u0161tovn\u00ED adresa.",
            2137: "Faktura neexistuje.",
            2138: "Server neexistuje.",
            2139: "IP adresa je ji\u017E pou\u017Eita.",
            2140: "IP adresa nespad\u00E1 do \u017E\u00E1dn\u00E9ho IP rozsahu klienta",
            2141: "Reverzn\u00ED z\u00E1znam nelze nastavit. IP adresa nen\u00ED p\u0159ipojena k \u017E\u00E1dn\u00E9mu serveru.",
            2142: "P\u0159\u00EDstup do Server Park nenalezen",
            2143: "Minim\u00E1ln\u011B jeden propojen\u00FD u\u017Eivatel v roli \"Administr\u00E1tor\" je po\u017Eadov\u00E1n u ka\u017Ed\u00E9ho aktivn\u00EDho klienta.",
            2144: "Aktivn\u00ED u\u017Eivatel mus\u00ED b\u00FDt p\u0159ipojen\u00FD alespo\u0148 k jednomu u\u017Eivateli.",
            2145: "U\u017Eivatel nem\u00E1 opr\u00E1vn\u011Bn\u00ED na p\u0159ij\u00EDm\u00E1n\u00ED zabezpe\u010Den\u00E9 komunikace.",
            2146: "\u017D\u00E1dn\u00E1 voln\u00E1 IP adresa nebyla nalezena.",
            2147: "Ip adresa je rezervov\u00E1na a nem\u016F\u017Ee b\u00FDt p\u0159i\u0159azena k serveru.",
            2148: "Po\u017Eadavek nebyl nalezen.",
            2149: "P\u0159\u00EDloha po\u017Eadavku nebyla nalezena.",
            2150: "Odd\u011Blen\u00ED nebylo nalezeno.",
            2151: "V\u00E1\u0161 email nen\u00ED zapsan\u00FD v klientsk\u00FDch kontaktech. Va\u0161e zpr\u00E1va bude zahozena.",
            2152: "Vytv\u00E1\u0159en\u00ED po\u017Eadavk\u016F pomoc\u00ED emailu byla pro V\u00E1\u0161 \u00FA\u010Det zak\u00E1z\u00E1na. Nastaven\u00ED m\u016F\u017Eete zm\u011Bnit v klientsk\u00E9 z\u00F3n\u011B VSHostingu.",
            2153: "Nezn\u00E1m\u00E1 emailov\u00E1 schr\u00E1nka.",
            2154: "Po\u017Eadavek je uzav\u0159en\u00FD. Dal\u0161\u00ED zpr\u00E1vy ji\u017E nelze p\u0159id\u00E1vat.",
            2155: "Nen\u00ED dovoleno vytv\u00E1\u0159et po\u017Eadavky z \u00FA\u010Dtu extern\u00EDho administr\u00E1tora.",
            2156: "Hodnocen\u00ED po\u017Eadavku ji\u017E bylo odesl\u00E1no.",
            2157: "Hodnocen\u00ED je mo\u017En\u00E9 p\u0159id\u00E1vat pouze ke klientsk\u00FDm po\u017Eadavk\u016Fm.",
            2158: "V p\u0159\u00EDpad\u011B \u0161patn\u00E9ho hodnocen\u00ED je t\u0159eba p\u0159idat pozn\u00E1mku.",
            2159: "Po\u017Eadavek nepat\u0159\u00ED klientovi.",
            2160: "Administr\u00E1torovi nen\u00ED dovoleno zakl\u00E1dat po\u017Eadavky.",
            2161: "Administr\u00E1tor sm\u00ED odpov\u00EDdat pouze na intern\u00ED po\u017Eadavky, kter\u00E9 zalo\u017Eil.",
            2162: "Po\u017Eadavek je uzav\u0159en a tedy nem\u016F\u017Ee b\u00FDt upravov\u00E1n.",
            2163: "Po\u017Eadavek je zam\u00EDtnut\u00FD, proto\u017Ee obsahuje duplik\u00E1t otisku.",
            2164: "Vytv\u00E1\u0159en\u00ED po\u017Eadavk\u016F pomoc\u00ED emailu nen\u00ED povoleno. Zalo\u017Ete pros\u00EDm po\u017Eadavek v dashboardu Snackhostu.",
            2165: "Nastal probl\u00E9m p\u0159i zm\u011Bn\u011B DPH. Pros\u00EDm zkuste to za chv\u00EDli.",
            2166: "Nastal probl\u00E9m p\u0159i fakturaci. Zkuste to pros\u00EDm za chv\u00EDli.",
            2167: "U\u017Eivatel klienta nebyl nalezen, nebo je neaktivn\u00ED.",
            2168: "U\u017Eivatel nen\u00ED opr\u00E1vn\u011Bn odes\u00EDlat nebo upravovat po\u017Eadavky.",
            2169: "Vazba mezi klientam a u\u017Eivatelem nebyla nalezena.",
            2170: "Autoriza\u010Dn\u00ED token je neplatn\u00FD nebo ji\u017E vypr\u0161el.",
            2171: "PTR z\u00E1znam nem\u016F\u017Ee b\u00FDt nastaven, proto\u017Ee IP adresa nen\u00ED p\u0159i\u0159azena \u017E\u00E1dn\u00E9mu serveru.",
            2172: "Platba neexistuje.",
            2173: "Po\u010Det po\u017Eadovan\u00FDch z\u00E1znam\u016F je mimo limit.",
            2174: "Chybn\u00FD typ souboru.",
            2175: "Graf serveru neexistuje.",
            2176: "Chybn\u00FD nebo expirovan\u00FD aktiva\u010Dn\u00ED odkaz.",
            2177: "Tato osoba ji\u017E m\u00E1 do ServerParku p\u0159id\u011Blen\u00FD p\u0159\u00EDstup.",
            2178: "Po\u0161lete pros\u00EDm pr\u00E1v\u011B jeden soubor pomoc\u00ED metody form-data.",
            2179: "Intern\u00ED chyba syst\u00E9mu.",
            2180: "Datum n\u00E1v\u0161t\u011Bvy mus\u00ED b\u00FDt v budoucnosti.",
            2181: "Smazan\u00E9ho u\u017Eivatele nelze upravovat.",
            2182: "Nespr\u00E1vn\u00E9 \u010D\u00EDslo identifika\u010Dn\u00EDho dokumentu.",
            2183: "Datum narozen\u00ED mus\u00ED b\u00FDt v minulosti.",
            2184: "\u0160patn\u00FD enbo expirovan\u00FD odkaz.",
            2185: "U\u017Eivatel nem\u00E1 u tohoto klienta povolen\u00FD p\u0159\u00EDstup do CloudDns.",
            2186: "Po\u017Eadavek nebyl odeslan\u00FD z emalu pat\u0159\u00EDc\u00EDmu p\u0159ihl\u00E1\u0161en\u00E9mu u\u017Eivateli.",
            2190: "Tento e-mail se ji\u017E pou\u017E\u00EDv\u00E1 pro \u00FA\u010Det spr\u00E1vce VSHosting. Pou\u017Eijte jin\u00FD e-mail.",
            2194: "Tento ticket nem\u00E1 \u017E\u00E1dnou cenovou nab\u00EDdku.",
            2195: "K po\u017Eadavku byla p\u0159id\u00E1na nov\u00E1 zpr\u00E1va. P\u0159ed potvrzen\u00EDm cenov\u00E9 nab\u00EDdky si ji nejprve p\u0159e\u010Dt\u011Bte.",
            2196: "Cenov\u00E1 nab\u00EDdka ji\u017E byla schv\u00E1lena.",
            2197: "Ticket nem\u00E1 \u017E\u00E1dn\u00FD datum realizace \u010Dekaj\u00EDc\u00ED na schv\u00E1len\u00ED.",
            2198: "Datum realizace nem\u016F\u017Ee b\u00FDt schv\u00E1len, proto\u017Ee je v minulosti.",
            2199: "Datum realizace ji\u017E byl schv\u00E1len.",
            2200: "U\u017Eivatel je opr\u00E1vn\u011Bnou osobou r\u00E1mcov\u00E9 smlouvy. P\u0159ed zm\u011Bnou role je t\u0159eba u\u017Eivatele odebrat ze seznamu\n      opr\u00E1vn\u011Bn\u00FDch osob. Kontaktujte, pros\u00EDm, podporu VSHosting.",
            2201: "U\u017Eivatel je opr\u00E1vn\u011Bnou osobou r\u00E1mcov\u00E9 smlouvy. P\u0159ed smaz\u00E1n\u00EDm je t\u0159eba u\u017Eivatele odebrat ze seznamu\n      opr\u00E1vn\u011Bn\u00FDch osob. Kontaktujte, pros\u00EDm, podporu VSHosting.",
            2501: "Nespecifikovan\u00E1 chyba serveru. Chyba byla nahl\u00E1\u0161ena spr\u00E1vci syst\u00E9mu.",
            2502: "Akce je do\u010Dasn\u00E9 blokov\u00E1na jinou operac\u00ED. Zkuste to pros\u00EDm za chv\u00EDli.",
            2503: "P\u0159i odes\u00EDl\u00E1n\u00ED emailu nastala chyba.",
            2504: "U\u017Eivatele se nepoda\u0159ilo automaticky p\u0159ihl\u00E1sit.",
            2505: "Nepoda\u0159ilo se zm\u011Bnit PIN klienta",
            4101: "Klient nenalezen.",
            4102: "Neplatn\u00FD n\u00E1zev dom\u00E9ny.",
            4103: "N\u00E1zev dom\u00E9ny je p\u0159\u00EDli\u0161 dlouh\u00FD.",
            4104: "Povoleny jsou pouze dom\u00E9ny druh\u00E9ho \u0159\u00E1du a \u0159\u00E1du prvn\u00EDho p\u0159ed ve\u0159ejn\u00FDm suffixem.",
            4105: "Dom\u00E9na je smaz\u00E1na. Zm\u011Bny dom\u00E9nov\u00FDch z\u00E1znam\u016F nejsou povoleny. Nejprve pros\u00EDm obnovte dom\u00E9nu.",
            4106: "Dom\u00E9na je deaktivov\u00E1na. Zm\u011Bny dom\u00E9nov\u00FDch z\u00E1znam\u016F nejsou povoleny. Nejprve pros\u00EDm aktivujte dom\u00E9nu.",
            4107: "Tuto akci m\u016F\u017Ee prov\u00E9st pouze vshosting~ administr\u00E1tor.",
            4108: "Dom\u00E9na nenalazena.",
            4109: "Dom\u00E9nov\u00FD z\u00E1znam nenalezen.",
            4110: "Verze dom\u00E9ny nenalezena.",
            4111: "Log dom\u00E9ny nenalezen.",
            4112: "Tato dom\u00E9na ji\u017E je ve va\u0161em seznamu dom\u00E9n.",
            4113: "Tuto dom\u00E9u ji\u017E pou\u017E\u00EDv\u00E1 jin\u00FD klient. Pokud je dom\u00E9na ve va\u0161em vlastnictv\u00ED, obra\u0165te se pros\u00EDm na na\u0161i\n        <a class=\"__link\" href=\"/tickets/new/MANAGED_SERVICES_ISSUE?title=Vlastnictv\u00ED dom\u00E9ny\">vshosting~ podporu</a>.\n      ",
            4114: "Dom\u00E9nov\u00FD z\u00E1znam nen\u00ED smazan\u00FD.",
            4115: "Dom\u00E9nov\u00FD z\u00E1znam je sou\u010D\u00E1st\u00ED neaktu\u00E1ln\u00ED dom\u00E9nov\u00E9 zm\u011Bny a nem\u016F\u017Ee b\u00FDt obnoven. Pros\u00EDm obnovte seznam dom\u00E9nov\u00FDch z\u00E1znam\u016F.",
            4116: "Dom\u00E9na ji\u017E byla obnovena.",
            4117: "Nelze stornovat ji\u017E publikovan\u00E9 zm\u011Bny nebo zm\u011Bny, u kter\u00FDch prob\u00EDh\u00E1 publikace.",
            4118: "Nepublikovan\u00E1 a smazan\u00E1 dom\u00E9na nem\u016F\u017Ee b\u00FDt deaktivov\u00E1na.",
            4119: "Vy\u017Eadov\u00E1na deaktivovan\u00E1 dom\u00E9na.",
            4120: "Pro n\u00E1zev dom\u00E9ny jsou povoleny pouze znaky ASCII nebo A-label.",
            4121: "Nelze aktivovat DNSSEC na t\u00E9to dom\u00E9n\u011B.",
            4122: "Nelze deaktivovat DNSSEC na t\u00E9to dom\u00E9n\u011B.",
            4123: "DNSSEC je ji\u017E aktivovan\u00FD.",
            4124: "DNSSEC je ji\u017E deaktivovan\u00FD.",
            4125: "Nelze publikovat ve\u0159ejn\u00FD kl\u00ED\u010D DNSSECu.",
            4126: "Nelze zru\u0161it publikaci ve\u0159ejn\u00E9ho kl\u00ED\u010De DNSSECu.",
            4127: "Chyba p\u0159i \u010Dten\u00ED ve\u0159ejn\u00E9ho kl\u00ED\u010De DNSSECu.",
            4128: "DNSSEC m\u016F\u017Ee b\u00FDt deaktivov\u00E1n nejd\u0159\u00EDve hodinu po aktivaci.",
            4129: "Nelze deaktivovat DNSSEC, pokud je ve\u0159ejn\u00FD kl\u00ED\u010D u registr\u00E1tora dom\u00E9ny smaz\u00E1n.",
            4130: "DNSSEC kl\u00ED\u010D dom\u00E9ny a registr\u00E1tora se neshoduj\u00ED.",
            4131: "Nepodporovan\u00FD typ z\u00E1znamu.",
            4132: "Nelze publikovat ve\u0159ejn\u00FD kl\u00ED\u010D p\u0159ed aktivov\u00E1n\u00EDm DNSSECU.",
            4133: "Dom\u00E9na je pr\u00E1v\u011B upravov\u00E1na. Opakujte pros\u00EDm akci pozd\u011Bji",
            4134: "Nepodporovan\u00E1 hodnota TTL. Podporovan\u00E9 rozmez\u00ED je 60 a\u017E 86400 vte\u0159in.",
            4135: "Dom\u00E9nov\u00FD z\u00E1znam je sou\u010D\u00E1st\u00ED neaktu\u00E1ln\u00ED dom\u00E9nov\u00E9 zm\u011Bny a nem\u016F\u017Ee b\u00FDt upraven. Pros\u00EDm obnovte seznam dom\u00E9nov\u00FDch z\u00E1znam\u016F.",
            4136: "Vlo\u017Een\u00FD dom\u00E9nov\u00FD z\u00E1znam je duplicitn\u00ED s ji\u017E existuj\u00EDc\u00EDm z\u00E1znamem. Duplicitn\u00ED z\u00E1znamy nejsou povoleny.",
            4137: "",
            4138: "Nelze obnovit dom\u00E9nov\u00FD z\u00E1znam, jeliko\u017E identick\u00FD z\u00E1znam ji\u017E existuje.",
            4139: "Dom\u00E9nov\u00FD z\u00E1znam se shodnou hodnotou existuje v seznamu SMAZAN\u00DDCH z\u00E1znam\u016F.\n        Pou\u017Eijte pros\u00EDm fukci obnoven\u00ED nam\u00EDsto p\u0159id\u00E1n\u00ED nov\u00E9ho z\u00E1znamu.",
            4140: "Nelze publikovat dom\u00E9nu. Dom\u00E9nov\u00E9 z\u00E1znamy nebyly zm\u011Bn\u011Bny.",
            4141: "Byl p\u0159ekro\u010Den limit na po\u010Det dom\u00E9nov\u00FDch z\u00E1znam\u016F. Pros\u00EDm kontaktujte technickou podporu ~vshosting.",
            4142: "Nespr\u00E1vn\u00E9 znaky ve jm\u00E9nu dom\u00E9nov\u00E9ho z\u00E1znamu. Validn\u00ED hosstname je vy\u017Eadov\u00E1no.",
            4143: "Nespr\u00E1vn\u00FD dom\u00E9nov\u00FD z\u00E1znam. Validn\u00ED hosstname je vy\u017Eadov\u00E1no.",
            4144: "Jsou povoleny pouze ASCII znaky. Jin\u00E9 ne\u017E ASCII znaky mus\u00ED b\u00FDt manu\u00E1ln\u011B zm\u011Bn\u011Bny na IDN form\u00E1t.",
            4145: "Nespr\u00E1vna\u00E1 d\u00E9lka dom\u00E9nov\u00E9ho z\u00E1znamu. D\u00E9lka hodnoty dom\u00E9nov\u00E9ho z\u00E1znamu mus\u00ED b\u00FDt v rozmez\u00ED 1 a\u017E 1024 charakter\u016F.",
            4146: "Dom\u00E9na je spravov\u00E1na extern\u00ED aplikac\u00ED a nem\u016F\u017Ee b\u00FDt upravena v CloudDNS.",
            4147: "Byl p\u0159ekro\u010Den limit na po\u010Det dom\u00E9n. Pros\u00EDm kontaktujte technickou podporu ~vshosting",
            4148: "Pro p\u0159id\u00E1n\u00ED dom\u00E9ny je vy\u017Eadov\u00E1n aktivn\u00ED klient.",
            4149: "Dom\u00E9na ji\u017E existuje v PowerDNS v jin\u00E9m re\u017Eimu ne\u017E Master. Kontaktujte pros\u00EDm podporu ~vshosting.",
            4150: "Dom\u00E9na je na seznamu zak\u00E1zan\u00FDch dom\u00E9n a nem\u016F\u017Ee b\u00FDt smaz\u00E1na nebo deaktivov\u00E1na.",
            4151: "Dom\u00E9na je na seznamu zak\u00E1zan\u00FDch dom\u00E9n a nem\u016F\u017Ee b\u00FDt upravena extern\u00ED aplikac\u00ED.",
            4152: "Vlo\u017Een\u00FD dom\u00E9nov\u00FD z\u00E1znam je v konfliktu s existuj\u00EDc\u00EDm CNAME z\u00E1znamem se stejn\u00FDm n\u00E1zvem.",
            4153: "Vlo\u017Een\u00FD dom\u00E9nov\u00FD z\u00E1znam je v konfliktu s existuj\u00EDc\u00EDm z\u00E1znamem se stejn\u00FDm n\u00E1zvem.",
            4154: "Dom\u00E9na je spravov\u00E1na CloudDNS a nem\u016F\u017Ee b\u00FDt upravena extern\u00ED aplikac\u00ED. Nejprve pros\u00EDm sma\u017Ete dom\u00E9nu v CloudDNS\n        nebo kontaktujte podporu vshosting~.",
            4155: "Dom\u00E9na je spravov\u00E1na jinou extern\u00ED aplikac\u00ED. Nejprve ji sma\u017Ete v apliikaci nebo kontaktujte podporu vshosting~.",
            4501: "Intern\u00ED chyba syst\u00E9mu. Kontroln\u00ED sou\u010Det se nezda\u0159il.",
            4502: "Dom\u00E9na je do\u010Dasn\u011B zam\u010Dena jinou konflitn\u00ED akc\u00ED. Pros\u00EDm zkuste to pozd\u011Bji.",
            4503: "Klient je do\u010Dasn\u011B nedostupn\u00FD. Pros\u00EDm zkuste to pozd\u011Bji.",
            4504: "DnsClientApp je do\u010Dasn\u011B nedostupn\u00E1. Pros\u00EDm zkuste to pozd\u011Bji.",
            4505: "Aktivace DNSSEC selhala.",
            4506: "Deaktivace DNSSEC selhala.",
            4507: "Publikace DNSSEC ve\u0159ejn\u00E9ho kl\u00ED\u010De selhala.",
            4508: "Odvol\u00E1n\u00ED ve\u0159ejn\u00E9ho DNSSEC kl\u00ED\u010De selhalo.",
            4509: "Na\u010Dten\u00ED ve\u0159ejn\u00E9ho DNSSEC kl\u00ED\u010De selhalo.",
            5001: "Tato akce nen\u00ED povolena pro u\u017Eivatele VSHosting.",
            5002: "Z bezpe\u010Dnostn\u00EDch d\u016Fvod\u016F se p\u0159ed aktualizac\u00ED p\u0159ihla\u0161ovac\u00EDch \u00FAdaj\u016F pros\u00EDm znovu p\u0159ihla\u0161te.",
            5003: "Autentifika\u010Dn\u00ED token MojeID vypr\u0161el. Pros\u00EDm, p\u0159ihla\u0161te se znovu do sv\u00E9ho \u00FA\u010Dtu MojeID.",
            5004: "Ov\u011B\u0159en\u00ED va\u0161eho \u00FA\u010Dtu MojeID selhalo. Pros\u00EDm, p\u0159ihla\u0161te se znovu do sv\u00E9ho \u00FA\u010Dtu MojeID. Pokud probl\u00E9my\n      p\u0159etrv\u00E1vaj\u00ED, kontaktujte podporu VSHosting.",
            5005: "K tomuto \u00FA\u010Dtu MojeID nen\u00ED p\u0159i\u0159azen \u017E\u00E1dn\u00FD \u00FA\u010Det VSHosting. Nejprve se p\u0159ihla\u0161te e-mailem a heslem a p\u0159ipojte\n      sv\u016Fj \u00FA\u010Det MojeID k va\u0161emu \u00FA\u010Dtu VSHosting.",
            5006: "Autentifika\u010Dn\u00ED token Google vypr\u0161el. Pros\u00EDm, p\u0159ihla\u0161te se znovu do sv\u00E9ho Google \u00FA\u010Dtu.",
            5007: "Ov\u011B\u0159en\u00ED va\u0161eho Google \u00FA\u010Dtu selhalo. Pros\u00EDm, p\u0159ihla\u0161te se znovu do sv\u00E9ho Google \u00FA\u010Dtu. Pokud probl\u00E9my p\u0159etrv\u00E1vaj\u00ED,\n      kontaktujte podporu VSHosting.",
            5008: "K tomuto Google \u00FA\u010Dtu nen\u00ED p\u0159i\u0159azen \u017E\u00E1dn\u00FD \u00FA\u010Det VSHosting. Nejprve se p\u0159ihla\u0161te e-mailem a heslem a p\u0159ipojte sv\u016Fj\n      Google \u00FA\u010Det k va\u0161emu \u00FA\u010Dtu VSHosting.",
            5009: "V\u00E1\u0161 \u00FA\u010Det MojeID je ji\u017E p\u0159ipojen k jin\u00E9mu \u00FA\u010Dtu u\u017Eivatele VSHosting. Pros\u00EDm, pou\u017Eijte jin\u00FD \u00FA\u010Det MojeID.",
            5010: "V\u00E1\u0161 Google \u00FA\u010Det je ji\u017E p\u0159ipojen k jin\u00E9mu \u00FA\u010Dtu u\u017Eivatele VSHosting. Pros\u00EDm, p\u0159ihla\u0161te se k jin\u00E9mu Google \u00FA\u010Dtu.",
            5011: "Je vy\u017Eadov\u00E1na alespo\u0148 jedna metoda p\u0159ihl\u00E1\u0161en\u00ED.",
            5012: "Ov\u011B\u0159en\u00ED va\u0161eho Apple ID selhalo. Pros\u00EDm, p\u0159ihla\u0161te se znovu do sv\u00E9ho \u00FA\u010Dtu Apple ID. Pokud probl\u00E9my\n      p\u0159etrv\u00E1vaj\u00ED, kontaktujte podporu VSHosting.",
            5013: "K tomuto Apple ID nen\u00ED p\u0159i\u0159azen \u017E\u00E1dn\u00FD \u00FA\u010Det VSHosting. Nejprve se p\u0159ihla\u0161te e-mailem a heslem a p\u0159ipojte\n      sv\u00E9 Apple ID k va\u0161emu \u00FA\u010Dtu VSHosting.",
            5014: "Va\u0161e Apple ID je ji\u017E p\u0159ipojeno k jin\u00E9mu \u00FA\u010Dtu u\u017Eivatele VSHosting. Pros\u00EDm, pou\u017Eijte jin\u00E9 Apple ID.",
            5015: "Autentifika\u010Dn\u00ED token Apple ID vypr\u0161el. Pros\u00EDm, p\u0159ihla\u0161te se znovu do sv\u00E9ho Apple ID \u00FA\u010Dtu.",
            5101: "Intern\u00ED chyba syst\u00E9mu.",
            5102: "Intern\u00ED chyba syst\u00E9mu.",
            5103: "Intern\u00ED chyba syst\u00E9mu.",
            5104: "Intern\u00ED chyba syst\u00E9mu.",
            5105: "Intern\u00ED chyba syst\u00E9mu.",
            5106: "Intern\u00ED chyba syst\u00E9mu.",
            5107: "Intern\u00ED chyba syst\u00E9mu.",
            5108: "Intern\u00ED chyba syst\u00E9mu.",
            5109: "Intern\u00ED chyba syst\u00E9mu.",
            5110: "Tato dom\u00E9na je chr\u00E1n\u011Bna proti smaz\u00E1n\u00ED.",
            5111: "Intern\u00ED chyba syst\u00E9mu.",
            5112: "Intern\u00ED chyba syst\u00E9mu.",
            5113: "Intern\u00ED chyba syst\u00E9mu.",
            5114: "Intern\u00ED chyba syst\u00E9mu.",
            5115: "Intern\u00ED chyba syst\u00E9mu.",
            5116: "Intern\u00ED chyba syst\u00E9mu.",
            5117: "Intern\u00ED chyba syst\u00E9mu.",
            5118: "Intern\u00ED chyba syst\u00E9mu.",
            5119: "Intern\u00ED chyba syst\u00E9mu.",
            5500: "Intern\u00ED chyba syst\u00E9mu.",
            6101: "Limit dom\u00E9n klient\u016F mus\u00ED b\u00FDt kladn\u00E9 \u010D\u00EDslo.",
            6102: "Limit dom\u00E9nov\u00FDch z\u00E1znam\u016F mus\u00ED b\u00FDt kladn\u00E9 \u010D\u00EDslo.",
            6103: "Dom\u00E9na nenalezena.",
            6104: "Dom\u00E9nov\u00FD log nenalezen.",
            6105: "Klient nenalezen.",
            6106: "ClientDNSApp nenalezena.",
            6107: "Log akc\u00ED nenalezen.",
            6108: "Log zm\u011Bn nenalezen.",
            6109: "Nelze importovat dom\u00E9ny existuj\u00EDc\u00ED v CloudDNS.",
            6110: "Fukce je deaktivov\u00E1na v configuraci aplikace."
        }
    },
    hu: {
        error: {
            400: "A bemeneti adatok \u00E9rv\u00E9nytelenek",
            401: "A m\u0171velet v\u00E9grehajt\u00E1s\u00E1hoz bejelentkez\u00E9s sz\u00FCks\u00E9ges",
            402: "Nincs elegend\u0151 jogosults\u00E1ga a m\u0171velet v\u00E9grehajt\u00E1s\u00E1hoz",
            403: "Refresh token \u00E9rv\u00E9nytelen vagy lej\u00E1rt",
            404: "Az endpoint nem tal\u00E1lhat\u00F3",
            405: "Lehets\u00E9ges csal\u00F3 viselked\u00E9st \u00E9szleltek. K\u00E9rj\u00FCk, ellen\u0151rizze a hiteles\u00EDt\u00E9st a Google reCaptcha szolg\u00E1ltat\u00E1ssal.",
            406: "\u00C9rv\u00E9nytelen Google reCaptcha v\u00E1lasz",
            407: "A token a let\u00F6lt\u00E9shez \u00E9rv\u00E9nytelen vagy lej\u00E1rt",
            408: "\u00C9rv\u00E9nytelen URL c\u00EDm form\u00E1tum",
            409: "",
            410: "",
            411: "Nincsenek jogosults\u00E1gai a kiv\u00E1lasztott m\u0171velet v\u00E9grehajt\u00E1s\u00E1hoz.",
            412: "Meghaladta a f\u00E1jl maxim\u00E1lis 4MB m\u00E9ret\u00E9t a felt\u00F6lt\u00E9shez.",
            413: "V\u00E1ratlan alkalmaz\u00E1si hiba. K\u00E9rj\u00FCk, ism\u00E9telje meg a m\u0171veletet. Ha a probl\u00E9ma tov\u00E1bbra is fenn\u00E1ll,\n      jelentse a hib\u00E1t a fejleszt\u0151knek.",
            414: "Ez a m\u0171velet nem enged\u00E9lyezett a VSHosting felhaszn\u00E1l\u00F3k sz\u00E1m\u00E1ra.",
            500: "Bels\u0151 rendszerhiba.",
            2000: "Hib\u00E1s bejelentkez\u00E9si adatok.",
            2001: "\u00C9rv\u00E9nytelen felhaszn\u00E1l\u00F3i adatok.",
            2002: "\u00C9rv\u00E9nytelen felhaszn\u00E1l\u00F3i azonos\u00EDt\u00E1s.",
            2003: "A jelsz\u00F3v\u00E1ltoztat\u00E1s id\u0151korl\u00E1tja lej\u00E1rt, k\u00E9rj\u00FCk, k\u00E9rje \u00FAjra a jelsz\u00F3v\u00E1ltoztat\u00E1st.",
            2004: "A token nem egyezik egyetlen bejegyz\u00E9ssel sem.",
            2005: "A megadott felhaszn\u00E1l\u00F3i jelsz\u00F3 nem felel meg a biztons\u00E1gi k\u00F6vetelm\u00E9nyeknek. A jelsz\u00F3nak tartalmaznia kell legal\u00E1bb 8\n      karaktert, kiv\u00E9ve a sz\u00F3t\u00E1ri szavakat, vagy legal\u00E1bb 12 karaktert. A jelsz\u00F3 nem lehet hosszabb 72 karaktern\u00E9l.",
            2006: "\u00C9rv\u00E9nytelen IP-c\u00EDm",
            2007: "A felhaszn\u00E1l\u00F3i profilja jelenleg nem akt\u00EDv. Aktiv\u00E1l\u00E1s\u00E1hoz k\u00E9rj\u00FCk forduljon a \"VSHosting\" \u00FCgyf\u00E9lfi\u00F3k rendszergazd\u00E1hoz.",
            2008: "A m\u0171velet nem v\u00E9gezhet\u0151 el, mert a lefoglalt id\u0151szak lej\u00E1rt.",
            2009: "Nincs jogosults\u00E1ga a k\u00E9rt m\u0171velethez. A jogosults\u00E1gi be\u00E1ll\u00EDt\u00E1sok val\u00F3sz\u00EDn\u0171leg megv\u00E1ltoztak.",
            2010: "Nincs hozz\u00E1f\u00E9r\u00E9se az \u00FCgyf\u00E9lz\u00F3n\u00E1hoz. K\u00E9rj\u00FCk, l\u00E9pjen kapcsolatba a VSHosting-fi\u00F3k rendszergazd\u00E1j\u00E1val.",
            2011: "A megadott PTR bejegyz\u00E9s nem \u00E9rv\u00E9nyes. K\u00E9rj\u00FCk, adja meg FQDN form\u00E1tumban, pl. admin.vshosting.cloud",
            2012: "Az e-maillel \u00E9s jelsz\u00F3val t\u00F6rt\u00E9n\u0151 bejelentkez\u00E9s le lett tiltva. K\u00E9rj\u00FCk, haszn\u00E1ljon egy m\u00E1sik bejelentkez\u00E9si m\u00F3dszert.",
            2013: "Az e-maillel \u00E9s jelsz\u00F3val t\u00F6rt\u00E9n\u0151 bejelentkez\u00E9s le lett tiltva. Emiatt az elfelejtett jelsz\u00F3 vissza\u00E1ll\u00EDt\u00E1sa funkci\u00F3\n      nem \u00E9rhet\u0151 el. K\u00E9rj\u00FCk, haszn\u00E1ljon egy m\u00E1sik bejelentkez\u00E9si m\u00F3dszert.",
            2101: "A felhaszn\u00E1l\u00F3i fi\u00F3k nem l\u00E9tezik.",
            2102: "A felhaszn\u00E1l\u00F3i fi\u00F3k m\u00E1r l\u00E9tezik..",
            2103: "A megadott nyelvi k\u00F3d \u00E9rv\u00E9nytelen.",
            2104: "A megadott telefonos orsz\u00E1gk\u00F3d \u00E9rv\u00E9nytelen.",
            2105: "\u00C9rv\u00E9nytelen sablon.",
            2106: "A telefonos k\u00F6rzetsz\u00E1m nem felel meg a telefonsz\u00E1mnak.",
            2107: "\u00C9rv\u00E9nytelen telefonsz\u00E1m.",
            2108: "\u00C9rv\u00E9nytelen felhaszn\u00E1l\u00F3i szerepk\u00F6r.",
            2109: "\u00DCgyf\u00E9l nem tal\u00E1lhat\u00F3.",
            2110: "\u00C9rv\u00E9nytelen \u00FCgyf\u00E9l \u00E9s felhaszn\u00E1l\u00F3i csoport kombin\u00E1ci\u00F3.",
            2111: "A megadott e-mail c\u00EDmmel vagy telefonsz\u00E1mmal rendelkez\u0151 felhaszn\u00E1l\u00F3 m\u00E1r l\u00E9tezik.",
            2112: "Ezzl az e-mail c\u00EDmmel rendelkez\u0151 felhaszn\u00E1l\u00F3 m\u00E1r csatlakozott a {name} \u00FCgyf\u00E9lhez.",
            2113: "A megadott \u00FCzenett\u00EDpus nem l\u00E9tezik.",
            2114: "Az \u00E1tadott param\u00E9terek \u00E9rv\u00E9nytelenek.",
            2115: "Az \u00FCgyf\u00E9l m\u00E1r aktiv\u00E1lva van.",
            2116: "A token alakja helytelen.",
            2117: "\u00C9rv\u00E9nytelen e-mail form\u00E1tum",
            2118: "\u00C9rv\u00E9nytelen az \u00FCgyf\u00E9lsz\u00E1m alakja.",
            2119: "\u00C9rv\u00E9nytelen a felhaszn\u00E1l\u00F3i sz\u00E1m alakja",
            2120: "A megadott orsz\u00E1gk\u00F3d \u00E9rv\u00E9nytelen.",
            2121: "A m\u0171veletet nem lehetett v\u00E9grehajtani, mert a megadott adatok \u00E9rv\u00E9nytelenek.",
            2122: "A bejegyz\u00E9s nem tal\u00E1lhat\u00F3.",
            2123: "A felhaszn\u00E1l\u00F3k maxim\u00E1lis sz\u00E1ma (70) az \u00FCgyf\u00E9ln\u00E9l t\u00FAll\u00E9p\u00E9sre ker\u00FClt.",
            2124: "\u00C9rv\u00E9nytelen a PIN-k\u00F3d alakja",
            2125: "\u00C9rv\u00E9nytelen k\u00E9r\u00E9si priorit\u00E1s t\u00EDpusa.",
            2126: "\u00C9rv\u00E9nytelen az ig\u00E9ny t\u00EDpusa.",
            2127: "\u00C9rv\u00E9nytelen alkalmaz\u00E1st\u00EDpus.",
            2128: "\u00C9rv\u00E9nytelen ki\u00E9rt\u00E9kel\u00E9si t\u00EDpus.",
            2129: "A k\u00E9r\u00E9s nincs megoldva vagy lez\u00E1rva Ki\u00E9rt\u00E9kel\u00E9s csak a k\u00E9relem lez\u00E1r\u00E1sa ut\u00E1n lehets\u00E9ges.",
            2130: "Adatok nem tal\u00E1lhat\u00F3k.",
            2131: "A felhaszn\u00E1l\u00F3 nincs a \"t\u00F6rl\u00E9sre k\u00E9sz\" \u00E1llapotban.",
            2132: "A k\u00E9r\u00E9s m\u00E1r t\u00F6rl\u00E9sre ker\u00FClt.",
            2133: "Nem siker\u00FClt t\u00F6r\u00F6lni a saj\u00E1t fi\u00F3kot.",
            2134: "Az \u00FCgyf\u00E9l els\u0151 felhaszn\u00E1l\u00F3j\u00E1nak a \u201ERendszergazda\" szerepk\u00F6rben kell csatlakoznia.",
            2135: "A felhaszn\u00E1l\u00F3 e-mail c\u00EDme csak a l\u00E9trehoz\u00E1st k\u00F6vet\u0151 els\u0151 24 \u00F3r\u00E1ban szerkeszthet\u0151.  ",
            2136: "Helytelen\u00FCl megadott postac\u00EDm.",
            2137: "Sz\u00E1mla nem l\u00E9tezik.",
            2138: "Szerver nem l\u00E9tezik.",
            2139: "Az IP-c\u00EDm m\u00E1r haszn\u00E1latban van.",
            2140: "Az IP-c\u00EDm nem tartozik egyik \u00FCgyf\u00E9l IP-tartom\u00E1ny\u00E1ba sem",
            2141: "Ford\u00EDtott bejegyz\u00E9s nem \u00E1ll\u00EDthat\u00F3 be. Az IP-c\u00EDm nem kapcsol\u00F3dik egyetlen szerverhez sem.",
            2142: "El\u00E9r\u00E9s a Server Parkhoz nem tal\u00E1lhat\u00F3.",
            2143: "Mindegyik akt\u00EDv \u00FCgyf\u00E9lhez legal\u00E1bb egy \"Rendszergazda\" szerepk\u00F6rben l\u00E9v\u0151 kapcsol\u00F3d\u00F3 felhaszn\u00E1l\u00F3 sz\u00FCks\u00E9ges.",
            2144: "Egy akt\u00EDv felhaszn\u00E1l\u00F3nak legal\u00E1bb egy felhaszn\u00E1l\u00F3hoz kapcsol\u00F3dnia kell.",
            2145: "A felhaszn\u00E1l\u00F3nak nincs jogosults\u00E1g a biztos\u00EDtott kommunik\u00E1ci\u00F3 fogad\u00E1s\u00E1ra.",
            2146: "Nem tal\u00E1lhat\u00F3 szabad IP-c\u00EDm.",
            2147: "Az IP-c\u00EDm foglalt \u00E9s nem rendelhet\u0151 a szerverhez.",
            2148: "Az ig\u00E9ny nem tal\u00E1lhat\u00F3.",
            2149: "Az ig\u00E9ny csatolm\u00E1nya nem tal\u00E1lhat\u00F3.",
            2150: "Az oszt\u00E1ly nem tal\u00E1lhat\u00F3.",
            2151: "Az \u00D6n e-mail c\u00EDme nem szerepel az \u00FCgyf\u00E9lkapcsolatok k\u00F6z\u00F6tt. Az \u00FCzenet\u00E9t elvetett\u00E9k.",
            2152: "Az ig\u00E9nyek l\u00E9trehoz\u00E1sa e-mail seg\u00EDts\u00E9g\u00E9vel letilt\u00E1sra ker\u00FClt az \u00D6n fi\u00F3kj\u00E1ban. A be\u00E1ll\u00EDt\u00E1sokat a\n      VSHosting \u00FCgyf\u00E9lk\u00F6r\u00E9ben m\u00F3dos\u00EDthatja",
            2153: "Ismeretlen e-mail postafi\u00F3k.",
            2154: "Az ig\u00E9ny lez\u00E1rult. T\u00F6bb \u00FCzenet m\u00E1r nem adhat\u00F3 hozz\u00E1.",
            2155: "Nem enged\u00E9lyezett az ig\u00E9nyek l\u00E9trehoz\u00E1sa k\u00FCls\u0151 rendszergazdai fi\u00F3kb\u00F3l.",
            2156: "Az ig\u00E9ny \u00E9rt\u00E9kel\u00E9se m\u00E1r elk\u00FCld\u00E9sre ker\u00FClt.",
            2157: "\u00C9rt\u00E9kel\u00E9sek csak az \u00FCgyf\u00E9lig\u00E9nyekhez adhat\u00F3k.",
            2158: "Rossz \u00E9rt\u00E9kel\u00E9s eset\u00E9n megjegyz\u00E9s hozz\u00E1ad\u00E1sa sz\u00FCks\u00E9ges.",
            2159: "Az ig\u00E9ny nem az \u00FCgyf\u00E9lhez tartozik.",
            2160: "A rendszergazd\u00E1nak nem enged\u00E9lyezett ig\u00E9nyek l\u00E9trehoz\u00E1sa.",
            2161: "A rendszergazda csak az \u00E1ltala l\u00E9trehozott bels\u0151 ig\u00E9nyekre v\u00E1laszolhat.",
            2162: "Az ig\u00E9ny lez\u00E1rult, \u00EDgy nem m\u00F3dos\u00EDthat\u00F3.",
            2163: "Az ig\u00E9nyt elutas\u00EDtott\u00E1k, mert a lenyomat duplik\u00E1tum\u00E1t tartalmazza.",
            2164: "Az ig\u00E9ny l\u00E9trehoz\u00E1sa e-mailben nem enged\u00E9lyezett. K\u00E9rj\u00FCk, hozza l\u00E9tre az ig\u00E9nyt a Snackhost fel\u00FCleten.",
            2165: "Probl\u00E9ma mer\u00FClt fel az \u00C1FA m\u00F3dos\u00EDt\u00E1s\u00E1val kapcsolatban. K\u00E9rj\u00FCk, pr\u00F3b\u00E1lja meg \u00FAjra egy pillanat m\u00FAlva.",
            2166: "Probl\u00E9ma ad\u00F3dott a sz\u00E1ml\u00E1z\u00E1ssal. Pr\u00F3b\u00E1lja meg \u00FAjra egy kis id\u0151 m\u00FAlva.",
            2167: "Az \u00FCgyf\u00E9l felhaszn\u00E1l\u00F3j\u00E1t nem tal\u00E1ltuk vagy inakt\u00EDv.",
            2168: "A felhaszn\u00E1l\u00F3nak nincs jogosults\u00E1ga az ig\u00E9nyek k\u00FCld\u00E9s\u00E9re vagy szerkeszt\u00E9s\u00E9re.",
            2169: "Az \u00FCgyf\u00E9l \u00E9s a felhaszn\u00E1l\u00F3 k\u00F6z\u00F6tti kapcsolatot nem tal\u00E1ltuk.",
            2170: "Az autoriz\u00E1ci\u00F3s token \u00E9rv\u00E9nytelen vagy lej\u00E1rt.",
            2171: "PTR bejegyz\u00E9s nem \u00E1ll\u00EDthat\u00F3 be, mert az IP-c\u00EDm nincs hozz\u00E1rendelve egyetlen szerverhez sem.",
            2172: "Fizet\u00E9s nem l\u00E9tezik.",
            2173: "A k\u00E9rt bejegyz\u00E9sek sz\u00E1ma t\u00FAll\u00E9pte a korl\u00E1tot.",
            2174: "Sikertelen f\u00E1jlt\u00EDpus.",
            2175: "A szerver grafikon nem l\u00E9tezik.",
            2176: "\u00C9rv\u00E9nytelen vagy lej\u00E1rt aktiv\u00E1l\u00E1si hivatkoz\u00E1s.",
            2177: "Ez a szem\u00E9ly m\u00E1r rendelkezik hozz\u00E1f\u00E9r\u00E9ssel a ServerParkhoz.",
            2178: "K\u00E9remm, csak egy f\u00E1jlt k\u00FCldj\u00F6n el a form-data m\u00F3dszerrel.",
            2179: "Bels\u0151 rendszerhiba.",
            2180: "A l\u00E1togat\u00E1s d\u00E1tum\u00E1nak a j\u00F6v\u0151ben kell lennie.",
            2181: "A t\u00F6r\u00F6lt felhaszn\u00E1l\u00F3 nem szerkeszthet\u0151.",
            2182: "A dokumentum azonos\u00EDt\u00F3sz\u00E1ma helytelen.",
            2183: "A sz\u00FClet\u00E9si d\u00E1tumnak a m\u00FAltban kell lennie.",
            2184: "Rossz vagy lej\u00E1rt hivatkoz\u00E1s.",
            2185: "A felhaszn\u00E1l\u00F3 nem f\u00E9rhet hozz\u00E1 a CloudDns-hez enn\u00E9l az \u00FCgyf\u00E9ln\u00E9l.",
            2186: "A k\u00E9r\u00E9st nem a bejelentkezett felhaszn\u00E1l\u00F3hoz tartoz\u00F3 e-mail c\u00EDmr\u0151l k\u00FCldt\u00E9k.",
            2190: "Ez az e-mail m\u00E1r haszn\u00E1latban van a VSHosting rendszergazdai fi\u00F3khoz. Haszn\u00E1ljon m\u00E1sik e-mail c\u00EDmet.",
            2194: "Ez a ticket nem tartalmaz \u00E1raj\u00E1nlatot.",
            2195: "A k\u00E9relemhez \u00FAj \u00FCzenet ker\u00FClt hozz\u00E1ad\u00E1sra. Az \u00E1raj\u00E1nlat meger\u0151s\u00EDt\u00E9se el\u0151tt olvassa el.",
            2196: "Az \u00E1raj\u00E1nlatot m\u00E1r j\u00F3v\u00E1hagyt\u00E1k.",
            2197: "A tickethez nincs j\u00F3v\u00E1hagy\u00E1sra v\u00E1r\u00F3 v\u00E9grehajt\u00E1si d\u00E1tum.",
            2198: "Ez a v\u00E9grehajt\u00E1si d\u00E1tum nem hagyhat\u00F3 j\u00F3v\u00E1, mert m\u00E1r a m\u00FAltban van.",
            2199: "A v\u00E9grehajt\u00E1si d\u00E1tumot m\u00E1r j\u00F3v\u00E1hagyt\u00E1k.",
            2501: "Meghat\u00E1rozatlan szerverhiba. A hib\u00E1t jelentett\u00E9k a rendszergazd\u00E1nak.",
            2502: "A m\u0171veletet egy m\u00E1sik m\u0171velet ideiglenesen blokkolja. Pr\u00F3b\u00E1lja meg \u00FAjra egy kis id\u0151 m\u00FAlva.",
            2503: "Hiba t\u00F6rt\u00E9nt az e-mail k\u00FCld\u00E9se k\u00F6zben.",
            2504: "A felhaszn\u00E1l\u00F3 nem tudott automatikusan bejelentkezni.",
            2505: "Nem tudta megv\u00E1ltoztatni az \u00FCgyf\u00E9l PIN-k\u00F3dj\u00E1t",
            4101: "\u00DCgyf\u00E9l nem tal\u00E1lhat\u00F3.",
            4102: "\u00C9rv\u00E9nytelen domain n\u00E9v",
            4103: "T\u00FAl hossz\u00FA a domain n\u00E9v.",
            4104: "Csak m\u00E1sodrend\u0171 \u00E9s els\u0151rend\u0171 domainek enged\u00E9lyezettek a nyilv\u00E1nos ut\u00F3tag el\u0151tt.",
            4105: "A domain t\u00F6rl\u00E9sre ker\u00FClt. A domain bejegyz\u00E9sek m\u00F3dos\u00EDt\u00E1sa nem enged\u00E9lyezett. K\u00E9rj\u00FCk, el\u0151sz\u00F6r \u00E1ll\u00EDtsa vissza a domaint.",
            4106: "A domaint deaktiv\u00E1lt\u00E1k. A domain bejegyz\u00E9sek m\u00F3dos\u00EDt\u00E1sa nem enged\u00E9lyezett. K\u00E9rj\u00FCk, el\u0151sz\u00F6r aktiv\u00E1lja a domaint.",
            4107: "Ezt a m\u0171veletet csak a vshosting~ remdszergazda v\u00E9gezheti el.",
            4108: "A domain nem tal\u00E1lhat\u00F3.",
            4109: "A domain bejegyz\u00E9s nem tal\u00E1lhat\u00F3.",
            4110: "A domain verzi\u00F3 nem tal\u00E1lhat\u00F3.",
            4111: "A domain log-ja nem tal\u00E1lhat\u00F3.",
            4112: "Ez a domain m\u00E1r szerepel a domainlist\u00E1n.",
            4113: "Ezt a domaint m\u00E1r egy m\u00E1sik \u00FCgyf\u00E9l haszn\u00E1lja. Ha a domain az \u00D6n tulajdon\u00E1ban van, k\u00E9rj\u00FCk, l\u00E9pjen kapcsolatba a\n        <a class=\"__link\" href=\"/tickets/new/MANAGED_SERVICES_ISSUE?title=Vlastnictv\u00ED dom\u00E9ny\">vshosting~ \u00FCgyf\u00E9lszolg\u00E1lat\u00E1val\u2019</a>.",
            4114: "A domain bejegyz\u00E9s nincs t\u00F6r\u00F6lve.",
            4115: "A domain bejegyz\u00E9s egy elavult domainv\u00E1lt\u00E1s r\u00E9sze, \u00E9s nem friss\u00EDthet\u0151. K\u00E9rj\u00FCk, friss\u00EDtse a domain bejegyz\u00E9sek list\u00E1j\u00E1t.",
            4116: "A domain m\u00E1r friss\u00EDtve lett.",
            4117: "Nem sztorn\u00F3zhat\u00F3k a m\u00E1r k\u00F6zz\u00E9tett v\u00E1ltoz\u00E1sok, vagy a k\u00F6zz\u00E9t\u00E9tel alatt \u00E1ll\u00F3 v\u00E1ltoz\u00E1sok.",
            4118: "Egy m\u00E9g nem publik\u00E1lt \u00E9s t\u00F6r\u00F6lt domain nem deaktiv\u00E1lhat\u00F3.",
            4119: "Ig\u00E9nyelt deaktiv\u00E1lt domain.",
            4120: "A domain n\u00E9vben csak ASCII vagy A-label karakterek enged\u00E9lyezettek.",
            4121: "A DNSSEC nem aktiv\u00E1lhat\u00F3 ezen a tartom\u00E1nyon.",
            4122: "A DNSSEC nem deaktiv\u00E1lhat\u00F3 ezen a domainen.",
            4123: "A DNSSEC m\u00E1r aktiv\u00E1lva van.",
            4124: "A DNSSEC m\u00E1r deaktiv\u00E1lva van.",
            4125: "Nem tehet\u0151 k\u00F6zz\u00E9 a DNSSEC nyilv\u00E1nos kulcsa.",
            4126: "Nem t\u00F6r\u00F6lhet\u0151 a DNSSEC nyilv\u00E1nos kulcs\u00E1nak k\u00F6zz\u00E9t\u00E9tele.",
            4127: "Hiba a DNSSEC nyilv\u00E1nos kulcs\u00E1nak olvas\u00E1s\u00E1ban.",
            4128: "A DNSSEC legkor\u00E1bban egy \u00F3r\u00E1val az aktiv\u00E1l\u00E1s ut\u00E1n deaktiv\u00E1lhat\u00F3.",
            4129: "Nem deaktiv\u00E1lhat\u00F3 a DNSSEC, ha a nyilv\u00E1nos kulcsot a domain-regisztr\u00E1torn\u00E1l t\u00F6r\u00F6lt\u00E9k.",
            4130: "A domain \u00E9s a regisztr\u00E1tor DNSSEC kulcsa nem egyezik meg.",
            4131: "Nem t\u00E1mogatott bejegyz\u00E9s t\u00EDpus.",
            4132: "Nem tehet\u0151 k\u00F6zz\u00E9 a nyilv\u00E1nos kulcs a DNSSEC aktiv\u00E1l\u00E1sa el\u0151tt.",
            4133: "A domain jelenleg m\u00F3dos\u00EDt\u00E1s alatt \u00E1ll. K\u00E9rj\u00FCk, ism\u00E9telje meg a m\u0171veletet k\u00E9s\u0151bb.",
            4134: "Nem t\u00E1mogatott TTL \u00E9rt\u00E9k. A t\u00E1mogatott tartom\u00E1ny 60 \u00E9s 86400 m\u00E1sodperc k\u00F6z\u00F6tt van.",
            4135: "A domain bejegyz\u00E9s egy elavult domainv\u00E1lt\u00E1s r\u00E9sze, \u00E9s nem m\u00F3dos\u00EDthat\u00F3. K\u00E9rj\u00FCk, friss\u00EDtse a domain bejegyz\u00E9sek list\u00E1j\u00E1t.",
            4136: "A beillesztett domain bejegyz\u00E9s duplik\u00E1l\u00F3dik egy megl\u00E9v\u0151 bejegyz\u00E9sdal. A duplik\u00E1lt bejegyz\u00E9sok nem enged\u00E9lyezettek.",
            4137: "",
            4138: "Nem lehet vissza\u00E1ll\u00EDtani egy tartom\u00E1nybejegyz\u00E9sot, mert egy azonos bejegyz\u00E9s m\u00E1r l\u00E9tezik.",
            4139: "Egy azonos \u00E9rt\u00E9k\u0171 tartom\u00E1nybejegyz\u00E9s l\u00E9tezik a SHRINKED bejegyz\u00E9sok list\u00E1j\u00E1ban.\n      \u00DAj bejegyz\u00E9s hozz\u00E1ad\u00E1sa helyett haszn\u00E1lja a restore funkci\u00F3t.",
            4140: "Nem lehet k\u00F6zz\u00E9tenni a tartom\u00E1nyt. A tartom\u00E1nybejegyz\u00E9sok nem lettek megv\u00E1ltoztatva.",
            4141: "A tartom\u00E1nybejegyz\u00E9sok sz\u00E1m\u00E1nak korl\u00E1tja t\u00FAll\u00E9p\u00E9sre ker\u00FClt. K\u00E9rj\u00FCk, l\u00E9pjen kapcsolatba a ~vshosting technikai t\u00E1mogat\u00E1ssal",
            4142: "Hib\u00E1s karakterek a domain bejegyz\u00E9s nev\u00E9ben. \u00C9rv\u00E9nyes hosstname sz\u00FCks\u00E9ges.",
            4143: "Hib\u00E1s domain bejegyz\u00E9s. \u00C9rv\u00E9nyes hosstname sz\u00FCks\u00E9ges.",
            4144: "Kiz\u00E1r\u00F3lag ASCII karakterek enged\u00E9lyezettek. A nem ASCII karaktereket k\u00E9zzel kell IDN form\u00E1tumra m\u00F3dos\u00EDtani.",
            4145: "Helytelen domain bejegyz\u00E9s hossza. A domain bejegyz\u00E9s \u00E9rt\u00E9k\u00E9nek hossza 1 \u00E9s 1024 karakter k\u00F6z\u00F6tt kell lennie.",
            4146: "A tartom\u00E1nyt egy k\u00FCls\u0151 alkalmaz\u00E1s kezeli, \u00E9s nem m\u00F3dos\u00EDthat\u00F3 a CloudDNS-ben.",
            4147: "A tartom\u00E1nyok sz\u00E1m\u00E1nak korl\u00E1tja t\u00FAll\u00E9p\u00E9sre ker\u00FClt. K\u00E9rj\u00FCk, vegye fel a kapcsolatot a ~vshosting technikai t\u00E1mogat\u00E1s\u00E1val",
            4148: "A domain hozz\u00E1ad\u00E1s\u00E1hoz akt\u00EDv \u00FCgyf\u00E9l sz\u00FCks\u00E9ges.",
            4149: "A tartom\u00E1ny m\u00E1r l\u00E9tezik a PowerDNS-ben a Master-t\u0151l elt\u00E9r\u0151 \u00FCzemm\u00F3dban. K\u00E9rj\u00FCk, l\u00E9pjen\n      kapcsolatba a ~vshosting technikai t\u00E1mogat\u00E1s\u00E1val.",
            4150: "A domain a tilt\u00F3list\u00E1n van, \u00E9s nem t\u00F6r\u00F6lhet\u0151 vagy deaktiv\u00E1lhat\u00F3.",
            4151: "A domain a tiltott domainek list\u00E1j\u00E1n szerepel, \u00E9s k\u00FCls\u0151 alkalmaz\u00E1ssal nem m\u00F3dos\u00EDthat\u00F3.",
            4152: "A beillesztett domain bejegyz\u00E9s \u00FCtk\u00F6zik egy l\u00E9tez\u0151, azonos nev\u0171 CNAME bejegyz\u00E9ssel.",
            4153: "A beillesztett domain bejegyz\u00E9s \u00FCtk\u00F6zik egy l\u00E9tez\u0151, azonos nev\u0171 bejegyz\u00E9ssel.",
            4154: "A tartom\u00E1nyt a CloudDNS kezeli, \u00E9s k\u00FCls\u0151 alkalmaz\u00E1ssal nem m\u00F3dos\u00EDthat\u00F3. K\u00E9rj\u00FCk, el\u0151sz\u00F6r t\u00F6r\u00F6lje a tartom\u00E1nyt a CloudDNS-ben.\n      Vagy l\u00E9pjen kapcsolatba a ~vshosting \u00FCgyf\u00E9lszolg\u00E1lattal.",
            4155: "A domaint egy m\u00E1sik k\u00FCls\u0151 alkalmaz\u00E1s kezeli. K\u00E9rj\u00FCk, el\u0151sz\u00F6r t\u00F6r\u00F6lje az alkalmaz\u00E1sban, vagy l\u00E9pjen\n      kapcsolatba a vshosting~ \u00FCgyf\u00E9lszolg\u00E1lattal",
            4501: "Bels\u0151 rendszerhiba. Az ellen\u0151rz\u0151 \u00F6sszegz\u00E9s sikertelen.",
            4502: "A domain ideiglenesen le van z\u00E1rva egy m\u00E1sik konflikt m\u0171velet miatt. K\u00E9rj\u00FCk, pr\u00F3b\u00E1lja meg k\u00E9s\u0151bb \u00FAjra.",
            4503: "Az \u00FCgyf\u00E9l \u00E1tmenetileg nem el\u00E9rhet\u0151. K\u00E9rj\u00FCk, pr\u00F3b\u00E1lja meg k\u00E9s\u0151bb \u00FAjra.",
            4504: "A DnsClientApp \u00E1tmenetileg nem el\u00E9rhet\u0151. K\u00E9rj\u00FCk, pr\u00F3b\u00E1lja meg k\u00E9s\u0151bb.",
            4505: " DNSSEC aktiv\u00E1l\u00E1sa sikertelen.",
            4506: "DNSSEC deaktiv\u00E1l\u00E1sa sikertelen.",
            4507: "DNSSEC nyilv\u00E1nos kulcs k\u00F6zz\u00E9t\u00E9tele sikertelen.",
            4508: "DNSSEC nyilv\u00E1nos kulcs megh\u00EDv\u00E1sa sikertelen.",
            4509: "DNSSEC nyilv\u00E1nos kulcs olvas\u00E1sa sikertelen.",
            5001: "Ez a m\u0171velet nem enged\u00E9lyezett a VSHosting felhaszn\u00E1l\u00F3k sz\u00E1m\u00E1ra.",
            5002: "Biztons\u00E1gi okokb\u00F3l k\u00E9rj\u00FCk, jelentkezzen be \u00FAjra a bejelentkez\u00E9si be\u00E1ll\u00EDt\u00E1sok friss\u00EDt\u00E9se el\u0151tt.",
            5003: "A MojeID hiteles\u00EDt\u00E9si token lej\u00E1rt. K\u00E9rj\u00FCk, jelentkezzen be \u00FAjra a MojeID fi\u00F3kj\u00E1ba.",
            5004: "A MojeID fi\u00F3kja ellen\u0151rz\u00E9se sikertelen volt. K\u00E9rj\u00FCk, jelentkezzen be \u00FAjra a MojeID fi\u00F3kj\u00E1ba.\n       Ha a probl\u00E9ma tov\u00E1bbra is fenn\u00E1ll, l\u00E9pjen kapcsolatba a VSHosting t\u00E1mogat\u00E1ssal.",
            5005: "Nincs VSHosting fi\u00F3k csatlakoztatva ehhez a MojeID fi\u00F3khoz. El\u0151sz\u00F6r jelentkezzen be e-mail\n      c\u00EDm\u00E9vel \u00E9s jelszav\u00E1val, majd csatlakoztassa a MojeID fi\u00F3kj\u00E1t a VSHosting fi\u00F3kj\u00E1hoz.",
            5006: "A Google hiteles\u00EDt\u00E9si token lej\u00E1rt. K\u00E9rj\u00FCk, jelentkezzen be \u00FAjra a Google fi\u00F3kj\u00E1ba.",
            5007: "A Google fi\u00F3kja ellen\u0151rz\u00E9se sikertelen volt. K\u00E9rj\u00FCk, jelentkezzen be \u00FAjra a Google fi\u00F3kj\u00E1ba.\n      Ha a probl\u00E9ma tov\u00E1bbra is fenn\u00E1ll, l\u00E9pjen kapcsolatba a VSHosting t\u00E1mogat\u00E1ssal.",
            5008: "Nincs VSHosting fi\u00F3k csatlakoztatva ehhez a Google fi\u00F3khoz. El\u0151sz\u00F6r jelentkezzen be e-mail c\u00EDm\u00E9vel \u00E9s jelszav\u00E1val,\n      majd csatlakoztassa a Google fi\u00F3kj\u00E1t a VSHosting fi\u00F3kj\u00E1hoz.",
            5009: "A MojeID fi\u00F3kja m\u00E1r csatlakozik egy m\u00E1sik VSHosting felhaszn\u00E1l\u00F3i fi\u00F3khoz. K\u00E9rj\u00FCk, haszn\u00E1ljon egy m\u00E1sik MojeID fi\u00F3kot.",
            5010: "A Google fi\u00F3kja m\u00E1r csatlakozik egy m\u00E1sik VSHosting felhaszn\u00E1l\u00F3i fi\u00F3khoz. K\u00E9rj\u00FCk, jelentkezzen be egy m\u00E1sik Google fi\u00F3kba.",
            5011: "Legal\u00E1bb egy bejelentkez\u00E9si m\u00F3dszer sz\u00FCks\u00E9ges.",
            5101: "Bels\u0151 rendszerhiba.",
            5102: "Bels\u0151 rendszerhiba.",
            5103: "Bels\u0151 rendszerhiba.",
            5104: "Bels\u0151 rendszerhiba.",
            5105: "Bels\u0151 rendszerhiba.",
            5106: "Bels\u0151 rendszerhiba.",
            5107: "Bels\u0151 rendszerhiba.",
            5108: "Bels\u0151 rendszerhiba.",
            5109: "Bels\u0151 rendszerhiba.",
            5110: "Ez a domain v\u00E9dett a t\u00F6rl\u00E9s ellen.",
            5111: "Bels\u0151 rendszerhiba.",
            5112: "Bels\u0151 rendszerhiba.",
            5113: "Bels\u0151 rendszerhiba.",
            5114: "Bels\u0151 rendszerhiba.",
            5115: "Bels\u0151 rendszerhiba.",
            5116: "Bels\u0151 rendszerhiba.",
            5117: "Bels\u0151 rendszerhiba.",
            5118: "Bels\u0151 rendszerhiba.",
            5119: "Bels\u0151 rendszerhiba.",
            5500: "Bels\u0151 rendszerhiba.",
            6101: "Az \u00FCgyf\u00E9l domain hat\u00E1r\u00E9rt\u00E9knek pozit\u00EDv sz\u00E1mnak kell lennie.",
            6102: "A domain bejegyz\u00E9s limitnek pozit\u00EDv sz\u00E1mnak kell lennie.",
            6103: "A domain nem tal\u00E1lhat\u00F3.",
            6104: "A domain log nem tal\u00E1lhat\u00F3.",
            6105: "\u00DCgyf\u00E9l nem tal\u00E1lhat\u00F3.",
            6106: "A ClientDNSApp nem tal\u00E1lhat\u00F3.",
            6107: "A m\u0171velet log-ja nem tal\u00E1lhat\u00F3.",
            6108: "A v\u00E1ltoztat\u00E1sok log-ja nem tal\u00E1lhat\u00F3.",
            6109: "A CloudDNS-ben l\u00E9tez\u0151 domainek import\u00E1l\u00E1sa nem lehets\u00E9ges.",
            6110: "Funkci\u00F3 letiltva az alkalmaz\u00E1s konfigur\u00E1ci\u00F3j\u00E1ban."
        }
    },
    de: {
        error: {
            400: "Die eingegebenen Daten sind ung\u00FCltig",
            401: "F\u00FCr die Durchf\u00FChrung des Vorgangs ist eine Anmeldung erforderlich",
            402: "Sie verf\u00FCgen nicht \u00FCber ausreichende Berechtigungen, um den Vorgang durchzuf\u00FChren.",
            403: "Refresh-Token ist ung\u00FCltig oder abgelaufen",
            404: "Endpoint nicht gefunden",
            405: "Potenziell betr\u00FCgerisches Verhalten wurde festgestellt. Bitte verifizieren Sie mit Google reCaptcha.",
            406: "Ung\u00FCltige Google reCaptcha-Antwort",
            407: "Download-Token ist ung\u00FCltig oder abgelaufen",
            408: "Ung\u00FCltiges URL-Format",
            409: "",
            410: "",
            411: "Sie haben keine Berechtigungen, um die ausgew\u00E4hlte Aktion durchzuf\u00FChren.",
            412: "Die maximale Dateigr\u00F6\u00DFe von 4 MB wurde beim Hochladen \u00FCberschritten.",
            413: "Unerwarteter Anwendungsfehler. Bitte wiederholen Sie den Vorgang erneut.\n        Wenn die Probleme weiterhin bestehen, melden Sie den Fehler den Entwicklern.",
            500: "Interner Systemfehler.",
            2000: "Falsche Anmeldedaten.",
            2001: "Ung\u00FCltige Benutzerdaten.",
            2002: "Ung\u00FCltige Benutzeridentifizierung.",
            2003: "Die Frist f\u00FCr die \u00C4nderung Ihres Passworts ist abgelaufen. Bitte beantragen Sie erneut eine Passwort\u00E4nderung.",
            2004: "Das Token stimmt mit keinem Eintrag \u00FCberein.",
            2005: "Das eingegebene Benutzerpasswort entspricht nicht den Sicherheitsanforderungen. Das Passwort muss mindestens\n        8 Zeichen ohne W\u00F6rter aus dem W\u00F6rterbuch oder mindestens 12 Zeichen enthalten. Es darf aber nicht l\u00E4nger als 72 Zeichen sein.",
            2006: "Ung\u00FCltige IP-Adresse.",
            2007: "Ihr Benutzerprofil ist zur Zeit nicht aktiv. Um es zu aktivieren,\n        wenden Sie sich bitte an den Administrator Ihres Kundenkontos \"VSHosting.\"",
            2008: "Der Vorgang konnte nicht durchgef\u00FChrt werden, weil das zugewiesene Zeitfenster bereits abgelaufen war.",
            2009: "Sie sind f\u00FCr den angeforderten Vorgang nicht autorisiert. Die Rechteeinstellungen wurden wahrscheinlich ge\u00E4ndert.",
            2010: "Sie haben keine Zugriffsrechte auf den Kundenbereich. Bitte kontaktieren Sie den Administrator des VSHosting-Kontos.",
            2011: "Der eingegebene PTR-Eintrag ist nicht g\u00FCltig. Geben Sie ihn im FQDN-Format ein, z. B. admin.vshosting.cloud",
            2101: "Das Nutzerkonto existiert nicht.",
            2102: "Das Nutzerkonto existiert bereits.",
            2103: "Der eingegebene Sprachcode ist ung\u00FCltig.",
            2104: "Die L\u00E4ndervorwahl ist ung\u00FCltig.",
            2105: "Ung\u00FCltige Vorlage.",
            2106: "Die Vorwahl entspricht nicht der Telefonnummer.",
            2107: "Ung\u00FCltige Telefonnummer.",
            2108: "Ung\u00FCltige Benutzerrolle.",
            2109: "Kunde nicht gefunden.",
            2110: "Ung\u00FCltige Kombination von Kunde und Benutzergruppe.",
            2111: "Der Benutzer mit der eingegebenen E-Mail-Adresse oder Telefonnummer existiert bereits.",
            2112: "Ein Benutzer mit der gleichen E-Mail-Adresse ist bereits mit dem Kunden verbunden {name}.",
            2113: "Der eingegebene Nachrichtentyp existiert nicht.",
            2114: "Die \u00FCbergebenen Parameter sind ung\u00FCltig.",
            2115: "Der Kunde ist bereits aktiviert.",
            2116: "Der Token hat die falsche Form.",
            2117: "Ung\u00FCltiges E-Mail-Format.",
            2118: "Ung\u00FCltige Form der Kundennummer.",
            2119: "Ung\u00FCltige Form der Benutzernummer.",
            2120: "Der eingegebene L\u00E4ndercode ist ung\u00FCltig.",
            2121: "Der Vorgang konnte nicht ausgef\u00FChrt werden, weil die eingegebenen Daten nicht g\u00FCltig sind.",
            2122: "Eintrag nicht gefunden.",
            2123: "Die maximale Anzahl von Benutzern pro Kunde (70) wurde \u00FCberschritten.",
            2124: "Ung\u00FCltige PIN-Form.",
            2125: "Ung\u00FCltiger Priorit\u00E4tstyp der Anfrage.",
            2126: "Ung\u00FCltiger Anfragetyp.",
            2127: "Ung\u00FCltiger Anwendungstyp.",
            2128: "Ung\u00FCltiger Bewertungstyp.",
            2129: "Die Anfrage ist noch nicht gel\u00F6st oder abgeschlossen. Die Bewertung ist erst m\u00F6glich, wenn die Anfrage abgeschlossen ist.",
            2130: "Keine Daten gefunden.",
            2131: "Der Benutzer befindet sich nicht im Status \"bereit zur L\u00F6schung\".",
            2132: "Die Anfrage wurde bereits gel\u00F6scht.",
            2133: "Das eigene Konto kann nicht gel\u00F6scht werden.",
            2134: "Der erste Benutzer des Kunden muss mit der Rolle \"Administrator\" verbunden sein.",
            2135: "Die E-Mail-Adresse eines Benutzers kann nur innerhalb der ersten 24 Stunden nach ihrer Erstellung bearbeitet werden.",
            2136: "Falsch eingegebene Postanschrift.",
            2137: "Die Rechnung existiert nicht.",
            2138: "Der Server existiert nicht.",
            2139: "Die IP-Adresse wird bereits verwendet.",
            2140: "Die IP-Adresse liegt nicht in einem IP-Bereich des Kunden",
            2141: "Der Reverse-Eintrag kann nicht gesetzt werden. Die IP-Adresse ist mit keinem Server verbunden.",
            2142: "Zugang zu Server Park nicht gefunden",
            2143: "F\u00FCr jeden aktiven Kunden ist mindestens ein verbundener Benutzer mit der Rolle \"Administrator\" erforderlich.",
            2144: "Der aktive Benutzer muss mit mindestens einem anderen Benutzer verbunden sein.",
            2145: "Der Benutzer hat nicht die Berechtigung, sichere Kommunikation zu empfangen.",
            2146: "Es wurde keine freie IP-Adresse gefunden.",
            2147: "Die IP-Adresse ist reserviert und kann nicht an einen Server zugewiesen werden.",
            2148: "Die Anfrage wurde nicht gefunden.",
            2149: "Die Anlage zur Anfrage wurde nicht gefunden.",
            2150: "Die Abteilung wurde nicht gefunden.",
            2151: "Ihre E-Mail-Adresse ist nicht in den Kundenkontakten registriert.\n        Ihre Nachricht wird verworfen.",
            2152: "Das Erstellen von Anfragen per E-Mail wurde f\u00FCr Ihr Konto gesperrt.\n        Sie k\u00F6nnen die Einstellungen im VSHosting-Kundenbereich \u00E4ndern.",
            2153: "Unbekanntes E-Mail-Postfach.",
            2154: "Die Anfrage ist abgeschlossen. Es k\u00F6nnen keine weiteren Nachrichten hinzugef\u00FCgt werden.",
            2155: "Es ist nicht erlaubt, Anfragen von einem externen Administratorkonto aus zu erstellen.",
            2156: "Die Bewertung der Anfrage wurde bereits abgeschickt.",
            2157: "Bewertungen k\u00F6nnen nur zu Kundenanfragen hinzugef\u00FCgt werden.",
            2158: "Im Falle einer schlechten Bewertung sollte ein Vermerk hinzugef\u00FCgt werden.",
            2159: "Die Anfrage geh\u00F6rt nicht dem Kunden.",
            2160: "Der Administrator ist nicht berechtigt, Anfragen zu erstellen.",
            2161: "Der Administrator darf nur auf interne Anfragen antworten, die er selbst erstellt hat.",
            2162: "Die Anfrage ist abgeschlossen und kann daher nicht ge\u00E4ndert werden.",
            2163: "Die Anfrage wird abgelehnt, weil sie ein Abdruckduplikat enth\u00E4lt.",
            2164: "Die Erstellung von Anfragen per E-Mail ist nicht zul\u00E4ssig. Bitte erstellen Sie eine Anfrage im Snackhost Dashboard.",
            2165: "Es trat ein Problem bei der \u00C4nderung der Mehrwertsteuer auf. Versuchen Sie es bitte gleich noch einmal.",
            2166: "es trat ein Problem mit der Rechnungsstellung auf. Bitte versuchen Sie es gleich noch einmal.",
            2167: "Der Benutzer des Kunden wurde nicht gefunden oder ist inaktiv.",
            2168: "Der Benutzer ist nicht berechtigt, Anfragen zu senden oder zu \u00E4ndern.",
            2169: "Die Verbindung zwischen dem Kunden und dem Benutzer wurde nicht gefunden.",
            2170: "Das Autorisierungs-Token ist ung\u00FCltig oder abgelaufen.",
            2171: "Der PTR-Eintrag kann nicht gesetzt werden, da die IP-Adresse keinem Server zugewiesen ist.",
            2172: "Die Zahlung existiert nicht.",
            2173: "Die Anzahl der angeforderten Eintr\u00E4ge \u00FCbersteigt das Limit.",
            2174: "Falscher Dateityp.",
            2175: "Der Server-Graph existiert nicht.",
            2176: "Falscher oder abgelaufener Aktivierungslink.",
            2177: "Dieser Person ist bereits ein Zugang zum ServerPark zugewiesen.",
            2178: "Bitte senden Sie nur eine Datei mit der \"form-data\"-Methode.",
            2179: "Interner Systemfehler.",
            2180: "Das Datum des Besuchs muss in der Zukunft liegen.",
            2181: "Ein gel\u00F6schter Benutzer kann nicht bearbeitet werden.",
            2182: "Falsche ID-Dokumentennummer.",
            2183: "Das Geburtsdatum muss in der Vergangenheit liegen.",
            2184: "Falscher oder abgelaufener Link.",
            2185: "Der Benutzer ist nicht berechtigt, auf CloudDns bei diesem Kunden zuzugreifen.",
            2186: "Die Anfrage wurde nicht von einer E-Mail-Adresse gesendet, die dem angemeldeten Benutzer geh\u00F6rt.",
            2190: "Diese E-Mail-Adresse wird bereits f\u00FCr das VSHosting-Administratorkonto verwendet. Verwenden Sie eine andere E-Mail-Adresse.",
            2194: "Dieses Ticket hat kein Preisangebot.",
            2195: "Der Anfrage wurde eine neue Nachricht hinzugef\u00FCgt. Bitte lesen Sie sie zuerst, bevor Sie das Preisangebot best\u00E4tigen.",
            2196: "Das Preisangebot wurde bereits genehmigt.",
            2197: "Das Ticket hat kein Datum f\u00FCr die Umsetzung, das auf die Genehmigung wartet.",
            2198: "Das Datum der Umsetzung kann nicht genehmigt werden, weil es in der Vergangenheit liegt.",
            2199: "Das Datum der Umsetzung wurde bereits genehmigt.",
            2501: "Nicht spezifizierter Serverfehler. Der Fehler wurde an den Systemadministrator gemeldet.",
            2502: "Der Vorgang ist vor\u00FCbergehend durch einen anderen Vorgang blockiert. Bitte versuchen Sie es gleich noch einmal.",
            2503: "Beim Senden einer E-Mail ist ein Fehler aufgetreten.",
            2504: "Der Benutzer konnte nicht automatisch eingeloggt werden.",
            2505: "\u00C4nderung der Kunden-PIN fehlgeschlagen",
            4101: "Kunde nicht gefunden.",
            4102: "Ung\u00FCltiger Domainname.",
            4103: "Der Domainname ist zu lang.",
            4104: "Nur Domains zweiter und erster Ordnung vor dem \u00F6ffentlichen Suffix sind zul\u00E4ssig.",
            4105: "Domain wurde gel\u00F6scht. \u00C4nderungen an Domaineintr\u00E4gen sind nicht erlaubt. Bitte erneuern Sie zun\u00E4chst die Domain.",
            4106: "Domain wurde deaktiviert. \u00C4nderungen an Domaineintr\u00E4gen sind nicht erlaubt. Bitte aktivieren Sie zun\u00E4chst die Domain.",
            4107: "Dieser Vorgang kann nur vom vshosting~-Administrator durchgef\u00FChrt werden.",
            4108: "Domain nicht gefunden.",
            4109: "Domaineintrag nicht gefunden.",
            4110: "Domainversion nicht gefunden.",
            4111: "Domainprotokoll nicht gefunden.",
            4112: "Diese Domain befindet sich bereits in Ihrer Domainliste.",
            4113: "Diese Domain wird bereits von einem anderen Kunden verwendet. Wenn Sie Eigent\u00FCmer der Domain sind,\n        wenden Sie sich bitte an unseren\n        <a class=\"__link\" href=\"/tickets/new/MANAGED_SERVICES_ISSUE?title=Domainbesitz\">vshosting~ Support</a>.",
            4114: "Domaineintrag wurde gel\u00F6scht.",
            4115: "Der Domaineintrag ist Teil eines nicht mehr aktuellen Domainwechsels und kann\n          nicht wiederhergestellt werden. Bitte aktualisieren Sie die Liste der Domaineintr\u00E4ge.",
            4116: "Die Domain wurde bereits wiederhergestellt.",
            4117: "Bereits ver\u00F6ffentlichte oder in der Ver\u00F6ffentlichung befindliche \u00C4nderungen k\u00F6nnen nicht r\u00FCckg\u00E4ngig gemacht werden.",
            4118: "Eine unver\u00F6ffentlichte und gel\u00F6schte Domain kann nicht deaktiviert werden.",
            4119: "Deaktivierte Domain erforderlich.",
            4120: "F\u00FCr den Domain-Namen sind nur ASCII- oder A-Label-Zeichen zul\u00E4ssig.",
            4121: "DNSSEC kann f\u00FCr diese Domain nicht aktiviert werden.",
            4122: "DNSSEC kann f\u00FCr diese Domain nicht deaktiviert werden.",
            4123: "DNSSEC ist bereits aktiviert.",
            4124: "DNSSEC ist bereits deaktiviert.",
            4125: "Ein \u00F6ffentlicher DNSSEC-Schl\u00FCssel kann nicht ver\u00F6ffentlicht werden.",
            4126: "Die Ver\u00F6ffentlichung eines \u00F6ffentlichen DNSSEC-Schl\u00FCssels kann nicht r\u00FCckg\u00E4ngig gemacht werden.",
            4127: "Fehler beim Lesen des \u00F6ffentlichen DNSSEC-Schl\u00FCssels.",
            4128: "DNSSEC kann fr\u00FChestens eine Stunde nach der Aktivierung deaktiviert werden.",
            4129: "DNSSEC kann nicht deaktiviert werden, wenn der \u00F6ffentliche Schl\u00FCssel bei dem Domain-Registrar gel\u00F6scht wurde.",
            4130: "Der DNSSEC-Schl\u00FCssel der Domain und des Registrars stimmen nicht \u00FCberein.",
            4131: "Nicht unterst\u00FCtzter Eintragstyp.",
            4132: "Ein \u00F6ffentlicher Schl\u00FCssel kann nicht vor der Aktivierung von DNSSEC\n        ver\u00F6ffentlicht werden.",
            4133: "Die Domain wird derzeit bearbeitet. Bitte wiederholen Sie den Vorgang sp\u00E4ter",
            4134: "Nicht unterst\u00FCtzter TTL-Wert. Der unterst\u00FCtzte Bereich liegt zwischen 60 und 86.400 Sekunden.",
            4135: "Der Domaineintrag ist Teil eines nicht mehr aktuellen Domainwechsels und kann nicht bearbeitet werden.\n        Bitte aktualisieren Sie die Liste der Domaineintr\u00E4ge.",
            4136: "Der eingef\u00FCgte Domaineintrag stimmt mit einem bestehenden Eintrag \u00FCberein.\n        Doppelte Eintr\u00E4ge sind nicht erlaubt.",
            4137: "",
            4138: "Ein Domaineintrag kann nicht wiederhergestellt werden, da bereits ein identischer Eintrag existiert.",
            4139: "In der Liste der GEL\u00D6SCHTEN Eintr\u00E4ge existiert ein Domaineintrag mit dem identischen Wert.\n        Bitte verwenden Sie die Wiederherstellungsfunktion, anstatt einen neuen Eintrag hinzuzuf\u00FCgen.",
            4140: "Domain kann nicht ver\u00F6ffentlicht werden.\n        Die Domaineintr\u00E4ge wurden nicht ge\u00E4ndert.",
            4141: "Das Limit f\u00FCr die Anzahl der Domaineintr\u00E4ge wurde \u00FCberschritten.\n        Bitte kontaktieren Sie den technischen Support von ~vshosting.",
            4142: "Falsche Zeichen im Namen des Domaineintrags. Ein g\u00FCltiger Hostname ist erforderlich.",
            4143: "Falscher Domaineintrag. Ein g\u00FCltiger Hostname ist erforderlich.",
            4144: "Nur ASCII-Zeichen sind erlaubt. Nicht-ASCII-Zeichen m\u00FCssen manuell in das IDN-Format ge\u00E4ndert werden.",
            4145: "Falsche L\u00E4nge des Domaineintrags. Die L\u00E4nge des Domaineneintragswertes muss zwischen 1 und 1024 Zeichen liegen.",
            4146: "Die Domain wird von einer externen Anwendung verwaltet und kann in CloudDNS nicht bearbeitet werden.",
            4147: "Das Limit f\u00FCr die Anzahl der Domains wurde \u00FCberschritten. Bitte kontaktieren Sie den technischen Support von ~vshosting",
            4148: "Um eine Domain hinzuzuf\u00FCgen, ist ein aktiver Kunde erforderlich.",
            4149: "Die Domain existiert bereits in PowerDNS in einem anderen Modus als Master.\n        Bitte kontaktieren Sie den ~vshosting-Support.",
            4150: "Die Domain steht auf der Liste der gesperrten Domains und kann nicht gel\u00F6scht oder deaktiviert werden.",
            4151: "Die Domain steht auf der Liste der gesperrten Domains und kann von einer externen Anwendung nicht bearbeitet werden.",
            4152: "Der eingef\u00FCgte Domaineintrag steht in Konflikt mit einem vorhandenen CNAME-Eintrag mit demselben Namen.",
            4153: "Der eingef\u00FCgte Domaineintrag steht in Konflikt mit einem vorhandenen Eintrag mit demselben Namen.",
            4154: "Die Domain wird von CloudDNS verwaltet und kann in einer externen Anwendung nicht bearbeitet werden.\n        Bitte l\u00F6schen Sie zun\u00E4chst die Domain in CloudDNS oder kontaktieren Sie den vshosting~-Support.",
            4155: "Die Domain wird von einer anderen externen Anwendung verwaltet.\n        L\u00F6schen Sie sie zun\u00E4chst in der Anwendung oder kontaktieren Sie den vshosting~-Support.",
            4501: "Interner Serverfehler. Die Pr\u00FCfsumme ist fehlgeschlagen.",
            4502: "Die Domain ist vor\u00FCbergehend durch einen anderen kollidierenden Vorgang gesperrt. Bitte versuchen Sie es sp\u00E4ter noch einmal.",
            4503: "Kunde ist vor\u00FCbergehend nicht verf\u00FCgbar. Bitte versuchen Sie es sp\u00E4ter noch einmal.",
            4504: "DnsClientApp ist vor\u00FCbergehend nicht verf\u00FCgbar. Bitte versuchen Sie es sp\u00E4ter noch einmal.",
            4505: "DNSSEC-Aktivierung fehlgeschlagen.",
            4506: "DNSSEC-Deaktivierung fehlgeschlagen.",
            4507: "Ver\u00F6ffentlichung des \u00F6ffentlichen DNSSEC-Schl\u00FCssels fehlgeschlagen.",
            4508: "Widerruf des \u00F6ffentlichen DNSSEC-Schl\u00FCssels fehlgeschlagen.",
            4509: "Laden des \u00F6ffentlichen DNSSEC-Schl\u00FCssels fehlgeschlagen.",
            5101: "Interner Serverfehler.",
            5102: "Interner Serverfehler.",
            5103: "Interner Serverfehler.",
            5104: "Interner Serverfehler.",
            5105: "Interner Serverfehler.",
            5106: "Interner Serverfehler.",
            5107: "Interner Serverfehler.",
            5108: "Interner Serverfehler.",
            5109: "Interner Serverfehler.",
            5110: "Diese Domain ist gegen L\u00F6schung gesch\u00FCtzt.",
            5111: "Interner Serverfehler.",
            5112: "Interner Serverfehler.",
            5113: "Interner Serverfehler.",
            5114: "Interner Serverfehler.",
            5115: "Interner Serverfehler.",
            5116: "Interner Serverfehler.",
            5117: "Interner Serverfehler.",
            5118: "Interner Serverfehler.",
            5119: "Interner Serverfehler.",
            5500: "Interner Serverfehler.",
            6101: "Der Grenzwert der Kunden-Domains muss eine positive Zahl sein.",
            6102: "Der Grenzwert der Domaineintr\u00E4ge muss eine positive Zahl sein.",
            6103: "Domain nicht gefunden.",
            6104: "Domainprotokoll nicht gefunden.",
            6105: "Kunde nicht gefunden.",
            6106: "ClientDNSApp nicht gefunden.",
            6107: "Protokoll der Vorg\u00E4nge nicht gefunden.",
            6108: "Protokoll der \u00C4nderungen nicht gefunden.",
            6109: "Domains, die in CloudDNS existieren, k\u00F6nnen nicht importiert werden.",
            6110: "Die Funktion ist in der Anwendungskonfiguration deaktiviert."
        }
    }
};
var loggedUser = {
    cs: {
        loggedUser: {
            myAccountButton: 'Můj profil',
            passChangeButton: 'Změna hesla',
            logoutButton: 'Odhlásit se',
            companiesHeading: 'Spravované účty',
            changePasswordConfirm: 'Opravdu si přejete změnit heslo?',
            changePassword: 'Změnit heslo'
        }
    },
    en: {
        loggedUser: {
            myAccountButton: 'My account',
            passChangeButton: 'Change password',
            logoutButton: 'Logout',
            companiesHeading: 'Managed companies',
            changePasswordConfirm: 'Do you really want to change your password?',
            changePassword: 'Change password'
        }
    },
    hu: {
        loggedUser: {
            myAccountButton: 'Saját profil',
            passChangeButton: 'Jelszó megváltoztatása',
            logoutButton: 'Kijelentkezés',
            companiesHeading: 'Kezelt fiókok',
            changePasswordConfirm: 'Valóban szeretné megváltoztatni a jelszót?',
            changePassword: 'A jelszó megváltoztatása'
        }
    },
    de: {
        loggedUser: {
            myAccountButton: 'Mein Profil',
            passChangeButton: 'Passwortänderung',
            logoutButton: 'Abmelden',
            companiesHeading: 'Verwaltete Konten',
            changePasswordConfirm: 'Wollen Sie Ihr Passwort wirklich ändern?',
            changePassword: 'Passwort ändern'
        }
    },
};
var authBase = {
    cs: {
        authBase: {
            passwordChangeSuccessSnack: 'Odkaz na změnu hesla byl odeslán na váš E-mail.',
            externalAuthValidationSuccess: 'Propojení proběhlo úspěšně',
            externalAuthDeleteSuccess: 'Propojení bylo úspěšně zrušeno',
            emailLoginDisabledHint: "Z d\u016Fvodu bezpe\u010Dnosti bylo p\u0159ihl\u00E1\u0161en\u00ED e-mailem a heslem deaktivov\u00E1no.\n      Ze stejn\u00E9ho d\u016Fvodu nelze pou\u017E\u00EDt funkci pro zasl\u00E1n\u00ED zapomenut\u00E9ho hesla."
        }
    },
    en: {
        authBase: {
            passwordChangeSuccessSnack: 'URL for password change was sent to your E-mail address.',
            externalAuthValidationSuccess: 'Connection was successful',
            externalAuthDeleteSuccess: 'Connection was successfully removed',
            emailLoginDisabledHint: "For security reasons, login by email and password has been deactivated.\n      The forgotten password function has also been disabled."
        }
    },
    hu: {
        authBase: {
            passwordChangeSuccessSnack: 'A jelszó megváltoztatásához szükséges hivatkozást elküldtük az Ön e-mail címére.',
            externalAuthValidationSuccess: 'A kapcsolat sikeres volt.',
            externalAuthDeleteSuccess: 'A kapcsolat sikeresen eltávolításra került',
            emailLoginDisabledHint: "Biztons\u00E1gi okokb\u00F3l az e-mail \u00E9s jelsz\u00F3val t\u00F6rt\u00E9n\u0151 bejelentkez\u00E9s le van tiltva.\n      Az elfelejtett jelsz\u00F3 funkci\u00F3 is ki van kapcsolva."
        }
    },
    de: {
        authBase: {
            passwordChangeSuccessSnack: 'Ein Link zum Ändern Ihres Passworts wurde an Ihre E-Mail-Adresse gesendet.',
            externalAuthValidationSuccess: 'Die Verbindung war erfolgreich',
            externalAuthDeleteSuccess: 'Die Verbindung war erfolgreich entfernt',
            emailLoginDisabledHint: "Aus Sicherheitsgr\u00FCnden wurde die Anmeldung per E-Mail und Passwort deaktiviert.\n        Die Funktion zum Zur\u00FCcksetzen des Passworts wurde ebenfalls deaktiviert."
        }
    },
};
var domainsBase = {
    cs: {
        domainsBase: {
            deleteSuccessSnack: 'Doména úspěšně smazána.',
            restoreSuccessSnack: 'Doména úspěšně obnovena.',
            deactivateDomainSuccessSnack: 'Doména úspěšně deaktivována',
            activateDomainSuccessSnack: 'Doména úspěšně aktivována',
            deleteRecordSuccessSnack: 'Záznam úspěšně smazán.',
            restoreRecordSuccessSnack: 'Záznam úspěšně obnoven.'
        }
    },
    en: {
        domainsBase: {
            deleteSuccessSnack: 'Domain succesfully deleted.',
            restoreSuccessSnack: 'Domain succesfully restored.',
            deactivateDomainSuccessSnack: 'Domain succesfully deactivated',
            activateDomainSuccessSnack: 'Domain succesfully activated',
            deleteRecordSuccessSnack: 'Record succesfully deleted.',
            restoreRecordSuccessSnack: 'Record succesfully restored.'
        }
    },
    hu: {
        domainsBase: {
            deleteSuccessSnack: 'A domain sikeresen törölve.',
            restoreSuccessSnack: 'A domain sikeresen visszaállítva.',
            deactivateDomainSuccessSnack: 'A domain sikeresen deaktiválva',
            activateDomainSuccessSnack: 'A domain sikeresen aktiválva',
            deleteRecordSuccessSnack: 'A bejegyzés sikeresen törölve.',
            restoreRecordSuccessSnack: 'A bejegyzés sikeresen visszaállítva.'
        }
    },
    de: {
        domainsBase: {
            deleteSuccessSnack: 'Domain erfolgreich gelöscht.',
            restoreSuccessSnack: 'Domain erfolgreich wiederhergestellt.',
            deactivateDomainSuccessSnack: 'Domain erfolgreich deaktiviert.',
            activateDomainSuccessSnack: 'Domain erfolgreich aktiviert.',
            deleteRecordSuccessSnack: 'Eintrag erfolgreich gelöscht.',
            restoreRecordSuccessSnack: 'Eintrag erfolgreich wiederhergestellt.',
        }
    }
};
var menuItems = {
    en: {
        menuItems: {
            company: 'Company',
            billing: 'Billing',
            services: 'Services'
        }
    },
    cs: {
        menuItems: {
            company: 'Firma',
            billing: 'Fakturace',
            services: 'Služby'
        }
    },
    hu: {
        menuItems: {
            company: 'Cég',
            billing: 'Számlázás',
            services: 'Szolgáltatások'
        }
    },
    de: {
        menuItems: {
            company: 'Firma',
            billing: 'Fakturierung',
            services: 'Dienste'
        }
    }
};
var billingCard = {
    en: {
        billingCard: {
            latestInvoices: 'Latest invoices',
            seeAll: 'See all',
            lastPayments: 'Last payments'
        }
    },
    cs: {
        billingCard: {
            latestInvoices: 'Nejnovější faktury',
            seeAll: 'Vše',
            lastPayments: 'Poslední platby'
        }
    },
    hu: {
        billingCard: {
            latestInvoices: 'Legutóbbi számlák',
            seeAll: 'Összes',
            lastPayments: 'Az utolsó kifizetések'
        }
    },
    de: {
        billingCard: {
            latestInvoices: 'Letzte Rechnungen',
            seeAll: 'Alle',
            lastPayments: 'Letzte Zahlungen'
        }
    }
};
var servicesCard = {
    en: {
        servicesCard: {
            graphsAndSettings: 'Graphs & settings',
            external: 'External',
            financialAndStatus: 'Lastest installed services',
            seeAll: 'See all',
            lookingForNewService: 'Looking for a new Service?',
            press: 'Press the ',
            toTalk: ' icon to talk with our helpful <strong>customer support</strong>.'
        }
    },
    cs: {
        servicesCard: {
            graphsAndSettings: 'Grafy & nastavení',
            external: 'Externí',
            financialAndStatus: 'Poslední instalované služby',
            seeAll: 'Vše',
            lookingForNewService: 'Hledáte novou službu?',
            press: 'Stiskněte ',
            toTalk: ' k vytvoření požadavku na naši <strong>technickou podporu</strong>.'
        }
    },
    hu: {
        servicesCard: {
            graphsAndSettings: 'Grafikokon és beállítások',
            external: 'Külső',
            financialAndStatus: 'Utoljára telepített szolgáltatások',
            seeAll: 'Összes',
            lookingForNewService: 'Új szolgáltatást keres?',
            press: 'Lépjen kapcsolatba ügyfélszolgálatunkkal a ',
            toTalk: ' <strong>ikonra kattintva!</strong>.'
        }
    },
    de: {
        servicesCard: {
            graphsAndSettings: 'Diagramme & Einstellungen',
            external: 'Extern',
            financialAndStatus: 'Zuletzt eingerichtete Dienste',
            seeAll: 'Alle',
            lookingForNewService: 'Suchen Sie einen neuen Dienst?',
            press: 'Drücken Sie',
            toTalk: ' um eine Anfrage für unseren <strong>technischen Support</strong> zu erstellen.'
        }
    }
};
var recaptcha = {
    en: {
        recaptcha: {
            title: "Hmm, something doesn't look right",
            desc: "We've detected some unusual traffic, looks like someone on your network\n        is sending automatic requests to ours servers. If you want to proceed, please\n        let us know that you are not a robot. Beep. Boop.",
            footer: 'Need help? Contact us at +420 246 035 835'
        }
    },
    cs: {
        recaptcha: {
            title: "Detekovali jsme neobvykl\u00FD provoz",
            desc: "Vypad\u00E1 to, \u017Ee n\u011Bkdo z va\u0161\u00ED s\u00EDt\u011B pos\u00EDl\u00E1 automatick\u00E9 dotazy na na\u0161e servery,\n        pokud chcete pokra\u010Dovat, dejte n\u00E1m v\u011Bd\u011Bt, \u017Ee nejste robot.",
            footer: 'Potřebujete pomoct? Kontaktujte nás na telefonu +420 246 035 835'
        }
    },
    hu: {
        recaptcha: {
            title: "Szokatlan forgalmat \u00E9szlelt\u00FCnk",
            desc: "\u00DAgy t\u0171nik, hogy valaki az \u00D6n h\u00E1l\u00F3zat\u00E1n automatiz\u00E1lt lek\u00E9rdez\u00E9seket k\u00FCld a szervereinknek,\n        ha folytatni szeretn\u00E9, tudassa vel\u00FCnk, hogy nem robot.",
            footer: "Seg\u00EDts\u00E9gre van sz\u00FCks\u00E9ge? L\u00E9pjen kapcsolatba vel\u00FCnk a +36 80 206 005 telefonsz\u00E1mon."
        }
    },
    de: {
        recaptcha: {
            title: "Wir haben ungew\u00F6hnlichen Verkehr festgestellt",
            desc: "Es sieht so aus, als w\u00FCrde jemand in Ihrem Netzwerk automatische Anfragen an unsere Server senden,\n        wenn Sie fortfahren m\u00F6chten, lassen Sie uns wissen, dass Sie kein Roboter sind.",
            footer: 'Brauchen Sie Hilfe?? Kontaktieren Sie uns unter +420 246 035 835'
        }
    }
};
var invoiceItem = {
    en: {
        invoiceItem: {
            paid: 'Paid ',
            created: 'Created ',
            daysNumber: '{days, plural, =1 { 1 day} other { # days}} ',
            overdue: 'overdue',
            dueIn: 'Due in ',
            today: 'Due today'
        }
    },
    cs: {
        invoiceItem: {
            paid: 'Uhrazeno ',
            created: 'Vytvořeno ',
            daysNumber: '{days, plural, =1 { 1 den} =2 { 2 dny} =3 { 3 dny} =4 { 4 dny} other { # dní}} ',
            overdue: 'po splatnosti',
            dueIn: 'Splatnost za',
            today: 'Splatnost dnes'
        }
    },
    hu: {
        invoiceItem: {
            paid: 'Fizetve',
            created: 'Létrehozva',
            daysNumber: '{days, plural, =1 { 1 nap} =2 { 2 nap} =3 { 3 nap} =4 { 4 nap} other { # nap}} ',
            overdue: 'lejárt',
            dueIn: 'Esedékes ezért',
            today: 'Ma esedékes'
        }
    },
    de: {
        invoiceItem: {
            paid: 'Bezahlt ',
            created: 'Erstellt ',
            daysNumber: '{days, plural, =1 { 1 Tag} =2 { 2 Tage} =3 { 3 Tage} =4 { 4 Tage} other { # Tage}} ',
            overdue: 'überfällig',
            dueIn: 'Fällig in',
            today: 'Fällig heute'
        }
    }
};
var clientServiceItem = {
    en: {
        clientServiceItem: {
            installed: 'Installed on <strong>{date}</strong>'
        }
    },
    cs: {
        clientServiceItem: {
            installed: 'Instalováno dne <strong>{date}</strong>'
        }
    },
    hu: {
        clientServiceItem: {
            installed: 'Telepítve <strong>{date}</strong> napon'
        }
    },
    de: {
        clientServiceItem: {
            installed: 'Installiert am <strong>{date}</strong>'
        }
    }
};
var ticketBase = {
    cs: {
        ticketBase: {
            copyListDuplicaterErr: 'Zvolený email již v seznamu existuje.',
            approvePriceOfferSuccess: 'Cenová nabídka byla schválena.',
            rejectPriceOfferSuccess: 'Cenová nabídka byla zamítnuta.',
            attachedPriceOrderText: "Cenov\u00E1 nab\u00EDdka \u010Dek\u00E1 na Va\u0161e schv\u00E1len\u00ED.\n        Dal\u0161\u00ED pr\u00E1ce na ticketu vy\u017Eaduj\u00ED schv\u00E1len\u00ED cenov\u00E9 nab\u00EDdky.",
            realizationDateText: 'Další práce na ticketu vyžadují schválení navrhovaného termínu realizace.',
            approve: 'Schválit',
            reject: 'Zamítnout',
            rejectPriceOfferPopTitle: 'Zamítnout cenovou nabídku',
            rejectRealizationDatePopTitle: 'Zamítnout datum realizace',
            confirmRealizationDateSuccess: 'Děkujeme! Ticket byl naplánován na domluvený datum realizace.',
            rejectRealizationDateSuccess: 'Navržený termín realizace byl zamítnut. Ticket byl předán k dalšímu zpracování.',
        }
    },
    en: {
        ticketBase: {
            copyListDuplicaterErr: 'Mail address already a part of copy list.',
            approvePriceOfferSuccess: 'Price offer was approved.',
            rejectPriceOfferSuccess: 'Price offer was rejected.',
            attachedPriceOrderText: "The price offer is waiting for your approval.\n        Further work on the ticket requires the approval of the price offer.",
            realizationDateText: 'Further work on the ticket requires the approval of the realization date.',
            approve: 'Approve',
            reject: 'Reject',
            rejectPopTitle: 'Reject price offer',
            confirmRealizationDateSuccess: 'Thank you, the ticket was scheduled for agreed realization date!',
            rejectRealizationDateSuccess: "The suggested implementation date was rejected.\n        The ticket has been handed over for further processing.",
        }
    },
    hu: {
        ticketBase: {
            copyListDuplicaterErr: 'A kiválasztott e-mail már létezik a listában.',
            approvePriceOfferSuccess: 'Az árajánlatot jóváhagyták.',
            rejectPriceOfferSuccess: 'Az árajánlatot elutasították.',
            attachedPriceOrderText: "Az \u00E1raj\u00E1nlat az \u00D6n j\u00F3v\u00E1hagy\u00E1s\u00E1ra v\u00E1r. A ticketen v\u00E9gzett tov\u00E1bbi\n      munk\u00E1hoz az \u00E1raj\u00E1nlat j\u00F3v\u00E1hagy\u00E1sa sz\u00FCks\u00E9ges.",
            realizationDateText: 'A tickettel kapcsolatos további munkához a javasolt végrehajtási időpont jóváhagyása szükséges.',
            approve: 'Jóváhagyás',
            reject: 'Elutasítani',
            rejectPriceOfferPopTitle: 'Az árajánlat visszautasítása',
            rejectRealizationDatePopTitle: 'A végrehajtás dátumának visszautasítása',
            confirmRealizationDateSuccess: 'Köszönjük! A ticketet a megállapodott végrehajtási időpontra ütemezték.',
            rejectRealizationDateSuccess: "A v\u00E9grehajt\u00E1s javasolt id\u0151pontj\u00E1t elutas\u00EDtott\u00E1k. A ticketet tov\u00E1bb\u00EDtottuk tov\u00E1bbi feldolgoz\u00E1sra.",
        }
    },
    de: {
        ticketBase: {
            copyListDuplicaterErr: 'Die ausgewählte E-Mail-Adresse ist bereits in der Liste vorhanden.',
            approvePriceOfferSuccess: 'Das Preisangebot wurde genehmigt.',
            rejectPriceOfferSuccess: 'Das Preisangebot wurde abgelehnt.',
            attachedPriceOrderText: "Das Preisangebot wartet auf Ihre Genehmigung.\n        Die weitere Bearbeitung des Tickets erfordert\n        die Genehmigung des Preisangebots.",
            realizationDateText: 'Die weitere Bearbeitung des Tickets erfordert die Genehmigung des vorgeschlagenen Umsetzungstermins.',
            approve: 'Genehmigen',
            reject: 'Ablehnen',
            rejectPriceOfferPopTitle: 'Das Preisangebot ablehnen',
            rejectRealizationDatePopTitle: 'Umsetzungsdatum ablehnen',
            confirmRealizationDateSuccess: 'Vielen Dank! Das Ticket wurde für das vereinbarte Umsetzungsdatum eingeplant.',
            rejectRealizationDateSuccess: "Der vorgeschlagene Umsetzungstermin wurde abgelehnt.\n        Das Ticket ist zur weiteren Bearbeitung weitergeleitet worden.",
        }
    }
};
var ticketState = {
    cs: {
        ticketState: {
            CLOSED: 'Uzavřený',
            PLANNED: 'Plánovaný',
            RESOLVED: 'Vyřešený',
            ACCEPTED: 'Přijatý',
            IN_REALISATION: 'Pracujeme na tom',
            WAITING_FOR_CLIENT: 'Čeká na klienta',
            WAITING_AUTHORIZATION: 'Čeká na ověření'
        }
    },
    en: {
        ticketState: {
            CLOSED: 'Closed',
            PLANNED: 'Planned',
            RESOLVED: 'Resolved',
            ACCEPTED: 'Accepted',
            IN_REALISATION: 'In Realisation',
            WAITING_FOR_CLIENT: 'Waiting for Client',
            WAITING_AUTHORIZATION: 'Waiting for authorization'
        }
    },
    hu: {
        ticketState: {
            CLOSED: 'Lezárva',
            PLANNED: 'Tervezett',
            RESOLVED: 'Megoldva',
            ACCEPTED: 'Beértkezett',
            IN_REALISATION: 'Dolgozunk rajta',
            WAITING_FOR_CLIENT: 'Várakozás az ügyfélre',
            WAITING_AUTHORIZATION: 'Ellenőrzésre vár'
        }
    },
    de: {
        ticketState: {
            CLOSED: 'Geschlossen',
            PLANNED: 'Geplant',
            RESOLVED: 'Gelöst',
            ACCEPTED: 'Angenommen',
            IN_REALISATION: 'Wir arbeiten daran',
            WAITING_FOR_CLIENT: 'Warten auf den Kunden',
            WAITING_AUTHORIZATION: 'Warten auf Autorisierung'
        }
    }
};
var ticketList = {
    en: {
        ticketList: {
            emptyStateTitle: 'Nothing to be found..',
            emptyStateDescPreLink: 'There are no results with this filter on. Consider ',
            emptyStateDescLink: 'resetting',
            emptyStateDescPostLink: ' it.'
        }
    },
    cs: {
        ticketList: {
            emptyStateTitle: 'Žádný ticket nenalezen..',
            emptyStateDescPreLink: 'Neexistují žádné tickety s tímto filtrem. Zkuste ho ',
            emptyStateDescLink: 'vyresetovat',
            emptyStateDescPostLink: '.'
        }
    },
    hu: {
        ticketList: {
            emptyStateTitle: 'A ticket nem található...',
            emptyStateDescPreLink: 'Nincsenek ticketek ezzel a szűrővel. Próbálja meg ',
            emptyStateDescLink: 'újraindítani',
            emptyStateDescPostLink: '.'
        }
    },
    de: {
        ticketList: {
            emptyStateTitle: 'Kein Ticket gefunden.',
            emptyStateDescPreLink: 'Es gibt keine Tickets mit diesem Filter. Versuchen Sie ',
            emptyStateDescLink: 'es zurückzusetzen',
            emptyStateDescPostLink: '.'
        }
    }
};
var ticketsCard = {
    en: {
        ticketsCard: {
            emptyStateTitle: "You don't have any tickets yet..",
            emptyStateDesc: 'You can add one by clicking on the support button at the bottom right of the page.',
            emptyStateButton: 'Open support now'
        }
    },
    cs: {
        ticketsCard: {
            emptyStateTitle: "Dosud nebyl zad\u00E1n \u017E\u00E1dn\u00FD po\u017Eadavek.",
            emptyStateDesc: 'Nový požadavek můžete přidat kliknutím na tlačítko podpory v pravo dole na stránce.',
            emptyStateButton: 'Otevřít podporu nyní'
        }
    },
    hu: {
        ticketsCard: {
            emptyStateTitle: "\u00D6nnek nincs nyitott hibajegye",
            emptyStateDesc: 'Új hibajegyet a jobb alsó sarokban található támogatás gombra kattintva nyithat.',
            emptyStateButton: 'Támogatás megnyitása most'
        }
    },
    de: {
        ticketsCard: {
            emptyStateTitle: "Es wurde noch keine Anfrage gestellt.",
            emptyStateDesc: 'Sie können eine neue Anfrage hinzufügen, indem Sie auf die Support-Schaltfläche unten rechts auf der Seite klicken.',
            emptyStateButton: 'Support jetzt öffnen'
        }
    }
};
var ticketAddContainer = {
    en: {
        ticketAddContainer: {
            filesLabel: 'Select up to 5 files to attach to ticket',
            addTicket: 'Add new Ticket'
        }
    },
    cs: {
        ticketAddContainer: {
            filesLabel: 'Připojte až 5 souborů k požadavku',
            addTicket: 'Přidat nový Požadavek'
        }
    },
    hu: {
        ticketAddContainer: {
            filesLabel: 'Legfeljebb 5 fájl csatolása az igényhez',
            addTicket: 'Új igény hozzáadása',
        }
    },
    de: {
        ticketAddContainer: {
            filesLabel: 'Hängen Sie bis zu 5 Dateien an eine Anfrage an',
            addTicket: 'Neue Anfrage hinzufügen'
        }
    }
};
var ticketsCommon = {
    en: {
        ticketsCommon: {
            numMore: '{num} more messages',
            openHistoryTitle: "Don't see what you're looking for?",
            openHistoryPress: 'Press ',
            openHistoryToShow: ' to open list of all tickets, or ',
            specialWindow: 'open them in a new window.'
        }
    },
    cs: {
        ticketsCommon: {
            numMore: '{num} dalších zpráv',
            openHistoryTitle: 'Nevidíte, co potřebujete?',
            openHistoryPress: 'Stiskněte ',
            openHistoryToShow: ' pro otevření <strong>seznamu všech požadavků</strong>, nebo si je',
            specialWindow: 'zobrazte ve vlastním okně.'
        }
    },
    hu: {
        ticketsCommon: {
            numMore: '{num} további üzenetek',
            openHistoryTitle: 'Nem találja, amit keres?',
            openHistoryPress: 'Nyomja meg ',
            openHistoryToShow: ' az összes hibajegy <strong>megtekintéséhez</strong>, vagy ',
            specialWindow: 'nyissa meg egy új ablakban',
        }
    },
    de: {
        ticketsCommon: {
            numMore: '{num} weitere Nachrichten',
            openHistoryTitle: 'Sie sehen nicht, was Sie brauchen?',
            openHistoryPress: 'Drücken Sie',
            openHistoryToShow: ' um die <strong>Liste aller Anfragen</strong> zu öffnen, oder sie',
            specialWindow: 'in eigenem Fenster anzuzeigen.'
        }
    }
};
var search = {
    en: {
        search: {
            inputPlaceholder: 'Enter any keyword to start searching..',
            emptyStateHeading: 'No results were found, sorry!',
            emptySuggestStateHeading: 'Uh oh, nothing to suggest, sorry!',
            emptyStateDesc: 'Looks like there is nothing under <strong>{keyword}</strong>, try editing the search term.',
            entities: {
                ticket: 'Ticket',
                clientUser: 'User',
                clientPersonServerParkAccess: 'Server Park Access',
                invoice: 'Invoice',
                cloudManagedServer: 'Cloud Managed Server',
                windowsManagedServer: 'Managed Windows Server',
                windowsManagedCluster: 'Managed Windows Cluster',
                windowsManagedBasic: 'Managed Windows Basic',
                windowsManagedCloud: 'Managed Windows Cloud',
                managedCluster: 'Managed Cluster',
                managedServer: 'Managed Server',
                m1ManagedCluster: 'M1 Managed Cluster',
                m1ManagedServer: 'M1 Managed Server',
                m2ManagedCluster: 'M2 Managed Cluster',
                m2ManagedServer: 'M2 Managed Server',
                serverHosting: 'Server Hosting',
                rackHosting: 'Rack Hosting',
                dedicatedServer: 'Dedicated Server',
                vds: 'VDS',
                domain: 'Domain'
            },
            navigation: {
                start: 'Press arrow ',
                or: ' or ',
                middle: ' to cycle through results. When item is selected, press ',
                end: ' to navigate to it\'s detail.'
            }
        }
    },
    cs: {
        search: {
            inputPlaceholder: 'Začněte vyhledávat..',
            emptyStateHeading: 'Je nám líto, nenalezli jsme žádné výsledky!',
            emptySuggestStateHeading: 'Bohužel nebyla nalezena žádná data.',
            emptyStateDesc: 'Zkuste prosím upravit vyhledávací dotaz <strong>{keyword}</strong>.',
            entities: {
                ticket: 'Ticket',
                clientUser: 'Uživatel',
                clientPersonServerParkAccess: 'Přístup do Server Parku',
                invoice: 'Faktura',
                cloudManagedServer: 'Cloud Managed Server',
                windowsManagedServer: 'Managed Windows Server',
                windowsManagedCluster: 'Managed Windows Cluster',
                windowsManagedBasic: 'Managed Windows Basic',
                windowsManagedCloud: 'Managed Windows Cloud',
                managedCluster: 'Managed Cluster',
                managedServer: 'Managed Server',
                m1ManagedCluster: 'M1 Managed Cluster',
                m1ManagedServer: 'M1 Managed Server',
                m2ManagedCluster: 'M2 Managed Cluster',
                m2ManagedServer: 'M2 Managed Server',
                serverHosting: 'Server Hosting',
                rackHosting: 'Rack Hosting',
                dedicatedServer: 'Dedikovaný Server',
                vds: 'VDS',
                domain: 'Domain'
            },
            navigation: {
                start: 'Stiskněte šipku ',
                or: ' nebo ',
                middle: ' k procházení výsledků. Když je položka vybrána, stisknutím ',
                end: ' otevřete její detail.'
            }
        }
    },
    hu: {
        search: {
            inputPlaceholder: 'Keresés megkezdése..',
            emptyStateHeading: 'Sajnálom, nem találtunk találatot!',
            emptySuggestStateHeading: 'Nincs találat.',
            emptyStateDesc: 'Kérjük módosítson a keresésen <strong>{keyword}</strong>.',
            entities: {
                ticket: 'Ticket',
                clientUser: 'Felhasználó',
                clientPersonServerParkAccess: 'Hozzáférés a Server Park-hoz',
                invoice: 'Számla',
                cloudManagedServer: 'Cloud Managed Server',
                windowsManagedServer: 'Managed Windows Server',
                windowsManagedCluster: 'Managed Windows Cluster',
                windowsManagedBasic: 'Managed Windows Basic',
                windowsManagedCloud: 'Managed Windows Cloud',
                managedCluster: 'Managed Cluster',
                managedServer: 'Managed Server',
                m1ManagedCluster: 'M1 Managed Cluster',
                m1ManagedServer: 'M1 Managed Server',
                m2ManagedCluster: 'M2 Managed Cluster',
                m2ManagedServer: 'M2 Managed Server',
                serverHosting: 'Server Hosting',
                rackHosting: 'Rack Hosting',
                dedicatedServer: 'Dedikált szerver',
                vds: 'VDS',
                domain: 'Domain'
            },
            navigation: {
                start: 'Nyomja meg a nyilat ',
                or: ' vagy ',
                middle: ' a találatok közötti lapozáshoz. Ha kiválasztott egy elemet, nyomja meg a ',
                end: ' részletek megnyitásához.'
            }
        }
    },
    de: {
        search: {
            inputPlaceholder: 'Suche starten..',
            emptyStateHeading: 'Tut uns leid, wir haben keine Ergebnisse gefunden!',
            emptySuggestStateHeading: 'Leider wurden keine Daten gefunden.',
            emptyStateDesc: 'Bitte versuchen Sie, Ihre Suchanfrage <strong>{keyword}</strong> zu ändern.',
            entities: {
                ticket: 'Ticket',
                clientUser: 'Benutzer',
                clientPersonServerParkAccess: 'Zugang zu Server Park',
                invoice: 'Rechnung',
                cloudManagedServer: 'Cloud Managed Server',
                windowsManagedServer: 'Managed Windows Server',
                windowsManagedCluster: 'Managed Windows Cluster',
                windowsManagedBasic: 'Managed Windows Basic',
                windowsManagedCloud: 'Managed Windows Cloud',
                managedCluster: 'Managed Cluster',
                managedServer: 'Managed Server',
                m1ManagedCluster: 'M1 Managed Cluster',
                m1ManagedServer: 'M1 Managed Server',
                m2ManagedCluster: 'M2 Managed Cluster',
                m2ManagedServer: 'M2 Managed Server',
                serverHosting: 'Server Hosting',
                rackHosting: 'Rack Hosting',
                dedicatedServer: 'Dedizierter Server',
                vds: 'VDS',
                domain: 'Domain'
            },
            navigation: {
                start: 'Drücken Sie die Pfeiltaste ',
                or: ' oder ',
                middle: ' zum Durchsuchen der Ergebnisse. Wenn ein Eintrag ausgewählt ist, drücken Sie auf ',
                end: ' um seine Details zu öffnen.'
            }
        }
    }
};
var ticketTopics = {
    en: {
        ticketTopics: {
            TECHNICAL: 'Technical',
            BILLING: 'Billing',
            SALES: 'Sales'
        }
    },
    cs: {
        ticketTopics: {
            TECHNICAL: 'Technické témata',
            BILLING: 'Účtování',
            SALES: 'Prodeje a služby'
        }
    },
    hu: {
        ticketTopics: {
            TECHNICAL: 'Műszaki témák',
            BILLING: 'Számlázás',
            SALES: 'Eladás és szolgáltatások'
        }
    },
    de: {
        ticketTopics: {
            TECHNICAL: 'Technische Themen',
            BILLING: 'Abrechnung',
            SALES: 'Verkäufe und Dienste'
        }
    }
};
export var ticketsFilter = {
    en: {
        ticketsFilter: {
            buttonPrefix: 'Status includes',
            buttonPlaceholder: 'Ticket status filter'
        }
    },
    cs: {
        ticketsFilter: {
            buttonPrefix: 'Status obsahuje',
            buttonPlaceholder: 'Filtr statusů ticketu'
        }
    },
    hu: {
        ticketsFilter: {
            buttonPrefix: 'Állapot tartalmazza',
            buttonPlaceholder: 'Ticket státusz szűrő'
        }
    },
    de: {
        ticketsFilter: {
            buttonPrefix: 'Der Status enthält',
            buttonPlaceholder: 'Filter für den Ticketstatus'
        }
    }
};
export var ticketsMessage = {
    en: {
        ticketsMessage: {
            failSnack: 'Message could not be added - {err} - please try again'
        }
    },
    cs: {
        ticketsMessage: {
            failSnack: 'Zpráva nemohla být přidána - {err} - zkuste to prosím znovu.'
        }
    },
    hu: {
        ticketsMessage: {
            failSnack: 'Üzenetet nem sikerült hozzáadni - {err} - kérjük, próbálja meg újra.'
        }
    },
    de: {
        ticketsMessage: {
            failSnack: 'Nachricht konnte nicht hinzugefügt werden - {err} - bitte versuchen Sie es erneut.'
        }
    }
};
export var ticketsTrigger = {
    en: {
        ticketsTrigger: {
            supportButton: 'Support',
            supportAddButtonTooltip: 'Select topic and create new ticket',
            closedGroupHeading: 'All Closed statuses',
            openGroupHeading: 'All Open statuses',
            title: 'VSHosting Support HUB',
            topicSelect: 'Select Topic and Add new Support Ticket',
            or: 'OR',
            showHistory: 'Show Support Ticket History',
            newTicket: 'New support Ticket with topic <strong>{topic}</strong>',
            copyList: 'Ticket copy list',
            copyListDesc: 'All ticket communication will be send as CC to:',
            subscriptionTooltip: 'Add a recipient who will receive information about the ticket',
            unwatch: 'Stop following the ticket',
            watch: 'Start following the ticket',
            watchingTooltip: 'You are watching the ticket and you will be notified of new messages',
            notWatchingTooltip: 'You are not watching the ticket, you will be not notified of new messages',
            technicalSupport: 'Technical support',
            technicalSupportDesc: 'In case of critical issues (outages or critical failure of server)',
            salesDepartment: 'Sales department',
            phonePrefix: '420',
            phoneSupport: '246035835',
            mailSupport: 'podpora@vshosting.cz',
            mailSalesDepartment: 'obchod@vshosting.cz'
        }
    },
    cs: {
        ticketsTrigger: {
            supportButton: 'Podpora',
            closedGroupHeading: 'Všechny zavřené statusy',
            supportAddButtonTooltip: 'Vyberte předmět a přidejte nový požadavek',
            openGroupHeading: 'Všechny otevřené statusy',
            title: 'Centrum podpory VSHosting',
            topicSelect: 'Vyberte předmět a přidejte nový požadavek',
            or: 'NEBO',
            showHistory: 'Zobrazit historii požadavků',
            newTicket: 'Nový Požadavek s předmětem <strong>{topic}</strong>',
            copyList: 'Seznam příjemců informací o požadavku',
            copyListDesc: 'Veškerá komunikace o požadavku bude odeslána jako kopie.',
            subscriptionTooltip: 'Přidat příjemce informací o požadavku',
            unwatch: 'Přestat sledovat požadavek',
            watch: 'Začít sledovat požadavek',
            watchingTooltip: 'Tento požadavek sledujete a budete notifikován e-mailem o nových zprávách',
            notWatchingTooltip: 'Tento požadavek nesledujete, nebudete notifikován e-mailem o nových zprávách',
            technicalSupport: 'Technická podpora',
            technicalSupportDesc: 'V případě kritické situace (výpadky či kritická nefunkčnost části serveru)',
            salesDepartment: 'Obchodní oddělení',
            phonePrefix: '420',
            phoneSupport: '246035835',
            mailSupport: 'podpora@vshosting.cz',
            mailSalesDepartment: 'obchod@vshosting.cz'
        }
    },
    hu: {
        ticketsTrigger: {
            supportButton: 'Támogatás',
            closedGroupHeading: 'Minden lezárt státusz',
            supportAddButtonTooltip: 'Válassza ki a tárgyat és adjon hozzá új igényt',
            openGroupHeading: 'Minden nyitott státusz',
            title: 'A VSHosting támogatási központja',
            topicSelect: 'Válassza ki a tárgyat és adjon hozzá új igényt',
            or: 'VAGY',
            showHistory: 'Előzmények megtekintése',
            newTicket: 'Új Igény <strong>{topic}</strong> tárggyal',
            copyList: 'Az igénnyel kapcsolatos információk címzettjeinek listája',
            copyListDesc: 'Az igénnyel kapcsolatos minden kommunikáció másolatban kerül elküldésre.',
            subscriptionTooltip: 'Az igénnyel kapcsolatos információk címzettjének hozzáadása',
            unwatch: 'Az igény nyomon követésének leállítása',
            watch: 'Az igény nyomon követésének megkezdése',
            watchingTooltip: 'Ezt az igény nyomonköveti, és az új üzenetekről e-mailben kap értesítést',
            notWatchingTooltip: 'Ön nem követi ezt az igényt, nem kap e-mailben értesítést az új üzenetekről',
            technicalSupport: 'Műszaki támogatás',
            technicalSupportDesc: 'Kritikus helyzet esetén (kiesés vagy a szerver egy részének kritikus meghibásodása)',
            salesDepartment: 'Sales támogatás',
            phonePrefix: '36',
            phoneSupport: '80206005',
            mailSupport: 'podpora@vshosting.cz',
            mailSalesDepartment: 'obchod@vshosting.cz'
        }
    },
    de: {
        ticketsTrigger: {
            supportButton: 'Support',
            closedGroupHeading: 'Alle geschlossenen Status',
            supportAddButtonTooltip: 'Wählen Sie einen Betreff aus und fügen Sie eine neue Anfrage hinzu',
            openGroupHeading: 'Alle offenen Status',
            title: 'VSHosting Support Center',
            topicSelect: 'Wählen Sie einen Betreff aus und fügen Sie eine neue Anfrage hinzu',
            or: 'ODER',
            showHistory: 'Anfrageverlauf anzeigen',
            newTicket: 'Neue Anfrage mit Betreff <strong>{topic}</strong>',
            copyList: 'Liste der Empfänger der Informationen über die Anfrage',
            copyListDesc: 'Alle Mitteilungen über die Anfrage werden in Kopie versandt.',
            subscriptionTooltip: 'Empfänger von Informationen über die Anfrage hinzufügen',
            unwatch: 'Nicht mehr die Anfrage verfolgen',
            watch: 'Verfolgung der Anfrage beginnen',
            watchingTooltip: 'Sie verfolgen diese Anfrage und werden per E-Mail über neue Nachrichten informiert',
            notWatchingTooltip: 'Sie verfolgen diese Anfrage nicht, Sie werden nicht per E-Mail über neue Nachrichten informiert',
            technicalSupport: 'Technischer Support',
            technicalSupportDesc: 'Im Falle einer kritischen Situation (Ausfälle oder kritisches Nichtfunktionieren eines Teils des Servers)',
            salesDepartment: 'Vertriebsabteilung',
            phonePrefix: '49',
            phoneSupport: '22194899055',
            mailSupport: 'support@vshosting.de',
            mailSalesDepartment: 'vertrieb@vshosting.de'
        }
    }
};
export var wsReconnect = {
    en: {
        wsReconnect: {
            reconnecting: 'Reconnecting websocket...',
            outdated: 'The data may not be actual',
            connected: 'Connected!'
        }
    },
    cs: {
        wsReconnect: {
            reconnecting: 'Připojování websocketu...',
            outdated: 'Vaše data nemusí být aktuální',
            connected: 'Připojeno!'
        }
    },
    hu: {
        wsReconnect: {
            reconnecting: 'A websocket csatlakoztatása...',
            outdated: 'Előfordulhat, hogy az Ön adatai nem naprakészek',
            connected: 'Csatlakoztatva!'
        }
    },
    de: {
        wsReconnect: {
            reconnecting: 'Verbinden eines Websockets...',
            outdated: 'Die Daten sind möglicherweise nicht aktuell',
            connected: 'Verbunden!'
        }
    }
};
var keys = efg('ticketCopyList');
var ticketCopyList = {
    en: (_4 = {
            ticketCopyList: {
                addButton: 'Add to copy list',
                empty: '👀 No Copy E-mails added, use the form below to add the first one.',
                securedCommunication: 'Secured communication',
                addAnyway: 'Add anyway',
                secureDialog: "\n        <p>You are adding a Copy E-mail which does not belong to any company user\n          with permission to accept secured communication.</p>\n\n        <p>Because company {company} has an option of a secured communication enabled,\n          only notifications about request change with a link to the content will be sent.\n          Recipients which are not authorized to access the client zone will thus be unable to view the content.</p>\n        "
            }
        },
        // form
        _4[keys.labels.email] = 'E-mail',
        _4[keys.errors.email.email] = 'E-mail has wrong format.',
        _4[keys.errors.email.required] = 'E-mail is required.',
        _4),
    cs: (_5 = {
            ticketCopyList: {
                addButton: 'Přidat do seznamu',
                empty: '👀 Nejsou zadány žádné E-maily pro příjem kopií, použijte formulář níže pro přidání.',
                securedCommunication: 'Zabezpečená komunikace',
                addAnyway: 'Přesto přidat',
                secureDialog: "\n        <p>Do seznamu p\u0159\u00EDjemc\u016F kopie po\u017Eadavk\u016F p\u0159id\u00E1v\u00E1te email, kter\u00FD nepat\u0159\u00ED \u017E\u00E1dn\u00E9mu u\u017Eivateli firmy {company},\n          kter\u00FD je opr\u00E1vn\u011Bn\u00FD p\u0159ij\u00EDmat zabezpe\u010Denou komunikaci.</p>\n\n        <p>Vzhledem k tomu, \u017Ee m\u00E1 firma {company} nastavenou volbu zabezpe\u010Den\u00E9 komunikace,\n          budou emailem zasl\u00E1ny pouze notifikace o zm\u011Bn\u011B po\u017Eadavku s odkazem na vlastn\u00ED obsah.\n          P\u0159\u00EDjemci, kte\u0159\u00ED nejsou opr\u00E1vn\u011Bni k p\u0159\u00EDstupu do klientsk\u00E9 z\u00F3ny tedy nebudou moci obsah zobrazit.</p>\n        "
            }
        },
        // form
        _5[keys.labels.email] = 'E-mail',
        _5[keys.errors.email.email] = 'E-mail má špatný formát.',
        _5[keys.errors.email.required] = 'E-mail je vyžadován.',
        _5),
    hu: (_6 = {
            ticketCopyList: {
                addButton: 'Hozzáadás a listához',
                empty: '👀 Nincs megadva e-mail cím a másolatok fogadásához, használja az alábbi űrlapot a hozzáadáshoz.',
                securedCommunication: 'Biztonságos kommunikáció',
                addAnyway: 'Hozzáadás ennek ellenére',
                secureDialog: "\n        <p>Egy olyan e-mailt ad hozz\u00E1 a m\u00E1solatk\u00E9r\u00E9sek c\u00EDmzettjeinek list\u00E1j\u00E1hoz, amely nem tartozik a c\u00E9g {company} egyetlen\n        felhaszn\u00E1l\u00F3j\u00E1hoz sem, aki jogosult a biztons\u00E1gos kommunik\u00E1ci\u00F3 fogad\u00E1s\u00E1ra.</p>\n\n        <p>Mivel a c\u00E9gn\u00E9l {company} be van \u00E1ll\u00EDtva a biztons\u00E1gos kommunik\u00E1ci\u00F3 opci\u00F3,\n        csak a m\u00F3dos\u00EDt\u00E1si ig\u00E9nyekr\u0151l sz\u00F3l\u00F3 \u00E9rtes\u00EDt\u00E9seket k\u00FCldi el e-mailben, a t\u00E9nyleges tartalomra mutat\u00F3 hivatkoz\u00E1ssal.\n        Ez\u00E9rt azok a c\u00EDmzettek, akiknek nincs jogosults\u00E1guk az \u00FCgyf\u00E9lz\u00F3n\u00E1hoz val\u00F3 hozz\u00E1f\u00E9r\u00E9sre, nem tekinthetik meg a tartalmat.</p>\n        "
            }
        },
        // form
        _6[keys.labels.email] = 'E-mail',
        _6[keys.errors.email.email] = 'Az e-mail rossz formátumú.',
        _6[keys.errors.email.required] = 'Az e-mail szükséges.',
        _6),
    de: (_7 = {
            ticketCopyList: {
                addButton: 'Zur Liste hinzufügen',
                empty: '👀 Es sind keine E-Mail-Adressen eingetragen, um Kopien zu erhalten. Verwenden Sie das Formular unten, um eine hinzuzufügen.',
                securedCommunication: 'Sichere Kommunikation',
                addAnyway: 'Dennoch hizufügen',
                secureDialog: "\n        <p>Sie f\u00FCgen der Liste der Empf\u00E4nger von Kopien der Anfragen eine E-Mail-Adresse hinzu, die keinem Benutzer von {company} geh\u00F6rt,\n          der berechtigt ist, sichere Mitteilungen zu empfangen.</p>\n\n        <p>Da die Firma {company} die Option Sichere Kommunikation aktiviert hat,\n          werden nur Benachrichtigungen \u00FCber \u00C4nderungen der Anfrage per E-Mail mit einem Link zum eigentlichen Inhalt versandt.\n          Daher k\u00F6nnen Empf\u00E4nger, die nicht zum Zugriff auf den Kundenbereich berechtigt sind, den Inhalt nicht sehen.</p>\n        "
            }
        },
        // form
        _7[keys.labels.email] = 'E-mail',
        _7[keys.errors.email.email] = 'Die E-Mail-Adresse hat ein falsches Format.',
        _7[keys.errors.email.required] = 'E-Mail-Adresse ist erforderlich.',
        _7)
};
var ticketUnlock = {
    en: {
        ticketUnlock: {
            heading: 'Ticket is locked to a single employee',
            tooltip: 'Ticket is locked to a single employee, click to find out more and optionally unlock it.',
            desc: "\n        <p>Your ticket would be best resolved by the same employee who was previously responsible for dealing with it;\n          this will help ensure that the resolution of your request wil have greater continuity.</p>\n\n        <p>You can expect a further reply within 72 hours.</p>\n\n        <p>If you don't agree with these lock for employee who was previously responsible for dealing with your request,\n          you can always cancel the lock.<p>\n      ",
            confirmButton: 'Cancel lock'
        }
    },
    cs: {
        ticketUnlock: {
            heading: 'Požadavek zpracováván konkrétním pracovníkem',
            tooltip: 'Požadavek zpracováván konkrétním pracovníkem, klikněte pro více informací a případné odemčení.',
            desc: "\n        <p>V\u00E1\u0161 po\u017Eadavek nejl\u00E9pe vy\u0159e\u0161\u00ED pracovn\u00EDk, kter\u00FD byl dosud odpov\u011Bdn\u00FD za jeho \u0159e\u0161en\u00ED,\n          nebo\u0165 \u0159e\u0161en\u00ED po\u017Eadavku bude m\u00EDt lep\u0161\u00ED kontinuitu.</p>\n\n        <p>Odpov\u011B\u010F m\u016F\u017Eete o\u010Dek\u00E1vat do 72 hodin.</p>\n\n        <p>Pokud nesouhlas\u00EDte s t\u00EDmto z\u00E1mkem na konkr\u00E9tn\u00EDho pracovn\u00EDka, kter\u00FD byl dosud zodpov\u011Bdn\u00FD\n          za \u0159e\u0161en\u00ED Va\u0161eho po\u017Eadavku, klikn\u011Bte na n\u00ED\u017Ee uveden\u00FD odkaz. Va\u0161i \u017E\u00E1dost bude \u0159e\u0161it jin\u00FD pracovn\u00EDk.</p>\n      ",
            confirmButton: 'Zrušit uzamčení'
        }
    },
    hu: {
        ticketUnlock: {
            heading: 'Az igényt egy adott munkatárs dolgozza fel',
            tooltip: 'Az igényt egy adott munkatárs dolgozza fel, kattintson a további információkért és az esetleges feloldásért.',
            desc: "\n        <p>Az \u00D6n ig\u00E9ny\u00E9t az a munkat\u00E1rs kezeli a legjobban, aki eddig is felel\u0151s volt annak megold\u00E1s\u00E1\u00E9rt,\n          mivel az ig\u00E9ny megold\u00E1sa \u00EDgy folyamatosabb lesz.</p>\n\n        <p>A v\u00E1laszra 72 \u00F3r\u00E1n bel\u00FCl sz\u00E1m\u00EDthat.</p>\n\n        <p>Ha nem \u00E9rt egyet ezzel a z\u00E1rol\u00E1ssal az a konkr\u00E9t munkat\u00E1rs, aki eddig felel\u0151s volt\n          az ig\u00E9nye megold\u00E1s\u00E1\u00E9rt, k\u00E9rj\u00FCk, kattintson az al\u00E1bbi hivatkoz\u00E1sra. Az ig\u00E9ny\u00E9t egy m\u00E1sik munkat\u00E1rs fogja kezelni.</p>\n      ",
            confirmButton: 'Zárolás megszüntetése'
        }
    },
    de: {
        ticketUnlock: {
            heading: 'Anfrage wird von einem bestimmten Mitarbeiter bearbeitet',
            tooltip: "Anfrage wird von einem bestimmten Mitarbeiter bearbeitet.\n        Klicken Sie f\u00FCr weitere Informationen und eine m\u00F6gliche Entsperrung.",
            desc: "\n        <p>Ihre Anfrage wird am besten von dem Mitarbeiter bearbeitet, der bisher f\u00FCr die L\u00F6sung der Anfrage zust\u00E4ndig war,\n          da die L\u00F6sung der Anfrage eine bessere Kontinuit\u00E4t aufweist.</p>\n\n        <p>Sie k\u00F6nnen innerhalb von 72 Stunden mit einer Antwort rechnen.</p>\n\n        <p>Wenn Sie mit dieser Sperre f\u00FCr einen bestimmten Mitarbeiter,\n          der bisher f\u00FCr die Bearbeitung Ihrer Anfrage zust\u00E4ndig war,\n          nicht einverstanden sind, klicken Sie bitte auf den unten stehenden Link.\n          Ihre Anfrage wird von einem anderen Mitarbeiter bearbeitet.</p>\n      ",
            confirmButton: 'Sperre aufheben'
        }
    }
};
var ticketRating = {
    en: {
        ticketRating: {
            title: 'Do you feel like you request has been resolved?',
            desc: 'Please let us know how satisfied you were with the process, ticket will then be closed.',
            goodRating: 'I was satisfied',
            badRating: 'It could have went better',
            badRatingHeading: 'Please rate your satisfaction with the ticket solution',
            sendRating: 'Send rating',
            messagePlaceholder: 'Your comments and suggestions',
            speedTitle: 'Speed',
            expertiseTitle: 'Expertise',
            communicationTitle: 'Communication'
        }
    },
    cs: {
        ticketRating: {
            title: 'Považujete požadavek za vyřešený?',
            desc: 'Dejte nám, prosím, vědět, jak jste byli spokojeni s řešením, poté bude ticket uzavřen.',
            goodRating: 'Byl/a jsem spokojen/a',
            badRating: 'Mohlo to být lepší',
            badRatingHeading: 'Ohodnoťte prosím Vaši spokojenost s řešením tiketu',
            sendRating: 'Odeslat hodnocení',
            messagePlaceholder: 'Vaše poznámky a připomínky',
            speedTitle: 'Rychlost',
            expertiseTitle: 'Odbornost',
            communicationTitle: 'Komunikace'
        }
    },
    hu: {
        ticketRating: {
            title: 'Megoldottnak tekinti az igényét?',
            desc: 'Kérjük, tájékoztasson, mennyire elégedett a megoldással, ezután a ticketet lezárjuk',
            goodRating: 'Elégedett voltam',
            badRating: 'Lehetett volna jobb is',
            badRatingHeading: 'Kérjük, értékelje, mennyire elégedett a ticket megoldásával',
            sendRating: 'Értékelés elküldése',
            messagePlaceholder: 'Az Ön megjegyzései',
            speedTitle: 'Sebessége',
            expertiseTitle: 'Szakmaiság',
            communicationTitle: 'Kommunikáció'
        }
    },
    de: {
        ticketRating: {
            title: 'Betrachten Sie die Anfrage als gelöst?',
            desc: 'Bitte teilen Sie uns mit, wie zufrieden Sie mit der Lösung waren, dann wird das Ticket geschlossen.',
            goodRating: 'Ich war zufrieden',
            badRating: 'Es hätte besser sein können',
            badRatingHeading: 'Bitte bewerten Sie Ihre Zufriedenheit mit der Ticketlösung',
            sendRating: 'Bewertung absenden',
            messagePlaceholder: 'Ihre Notizen und Kommentare',
            speedTitle: 'Schnelligkeit',
            expertiseTitle: 'Kompetenz',
            communicationTitle: 'Kommunikation'
        }
    }
};
var ticketAuthorization = {
    en: {
        ticketAuthorization: {
            heading: 'Ticket needs to be authorized',
            descSingle: "This ticket was created from your E-mail and need to be authorized first.",
            descMulti: "Since your E-mail is associated with multiple clients, select which the ticket belongs to first.",
            clientSelectLabel: 'Select which client to assign the ticket to',
            authorize: 'Authorize ticket',
            assign: '& assign to {company}',
            authorizeSuccess: 'The ticket has been authorized and assigned to company of your choice.'
        }
    },
    cs: {
        ticketAuthorization: {
            heading: 'Ticket je potřeba autorizovat',
            descSingle: 'Tento ticket byl vytvořen z vašeho E-mailu a je ho potřeba nejdříve autorizovat.',
            descMulti: 'Jelikož je váš E-mail spojen s několika klienty, vyberte ke kterému ticket patří.',
            clientSelectLabel: 'Vyberte, kterému klientovi ticket přiřadit',
            authorize: 'Autorizovat ticket',
            assign: 'a přiřadit {company}',
            authorizeSuccess: 'Ticket byl autorizován a přiřazen vámi zvolené firmě.'
        }
    },
    hu: {
        ticketAuthorization: {
            heading: 'A ticket engedélyezésre szorul',
            descSingle: 'Ezt a ticketet az Ön e-mailjéből hozták létre, és először engedélyeztetni kell.',
            descMulti: 'Mivel az Ön e-mailje több ügyfélhez is kapcsolódik, kérjük, válassza ki, melyik tickethez tartozik.',
            clientSelectLabel: 'Válassza ki, melyik ügyfélhez rendelje a ticketet',
            authorize: 'Ticket engedélyezése',
            assign: 'és hozzárendelése {company}',
            authorizeSuccess: 'A ticketet engedélyeztük és hozzárendeltük az Ön által kiválasztott vállalathoz.'
        }
    },
    de: {
        ticketAuthorization: {
            heading: 'Das Ticket muss autorisiert werden',
            descSingle: 'Dieses Ticket wurde von Ihrer E-Mail-Adresse aus erstellt und muss zunächst autorisiert werden.',
            descMulti: 'Da Ihre E-Mail mit mehreren Kunden verbunden ist, wählen Sie aus, zu welchem das Ticket gehört.',
            clientSelectLabel: 'Wählen Sie den Kunden, dem das Ticket zugewiesen werden soll',
            authorize: 'Ticket autorisieren',
            assign: 'und {company} zuweisen',
            authorizeSuccess: 'Das Ticket wurde genehmigt und der Firma Ihrer Wahl zugewiesen.'
        }
    }
};
var files = {
    en: {
        files: {
            addMoreFiles: 'Add more files'
        }
    },
    cs: {
        files: {
            addMoreFiles: 'Přidat další soubory'
        }
    },
    hu: {
        files: {
            addMoreFiles: 'További fájlok hozzáadása'
        }
    },
    de: {
        files: {
            addMoreFiles: 'Weitere Dateien hinzufügen'
        }
    }
};
var paymentItem = {
    en: {
        paymentItem: {
            on: 'on '
        }
    },
    cs: {
        paymentItem: {
            on: 'dne '
        }
    },
    hu: {
        paymentItem: {
            on: 'napon '
        }
    },
    de: {
        paymentItem: {
            on: 'am '
        }
    }
};
var dashboard = {
    en: {
        dashboard: {
            meta: {
                title: 'Dashboard'
            },
            company: 'Company',
            billing: 'Billing',
            tickets: '<strong>Open</strong> Support tickets',
            services: 'Services'
        }
    },
    cs: {
        dashboard: {
            meta: {
                title: 'Dashboard'
            },
            company: 'Firma',
            billing: 'Fakturace',
            tickets: '<strong>Otevřené</strong> Požadavky',
            services: 'Služby'
        }
    },
    hu: {
        dashboard: {
            meta: {
                title: 'Dashboard'
            },
            company: 'Cég',
            billing: 'Számlázás',
            tickets: 'Hibajegyek <strong>megnyitása</strong>',
            services: 'Szolgáltatások'
        }
    },
    de: {
        dashboard: {
            meta: {
                title: 'Dashboard'
            },
            company: 'Firma',
            billing: 'Fakturierung',
            tickets: '<strong>Offene</strong> Anfragen',
            services: 'Dienste'
        }
    }
};
var serversEditPages = {
    en: {
        serversEditPages: {
            graphsTitle: 'Graphs',
            mainCategoryTitle: 'Main',
            otherCategoryTitle: 'Others',
            activeMetricsTitle: 'Active metrics',
            metricsButtonPlaceholder: 'Select graphs to show',
            noDataPlaceholder: 'Data for this graph could not be loaded, try again later please.',
            autoRefreshLabel: 'Automatic refresh'
        }
    },
    cs: {
        serversEditPages: {
            graphsTitle: 'Grafy',
            mainCategoryTitle: 'Hlavní',
            otherCategoryTitle: 'Ostatní',
            activeMetricsTitle: 'Aktivní metriky',
            metricsButtonPlaceholder: 'Vyberte které grafy zobrazit',
            noDataPlaceholder: 'Data pro tento graf nebylo možné načíst, zkuste to prosím později.',
            autoRefreshLabel: 'Automatické obnovování'
        }
    },
    hu: {
        serversEditPages: {
            graphsTitle: 'Grafikonok',
            mainCategoryTitle: 'Fő',
            otherCategoryTitle: 'Egyéb',
            activeMetricsTitle: 'Aktív mérőszámok',
            metricsButtonPlaceholder: 'Válassza ki a megjelenítendő grafikonokat',
            noDataPlaceholder: 'Az adatokat ehhez a grafikonhoz nem sikerült betölteni, kérjük, próbálja meg később újra',
            autoRefreshLabel: 'Automatikus frissítés'
        }
    },
    de: {
        serversEditPages: {
            graphsTitle: 'Diagramme',
            mainCategoryTitle: 'Primär',
            otherCategoryTitle: 'Andere',
            activeMetricsTitle: 'Aktive Metriken',
            metricsButtonPlaceholder: 'Wählen Sie die anzuzeigenden Diagramme aus',
            noDataPlaceholder: 'Die Daten für dieses Diagramm konnten nicht geladen werden. Bitte versuchen Sie es später noch einmal.',
            autoRefreshLabel: 'Automatische Aktualisierung'
        }
    }
};
var companyCard = {
    en: {
        companyCard: {
            editButton: 'Change company settings',
            serverParkAccess: 'Server Park access',
            teamMembers: 'Team members',
            seeList: 'See List',
            youAndMe: 'You and me',
            bio: "\n        You have been our client for over <strong>{days, plural, =1 {1 day} other {# days}}</strong>.\n        Right now you are using <strong>{serviceCount} of our services</strong>\n        with total of ~<strong>{instances, plural, =1 {1 instance} other {# instances}}</strong>.\n        Your average spend is about <strong>{monthlySpend}</strong> a month.\n        "
        }
    },
    cs: {
        companyCard: {
            editButton: 'Změnit nastavení firmy',
            serverParkAccess: 'Přístup do Server Parku',
            teamMembers: 'Členové týmu',
            seeList: 'Seznam',
            youAndMe: 'Vy a My',
            bio: "\n        Jste na\u0161\u00EDm klientem ji\u017E <strong>{days, plural, =1 {1 den} =2 {2 dny} =3 {3 dny} =4 {4 dny} other {# dn\u00ED}}</strong>.\n        Pou\u017E\u00EDv\u00E1te <strong>{serviceCount, plural, =1 {1 na\u0161\u00ED slu\u017Ebu} =2 {2 na\u0161e slu\u017Eby} =3 {3 na\u0161e slu\u017Eby} =4 {4 na\u0161e slu\u017Eby}\n        other {# na\u0161ich slu\u017Eeb}}</strong>\n        s celkov\u00FDm po\u010Dtem ~<strong>{instances, plural, =1 {1 instance} other {# instanc\u00ED}}</strong>.\n        M\u011Bs\u00ED\u010Dn\u011B utrat\u00EDte okolo <strong>{monthlySpend}</strong>."
        }
    },
    hu: {
        companyCard: {
            editButton: 'Beállítások módosítása',
            serverParkAccess: 'Hozzáférés a Server Park-hoz',
            teamMembers: 'Csapattagok',
            seeList: 'Megnézem',
            youAndMe: 'Ön és mi',
            bio: "\n        \u00D6n m\u00E1r az \u00FCgyfel\u00FCnk <strong>{days, plural, =1 {1 nap} =2 {2 nap} =3 {3 nap} =4 {4 nap} other {# nap}}</strong>.\n        \u00D6n <strong>{serviceCount,\n          plural,\n          =1 {1 szolg\u00E1ltat\u00E1sunkat}\n          =2 {2 szolg\u00E1ltat\u00E1sunkat}\n          =3 {3 szolg\u00E1ltat\u00E1sunkat}\n          =4 {4 szolg\u00E1ltat\u00E1sunkat}\n        other {# szolg\u00E1ltat\u00E1sunkat}\n        } </strong>\n        \u00F6sszesen ~<strong>{instances, plural, =1 {1 instanci\u00E1val} other {# instanci\u00E1val}}</strong>.\n        Havonta kb. ennyit k\u00F6lt <strong>{monthlySpend}</strong>."
        }
    },
    de: {
        companyCard: {
            editButton: 'Firmeneinstellungen ändern',
            serverParkAccess: 'Zugang zu Server Park',
            teamMembers: 'Teammitglieder',
            seeList: 'Liste',
            youAndMe: 'Sie und Wir',
            bio: "\n        Sie sind seit <strong>{days, plural, =1 {1 Tag} =2 {2 Tagen} =3 {3 Tagen} =4 {4 Tagen} other {# Tagen}}</strong> unser Kunde.\n        Sie nutzen <strong>{serviceCount, plural, =1 {1 unseren Dienst} =2 {2 unsere Dienste} =3 {3 unsere Dienste} =4 {4 unsere Dienste}\n        other {# unsere Dienste}}</strong>\n        mit einer Gesamtzahl von ~<strong>{instances, plural, =1 {1 Instanz} other {# Instanzen}}</strong>.\n        Sie geben monatlich etwa <strong>{monthlySpend}</strong> aus."
        }
    }
};
var searchTable = {
    en: {
        searchTable: {
            title: 'Title',
            description: 'Description',
            date: 'Date',
            type: 'Type'
        }
    },
    cs: {
        searchTable: {
            title: 'Název',
            description: 'Popis',
            date: 'Datum',
            type: 'Typ'
        }
    },
    hu: {
        searchTable: {
            title: 'Elnevezés',
            description: 'Leírás',
            date: 'Dátum',
            type: 'Típus',
        }
    },
    de: {
        searchTable: {
            title: 'Name',
            description: 'Beschreibung',
            date: 'Datum',
            type: 'Typ'
        }
    }
};
var searchTrigger = {
    en: {
        searchTrigger: {
            start: 'Click ',
            or: ' or press ',
            end: ' anywhere to start searching..',
            endMob: ' to start searching..',
            search: 'Search..'
        }
    },
    cs: {
        searchTrigger: {
            start: 'Klikněte na ',
            or: ' nebo stiskněte ',
            end: ' kdekoliv pro vyhledávání..',
            endMob: ' pro vyhledávání..',
            search: 'Vyhledávání..'
        }
    },
    hu: {
        searchTrigger: {
            start: 'Kattintson a ',
            or: ' ikonra vagy nyomja meg az ',
            end: ' billentyűkombinációt a kereséshez..',
            endMob: ' a kereséshez..',
            search: 'Keresés..'
        }
    },
    de: {
        searchTrigger: {
            start: 'Klicken Sie auf ',
            or: ' oder drücken Sie ',
            end: ' irgendwo zum Suchen..',
            endMob: ' zum Suchen..',
            search: 'Suche..'
        }
    }
};
var avatarCard = {
    en: {
        avatarCard: {
            gravatarTooltip: 'Profile picture can be changed at www.gravatar.com',
            clearbitTooltip: 'Profile picture can be changed at www.clearbit.com'
        }
    },
    cs: {
        avatarCard: {
            gravatarTooltip: 'Profilový obrázek lze změnit na www.gravatar.com',
            clearbitTooltip: 'Profilový obrázek lze změnit na www.clearbit.com'
        }
    },
    hu: {
        avatarCard: {
            gravatarTooltip: 'A profilkép megváltoztatható a www.gravatar.com címen',
            clearbitTooltip: 'A profilkép megváltoztatható a www.clearbit.com'
        }
    },
    de: {
        avatarCard: {
            gravatarTooltip: 'Das Profilbild kann unter www.gravatar.com geändert werden.',
            clearbitTooltip: 'Das Profilbild kann unter www.clearbit.com geändert werden'
        }
    }
};
var loginCompanySelect = {
    en: {
        loginCompanySelect: {
            title: 'Select company',
            desc: 'Your account is connected to multiple companies',
            logout: 'Logout'
        }
    },
    cs: {
        loginCompanySelect: {
            title: 'Zvolte firmu',
            desc: 'Váš účet je propojen s více firmami',
            logout: 'Odhlásit se'
        }
    },
    hu: {
        loginCompanySelect: {
            title: 'Cég kiválasztása',
            desc: 'A fiókja több vállalathoz is kapcsolódik',
            logout: 'Kijelentkezés'
        }
    },
    de: {
        loginCompanySelect: {
            title: 'Wählen Sie eine Firma',
            desc: 'Ihr Konto ist mit mehreren Firmen verknüpft',
            logout: 'Abmelden'
        }
    }
};
var services = {
    en: {
        services: {
            codes: {
                ALL_MANAGED: 'Managed services',
                MANAGED_SERVERS: 'Managed servers',
                MANAGED_CLUSTERS: 'Managed clusters',
                CLOUD_MANAGED_SERVERS: 'Cloud managed servers',
                SERVER_HOSTINGS: 'Hosted servers',
                RACK_HOSTINGS: 'Hosted racks',
                DEDICATED_SERVERS: 'Dedicated servers',
                GPU_DEDICATED_SERVER: 'GPU dedicated server',
                MANAGED_SERVER: 'Managed server',
                MANAGED_CLUSTER: 'Managed cluster',
                MANAGED_WINDOWS_SERVER: 'Managed windows server',
                MANAGED_WINDOWS_CLUSTER: 'Managed windows cluster',
                MANAGED_WINDOWS_BASIC: 'Managed windows basic',
                MANAGED_WINDOWS_CLOUD: 'Managed windows cloud',
                AWS_MANAGED_SERVER: 'AWS Managed server',
                M2_MANAGED_SERVER: 'M2 managed server',
                M2_MANAGED_CLUSTER: 'M2 managed cluster',
                CLOUD_MANAGED_SERVER: 'Cloud managed server',
                SERVER_HOSTING: 'Hosted server',
                RACK_HOSTING: 'Hosted rack',
                DEDICATED_SERVER: 'Dedicated server',
                VDS: 'VDS',
                CLOUD_DNS: 'Cloud DNS',
                CDN_APP: 'CDN',
                CLOUD_MAIL: 'Cloud Mail',
                CDN: 'F1 CDN'
            }
        }
    },
    cs: {
        services: {
            codes: {
                ALL_MANAGED: 'Managed služby',
                MANAGED_SERVERS: 'Managed servery',
                MANAGED_CLUSTERS: 'Managed clustery',
                CLOUD_MANAGED_SERVERS: 'Cloud managed servery',
                SERVER_HOSTINGS: 'Hostované servery',
                RACK_HOSTINGS: 'Hostované racky',
                DEDICATED_SERVERS: 'Dedicated servery',
                GPU_DEDICATED_SERVER: 'GPU dedicated server',
                MANAGED_SERVER: 'Managed server',
                MANAGED_CLUSTER: 'Managed cluster',
                M2_MANAGED_CLUSTER: 'M2 managed cluster',
                CLOUD_MANAGED_SERVER: 'Cloud managed server',
                M1_MANAGED_SERVER: 'M1 managed server',
                M2_MANAGED_SERVER: 'M2 managed server',
                MANAGED_WINDOWS_SERVER: 'Managed windows server',
                MANAGED_WINDOWS_CLUSTER: 'Managed windows cluster',
                MANAGED_WINDOWS_BASIC: 'Managed windows basic',
                MANAGED_WINDOWS_CLOUD: 'Managed windows cloud',
                AWS_MANAGED_SERVER: 'AWS Managed server',
                SERVER_HOSTING: 'Hostovaný server',
                RACK_HOSTING: 'Hostovaný rack',
                DEDICATED_SERVER: 'Dedicated server',
                VDS: 'VDS',
                CLOUD_DNS: 'Cloud DNS',
                CDN_APP: 'CDN',
                CLOUD_MAIL: 'Cloud Mail',
                CDN: 'F1 CDN'
            }
        }
    },
    hu: {
        services: {
            codes: {
                ALL_MANAGED: 'Managed szolgáltatások',
                MANAGED_SERVERS: 'Managed szerverek',
                MANAGED_CLUSTERS: 'Managed clusterek',
                CLOUD_MANAGED_SERVERS: 'Cloud managed szerverek',
                SERVER_HOSTINGS: 'Hostolt szerverek',
                RACK_HOSTINGS: 'Hostolt rack-ek',
                DEDICATED_SERVERS: 'Dedicated szerverek',
                GPU_DEDICATED_SERVER: 'GPU dedicated szerverek',
                MANAGED_SERVER: 'Managed server',
                MANAGED_CLUSTER: 'Managed cluster',
                M2_MANAGED_CLUSTER: 'M2 managed cluster',
                CLOUD_MANAGED_SERVER: 'Cloud managed server',
                M1_MANAGED_SERVER: 'M1 managed server',
                M2_MANAGED_SERVER: 'M2 managed server',
                MANAGED_WINDOWS_SERVER: 'Managed windows server',
                MANAGED_WINDOWS_CLUSTER: 'Managed windows cluster',
                MANAGED_WINDOWS_BASIC: 'Managed windows basic',
                MANAGED_WINDOWS_CLOUD: 'Managed windows cloud',
                SERVER_HOSTING: 'Hostolt szerver',
                RACK_HOSTING: 'Hostolt rack',
                DEDICATED_SERVER: 'Dedicated szerver',
                VDS: 'VDS',
                CLOUD_DNS: 'Cloud DNS',
                CDN_APP: 'CDN',
                CLOUD_MAIL: 'Cloud Mail',
                CDN: 'F1 CDN'
            }
        }
    },
    de: {
        services: {
            codes: {
                ALL_MANAGED: 'Managed Dienste',
                MANAGED_SERVERS: 'Managed Server',
                MANAGED_CLUSTERS: 'Managed Cluster',
                CLOUD_MANAGED_SERVERS: 'Cloud managed Server',
                SERVER_HOSTINGS: 'Gehostete Server',
                RACK_HOSTINGS: 'Gehostete Racks',
                DEDICATED_SERVERS: 'Dedizierte Server',
                GPU_DEDICATED_SERVERS: 'GPU dedizierte Server',
                MANAGED_SERVER: 'Managed server',
                MANAGED_CLUSTER: 'Managed cluster',
                M2_MANAGED_CLUSTER: 'M2 managed cluster',
                CLOUD_MANAGED_SERVER: 'Cloud managed server',
                M1_MANAGED_SERVER: 'M1 managed Server',
                M2_MANAGED_SERVER: 'M2 managed server',
                MANAGED_WINDOWS_SERVER: 'Managed windows server',
                MANAGED_WINDOWS_CLUSTER: 'Managed windows cluster',
                MANAGED_WINDOWS_BASIC: 'Managed windows basic',
                MANAGED_WINDOWS_CLOUD: 'Managed windows cloud',
                SERVER_HOSTING: 'Gehosteter Server',
                RACK_HOSTING: 'Gehostetes Rack',
                DEDICATED_SERVER: 'Dedicated server',
                VDS: 'VDS',
                CLOUD_DNS: 'Cloud DNS',
                CLOUD_MAIL: 'Cloud Mail',
                CDN: 'F1 CDN'
            }
        }
    }
};
var graphTypes = {
    en: {
        graphTypes: {
            cpuUsage: 'CPU usage',
            memoryUsage: 'Memory usage',
            ioWait: 'IO wait',
            maxDiskSpaceQuotaUsage: 'Max disk space quota usage',
            diskSpaceQuotaUsage: 'Disk space quota usage',
            diskFilesFoldersQuotaUsage: 'Disk Files Folders Quota Usage',
            networkTraffic: 'Network traffic',
            networkTrafficRelative: 'Network traffic relative',
            apacheRequests: 'Apache Requests',
            nginxRequests: 'Nginx Requests',
            mySqlQueries: 'Mysql Queries',
            mySqlRunningThreads: 'Mysql Running Threads',
            mySqlSlowQueries: 'Mysql Slow Queries',
            postgreSqlQueries: 'PostgreSql Queries',
            postgreSqlActiveBackends: 'PostgreSql Active Backends',
            redisCommands: 'Redis Commands',
            redisMemoryUsage: 'Redis Memory Usage',
            mongoOperations: 'Mongo Operations',
            memcachedRequests: 'Memcached Requests',
            memcachedMemoryUsage: 'Memcached Memory Usage',
            elasticsearchOperations: 'Elasticsearch Operations',
            elasticSearchMemoryUsage: 'Elasticsearch Memory Usage'
        }
    },
    cs: {
        graphTypes: {
            cpuUsage: 'CPU usage',
            memoryUsage: 'Memory usage',
            ioWait: 'IO wait',
            maxDiskSpaceQuotaUsage: 'Max disk space quota usage',
            diskSpaceQuotaUsage: 'Disc space quota usage',
            diskFilesFoldersQuotaUsage: 'Disk Files Folders Quota Usage',
            networkTraffic: 'Network traffic',
            networkTrafficRelative: 'Network traffic relative',
            apacheRequests: 'Apache Requests',
            nginxRequests: 'Nginx Requests',
            mySqlQueries: 'Mysql Queries',
            mySqlRunningThreads: 'Mysql Running Threads',
            mySqlSlowQueries: 'Mysql Slow Queries',
            postgreSqlQueries: 'PostgreSql Queries',
            postgreSqlActiveBackends: 'PostgreSql Active Backends',
            redisCommands: 'Redis Commands',
            redisMemoryUsage: 'Redis Memory Usage',
            mongoOperations: 'Mongo Operations',
            memcachedRequests: 'Memcached Requests',
            memcachedMemoryUsage: 'Memcached Memory Usage',
            elasticsearchOperations: 'Elasticsearch Operations',
            elasticSearchMemoryUsage: 'Elasticsearch Memory Usage'
        }
    },
    hu: {
        graphTypes: {
            cpuUsage: 'CPU usage',
            memoryUsage: 'Memory usage',
            ioWait: 'IO wait',
            maxDiskSpaceQuotaUsage: 'Max disk space quota usage',
            diskSpaceQuotaUsage: 'Disc space quota usage',
            diskFilesFoldersQuotaUsage: 'Disk Files Folders Quota Usage',
            networkTraffic: 'Network traffic',
            networkTrafficRelative: 'Network traffic relative',
            apacheRequests: 'Apache Requests',
            nginxRequests: 'Nginx Requests',
            mySqlQueries: 'Mysql Queries',
            mySqlRunningThreads: 'Mysql Running Threads',
            mySqlSlowQueries: 'Mysql Slow Queries',
            postgreSqlQueries: 'PostgreSql Queries',
            postgreSqlActiveBackends: 'PostgreSql Active Backends',
            redisCommands: 'Redis Commands',
            redisMemoryUsage: 'Redis Memory Usage',
            mongoOperations: 'Mongo Operations',
            memcachedRequests: 'Memcached Requests',
            memcachedMemoryUsage: 'Memcached Memory Usage',
            elasticsearchOperations: 'Elasticsearch Operations',
            elasticSearchMemoryUsage: 'Elasticsearch Memory Usage'
        }
    },
    de: {
        graphTypes: {
            cpuUsage: 'CPU usage',
            memoryUsage: 'Memory usage',
            ioWait: 'IO wait',
            maxDiskSpaceQuotaUsage: 'Max disk space quota usage',
            diskSpaceQuotaUsage: 'Disc space quota usage',
            diskFilesFoldersQuotaUsage: 'Disk Files Folders Quota Usage',
            networkTraffic: 'Network traffic',
            networkTrafficRelative: 'Network traffic relative',
            apacheRequests: 'Apache Requests',
            nginxRequests: 'Nginx Requests',
            mySqlQueries: 'Mysql Queries',
            mySqlRunningThreads: 'Mysql Running Threads',
            mySqlSlowQueries: 'Mysql Slow Queries',
            postgreSqlQueries: 'PostgreSql Queries',
            postgreSqlActiveBackends: 'PostgreSql Active Backends',
            redisCommands: 'Redis Commands',
            redisMemoryUsage: 'Redis Memory Usage',
            mongoOperations: 'Mongo Operations',
            memcachedRequests: 'Memcached Requests',
            memcachedMemoryUsage: 'Memcached Memory Usage',
            elasticsearchOperations: 'Elasticsearch Operations',
            elasticSearchMemoryUsage: 'Elasticsearch Memory Usage'
        }
    }
};
var paymentTypes = {
    en: {
        paymentTypes: {
            BANK_TRANSFER: 'Bank transfer',
            INTERNAL: 'Internal transfer',
            CREDIT: 'By credit'
        }
    },
    cs: {
        paymentTypes: {
            BANK_TRANSFER: 'Bankovní převod',
            INTERNAL: 'Interní převod',
            CREDIT: 'Úhrada kreditem'
        }
    },
    hu: {
        paymentTypes: {
            BANK_TRANSFER: 'Banki átutalás',
            INTERNAL: 'Belső átvitel',
            CREDIT: 'Fizetés kredittel'
        }
    },
    de: {
        paymentTypes: {
            BANK_TRANSFER: 'Banküberweisung',
            INTERNAL: 'Interne Überweisung',
            CREDIT: 'Zahlung mit Guthaben'
        }
    }
};
var paymentStatuses = {
    en: {
        paymentStatuses: {
            tooltips: {
                OK: 'Everything OK',
                AMOUNT_NOT_MATCHED: 'Amount not matched'
            }
        }
    },
    cs: {
        paymentStatuses: {
            tooltips: {
                OK: 'Vše OK',
                AMOUNT_NOT_MATCHED: 'Částka neodpovídá'
            }
        }
    },
    hu: {
        paymentStatuses: {
            tooltips: {
                OK: 'Minden rendben',
                AMOUNT_NOT_MATCHED: 'Az összeg nem egyezik'
            }
        }
    },
    de: {
        paymentStatuses: {
            tooltips: {
                OK: 'Alles OK',
                AMOUNT_NOT_MATCHED: 'Der Betrag entspricht nicht'
            }
        }
    }
};
var invoiceStatuses = {
    en: {
        invoiceStatuses: {
            tooltips: {
                PAYMENT_OVERDUE: 'Payment overdue',
                WAITING_FOR_PAYMENT: 'Waiting for payment',
                PAID: 'Paid',
                CANCELED: 'Canceled'
            }
        }
    },
    cs: {
        invoiceStatuses: {
            tooltips: {
                PAYMENT_OVERDUE: 'Po splatnosti',
                WAITING_FOR_PAYMENT: 'Čeká se na platbu',
                PAID: 'Uhrazeno',
                CANCELED: 'Zrušeno'
            }
        }
    },
    hu: {
        invoiceStatuses: {
            tooltips: {
                PAYMENT_OVERDUE: 'Esedékesség után',
                WAITING_FOR_PAYMENT: 'Fizetésre várakozik',
                PAID: 'Fizetve',
                CANCELED: 'Megszüntetve'
            }
        }
    },
    de: {
        invoiceStatuses: {
            tooltips: {
                PAYMENT_OVERDUE: 'Überfällig',
                WAITING_FOR_PAYMENT: 'Wartet auf die Zahlung',
                PAID: 'Bezahlt',
                CANCELED: 'Abgebrochen'
            }
        }
    }
};
var domainStatuses = {
    en: {
        domainStatuses: {
            tooltips: {
                NEW: 'New',
                ACTIVE: 'Active',
                WAITING_TO_TTL: 'Propagating changes to the internet',
                PUBLISHING_TO_INTERNET: 'Being publicated',
                WARNING: 'Warning',
                DOMAIN_NOT_AVAILABLE: 'Not available',
                DOMAIN_NOT_HEADING_TO_VSHOSTING: 'Not heading to vshosting',
                DELETED: 'Deleted',
                DEACTIVATED: 'Deactivated'
            }
        }
    },
    cs: {
        domainStatuses: {
            tooltips: {
                NEW: 'Nová',
                ACTIVE: 'Aktivní',
                WAITING_TO_TTL: 'Probíhá propagace změn do internetu',
                PUBLISHING_TO_INTERNET: 'Publikována',
                WARNING: 'Varování',
                DOMAIN_NOT_AVAILABLE: 'Nedostupná',
                DOMAIN_NOT_HEADING_TO_VSHOSTING: 'Směrována mimo vshosting',
                DELETED: 'Smazána',
                DEACTIVATED: 'Deaktivována'
            }
        }
    },
    hu: {
        domainStatuses: {
            tooltips: {
                NEW: 'Új',
                ACTIVE: 'Aktív',
                WAITING_TO_TTL: 'Folyamatban van a változások közzététele az interneten',
                PUBLISHING_TO_INTERNET: 'Közzétéve',
                WARNING: 'Figyelemeztetés',
                DOMAIN_NOT_AVAILABLE: 'Nem elérhető',
                DOMAIN_NOT_HEADING_TO_VSHOSTING: 'A vshostingon kívülre irányítva',
                DELETED: 'Törölve',
                DEACTIVATED: 'Deaktiválva'
            }
        }
    },
    de: {
        domainStatuses: {
            tooltips: {
                NEW: 'Neu',
                ACTIVE: 'Aktiv',
                WAITING_TO_TTL: 'Verbreitung der Änderungen im Internet ist im Gange',
                PUBLISHING_TO_INTERNET: 'Veröffentlicht',
                WARNING: 'Warnung',
                DOMAIN_NOT_AVAILABLE: 'Nicht verfügbar',
                DOMAIN_NOT_HEADING_TO_VSHOSTING: 'Außerhalb von vshosting geroutet',
                DELETED: 'Gelöscht',
                DEACTIVATED: 'Deaktiviert'
            }
        }
    }
};
var clientServiceStatuses = {
    en: {
        clientServiceStatuses: {
            tooltips: {
                ACTIVE: 'Active',
                TERMINATED: 'Terminated'
            }
        }
    },
    cs: {
        clientServiceStatuses: {
            tooltips: {
                ACTIVE: 'Aktivní',
                TERMINATED: 'Ukončená'
            }
        }
    },
    hu: {
        clientServiceStatuses: {
            tooltips: {
                ACTIVE: 'Aktív',
                TERMINATED: 'Befejezve'
            }
        }
    },
    de: {
        clientServiceStatuses: {
            tooltips: {
                ACTIVE: 'Aktiv',
                TERMINATED: 'Abgeschlossen'
            }
        }
    }
};
var graphIntervals = {
    cs: {
        graphIntervals: (_8 = {},
            _8[ServerIntervalTypes.Hours] = 'Poslední <strong>3 hodiny</strong>',
            _8[ServerIntervalTypes.Day] = 'Posledních <strong>24 hodin</strong>',
            _8[ServerIntervalTypes.Days] = 'Posledních <strong>48 hodin</strong>',
            _8[ServerIntervalTypes.Week] = 'Posledních <strong>7 dní</strong>',
            _8[ServerIntervalTypes.Month] = 'Posledních <strong>30 dní</strong>',
            _8[ServerIntervalTypes.Months] = 'Posledních <strong>60 dní</strong>',
            _8[ServerIntervalTypes.Year] = 'Posledních <strong>366 dní</strong>',
            _8)
    },
    en: {
        graphIntervals: (_9 = {},
            _9[ServerIntervalTypes.Hours] = 'Last <strong>3 hours</strong>',
            _9[ServerIntervalTypes.Day] = 'Last <strong>24 hours</strong>',
            _9[ServerIntervalTypes.Days] = 'Last <strong>48 hours</strong>',
            _9[ServerIntervalTypes.Week] = 'Last <strong>7 days</strong>',
            _9[ServerIntervalTypes.Month] = 'Last <strong>30 days</strong>',
            _9[ServerIntervalTypes.Months] = 'Last <strong>60 days</strong>',
            _9[ServerIntervalTypes.Year] = 'Last <strong>366 days</strong>',
            _9)
    },
    hu: {
        graphIntervals: (_10 = {},
            _10[ServerIntervalTypes.Hours] = 'Az utolsó <strong>3 óra</strong>',
            _10[ServerIntervalTypes.Day] = 'Az utolsó <strong>24 óra</strong>',
            _10[ServerIntervalTypes.Days] = 'Az utolsó <strong>48 óra</strong>',
            _10[ServerIntervalTypes.Week] = 'Az utolsó <strong>7 nap</strong>',
            _10[ServerIntervalTypes.Month] = 'Az utolsó <strong>30 nap</strong>',
            _10[ServerIntervalTypes.Months] = 'Az utolsó <strong>60 nap</strong>',
            _10[ServerIntervalTypes.Year] = 'Az utolsó <strong>366 nap</strong>',
            _10)
    },
    de: {
        graphIntervals: (_11 = {},
            _11[ServerIntervalTypes.Hours] = 'Letzte <strong>3 Stunden</strong>',
            _11[ServerIntervalTypes.Day] = 'Letzte <strong>24 Stunden</strong>',
            _11[ServerIntervalTypes.Days] = 'Letzte <strong>48 Stunden</strong>',
            _11[ServerIntervalTypes.Week] = 'Letzte <strong>7 Tage</strong>',
            _11[ServerIntervalTypes.Month] = 'Letzte <strong>30 Tage</strong>',
            _11[ServerIntervalTypes.Months] = 'Letzte <strong>60 Tage</strong>',
            _11[ServerIntervalTypes.Year] = 'Letzte <strong>366 Tage</strong>',
            _11)
    }
};
var bulkPayment = {
    en: {
        bulkPayment: {
            dialogTitle: 'Pay {count, plural, =1 {1 unpaid invoice} other {# unpaid invoices}}',
            overviewTitle: "\n        Pay <strong class=\"u-color--primary\">{count, plural,\n        =1 {1 unpaid</strong> invoice} other {# unpaid</strong> invoices}}\n      ",
            dialogText: "\n        You can pay off multiple invoices in one payment.\n        ID (variable symbol) stays the same for each invoice.\n        Unpaid invoices are going to be paid off in order of their creation; the oldest will be paid off first.\n      ",
            dialogTriggerButton: 'Open full transfer details',
            info: 'Info',
            toBePaid: 'To be paid',
            total: 'Total',
            id: 'ID',
            bankAccount: 'Acc.',
            bankName: 'Bank',
            desc: 'Desc.',
            creditShort: 'from credit',
            creditLong: 'will be paid from credit'
        }
    },
    cs: {
        bulkPayment: {
            dialogTitle: "\n        Uhradit {count, plural, =1 {1 nezaplacenou fakturu}\n        =2 {2 nezaplacen\u00E9 faktury} =3 {3 nezaplacen\u00E9 faktury}\n        =4 {4 nezaplacen\u00E9 faktury} other {# nezaplacen\u00FDch faktur}}\n      ",
            overviewTitle: "\n        Uhradit <strong class=\"u-color--primary\">{count, plural, =1 {1 nezaplacenou</strong> fakturu}\n        =2 {2 nezaplacen\u00E9</strong> faktury} =3 {3 nezaplacen\u00E9</strong> faktury}\n        =4 {4 nezaplacen\u00E9</strong> faktury} other {# nezaplacen\u00FDch</strong> faktur}}\n      ",
            dialogText: "\n        V r\u00E1mci jedn\u00E9 platby lze uhradit v\u00EDce faktur.\n        Variabiln\u00ED symbol je pro v\u0161echny faktury stejn\u00FD.\n        Neuhrazen\u00E9 faktury budou hrazeny v po\u0159ad\u00ED jejich vystaven\u00ED, nejstar\u0161\u00ED faktura bude uhrazena nejd\u0159\u00EDve.\n      ",
            dialogTriggerButton: 'Zobrazit informace o převodu',
            info: 'Info',
            toBePaid: 'K zaplacení',
            total: 'Celkem',
            id: 'VS',
            bankAccount: 'Účet',
            bankName: 'Banka',
            desc: 'Popis',
            creditShort: 'z kreditu',
            creditLong: 'bude uhrazeno z kreditu'
        }
    },
    hu: {
        bulkPayment: {
            dialogTitle: "\n        {count, plural, =1 {1 az esed\u00E9kes sz\u00E1ml\u00E1t}\n        =2 {2 esed\u00E9kes sz\u00E1ml\u00E1t} =3 {3 esed\u00E9kes sz\u00E1ml\u00E1t}\n        =4 {4 esed\u00E9kes sz\u00E1ml\u00E1t} other {# esed\u00E9kes sz\u00E1ml\u00E1t}}\n      ",
            overviewTitle: "\n        <strong class=\"u-color--primary\">{count, plural, =1 {1 esed\u00E9kes</strong> sz\u00E1ml\u00E1t}\n        =2 {2 esed\u00E9kes</strong> sz\u00E1ml\u00E1t} =3 {3 esed\u00E9kes</strong> sz\u00E1ml\u00E1t}\n        =4 {4 esed\u00E9kes </strong> sz\u00E1ml\u00E1t} other {# esed\u00E9kes</strong> sz\u00E1ml\u00E1t}}\n      ",
            dialogText: "\n        Egy \u00E1tutal\u00E1ssal t\u00F6bb sz\u00E1mla is kifizethet\u0151.\n        A v\u00E1ltoz\u00F3 szimb\u00F3lum minden sz\u00E1mla eset\u00E9ben ugyanaz.\n        A kintl\u00E9v\u0151 sz\u00E1ml\u00E1k kifizet\u00E9se a ki\u00E1ll\u00EDt\u00E1suk sorrendj\u00E9ben t\u00F6rt\u00E9nik, a legr\u00E9gebbi sz\u00E1mla ker\u00FCl kifizet\u00E9sre el\u0151sz\u00F6r.\n      ",
            dialogTriggerButton: 'Részletek megtekintése',
            info: 'Info',
            toBePaid: 'Fizetendő',
            total: 'Összesen',
            id: 'VS',
            bankAccount: 'Számla',
            bankName: 'Bank',
            desc: 'Leírás',
            creditShort: 'a kreditből',
            creditLong: 'kreditből kerül kifizetésre'
        }
    },
    de: {
        bulkPayment: {
            dialogTitle: "\n        Bezahlen {count, plural, =1 {1 offene Rechnung}\n        =2 {2 offene Rechnungen} =3 {3 offene Rechnungen}\n        =4 {4 offene Rechnungen} other {# offene Rechnungen}}\n      ",
            overviewTitle: "\n        Bezahlen <strong class=\"u-color--primary\">{count, plural, =1 {1 offene</strong> Rechnung}\n        =2 {2 offene</strong> Rechnungen} =3 {3 offene</strong> Rechnungen}\n        =4 {4 offene</strong> Rechnungen} other {# offene</strong> Rechnungen}}\n      ",
            dialogText: "\n        Mehrere Rechnungen k\u00F6nnen mit einer Zahlung beglichen werden.\n        Das Kassenzeichen ist f\u00FCr alle Rechnungen gleich.\n        Offene Rechnungen werden in der Reihenfolge ihrer Ausstellung bezahlt, wobei die \u00E4lteste Rechnung zuerst bezahlt wird.\n      ",
            dialogTriggerButton: 'Informationen zur Überweisung anzeigen',
            info: 'Info',
            toBePaid: 'Zur Bezahlung',
            total: 'Summe',
            id: 'VS',
            bankAccount: 'Konto',
            bankName: 'Bank',
            desc: 'Beschreibung',
            creditShort: 'mit dem Guthaben',
            creditLong: 'wird mit dem Guthaben bezahlt'
        }
    }
};
var domainRoutingInfoDialog = {
    en: {
        domainRoutingInfoDialog: {
            dialogTitle: 'Domain routing',
            isCzDomainText: "You need to set NSSET <strong>NSSID: VSHOSTING</strong> in your registrar's {domainName} domain settings.\n        This will ensure the proper domain {domainName} routing to CloudDNS.",
            nonCzDomainText: "The following NS records must be set in the {domainName} domain settings at your registrar:\n        <br><strong>ns.vshosting.cz</strong>\n        <br><strong>ns.vshosting.cloud</strong>\n        <br><strong>ns.vshosting.eu</strong>\n        <br><br>This will ensure the proper domain {domainName} routing to CloudDNS.",
            additionalText: "If you had an active domain {domainName} DNSSEC at your current provider\n        it is necessary to deactivate it before changing the settings, otherwise the whole domain may not work.\n        If you are not sure of the correct settings, contact our",
            support: '~vshosting support'
        }
    },
    cs: {
        domainRoutingInfoDialog: {
            dialogTitle: 'Nasměrování domény',
            isCzDomainText: "V nastaven\u00ED dom\u00E9ny {domainName} u Va\u0161eho registr\u00E1tora je t\u0159eba nastavit NSSET <strong>NSSID:VSHOSTING</strong>.\n        T\u00EDm bude zaji\u0161t\u011Bno nasm\u011Brov\u00E1n\u00ED dom\u00E9ny {domainName} na CloudDNS.",
            nonCzDomainText: "V nastaven\u00ED dom\u00E9ny {domainName} u Va\u0161eho registr\u00E1tora je t\u0159eba nastavit n\u00E1sleduj\u00EDc\u00ED NS z\u00E1znamy:\n        <br><strong>ns.vshosting.cz</strong>\n        <br><strong>ns.vshosting.cloud</strong>\n        <br><strong>ns.vshosting.eu</strong>\n        <br><br>T\u00EDm bude zaji\u0161t\u011Bno nasm\u011Brov\u00E1n\u00ED dom\u00E9ny {domainName} na CloudDNS.",
            additionalText: "Pokud jste m\u011Bli u st\u00E1vaj\u00EDc\u00EDho poskytovatele aktivn\u00ED DNSSEC u dom\u00E9ny {domainName}\n        je t\u0159eba p\u0159ed zm\u011Bnou nastaven\u00ED u p\u016Fvodn\u00EDho poskytovatele DNSSEC deaktivovat, jinak hroz\u00ED nefunk\u010Dnost cel\u00E9 dom\u00E9ny.\n        Pokud si nejste jisti spr\u00E1vn\u00FDm nastaven\u00EDm, kontaktujte na\u0161i",
            support: '~vshosting podporu'
        }
    },
    hu: {
        domainRoutingInfoDialog: {
            dialogTitle: 'A domain irányítása',
            isCzDomainText: "A domai {domainName} be\u00E1ll\u00EDt\u00E1s\u00E1iban a regisztr\u00E1tor\u00E1n\u00E1l be kell \u00E1ll\u00EDtania az NSSET <strong>NSSID:VSHOSTING</strong>.\n        Ez biztos\u00EDtja, hogy a {domainName} domain a CloudDNS-re ir\u00E1nyuljon.",
            nonCzDomainText: "A domai {domainName} be\u00E1ll\u00EDt\u00E1s\u00E1iban a regisztr\u00E1tor\u00E1n\u00E1l be kell \u00E1ll\u00EDtania a k\u00F6vetkez\u0151 NS bejegyz\u00E9seket:\n        <br><strong>ns.vshosting.cz</strong>\n        <br><strong>ns.vshosting.cloud</strong>\n        <br><strong>ns.vshosting.eu</strong>\n        <br><br>Ez biztos\u00EDtja, hogy a {domainName} domain a CloudDNS-re ir\u00E1ny\u00EDt\u00E1s\u00E1t.",
            additionalText: "Ha a megl\u00E9v\u0151 szolg\u00E1ltat\u00F3j\u00E1n\u00E1l akt\u00EDv volt a DNSSEC a {domainName} domainn\u00E9l\n        a be\u00E1ll\u00EDt\u00E1sok m\u00F3dos\u00EDt\u00E1sa el\u0151tt deaktiv\u00E1lja a DNSSEC-et, k\u00FCl\u00F6nben a teljes domain m\u0171k\u00F6d\u00E9sk\u00E9ptelenn\u00E9 v\u00E1lhat.\n        Ha nem biztos a helyes be\u00E1ll\u00EDt\u00E1sokban, k\u00E9rj\u00FCk, l\u00E9pjen kapcsolatba a",
            support: '~vshosting támogatással'
        }
    },
    de: {
        domainRoutingInfoDialog: {
            dialogTitle: 'Domain-Routing',
            isCzDomainText: "In den Domaineinstellungen von {domainName} Ihres Registrars\n        m\u00FCssen Sie das NSSET <strong>NSSID:VSHOSTING</strong> setzen.\n        Dadurch wird die korrekte Weiterleitung der Domain {domainName} an CloudDNS sichergestellt.",
            nonCzDomainText: "In den Domaineneinstellungen von {domainName} Ihres Registrars m\u00FCssen Sie die folgenden NS-Eintr\u00E4ge festlegen:\n        <br><strong>ns.vshosting.cz</strong>\n        <br><strong>ns.vshosting.cloud</strong>\n        <br><strong>ns.vshosting.eu</strong>\n        <br><br>Dadurch wird die korrekte Weiterleitung der Domain {domainName} an CloudDNS sichergestellt.",
            additionalText: "Wenn Sie DNSSEC f\u00FCr die Domain {domainName} bei Ihrem\n        derzeitigen Provider aktiviert hatten,\n        m\u00FCssen Sie DNSSEC deaktivieren, bevor Sie die Einstellungen beim urspr\u00FCnglichen Provider\n        \u00E4ndern, da sonst die gesamte Domain nicht mehr funktionsf\u00E4hig sein kann.\n        Wenn Sie sich \u00FCber die korrekten Einstellungen nicht sicher sind, wenden Sie sich bitte an unseren",
            Support: '~vshosting-Support'
        }
    }
};
var ipListOerviewTrigger = {
    en: {
        ipListOverviewTrigger: {
            button: 'Show IP addresses',
            empty: 'Server has no IP address'
        }
    },
    cs: {
        ipListOverviewTrigger: {
            button: 'Zobrazit IP adresy',
            empty: 'Server nemá žádnou IP adresu'
        }
    },
    hu: {
        ipListOverviewTrigger: {
            button: 'Extra IP címek',
            empty: 'A szervernek nincs IP-címe'
        }
    },
    de: {
        ipListOverviewTrigger: {
            button: 'IP-Adressen anzeigen',
            empty: 'Der Server hat keine IP-Adresse'
        }
    }
};
var creditStatus = {
    en: {
        creditStatus: {
            tooltip: 'Credit of company',
            credit: 'Your credit: '
        },
    },
    cs: {
        creditStatus: {
            tooltip: 'Stav kreditu společnosti',
            credit: 'Váš kredit: '
        }
    },
    hu: {
        creditStatus: {
            tooltip: 'A társaság kredit státusza',
            credit: 'A Ön kreditje: '
        }
    },
    de: {
        creditStatus: {
            tooltip: 'Guthabenstand der Firma',
            credit: 'Ihr Guthaben: '
        }
    }
};
var serversBase = {
    en: {
        serversBase: {
            graphMetrics: {
                usage_idle: 'Usage',
                bytes_recv: 'Bytes recieved',
                bytes_sent: 'Bytes sent',
                commands_delete: 'Deletes',
                commands_insert: 'Inserts',
                commands_replace: 'Replaces',
                commands_select: 'Selects',
                commands_update: 'Updates',
                commands_per_sec: 'Commands/s',
                deletes_per_sec: 'Deletes/s',
                flushes_per_sec: 'Flushes/s',
                getmores_per_sec: 'Getmores/s',
                inserts_per_sec: 'Inserts/s',
                page_faults_per_sec: 'Page faults/s',
                queries_per_sec: 'Queries/s',
                cmd_get: 'Get',
                cmd_set: 'Set',
                BusyWorkers: 'Busy workers',
                TotalAccesses: 'Total accesses',
                waiting: 'Waiting',
                requests: 'Total requests',
                get_total: 'Get total',
                indexing_delete_total: 'Indexing delete total',
                indexing_index_total: 'Indexing index total',
                search_query_total: 'Search query total'
            },
            ip4AddSuccessSnack: 'IPv4 address {ip} was successfully added.',
            ip6AddSuccessSnack: 'IPv6 address {ip} was successfully added.',
            ipDeleteSuccessSnack: 'IP address {ip} was successfully removed.',
            ptrSaveSuccessSnack: 'PTR for {ip} was successfully updated.',
        }
    },
    cs: {
        serversBase: {
            graphMetrics: {
                usage_idle: 'Usage',
                bytes_recv: 'Bytes recieved',
                bytes_sent: 'Bytes sent',
                commands_delete: 'Deletes',
                commands_insert: 'Inserts',
                commands_replace: 'Replaces',
                commands_select: 'Selects',
                commands_update: 'Updates',
                commands_per_sec: 'Commands/s',
                deletes_per_sec: 'Deletes/s',
                flushes_per_sec: 'Flushes/s',
                getmores_per_sec: 'Getmores/s',
                inserts_per_sec: 'Inserts/s',
                page_faults_per_sec: 'Page faults/s',
                queries_per_sec: 'Queries/s',
                cmd_get: 'Get',
                cmd_set: 'Set',
                BusyWorkers: 'Čekající requesty',
                waiting: 'Čekající requesty',
                TotalAccesses: 'Počet requestů',
                requests: 'Počet requestů',
                get_total: 'Get total',
                indexing_delete_total: 'Indexing delete total',
                indexing_index_total: 'Indexing index total',
                search_query_total: 'Search query total'
            },
            ip4AddSuccessSnack: 'IPv4 adresa {ip} byla úspěšně přidána.',
            ip6AddSuccessSnack: 'IPv6 adresa {ip} byla úspěšně přidána.',
            ipDeleteSuccessSnack: 'IP adresa {ip} byla úspěšně smazána.',
            ptrSaveSuccessSnack: 'PTR pro {ip} bylo úspěšně nastaveno.',
        }
    },
    hu: {
        serversBase: {
            graphMetrics: {
                usage_idle: 'Használat',
                bytes_recv: 'Fogadott bájtok',
                bytes_sent: 'Küldött bájtok',
                commands_delete: 'Törlések',
                commands_insert: 'Beillesztések',
                commands_replace: 'Cserék',
                commands_select: 'Kijelölések',
                commands_update: 'Frissítések',
                commands_per_sec: 'Parancsok',
                deletes_per_sec: 'Törlések',
                flushes_per_sec: 'Öblítések',
                getmores_per_sec: 'Getmores/s',
                inserts_per_sec: 'Beillesztések',
                page_faults_per_sec: 'Oldalhibák',
                queries_per_sec: 'Lekérdezések',
                cmd_get: 'Beszerzés',
                cmd_set: 'Beállítás',
                BusyWorkers: 'Várakozó visszaigazolások',
                waiting: 'Várakozó visszaigazolások',
                TotalAccesses: 'Visszaigazolások száma',
                requests: 'Visszaigazolások száma',
                get_total: 'Összesen',
                indexing_delete_total: 'Indexelés törlés összesen',
                indexing_index_total: 'Indexelés index összesen',
                search_query_total: 'Keresési lekérdezés összesen'
            },
            ip4AddSuccessSnack: 'IPv4 címet {ip} sikeresen hozzáadták.',
            ip6AddSuccessSnack: 'IPv6 címet {ip} sikeresen hozzáadták.',
            ipDeleteSuccessSnack: 'IP címet {ip} sikeresen törölték.',
            ptrSaveSuccessSnack: 'PTR az {ip}-hez sikeresen beállították.',
        }
    },
    de: {
        serversBase: {
            graphMetrics: {
                usage_idle: 'Usage',
                bytes_recv: 'Bytes recieved',
                bytes_sent: 'Bytes sent',
                commands_delete: 'Deletes',
                commands_insert: 'Inserts',
                commands_replace: 'Replaces',
                commands_select: 'Selects',
                commands_update: 'Updates',
                commands_per_sec: 'Commands/s',
                deletes_per_sec: 'Deletes/s',
                flushes_per_sec: 'Flushes/s',
                getmores_per_sec: 'Getmores/s',
                inserts_per_sec: 'Inserts/s',
                page_faults_per_sec: 'Page faults/s',
                queries_per_sec: 'Queries/s',
                cmd_get: 'Get',
                cmd_set: 'Set',
                BusyWorkers: 'Wartende Anfragen',
                waiting: 'Wartende Anfragen',
                TotalAccesses: 'Anzahl der Anfragen',
                requests: 'Anzahl der Anfragen',
                get_total: 'Get total',
                indexing_delete_total: 'Indexing delete total',
                indexing_index_total: 'Indexing index total',
                search_query_total: 'Search query total'
            },
            ip4AddSuccessSnack: 'Die IPv4-Adresse {ip} wurde erfolgreich hinzugefügt.',
            ip6AddSuccessSnack: 'Die IPv6-Adresse {ip} wurde erfolgreich hinzugefügt.',
            ipDeleteSuccessSnack: 'Die IP-Adresse {ip} wurde erfolgreich gelöscht.',
            ptrSaveSuccessSnack: 'PTR für {ip} wurde erfolgreich gesetzt.',
        }
    }
};
// @translationDefinition:app
export var TRANSLATIONS = {
    en: tslib_1.__assign({}, ipListOerviewTrigger.en, loginCompanySelect.en, bulkPayment.en, domainRoutingInfoDialog.en, paymentStatuses.en, invoiceStatuses.en, clientServiceStatuses.en, paymentTypes.en, menuItems.en, billingCard.en, servicesCard.en, invoiceItem.en, clientServiceItem.en, dashboard.en, searchTrigger.en, avatarCard.en, searchTable.en, companyCard.en, files.en, paymentItem.en, userInfoFormDefault.en, passwordFormDefault.en, userCardDefault.en, ticketState.en, ticketList.en, ticketsCard.en, ticketUnlock.en, ticketTextFormDefault.en, priceOfferRejectFormDefault.en, realizationDateRejectFormDefault.en, ticketTopics.en, ticketsMessage.en, ticketsTrigger.en, wsReconnect.en, ticketsFilter.en, ticketCopyList.en, ticketRating.en, ticketAuthorization.en, ticketAddForm.en, ticketAddContainer.en, ticketsCommon.en, graphTypes.en, ticketBase.en, search.en, services.en, graphIntervals.en, serversBase.en, serversEditPages.en, COMMON.en, authBase.en, loggedUser.en, recaptcha.en, API_ERROR_CODES.en, domainStatuses.en, domainsBase.en, creditStatus.en),
    cs: tslib_1.__assign({}, ipListOerviewTrigger.cs, loginCompanySelect.cs, bulkPayment.cs, domainRoutingInfoDialog.cs, paymentStatuses.cs, invoiceStatuses.cs, clientServiceStatuses.cs, paymentTypes.cs, menuItems.cs, billingCard.cs, servicesCard.cs, invoiceItem.cs, clientServiceItem.cs, dashboard.cs, searchTrigger.cs, avatarCard.cs, searchTable.cs, companyCard.cs, files.cs, paymentItem.cs, userInfoFormDefault.cs, passwordFormDefault.cs, userCardDefault.cs, ticketState.cs, ticketList.cs, ticketsCard.cs, ticketUnlock.cs, ticketTextFormDefault.cs, priceOfferRejectFormDefault.cs, realizationDateRejectFormDefault.cs, ticketTopics.cs, ticketsMessage.cs, ticketsTrigger.cs, wsReconnect.cs, ticketsFilter.cs, ticketCopyList.cs, ticketRating.cs, ticketAuthorization.cs, ticketAddForm.cs, ticketAddContainer.cs, ticketsCommon.cs, graphTypes.cs, ticketBase.cs, search.cs, services.cs, graphIntervals.cs, serversBase.cs, serversEditPages.cs, COMMON.cs, authBase.cs, loggedUser.cs, recaptcha.cs, API_ERROR_CODES.cs, domainStatuses.cs, domainsBase.cs, creditStatus.cs),
    hu: tslib_1.__assign({}, ipListOerviewTrigger.hu, loginCompanySelect.hu, bulkPayment.hu, domainRoutingInfoDialog.hu, paymentStatuses.hu, invoiceStatuses.hu, clientServiceStatuses.hu, paymentTypes.hu, menuItems.hu, billingCard.hu, servicesCard.hu, invoiceItem.hu, clientServiceItem.hu, dashboard.hu, searchTrigger.hu, avatarCard.hu, searchTable.hu, companyCard.hu, files.hu, paymentItem.hu, userInfoFormDefault.hu, passwordFormDefault.hu, userCardDefault.hu, ticketState.hu, ticketList.hu, ticketsCard.hu, ticketUnlock.hu, ticketTextFormDefault.hu, priceOfferRejectFormDefault.hu, realizationDateRejectFormDefault.hu, ticketTopics.hu, ticketsMessage.hu, ticketsTrigger.hu, wsReconnect.hu, ticketsFilter.hu, ticketCopyList.hu, ticketRating.hu, ticketAuthorization.hu, ticketAddForm.hu, ticketAddContainer.hu, ticketsCommon.hu, graphTypes.hu, ticketBase.hu, search.hu, services.hu, graphIntervals.hu, serversBase.hu, serversEditPages.hu, COMMON.hu, authBase.hu, loggedUser.hu, recaptcha.hu, API_ERROR_CODES.hu, domainStatuses.hu, domainsBase.hu, creditStatus.hu),
    de: tslib_1.__assign({}, ipListOerviewTrigger.de, loginCompanySelect.de, bulkPayment.de, domainRoutingInfoDialog.de, paymentStatuses.de, invoiceStatuses.de, clientServiceStatuses.de, paymentTypes.de, menuItems.de, billingCard.de, servicesCard.de, invoiceItem.de, clientServiceItem.de, dashboard.de, searchTrigger.de, avatarCard.de, searchTable.de, companyCard.de, files.de, paymentItem.de, userInfoFormDefault.de, passwordFormDefault.de, userCardDefault.de, ticketState.de, ticketList.de, ticketsCard.de, ticketUnlock.de, ticketTextFormDefault.de, priceOfferRejectFormDefault.de, realizationDateRejectFormDefault.de, ticketTopics.de, ticketsMessage.de, ticketsTrigger.de, wsReconnect.de, ticketsFilter.de, ticketCopyList.de, ticketRating.de, ticketAuthorization.de, ticketAddForm.de, ticketAddContainer.de, ticketsCommon.de, graphTypes.de, ticketBase.de, search.de, services.de, graphIntervals.de, serversBase.de, serversEditPages.de, COMMON.de, authBase.de, loggedUser.de, recaptcha.de, API_ERROR_CODES.de, domainStatuses.de, domainsBase.de, creditStatus.de),
};
